import axios from '../shared/axios';
import { IApiResponse, IMessage } from '../types/api';

const PREFIX = "/comment";

export function getComments({
  page,
  companyId,
  label,
}: {
  page: number;
  companyId?: string;
  label?: string;
}) {
  return axios.get<IApiResponse<IMessage[]>>(`${PREFIX}/comment`, {
    params: {
      company: companyId,
      page: page,
      label: label,
    },
  });
}

export function createComment({
  companyId,
  commentTitle,
  comment,
  label,
  from,
  to,
}: {
  companyId: string;
  commentTitle: string;
  comment: string;
  label?: string;
  from?: number;
  to?: number;
}) {
  return axios.post<IApiResponse<{ commentId: string }>>(`${PREFIX}/comment`, {
    company: companyId,
    title: commentTitle,
    comment: comment,
    label: label || undefined,
    from: from || undefined,
    to: to || undefined,
  });
}

export function updateComment({
  companyId,
  commentId,
  commentTitle,
  comment,
  label,
  from,
  to,
}: {
  companyId: string;
  commentId: string;
  commentTitle: string;
  comment: string;
  label?: string;
  from?: number;
  to?: number;
}) {
  return axios.put<IApiResponse<{ commentId: string }>>(`${PREFIX}/comment`, {
    company: companyId,
    id: commentId,
    title: commentTitle,
    comment: comment,
    label: label || undefined,
    from: from || undefined,
    to: to || undefined,
  });
}

export function deleteComment({
  commentId,
}: {
  commentId: string;
}) {
  return axios.delete(`${PREFIX}/comment`, {
    params: {
      commentId: commentId,
    }
  });
}

export function getCommentSeenList(commentId: string) {
  return axios.get<
    IApiResponse<{
      commentId: string;
      notices: {
        userId: string;
        seen: boolean;
        lastSeen: string;
        email: string;
        firstName?: string;
        lastName?: string;
      }[];
    }>
  >(`${PREFIX}/commentSeenList/${commentId}`);
}
