import { CSSProperties } from "react";
import "./BetaFeature.scss";

interface IBetaFeatureProps {
  style?: CSSProperties;
}

function BetaFeature(props: IBetaFeatureProps) {
  return (
    <div className="beta-feature text-m-m space-grotesk" style={props.style}>
      Feature in Beta
    </div>
  );
}

export default BetaFeature;
