import { convertToNumberWithSpaces } from "../../../shared/utility";
import "./BalanceReportSubrow.scss";

interface IBalanceReportSubrowProps {
  title: string;
  ib: number;
  ub: number;
  isBold?: boolean;
}

function BalanceReportSubrow(props: IBalanceReportSubrowProps) {
  return (
    <tr
      className={`balance-report-subrow ${
        props.isBold ? "text-s-m" : "text-s-r"
      }`}
    >
      <td className="title">{props.title}</td>
      <td>{convertToNumberWithSpaces(props.ib)}</td>
      <td>{convertToNumberWithSpaces(props.ub - props.ib)}</td>
      <td>{convertToNumberWithSpaces(props.ub)}</td>
    </tr>
  );
}

export default BalanceReportSubrow;
