import {
  fromUnixTime,
  getUnixTime,
  lastDayOfMonth,
  lightFormat,
  startOfMonth,
  subMonths,
} from "date-fns";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SESSION_STORAGE_KEY } from "../../shared/enums";
import { months, years } from "../../shared/values";
import { IMessage } from "../../types/api";
import Button from "../UI/Button/Button";
import Dropdown from "../UI/Dropdown/Dropdown";
import "./MessageEdit.scss";

interface IMessageEditProps {
  message?: IMessage;
  onSave: (data: {
    id: string;
    title: string;
    comment: string;
    from: number;
    to: number;
  }) => void;
  onCreate: (data: {
    title: string;
    comment: string;
    from: number;
    to: number;
  }) => void;
  onDelete: (id: string) => void;
  isSaving?: boolean;
  isDeleting?: boolean;
}

function MessageEdit(props: IMessageEditProps) {
  const [title, setTitle] = useState("");
  const [comment, setComment] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  useEffect(() => {
    const reportData = sessionStorage.getItem(SESSION_STORAGE_KEY.ReportData);

    if (props.message) return;

    if (reportData) {
      const alerts: { description: string }[] = reportData
        ? JSON.parse(reportData)
        : [];

      let content = "";

      for (let i = 0; i < alerts.length; i++) {
        const notice = alerts[i];
        content += `${notice.description}\n\n`;
      }

      setComment(content);
    }
  }, [props.message]);

  useEffect(() => {
    if (props.message) {
      setTitle(props.message.title);
      setComment(props.message.comment);

      const from = fromUnixTime(props.message.range.from);
      const formatted = lightFormat(from, "yyyy-M");
      const [year, month] = formatted.split("-");

      setYear(year);
      setMonth(month);
    } else {
      const today = new Date();

      const formatted = lightFormat(subMonths(today, 1), "yyyy-M");
      const [year, month] = formatted.split("-");

      setYear(year);
      setMonth(month);
    }
  }, [props.message]);

  function submitHandler() {
    const from = getUnixTime(startOfMonth(new Date(+year, +month - 1)));
    const to = getUnixTime(lastDayOfMonth(fromUnixTime(from)));

    if (!title) {
      toast.error("Du måste ange en titel", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }

    if (!comment) {
      toast.error("Du måste ange en brödtext", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }

    if (props.message) {
      props.onSave({
        id: props.message!._id,
        title,
        comment,
        from,
        to,
      });
      return;
    }

    props.onCreate({
      title,
      comment,
      from,
      to,
    });
  }

  return (
    <div className="message-edit">
      <form
        className="text-m-r"
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Titel"
        />
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Brödtext"
        />
        <div className="bottom">
          <div className="dropdowns">
            <Dropdown
              title="År"
              onSelect={setYear}
              value={year}
              options={years()}
              width="150px"
              fixedMenu
              short
            />
            <Dropdown
              title="Månad"
              onSelect={setMonth}
              value={month}
              options={months}
              width="150px"
              fixedMenu
              short
            />
          </div>
          <Button
            label={props.message ? "Spara" : "Skapa"}
            isLoading={props.isSaving}
            color="black"
            short
          />
        </div>
      </form>
    </div>
  );
}

export default MessageEdit;
