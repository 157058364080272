import { isSameMonth } from "date-fns";
import { useEffect, useState } from "react";
import BudgetBulkPopup from "../../../containers/BudgetBulkPopup/BudgetBulkPopup";
import { isDateAfter, stringToDate } from "../../../shared/utility";
import { IKeyString, RowType } from "../../../types/internal";
import DotsButton from "../../UI/DotsButton/DotsButton";
import BudgetSheetCell from "../BudgetSheetCell/BudgetSheetCell";
import "./BudgetSheetRow.scss";

interface IBudgetSheetRowProps {
  title: string;
  orginalTitle: string;
  accountRange: {
    from: number;
    to: number;
  };
  row: IKeyString<number>;
  type: RowType;
  onValueChanged: (data: {
    year: number;
    month: number;
    value: number;
    title: string;
  }) => void;
  onTitleChanged: (oldTitle: string, newTitle: string) => void;
  onRemove: (title: string) => void;
  isComparing?: boolean;
  hasSaved?: boolean;
  compareRow?: IKeyString<number>;
  isResultRow?: boolean;
}

function BudgetSheetRow(props: IBudgetSheetRowProps) {
  const [title, setTitle] = useState(props.title);
  const [isRowPopupOpen, setIsRowPopupOpen] = useState(false);

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  const rowSum = Object.values(props.row).reduce(
    (result, value) => result + value,
    0
  );

  const compareRowSum = Object.values(props.compareRow || {}).reduce(
    (result, value) => result + value,
    0
  );

  function titleChangeHandler(newTitle: string) {
    setTitle(newTitle);
    props.onTitleChanged(props.title, newTitle);
  }

  function bulkUpdateRowHandler(newRow: IKeyString<number>) {
    Object.keys(newRow).forEach((mKey) => {
      const [year, month] = mKey.split("-");
      props.onValueChanged({
        year: +year,
        month: +month,
        value: newRow[mKey],
        title: props.title,
      });
    });
  }

  const accountText =
    props.accountRange.from === props.accountRange.to
      ? props.accountRange.from
      : `${props.accountRange.from}-${props.accountRange.to}`;

  return (
    <>
      <tr className="budget-sheet-row-title">
        <td
          colSpan={100}
          style={{
            backgroundColor:
              props.type === "income"
                ? "#DEEAFF"
                : props.type === "expense"
                ? "#FDF8FF"
                : "#E8F8F9",
          }}
        >
          <div className="content">
            {!props.isResultRow && (
              <i
                className="fa-regular fa-trash-can"
                onClick={() => props.onRemove(title)}
              />
            )}
            <input
              type="text"
              className="text-s-r"
              title={title}
              value={title}
              onChange={(e) => titleChangeHandler(e.target.value)}
              disabled={props.isResultRow}
            />
            {!props.isResultRow && (
              <span className="text-xs-m">{`${
                props.orginalTitle ? `${props.orginalTitle} - ` : ""
              } ${accountText}`}</span>
            )}
            {!props.isResultRow && (
              <DotsButton onClick={() => setIsRowPopupOpen(true)} />
            )}
          </div>
        </td>
      </tr>
      <tr className="budget-sheet-row">
        {Object.keys(props.row).map((mKey, i) => (
          <BudgetSheetCell
            key={i}
            value={props.row[mKey]}
            compareValue={
              props.isComparing ? props.compareRow?.[mKey] || 0 : undefined
            }
            isEditable={props.isResultRow ? false : true}
            isInThePast={isDateAfter(new Date(), stringToDate(mKey))}
            isCurrentMonth={isSameMonth(new Date(), stringToDate(mKey))}
            rowType={props.type}
            hasSaved={props.hasSaved}
            onEdit={(value) => {
              props.onValueChanged({
                year: +mKey.split("-")[0],
                month: +mKey.split("-")[1],
                value: value,
                title: props.title,
              });
            }}
          />
        ))}
        <BudgetSheetCell
          value={rowSum}
          compareValue={props.isComparing ? compareRowSum || 0 : undefined}
          isEditable={false}
          hasSaved={props.hasSaved}
          rowType={props.type}
        />
      </tr>
      <BudgetBulkPopup
        showPopup={isRowPopupOpen}
        onClose={() => setIsRowPopupOpen(false)}
        budgetRow={props.row}
        onUpdate={(newRow) => {
          setIsRowPopupOpen(false);
          bulkUpdateRowHandler(newRow);
        }}
      />
    </>
  );
}

export default BudgetSheetRow;
