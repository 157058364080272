import { IUnreadMessage } from "../types/api";

export function isMessageUnread(
  unreadNotifications: IUnreadMessage[],
  notificationId: string
) {
  return unreadNotifications.some(
    (notification) => notification.commentId === notificationId
  );
}
