import { ChartData, ChartDataset, ChartOptions } from "chart.js";
import { Line } from "react-chartjs-2";
import "./LineChart.scss";

interface ILineChartProps {
  labels: string[];
  datasets: ChartDataset<"line">[];
}

function LineChart(props: ILineChartProps) {
  const options: ChartOptions<"line"> = {
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    plugins: {
      tooltip: {
        backgroundColor: "#030229",
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const data: ChartData<"line"> = {
    labels: props.labels,
    datasets: props.datasets.map((ds) => ({
      ...ds,
      pointBorderColor: "#030229",
      pointBackgroundColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointBorderWidth: 2,
      pointHoverRadius: 7,
      pointHoverBorderWidth: 3,
      fill: "start",
      pointRadius: 3,
      lineTension: 0.3,
    })),
  };

  return (
    <div className="line-chart">
      <Line data={data} height="400px" options={options} />
    </div>
  );
}

export default LineChart;
