import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery } from "react-query";
import { adminGetUsers } from "../../api/admin";
import ShowingAllMessage from "../../components/ShowingAllMessage/ShowingAllMessage";
import Spinner from "../../components/UI/Spinner/Spinner";
import UserRow from "../../containers/UserRow/UserRow";
import { API_MAX_PER_PAGE } from "../../shared/enums";
import { useAdminValues } from "../../shared/hooks/useAdminValues";
import { IAdminUser, IAdminUserFilter, IAdminUserSort } from "../../types/api";
import "./AdminUsers.scss";
import AdminUsersFilter from "./AdminUsersFilter/AdminUsersFilter";

interface IAdminUsersProps {}

function AdminUsers(props: IAdminUsersProps) {
  const { setControlsComponent } = useAdminValues();

  const [userFilter, setUserFilter] = useState<IAdminUserFilter>({});

  const sort: IAdminUserSort = {
    created: -1,
  };

  const { data, isSuccess, fetchNextPage, hasNextPage } = useInfiniteQuery<
    IAdminUser[],
    unknown,
    IAdminUser[],
    ["admin-users", IAdminUserFilter, IAdminUserSort]
  >({
    queryKey: ["admin-users", userFilter, sort],
    queryFn: async ({ queryKey: [, filter, sort], pageParam = 1 }) => {
      const res = await adminGetUsers({
        page: pageParam,
        filter,
        sort,
      });

      return res.data.payload.users;
    },
    getNextPageParam: (lastPage, pages) =>
      lastPage.length < API_MAX_PER_PAGE.AdminUsers
        ? undefined
        : pages.length + 1,
  });

  useEffect(() => {
    setControlsComponent(undefined);
  }, [setControlsComponent]);

  return (
    <div className="admin-users">
      <AdminUsersFilter onFilter={setUserFilter} />
      {isSuccess ? (
        <InfiniteScroll
          dataLength={data.pages.flat().length}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          loader={<Spinner />}
          endMessage={<ShowingAllMessage text="Du visar alla användare" />}
          style={{
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
          scrollThreshold={0.5}
        >
          {data.pages.flat().map((user) => (
            <UserRow
              key={user._id}
              email={user.email}
              firstName={user.first_name}
              lastName={user.last_name}
              userId={user._id}
            />
          ))}
        </InfiniteScroll>
      ) : (
        <Spinner padding="10px" />
      )}
    </div>
  );
}

export default AdminUsers;
