import { convertToNumberWithSpacesDecimals } from "../../shared/utility";
import "./VoucherRow.scss";

interface IVoucherRowProps {
  accountNr: number;
  costCenter: string;
  description: string;
  transactionInfo: string;
  debit: number;
  credit: number;
  isRemoved: boolean;
}

function VoucherRow(props: IVoucherRowProps) {
  const voucherRowClasses = ["voucher-row"];
  if (props.isRemoved) {
    voucherRowClasses.push("removed");
  }

  return (
    <tr className={voucherRowClasses.join(" ")}>
      <td className="account">{props.accountNr}</td>
      <td>{props.costCenter}</td>
      <td>{props.description}</td>
      <td>{props.transactionInfo}</td>
      <td>{convertToNumberWithSpacesDecimals(props.debit)}</td>
      <td>{convertToNumberWithSpacesDecimals(props.credit)}</td>
    </tr>
  );
}

export default VoucherRow;
