import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import authReducer from "./slices/auth";
import companyReducer from "./slices/company";
import messageReducer from "./slices/message";
import consultReducer from "./slices/consult";
import extendedFilteringReducer from "./slices/extendedFiltering";

const rootReducer = combineReducers({
  auth: authReducer,
  company: companyReducer,
  message: messageReducer,
  consult: consultReducer,
  extendedFiltering: extendedFilteringReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  devTools: true,
});

export default store;
