import { months, years } from "../../../shared/values";
import Dropdown from "../Dropdown/Dropdown";
import "./MonthYearDropdown.scss";

type MonthYearDropdownProps = {
  monthValue: string;
  yearValue: string;
  onSelect: (month: string, year: string) => void;
  width?: string;
  short?: boolean;
  fixedMenu?: boolean;
  isLoading?: boolean;
};

function MonthYearDropdown(props: MonthYearDropdownProps) {
  function selectHandler(month: string, year: string) {
    props.onSelect(month, year);
  }

  return (
    <div className="month-year-dropdown" style={{ width: props.width || 300 }}>
      <Dropdown
        options={months}
        title="Månad"
        width="100%"
        borderRadius="40px 0 0 40px"
        value={props.monthValue}
        onSelect={(value) => selectHandler(value, props.yearValue)}
        short={props.short}
        fixedMenu={props.fixedMenu}
        isLoading={props.isLoading}
      />
      <Dropdown
        options={years()}
        title="År"
        width="100%"
        borderRadius="0 40px 40px 0"
        value={props.yearValue}
        onSelect={(value) => selectHandler(props.monthValue, value)}
        short={props.short}
        fixedMenu={props.fixedMenu}
        isLoading={props.isLoading}
      />
    </div>
  );
}

export default MonthYearDropdown;
