import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { getLicenses } from "../../api/license";
import ParentRouteNav from "../../components/ParentRouteNav/ParentRouteNav";
import Button from "../../components/UI/Button/Button";
import Dropdown from "../../components/UI/Dropdown/Dropdown";
import TextButton from "../../components/UI/TextButton/TextButton";
import CreateAccountingFirmPopup from "../../containers/CreateAccountingFirmPopup/CreateAccountingFirmPopup";
import { ROUTE, ROUTE_LABEL } from "../../shared/enums";
import { convertToDropdown } from "../../shared/utility";
import { setAgency } from "../../store/slices/consult";
import { IReduxState } from "../../types/redux";
import "./Settings.scss";

interface ISettingsProps {}

function Settings(props: ISettingsProps) {
  const { agency, consults } = useSelector(
    (state: IReduxState) => state.consult
  );
  const location = useLocation();
  const dispatch = useDispatch();

  const [controlsComponent, setControlsComponent] = useState<
    React.ReactNode | undefined
  >(undefined);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const { data: licenses, isSuccess } = useQuery({
    queryKey: "licenses",
    queryFn: async () => {
      const res = await getLicenses();
      return res.data.payload;
    },
  });

  const navItems = [
    { path: ROUTE.CompanySettings, label: ROUTE_LABEL.CompanySettings },
    /*{ path: ROUTE.ConcernSettings, label: ROUTE_LABEL.ConcernSettings },
    { path: ROUTE.GroupSettings, label: ROUTE_LABEL.GroupSettings },*/
    {
      path: ROUTE.AccountingFirmSettings,
      label: ROUTE_LABEL.AccountingFirmSettings,
    },
  ];

  return (
    <div className="settings">
      <div className="controls">
        <ParentRouteNav
          navItems={navItems.filter((ni) =>
            agency ? true : ni.path !== ROUTE.AccountingFirmSettings
          )}
          noMargin
        />
        {!agency && (
          <Button
            label={ROUTE_LABEL.AccountingFirmSettings}
            color="white"
            onClick={() => setIsPopupOpen(true)}
          />
        )}
        <div className="consult-dropdown">
          {location.pathname.includes(ROUTE.AccountingFirmSettings) &&
            agency &&
            consults.length > 1 && (
              <>
                <Dropdown
                  value={agency._id}
                  options={convertToDropdown({
                    list: consults,
                    labelKey: "name",
                    valueKey: "_id",
                  })}
                  title="Byrå"
                  onSelect={(value) =>
                    dispatch(setAgency(consults.find((c) => c._id === value)!))
                  }
                  width={200}
                  isSearchable
                />
                <TextButton
                  label="Skapa ny redovisningsbyrå"
                  onClick={() => setIsPopupOpen(true)}
                />
              </>
            )}
        </div>
        {controlsComponent}
      </div>
      <Outlet
        context={{ licenses: isSuccess ? licenses : [], setControlsComponent }}
      />
      <CreateAccountingFirmPopup
        showPopup={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
      />
    </div>
  );
}

export default Settings;
