import {
  eachMonthOfInterval,
  fromUnixTime,
  getUnixTime,
  lightFormat,
  subYears,
} from "date-fns";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getCashflowReport } from "../../api/report";
import CashflowReport from "../../components/CashflowReport/CashflowReport";
import LineChart from "../../components/Charts/LineChart/LineChart";
import FigureCard from "../../components/FigureCard/FigureCard";
import Spinner from "../../components/UI/Spinner/Spinner";
import ChartWrapper from "../../hoc/ChartWrapper/ChartWrapper";
import { useReportValues } from "../../shared/hooks/useReportValues";
import { getCashflowPosetiveAndNegative } from "../../shared/reportUtility";
import { months } from "../../shared/values";
import { ICashflowReport, ICashflowValue } from "../../types/api";
import { IThreeYearResult } from "../../types/internal";
import { IReduxState } from "../../types/redux";
import "./Cashflow.scss";

interface ICashflowProps {}

function Cashflow(props: ICashflowProps) {
  const { companyId } = useSelector((state: IReduxState) => state.company);
  const { from, to, breakUnix, setControlsComponent } = useReportValues();

  useEffect(() => {
    setControlsComponent(undefined);
  }, [setControlsComponent]);

  const { data, isLoading, isSuccess } = useQuery<
    IThreeYearResult<ICashflowReport>,
    unknown,
    IThreeYearResult<ICashflowReport>,
    ["cashflow", number, number, number, string]
  >({
    queryKey: ["cashflow", from, to, breakUnix, companyId],
    queryFn: async ({ queryKey: [, from, to, breakUnix, companyId] }) => {
      const promises = [];

      for (let i = 0; i < 3; i++) {
        promises.push(
          getCashflowReport({
            from: getUnixTime(subYears(fromUnixTime(from), i)),
            to: getUnixTime(
              subYears(fromUnixTime(i === 0 ? breakUnix : to), i)
            ),
            companyId,
          })
        );
      }

      const res = await Promise.allSettled(promises);

      const thisYear =
        res[0].status === "fulfilled"
          ? res[0].value?.data.payload
          : {
              Saldon: {},
              Resultat: {},
            };
      const prevYear =
        res[1].status === "fulfilled"
          ? res[1].value?.data.payload
          : {
              Saldon: {},
              Resultat: {},
            };
      const prevPrevYear =
        res[2].status === "fulfilled"
          ? res[2].value?.data.payload
          : {
              Saldon: {},
              Resultat: {},
            };

      return {
        thisYear,
        prevYear,
        prevPrevYear,
      };
    },
  });

  function getLineChartData(from: number, to: number, value?: ICashflowValue) {
    if (!value) return [];
    let accIB = value.IB || 0;
    const monthsArray = eachMonthOfInterval({
      start: fromUnixTime(from),
      end: fromUnixTime(to),
    }).map((d) => lightFormat(d, "yyyy-M"));

    return [
      value.IB,
      ...monthsArray.map((mKey) => {
        accIB = accIB + (value[mKey] || 0);
        return accIB;
      }),
    ];
  }

  function getChartLabel(from: number, to: number) {
    const fromYear = fromUnixTime(from).getFullYear();
    const toYear = fromUnixTime(to).getFullYear();

    if (fromYear === toYear) {
      return fromYear.toString();
    }

    return `${fromYear}/${toYear}`;
  }

  const fromMonthText = months[fromUnixTime(from).getMonth()].label.slice(0, 3);

  const toMonthText = months[fromUnixTime(breakUnix).getMonth()].label.slice(
    0,
    3
  );

  const periodText = `${fromMonthText} - ${toMonthText}`;

  const monthsArray = eachMonthOfInterval({
    start: fromUnixTime(from),
    end: fromUnixTime(to),
  }).map((d) => lightFormat(d, "yyyy-M"));

  const { positive, negative } = getCashflowPosetiveAndNegative(data?.thisYear);

  return (
    <div className="cashflow">
      {isLoading && <Spinner />}
      {isSuccess && (
        <div className="top-cards">
          <FigureCard
            title="Kassa"
            data={[
              {
                label: fromMonthText,
                value: data.thisYear.Saldon?.["UB likvida medel"].IB || 0,
              },
              {
                label: periodText,
                value:
                  data.thisYear.Saldon?.["UB likvida medel"].Förändring || 0,
              },
              {
                label: toMonthText,
                value: data.thisYear.Saldon?.["UB likvida medel"].UB || 0,
              },
            ]}
          />
          <FigureCard
            title="Vad har påverkat kassan mest negativt?"
            data={negative.slice(0, 3)}
          />
          <FigureCard
            title="Vad har påverkat kassan mest positivt?"
            data={positive.slice(0, 3)}
          />
        </div>
      )}

      {isSuccess && (
        <ChartWrapper
          leftLegend={[
            {
              label: "Kassa & Bank",
              color: "#FFB800",
              textColor: "#fff",
            },
          ]}
          rightLegend={[
            {
              label: getChartLabel(
                getUnixTime(subYears(fromUnixTime(from), 0)),
                getUnixTime(subYears(fromUnixTime(to), 0))
              ),
              color: "#FFB800",
              textColor: "#fff",
            },
            {
              label: getChartLabel(
                getUnixTime(subYears(fromUnixTime(from), 1)),
                getUnixTime(subYears(fromUnixTime(to), 1))
              ),
              color: "#FFB80099",
              textColor: "#fff",
            },
            {
              label: getChartLabel(
                getUnixTime(subYears(fromUnixTime(from), 2)),
                getUnixTime(subYears(fromUnixTime(to), 2))
              ),
              color: "#FFB80066",
              textColor: "#fff",
            },
          ]}
        >
          <LineChart
            labels={[
              "IB",
              ...monthsArray.map((mKey) =>
                months[+mKey.split("-")[1] - 1].label.slice(0, 3)
              ),
            ]}
            datasets={[
              {
                label: getChartLabel(
                  getUnixTime(subYears(fromUnixTime(from), 0)),
                  getUnixTime(subYears(fromUnixTime(to), 0))
                ),
                data: getLineChartData(
                  getUnixTime(subYears(fromUnixTime(from), 0)),
                  getUnixTime(subYears(fromUnixTime(to), 0)),
                  data.thisYear.Saldon?.["UB likvida medel"]
                ),
                borderColor: "#FFB800",
                backgroundColor: "transparent",
              },
              {
                label: getChartLabel(
                  getUnixTime(subYears(fromUnixTime(from), 1)),
                  getUnixTime(subYears(fromUnixTime(to), 1))
                ),
                data: getLineChartData(
                  getUnixTime(subYears(fromUnixTime(from), 1)),
                  getUnixTime(subYears(fromUnixTime(to), 1)),
                  data.prevYear.Saldon?.["UB likvida medel"]
                ),
                borderColor: "#FFB80099",
                backgroundColor: "transparent",
              },
              {
                label: getChartLabel(
                  getUnixTime(subYears(fromUnixTime(from), 2)),
                  getUnixTime(subYears(fromUnixTime(to), 2))
                ),
                data: getLineChartData(
                  getUnixTime(subYears(fromUnixTime(from), 2)),
                  getUnixTime(subYears(fromUnixTime(to), 2)),
                  data.prevPrevYear.Saldon?.["UB likvida medel"]
                ),
                borderColor: "#FFB80066",
                backgroundColor: "transparent",
              },
            ]}
          />
        </ChartWrapper>
      )}

      {isSuccess && <CashflowReport report={data.thisYear} />}
    </div>
  );
}

export default Cashflow;
