import Button from "../UI/Button/Button";
import "./ErrorComponent.scss";

interface IErrorComponentProps {
  error?: Error;
}

const ErrorComponent = (props: IErrorComponentProps) => {
  function reloadPageHandler() {
    window.location.reload();
  }

  return (
    <div className="error-component">
      <h1 className="title">Oops!</h1>
      <p className="sub-title">
        Något gick fel, klicka på knappen nedan för att försöka igen.
      </p>
      <Button
        color="black"
        label="Ladda om sidan"
        onClick={reloadPageHandler}
      />
      <div className="error">
        <p>{`Name: ${props.error?.name}`}</p>
        <p>{`Message: ${props.error?.message}`}</p>
        <p>{`Cause: ${props.error?.cause}`}</p>
      </div>
    </div>
  );
};

export default ErrorComponent;
