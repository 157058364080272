import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import ParentRouteNav from "../../components/ParentRouteNav/ParentRouteNav";
import { ROUTE, ROUTE_LABEL } from "../../shared/enums";
import { useSettingsValues } from "../../shared/hooks/useSettingsValues";
import "./AccountingFirmSettings.scss";

interface IAccountingFirmSettingsProps {}

function AccountingFirmSettings(props: IAccountingFirmSettingsProps) {
  const { setControlsComponent: setParentControlsComponent } =
    useSettingsValues();

  const [controlsComponent, setControlsComponent] = useState<
    React.ReactNode | undefined
  >(undefined);

  useEffect(() => {
    const navItems = [
      {
        path: ROUTE.AccountingFirmEmployees,
        label: ROUTE_LABEL.AccountingFirmEmployees,
      },
      {
        path: ROUTE.AccountingFirmCustomers,
        label: ROUTE_LABEL.AccountingFirmCustomers,
      },
      {
        path: ROUTE.AccountingFirmGroups,
        label: ROUTE_LABEL.AccountingFirmGroups,
      },
      {
        path: ROUTE.AccountingFirmProfile,
        label: ROUTE_LABEL.AccountingFirmProfile,
      },
    ];

    setParentControlsComponent(<ParentRouteNav navItems={navItems} noMargin />);
  }, [setParentControlsComponent]);

  return (
    <div className="accounting-firm-settings">
      <div className="controls">{controlsComponent}</div>
      <Outlet
        context={{
          setControlsComponent,
        }}
      />
    </div>
  );
}

export default AccountingFirmSettings;
