import {
  eachMonthOfInterval,
  endOfMonth,
  fromUnixTime,
  getUnixTime,
  startOfMonth,
  subMonths,
} from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import ParentRouteNav from "../../components/ParentRouteNav/ParentRouteNav";
import Dropdown from "../../components/UI/Dropdown/Dropdown";
import ExtendedFiltering from "../../containers/ExtendedFiltering/ExtendedFiltering";
import { QUERY_PARAM, ROUTE, ROUTE_LABEL } from "../../shared/enums";
import { useSearchQuery } from "../../shared/hooks/useSearchQuery";
import { getFinancialYearsDropdown } from "../../shared/utility";
import { months, years } from "../../shared/values";
import { setFinancialYear } from "../../store/slices/company";
import { IReduxState } from "../../types/redux";
import "./Reports.scss";

interface IReportsProps {}

function Reports(props: IReportsProps) {
  const dispatch = useDispatch();
  const {
    company: { financialYear, financialYears },
    extendedFiltering: { timePeriod, presetMonth },
  } = useSelector((state: IReduxState) => state);

  const query = useSearchQuery();

  const [breakMonth, setBreakMonth] = useState(
    `${subMonths(new Date(), 1).getMonth() + 1}`
  );

  const [fromMonth, setFromMonth] = useState("1");
  const [fromYear, setFromYear] = useState(
    subMonths(new Date(), presetMonth === "current" ? 0 : 1)
      .getFullYear()
      .toString()
  );
  const [toMonth, setToMonth] = useState(
    `${subMonths(new Date(), presetMonth === "current" ? 0 : 1).getMonth() + 1}`
  );
  const [toYear, setToYear] = useState(
    subMonths(new Date(), presetMonth === "current" ? 0 : 1)
      .getFullYear()
      .toString()
  );

  const [controlsComponent, setControlsComponent] = useState<
    React.ReactNode | undefined
  >(undefined);

  useEffect(() => {
    setToMonth(
      `${
        subMonths(new Date(), presetMonth === "current" ? 0 : 1).getMonth() + 1
      }`
    );
    setBreakMonth(
      `${
        subMonths(new Date(), presetMonth === "current" ? 0 : 1).getMonth() + 1
      }`
    );
  }, [presetMonth]);

  useEffect(() => {
    const fromQuery = query.get(QUERY_PARAM.From);
    const toQuery = query.get(QUERY_PARAM.To);
    if (!fromQuery || !toQuery) return;

    const fromQueryDate = fromUnixTime(+fromQuery);
    const toQueryDate = fromUnixTime(+toQuery);

    setFromMonth(`${fromQueryDate.getMonth() + 1}`);
    setFromYear(fromQueryDate.getFullYear().toString());
    setToMonth(`${toQueryDate.getMonth() + 1}`);
    setToYear(toQueryDate.getFullYear().toString());
    setBreakMonth(`${toQueryDate.getMonth() + 1}`);

    financialYears.forEach((fYear) => {
      if (+fromQuery >= fYear.fromDate && +toQuery <= fYear.toDate) {
        dispatch(setFinancialYear(fYear.financialYear.toString()));
      }
    });
  }, [dispatch, financialYears, query]);

  const navItems = [
    { path: ROUTE.Result, label: ROUTE_LABEL.Result },
    { path: ROUTE.Balance, label: ROUTE_LABEL.Balance },
    { path: ROUTE.CashFlow, label: ROUTE_LABEL.CashFlow },
  ];

  const fromDate = startOfMonth(fromUnixTime(financialYear.from));
  const toDate = endOfMonth(fromUnixTime(financialYear.to));

  const eachMonth = eachMonthOfInterval({ start: fromDate, end: toDate });

  const breakDates = eachMonth.filter((d) => d.getMonth() + 1 === +breakMonth);

  const breakDate = breakDates.reduce((prev, current) =>
    getUnixTime(prev) > getUnixTime(current) ? prev : current
  );

  let from = getUnixTime(fromDate);
  let to = getUnixTime(toDate);
  let breakUnix = getUnixTime(endOfMonth(breakDate));

  if (timePeriod === "custom") {
    from = getUnixTime(startOfMonth(new Date(+fromYear, +fromMonth - 1, 1)));
    to = getUnixTime(endOfMonth(new Date(+toYear, +toMonth - 1, 1)));
    breakUnix = getUnixTime(endOfMonth(new Date(+toYear, +toMonth - 1, 1)));
  }

  return (
    <div className="reports">
      <div className="controls">
        <ParentRouteNav navItems={navItems} />
        {controlsComponent}
        {timePeriod === "financial-year" ? (
          <>
            <Dropdown
              title="År"
              options={getFinancialYearsDropdown(financialYears)}
              onSelect={(v) => dispatch(setFinancialYear(v))}
              value={financialYear.identifier}
              width="200px"
            />
            <Dropdown
              title="Månad"
              options={months}
              onSelect={setBreakMonth}
              value={breakMonth}
            />
          </>
        ) : (
          <>
            <Dropdown
              title="Från år"
              options={years()}
              onSelect={setFromYear}
              value={fromYear}
            />
            <Dropdown
              title="Från månad"
              options={months}
              onSelect={setFromMonth}
              value={fromMonth}
            />
            <Dropdown
              title="Till år"
              options={years()}
              onSelect={setToYear}
              value={toYear}
            />
            <Dropdown
              title="Till månad"
              options={months}
              onSelect={(v) => {
                setToMonth(v);
                setBreakMonth(v);
              }}
              value={toMonth}
            />
          </>
        )}
        <ExtendedFiltering />
      </div>
      <Outlet
        context={{
          from,
          to,
          breakUnix,
          setControlsComponent,
        }}
      />
    </div>
  );
}

export default Reports;
