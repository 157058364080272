import { vatPeriodTypes } from '../shared/liquidity';
import { IKeyString, MessageLabel } from './internal';

export interface IApiResponse<T> {
  cached: boolean;
  cachedTime: number;
  error: boolean;
  message: string;
  payload: T;
}

export interface ICompany {
  companyId: string;
  role?: string;
  name: string;
  created: number;
  redoSetup: boolean;
  orgNr: string;
  countryCode: string;
  city: string;
  zipcode: string;
  isConcern?: boolean;
  hasIndependantAccess?: boolean;
  isCustomer: boolean;
  subscription?: {
    type?: string;
    period?: number[];
  };
}

export interface ICompanySingle {
  company: {
    city: string;
    fortnoxRefreshTokenUpdated: number;
    fortnoxRefreshToken: string;
    isFortnox?: boolean;
    isFortnoxRedo?: boolean;
    isVisma?: boolean;
    isVismaRedo?: boolean;
    name: string;
    created: number;
    zipcode: string;
    deleted: boolean;
    consultingAgency?: string;
    consultingAgencyConfirmed?: boolean;
    consultConfirmDate?: number;
    consultingAgencyInfo?: {
      name: string;
    };
    orgNr: string;
    countryCode: string;
    isConcern?: boolean;
    isCustomer: boolean;
    subscription?: {
      type?: string;
      period?: number[];
    };
    settings: ICompanySettings;
    responsibleEmployee: {
      id: string;
      email: string;
      firstName?: string;
      lastName?: string;
    };
  };
  license: {
    hasIndependantAccess?: boolean;
    licenses?: IKeyString<{
      licenseId: string;
      active: boolean;
      created: number;
      updated: number;
      timestamps: {};
    }>;
    role: "admin" | "user";
  };
}

export interface ICompanySettings {
  paymentsTermsDays: string;
  vatPeriod: vatPeriodTypes;
  amountEmployees: number;
  branch: string;
  advancedBudget: boolean;
  disabledAlerts: string[];
  fortnoxScopes?: IFortnoxScopes;
}

export interface IFortnoxScopes {
  companyInformation: boolean;
  bookKeeping: boolean;
  invoice: boolean;
  payment: boolean;
  supplierInvoice: boolean;
  customer: boolean;
  salary: boolean;
  costCenter: boolean;
  article: boolean;
  project: boolean;
}

export interface IFinancialYear {
  financialYear: number;
  fromDate: number;
  toDate: number;
}

export interface IOverviewRow {
  budget: IBudgetPeriod[];
  cached: boolean;
  cachedTime: number;
  companyId: string;
  concernId?: string;
  companyName?: string;
  concernName?: string;
  latestComments: IMessage[];
  reports: IOverviewSection;
  reportsPrevious: IOverviewSection;
  settings: ICompanySettings;
  latestVouchers: IVoucher[];
  latestNotices: number;
  amountVouchers: number;
  isConcern?: boolean;
}

export interface IOverviewSection {
  "Eget kapital": IOverviewSectionValue;
  Intäkter: IOverviewSectionValue;
  Kostnader: IOverviewSectionValue;
  "Kassa och bank": IOverviewSectionValue;
}

export interface IOverviewSectionValue {
  Intervall: number[];
  Summa: number;
}

export interface IBudgetPeriod {
  created?: number;
  updated?: number;
  date?: string;
  month: number;
  year: number;
  rows: IBudgetPeriodRow[];
}

export interface IBudgetPeriodRow {
  accountRange: { from: number; to: number };
  value: number;
  title: string;
}

export interface IMessage {
  _id: string;
  comment: string;
  companyId: string;
  userId: string;
  created: number;
  updated: number;
  title: string;
  label?: MessageLabel;
  range: {
    from: number;
    to: number;
  };
  user: {
    email: string;
    firstName?: string;
    lastName?: string;
    phoneNr?: string;
    avatar: string | null;
  };
}

export interface IResultReport {
  [key: string]: IResultReportRow;
}
export interface IResultReportRow {
  [key: string]: IResultReportSubrow;
  Intervall?: any;
  Summa: IResultReportSubrow;
}
export interface IResultReportSubrow {
  [key: string]: number;
  Intervall?: any;
}

export interface IBalanceReport {
  "Eget kapital": IBalanceReportRow;
  Skulder: IBalanceReportRow;
  Tillgångar: IBalanceReportRow;
}

export interface IBalanceReportRow {
  [key: string]: IBalanceReportValue;
  Summa: IBalanceReportValue;
}

export interface IBalanceReportValue {
  [key: string]: number;
  Förändring: number;
  IB: number;
  UB: number;
}

export interface ICashflowReport {
  Saldon: ICashflowSection;
  Resultat: ICashflowSection;
}
export interface ICashflowSection {
  [key: string]: ICashflowValue;
}

export interface ICashflowValue {
  [key: string]: number;
  Förändring: number;
  IB: number;
  UB: number;
}

export type IPeriodBalances = IKeyString<IKeyString<number>>;

export interface IAccount {
  account: number;
  description: string;
  vatCode: string | null;
  isActive: boolean;
  name?: string;
}

export type ILiquidityArray = IKeyString<number>;
export interface ILiquidityReport {
  initialLiquidity: number;
  /**Pengar på banken vid månades start*/
  liquidityMonthStart: ILiquidityArray;
  /**Intäkter ink. moms*/
  income: ILiquidityArray;
  /**Fordringsbetalningar*/
  claimIncome: ILiquidityArray;
  /**Skuldbetalningar*/
  claimCost: ILiquidityArray;
  /**Utgifter ink. moms*/
  expenses: ILiquidityArray;
  /**Momsskuld/fordran*/
  vatDept: ILiquidityArray;
  /**Lönekostnader*/
  personalCost: ILiquidityArray;
  /**Pengar på banken vid månadens slut */
  liquidityMonthEnd: ILiquidityArray;
  /**Förändring */
  liquidityDifference: ILiquidityArray;
}

export interface ILiquidityData {
  periodBalances: IPeriodBalances;
  budget: IBudgetPeriod[];
  assets: IBalanceReportRow | undefined;
  initialLiquidity: number;
  accounts: IAccount[];
}

export interface IUnreadMessage {
  _id: string;
  title: string;
  content: string;
  type: number;
  commentId: string;
  userId: string;
  companyId: string;
  created: string;
  updated: string;
  seen: boolean;
  label: MessageLabel;
}

export interface IMeUser {
  avatar: string | null;
  email: string;
  firstName?: string;
  lastName?: string;
  phoneNr?: string;
  companies: ICompany[];
}

export interface ICompanyUser {
  userId: string;
  email: string;
  firstName?: string;
  lastName?: string;
  role: "user" | "admin";
  licenses: {
    active: boolean;
    created: number;
    licenseId: string;
    updated: number;
  }[];
  isGroup: boolean;
  groupId?: string;
  name?: string;
  alias?: string;
  isVerified: boolean;
}

export interface ILicense {
  _id: string;
  description: string;
  name: string;
  price: string;
}

export interface IVariable {
  _id: string;
  global?: boolean;
  name: string;
  value: string;
  variableType: VariableType;
  context?: string;
  showInTable?: boolean;
  isPercent?: boolean;
  userId?: string;
  tags: string[];
}

export type VariableType =
  | "KeyFigure"
  | "Number"
  | "Date"
  | "Account"
  | "Alert"
  | "Range"
  | "AccountRange";

export interface IKeyValues {
  [key: string]: IKeyValuesRow;
}

export type IKeyValuesRow = IKeyString<number | string | boolean>;

export interface ICostCenter {
  _id: StringConstructor;
  companyId: StringConstructor;
  created: number;
  updated: number;
  active: boolean;
  costCenterCode: string | number;
  name?: string;
  items?: ICostCenterItemVisma[];
  description?: string;
  note?: string;
  isFortnox?: boolean;
  isVisma?: boolean;
}

export interface ICostCenterItemVisma {
  costCenterId: string;
  id: string;
  name: string;
  shortName: string;
  isActive: boolean;
  createdUtc: string;
}

export interface IProject {
  _id: string;
  companyId: string;
  projectNumber: string | number;
  created: number;
  updated: number;
  status: string;
  customerId?: string;
  customerName?: string;
  notes?: string;
  projectName?: string;
  description?: string;
  comments?: string;
  contactPerson?: string;
  projectLeader?: string;
  startDate?: string;
  endDate?: string;
  isVisma?: boolean;
  isFortnox?: boolean;
}

export interface ITag {
  _id: string;
  name: string;
  companyId: string;
}

export interface IAlert extends IVariable {
  result: string | number;
  description?: string;
}

export interface IGroup {
  groupId: string;
  name: string;
  alias: string;
  description: string;
  created: number;
  updated: number;
  owner: string;
}

export interface IGroupCompany {
  _id: string;
  name: string;
  isFortnoxRedo: boolean;
  isVismaRedo: boolean;
  created: number;
  org_nr: string;
  country_code: string;
  city: string;
  zipcode: string;
}

export interface IGroupUser {
  userId: string;
  role: string;
  first_name: string;
  last_name: string;
  email: string;
}

export interface IConsultAgency {
  _id: string;
  orgNr: string;
  address: string;
  address2: string;
  city: string;
  contactPerson: IContactPerson;
  country: string;
  created: number;
  createdBy: string;
  isActive: boolean;
  name: string;
  updated: number;
  zipcode: string;
  confirmedDate: number;
}
export interface IContactPerson {
  email: string;
  firstName: string;
  lastName: string;
  ssn: string;
}

export interface IConsultEmployee {
  _id: string;
  userId: string;
  role: string;
  seatPrice: number;
  user: {
    _id: string;
    email: string;
    first_name: string;
    last_name: string;
  };
  groups: {
    groupId: string;
    userId: string;
    role: "admin" | "user";
    group: {
      name: string;
      owner: string;
    };
  }[];
}

export interface IConsultActiveCompany {
  _id: string;
  name: string;
  permissions: {
    licenseId?: string;
    active: boolean;
    created?: number;
    updated?: number;
  }[];
  permissionsCost: number;
  consultConfirmDate: number;
  subscription: {
    type: string;
    period: number[];
  };
  responsibleEmployee?: {
    email: string;
    firstName: string;
    lastName: string;
    ssn: string;
  };
}

export interface IConsultInactiveCompany {
  _id: string;
  name: string;
  responsibleEmployee?: { firstName: string; lastName: string; ssn: string };
}

export interface IConsultSearchResult {
  id: string;
  name: string;
  orgNr: string;
}

export interface IVoucher {
  _id: number;
  companyId?: string;
  voucherNumber?: number;
  voucherSeries?: string;
  year?: number;
  approvalState?: number;
  comments?: string;
  costCenter?: string;
  description?: string;
  project?: string;
  referenceNumber?: string;
  referenceType?: string;
  transactionDate?: number;
  updated?: number;
  voucherRows?: IVoucherRow[];
}

export interface IVoucherRow {
  account: number;
  costcenter: string;
  credit: number | string;
  description: string;
  debit: number | string;
  project: string;
  removed: boolean;
  transactioninformation: string;
  quantity: number;
}

export type VoucherSortKey =
  | "transactionDate:asc"
  | "transactionDate:desc"
  | "voucherRows.account:asc"
  | "voucherRows.account:desc"
  | "voucherRows.credit:asc"
  | "voucherRows.credit:desc"
  | "voucherRows.debit:asc"
  | "voucherRows.debit:desc"
  | "year:asc"
  | "year:desc"
  | "voucherNumber:asc"
  | "voucherNumber:desc"
  | "voucherSeries:asc"
  | "voucherSeries:desc"
  | "description:asc"
  | "description:desc";

export interface IVoucherFilter {
  from?: number;
  to?: number;
  description?: string | { regex?: string; options?: string };
  voucherNumber?: string;
  voucherSeries?: string;
  financialYear?: string | number;
  account?: number;
  accountRange?: [number, number];
  descriptionRow?: string | { regex?: string; options?: string };
  credit?: number;
  debit?: number;
  /*General*/
  limit?: number;
  page?: number;
  sort?: VoucherSortKey[];
}

export interface IAdminUserFilter {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNr?: string;
}
export interface IAdminUserSort {
  firstName?: 1 | -1;
  lastName?: 1 | -1;
  created?: 1 | -1;
  updated?: 1 | -1;
}

export interface IAdminUser {
  _id: string;
  first_name?: string;
  last_name?: string;
  email: string;
}

export interface IAdminSingleUser {
  _id: string;
  created: number;
  email: string;
  invitedBy?: string;
  isLocal?: boolean;

  first_name?: string;
  last_name?: string;
  phone_nr?: string | null;

  resetedPassword?: number;

  lastLogin?: number;
  lastLoginAttempt?: number;
  lastAuthenticated?: number;
  previousLastAuthenticated?: number;

  ttl?: number;
  avatar?: string;
  isGoogle?: boolean;
  whitelist?: string[];
}

export interface ICards {
  cards: ICard[];
}

export interface ICard {
  paymentMethodId: string;
  last4: string;
  brand: string;
  country: string;
  description: string;
  expMonth: number;
  expYear: number;
  issuer: string;
  isDefault: boolean;
}

export interface ISetHealthAlert {
  healthAlertId?: string; 
  companyId: string;
  variableId: string;
  scope: HealthAlertScope;
  active: boolean;
}

export enum HealthAlertScope {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly'
}

export interface IHealthAlert {
  id: string;
  companyId: string;
  scope: string;
  active: boolean;
  created: number;
  updated: number;
  variable: {
    variableId: string;
    name: string;
    value: string;
    isPercent: boolean;
    tags: Array<any>;
  } | null;
}