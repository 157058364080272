import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAuthState } from "../../types/redux";

const initialState: IAuthState = {
  accessToken: "",
  loading: false,
  authRedirectPath: "/",
  isBackendDown: false,
  adminAccessToken: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authStart(state) {
      state.loading = true;
    },
    authSuccess(
      state,
      action: PayloadAction<{ accessToken: string; adminAccessToken?: string }>
    ) {
      state.accessToken = action.payload.accessToken;
      state.adminAccessToken = action.payload.adminAccessToken || "";
      state.loading = false;
    },
    authLogout(state) {
      state.accessToken = "";
      state.adminAccessToken = "";
    },
    adminAuthLogout(state) {
      state.adminAccessToken = "";
    },
    authSetRedirectPath(state, action: PayloadAction<string>) {
      state.authRedirectPath = action.payload;
    },
    authSetIsBackendDown(state, action: PayloadAction<boolean>) {
      state.isBackendDown = action.payload;
    },
  },
});

export const {
  authStart,
  authSuccess,
  authLogout,
  adminAuthLogout,
  authSetRedirectPath,
  authSetIsBackendDown,
} = authSlice.actions;

export default authSlice.reducer;
