import {
  eachMonthOfInterval,
  fromUnixTime,
  getUnixTime,
  lightFormat,
  startOfMonth,
  subYears,
} from "date-fns";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getBudget } from "../../api/budget";
import { getAccounts } from "../../api/company";
import { getCustomVariables, getResultReport } from "../../api/report";
import BarChart from "../../components/Charts/BarChart/BarChart";
import FigureCard from "../../components/FigureCard/FigureCard";
import ResultsReport from "../../components/ResultsReport/ResultsReport";
import Spinner from "../../components/UI/Spinner/Spinner";
import KeyValuesTable from "../../containers/KeyValuesTable/KeyValuesTable";
import ChartWrapper from "../../hoc/ChartWrapper/ChartWrapper";
import { convertBudgetToPeriodBalances } from "../../shared/budget";
import { QUERY_PARAM } from "../../shared/enums";
import { useReportValues } from "../../shared/hooks/useReportValues";
import { useSearchQuery } from "../../shared/hooks/useSearchQuery";
import {
  convertPeriodBalancesToResultReport,
  formatResultReport,
  getExpenseMonthly,
  getExpenses,
  getIncome,
  getIncomeMonthly,
  getResult,
  getResultMonthly,
} from "../../shared/reportUtility";
import { months } from "../../shared/values";
import { IAccount, IBudgetPeriod, IResultReport } from "../../types/api";
import { IThreeYearResult, RowType } from "../../types/internal";
import { IReduxState } from "../../types/redux";
import "./Result.scss";

interface IResultProps {}

function Result(props: IResultProps) {
  const {
    company: { companyId, settings, financialYear },
    extendedFiltering: { compareWith },
  } = useSelector((state: IReduxState) => state);
  const { from, to, breakUnix, setControlsComponent } = useReportValues();
  const query = useSearchQuery();

  const [chartMode, setChartMode] = useState<RowType>("income");

  useEffect(() => {
    setControlsComponent(undefined);
  }, [setControlsComponent]);

  useEffect(() => {
    const queryGraph = query.get(QUERY_PARAM.Graph);
    if (queryGraph) {
      setChartMode(queryGraph as RowType);
    }
  }, [query]);

  const { data, isLoading, isSuccess } = useQuery<
    IThreeYearResult<IResultReport>,
    unknown,
    IThreeYearResult<IResultReport>,
    ["result", number, number, string, boolean]
  >({
    queryKey: ["result", from, to, companyId, settings.advancedBudget],
    queryFn: async ({ queryKey: [, from, to, companyId, accountLevel] }) => {
      const promises = [];

      for (let i = 0; i < 3; i++) {
        promises.push(
          getResultReport({
            from: getUnixTime(subYears(fromUnixTime(from), i)),
            to: getUnixTime(subYears(fromUnixTime(to), i)),
            companyId,
            accountLevel,
          })
        );
      }

      const res = await Promise.all(promises);

      return {
        thisYear: formatResultReport(res[0].data.payload),
        prevYear: formatResultReport(res[1].data.payload),
        prevPrevYear: formatResultReport(res[2].data.payload),
      };
    },
  });

  const { data: budget } = useQuery<
    IBudgetPeriod[],
    unknown,
    IBudgetPeriod[],
    ["budget", string, number, number]
  >({
    queryKey: ["budget", companyId, from, to],
    queryFn: async ({ queryKey: [, companyId, from, to] }) => {
      const [fromYear, fromMonth] = lightFormat(
        fromUnixTime(from),
        "yyyy-M"
      ).split("-");
      const [toYear, toMonth] = lightFormat(fromUnixTime(to), "yyyy-M").split(
        "-"
      );

      const res = await getBudget({
        fromMonth,
        fromYear,
        toMonth,
        toYear,
        companyId,
      });
      return res.data.payload;
    },
    enabled: compareWith === "budget",
  });

  const { data: variables, status: variablesStatus } = useQuery({
    queryKey: ["variables", companyId],
    queryFn: async ({ queryKey: [, companyId] }) => {
      const res = await getCustomVariables(companyId);

      return res.data.payload.variables;
    },
  });

  const { data: accounts } = useQuery<
    IAccount[],
    unknown,
    IAccount[],
    ["accounts", string, string]
  >({
    queryKey: ["accounts", companyId, financialYear.identifier],
    queryFn: async ({ queryKey: [, companyId, fId] }) => {
      const res = await getAccounts({ companyId, financialYear: fId });

      return res.data.payload;
    },
  });

  function getChartLabel(from: number, to: number) {
    const fromYear = fromUnixTime(from).getFullYear();
    const toYear = fromUnixTime(to).getFullYear();

    if (fromYear === toYear) {
      return fromYear.toString();
    }

    return `${fromYear}/${toYear}`;
  }

  const breakMonthText = months[fromUnixTime(breakUnix).getMonth()].label.slice(
    0,
    3
  );

  const periodText = `${months[fromUnixTime(from).getMonth()].label.slice(
    0,
    3
  )} - ${breakMonthText}`;

  const monthsArray = eachMonthOfInterval({
    start: fromUnixTime(from),
    end: fromUnixTime(to),
  }).map((d) => lightFormat(d, "yyyy-M"));

  const chartColors = {
    income: "#5B93FF",
    expense: "#EF60FC",
    result: "#00B448",
  };

  let compareReport = {};

  if (isSuccess) {
    compareReport =
      compareWith === "budget"
        ? convertPeriodBalancesToResultReport(
            data.thisYear,
            convertBudgetToPeriodBalances(budget || [])
          )
        : data.prevYear;
  }

  return (
    <div className="result">
      {isLoading && <Spinner />}
      {isSuccess && (
        <>
          <div className="top-cards">
            <FigureCard
              title="Intäkter"
              data={[
                {
                  label: periodText,
                  value: getIncome({
                    report: data.thisYear,
                    from,
                    to: breakUnix,
                  }),
                },
                {
                  label: breakMonthText,
                  value: getIncome({
                    report: data.thisYear,
                    from: getUnixTime(startOfMonth(fromUnixTime(breakUnix))),
                    to: breakUnix,
                  }),
                },
              ]}
            />
            <FigureCard
              title="Kostnader"
              data={[
                {
                  label: periodText,
                  value: getExpenses({
                    report: data.thisYear,
                    from,
                    to: breakUnix,
                  }),
                },
                {
                  label: breakMonthText,
                  value: getExpenses({
                    report: data.thisYear,
                    from: getUnixTime(startOfMonth(fromUnixTime(breakUnix))),
                    to: breakUnix,
                  }),
                },
              ]}
            />
            <FigureCard
              title="Resultat"
              data={[
                {
                  label: periodText,
                  value: getResult({
                    report: data.thisYear,
                    from,
                    to: breakUnix,
                  }),
                },
                {
                  label: breakMonthText,
                  value: getResult({
                    report: data.thisYear,
                    from: getUnixTime(startOfMonth(fromUnixTime(breakUnix))),
                    to: breakUnix,
                  }),
                },
              ]}
            />
          </div>
          <ChartWrapper
            leftLegend={[
              {
                label: "Intäkter",
                color: chartColors.income,
                textColor: chartMode === "income" ? "#fff" : "#A6A6AF",
                onClick: () => setChartMode("income"),
              },
              {
                label: "Kostnader",
                color: chartColors.expense,
                textColor: chartMode === "expense" ? "#fff" : "#A6A6AF",
                onClick: () => setChartMode("expense"),
              },
              {
                label: "Resultat",
                color: chartColors.result,
                textColor: chartMode === "result" ? "#fff" : "#A6A6AF",
                onClick: () => setChartMode("result"),
              },
            ]}
            rightLegend={[
              {
                label: getChartLabel(from, to),
                color: chartColors[chartMode],
                textColor: "#fff",
              },
              {
                label: getChartLabel(
                  getUnixTime(subYears(fromUnixTime(from), 1)),
                  getUnixTime(subYears(fromUnixTime(to), 1))
                ),
                color: chartColors[chartMode] + "99",
                textColor: "#fff",
              },
              {
                label: getChartLabel(
                  getUnixTime(subYears(fromUnixTime(from), 2)),
                  getUnixTime(subYears(fromUnixTime(to), 2))
                ),
                color: chartColors[chartMode] + "66",
                textColor: "#fff",
              },
            ]}
          >
            <BarChart
              labels={monthsArray.map((mKey) =>
                months[+mKey.split("-")[1] - 1].label.slice(0, 3)
              )}
              datasets={[
                {
                  label: getChartLabel(from, to),
                  data:
                    chartMode === "income"
                      ? getIncomeMonthly({
                          report: data.thisYear,
                          from,
                          to,
                          breakUnix,
                        })
                      : chartMode === "expense"
                      ? getExpenseMonthly({
                          report: data.thisYear,
                          from,
                          to,
                          breakUnix,
                        })
                      : getResultMonthly({
                          report: data.thisYear,
                          from,
                          to,
                          breakUnix,
                        }),
                  backgroundColor: chartColors[chartMode],
                },
                {
                  label: getChartLabel(
                    getUnixTime(subYears(fromUnixTime(from), 1)),
                    getUnixTime(subYears(fromUnixTime(to), 1))
                  ),
                  data:
                    chartMode === "income"
                      ? getIncomeMonthly({
                          report: data.prevYear,
                          from: getUnixTime(subYears(fromUnixTime(from), 1)),
                          to: getUnixTime(subYears(fromUnixTime(to), 1)),
                        })
                      : chartMode === "expense"
                      ? getExpenseMonthly({
                          report: data.prevYear,
                          from: getUnixTime(subYears(fromUnixTime(from), 1)),
                          to: getUnixTime(subYears(fromUnixTime(to), 1)),
                        })
                      : getResultMonthly({
                          report: data.prevYear,
                          from: getUnixTime(subYears(fromUnixTime(from), 1)),
                          to: getUnixTime(subYears(fromUnixTime(to), 1)),
                        }),
                  backgroundColor: chartColors[chartMode] + "99",
                },
                {
                  label: getChartLabel(
                    getUnixTime(subYears(fromUnixTime(from), 2)),
                    getUnixTime(subYears(fromUnixTime(to), 2))
                  ),
                  data:
                    chartMode === "income"
                      ? getIncomeMonthly({
                          report: data.prevPrevYear,
                          from: getUnixTime(subYears(fromUnixTime(from), 2)),
                          to: getUnixTime(subYears(fromUnixTime(to), 2)),
                        })
                      : chartMode === "expense"
                      ? getExpenseMonthly({
                          report: data.prevPrevYear,
                          from: getUnixTime(subYears(fromUnixTime(from), 2)),
                          to: getUnixTime(subYears(fromUnixTime(to), 2)),
                        })
                      : getResultMonthly({
                          report: data.prevPrevYear,
                          from: getUnixTime(subYears(fromUnixTime(from), 2)),
                          to: getUnixTime(subYears(fromUnixTime(to), 2)),
                        }),
                  backgroundColor: chartColors[chartMode] + "66",
                },
              ]}
            />
          </ChartWrapper>

          <ResultsReport
            from={from}
            to={to}
            breakUnix={breakUnix}
            report={data.thisYear}
            compareReport={compareReport}
            compareWith={compareWith}
            accounts={accounts}
          />
          {variablesStatus === "success" && (
            <KeyValuesTable
              title="Globala nyckeltal"
              companyId={companyId}
              variables={variables.filter(
                (v) => !!v.showInTable && v.global && !v.tags.length
              )}
              from={from}
              to={to}
              breakUnix={breakUnix}
              isGlobal
            />
          )}
        </>
      )}
    </div>
  );
}

export default Result;
