import { ICashflowReport } from "../../types/api";
import "./CashflowReport.scss";
import CashflowReportHeader from "./CashflowReportHeader/CashflowReportHeader";
import CashflowReportRow from "./CashflowReportRow/CashflowReportRow";

interface ICashflowReportProps {
  report: ICashflowReport;
}

function CashflowReport(props: ICashflowReportProps) {
  const liquidityFromResult =
    (props.report.Resultat?.["Försäljningsintäkter"]?.UB || 0) -
    (props.report.Resultat?.["Material och varor"]?.UB || 0) -
    (props.report.Resultat?.["Externa kostnader"]?.UB || 0) -
    (props.report.Resultat?.["Personalkostnader"]?.UB || 0) +
    (props.report.Resultat?.["Räntekostnader/-intäkter"]?.UB || 0) -
    (props.report.Resultat?.["Skatter"]?.UB || 0);

  const accumulatedLiquidityFromMovement =
    liquidityFromResult +
    (props.report.Saldon?.["Varulager"]?.Förändring || 0) +
    (props.report.Saldon?.["Kundfordringar"]?.Förändring || 0) +
    (props.report.Saldon?.["Övriga kortfristiga fordringar"]?.Förändring || 0) +
    (props.report.Saldon?.["Leverantörsskulder"]?.Förändring || 0) +
    (props.report.Saldon?.["Övriga kortfristiga skulder"]?.Förändring || 0);

  const accumulatedLiquidiyChanges =
    accumulatedLiquidityFromMovement +
    (props.report.Saldon?.["Nettoinvesteringar"]?.Förändring || 0) +
    (props.report.Resultat?.["Avskrivningar"]?.UB || 0) +
    (props.report.Saldon?.["Nettoförändring lån"]?.Förändring || 0) +
    (props.report.Saldon?.["Nettoförändring ägare"]?.Förändring || 0);

  return (
    <div className="cashflow-report">
      <div className="top">
        <span className="title text-m-m">Kassaflödesanalys</span>
      </div>
      <table>
        <thead>
          <tr>
            <th className="title" />
            <th className="value" />
            <th className="value" />
            <th className="value" />
            <th className="icon" />
            <th className="result" />
          </tr>
        </thead>
        <tbody>
          <CashflowReportRow
            title="Försäljningsintäkter"
            result={props.report.Resultat?.["Försäljningsintäkter"]?.UB || 0}
            icon="plus"
            isBold
          />
          <CashflowReportRow
            title="Summa försäljningsintäkter"
            result={props.report.Resultat?.["Försäljningsintäkter"]?.UB || 0}
            icon="equal"
            isResult
            isBold
          />
          <CashflowReportRow
            title="Material och varor"
            result={props.report.Resultat?.["Material och varor"]?.UB || 0}
            icon="minus"
          />
          <CashflowReportRow
            title="Externa kostnader"
            result={props.report.Resultat?.["Externa kostnader"]?.UB || 0}
            icon="minus"
          />
          <CashflowReportRow
            title="Personalkostnader"
            result={props.report.Resultat?.["Personalkostnader"]?.UB || 0}
            icon="minus"
          />
          <CashflowReportRow
            title="Räntekostnader/-intäkter"
            result={
              props.report.Resultat?.["Räntekostnader/-intäkter"]?.UB || 0
            }
            icon="indiffer"
          />
          <CashflowReportRow
            title="Skatter"
            result={props.report.Resultat?.["Skatter"]?.UB || 0}
            icon="minus"
          />
          <CashflowReportRow
            title="Likviditet från resultatet"
            result={liquidityFromResult}
            icon="equal"
            isResult
            isBold
          />
          <CashflowReportHeader labels={["Ingående", "Utgående", ""]} />
          <CashflowReportRow
            title="Varulager"
            ib={props.report.Saldon?.["Varulager"]?.IB || 0}
            ub={props.report.Saldon?.["Varulager"]?.UB || 0}
            result={props.report.Saldon?.["Varulager"]?.Förändring || 0}
            icon="equal"
          />
          <CashflowReportRow
            title="Kundfodringar"
            ib={props.report.Saldon?.["Kundfodringar"]?.IB || 0}
            ub={props.report.Saldon?.["Kundfodringar"]?.UB || 0}
            result={props.report.Saldon?.["Kundfodringar"]?.Förändring || 0}
            icon="equal"
          />
          <CashflowReportRow
            title="Övriga kortfristiga fordringar"
            ib={
              props.report.Saldon?.["Övriga kortfristiga fordringar"]?.IB || 0
            }
            ub={
              props.report.Saldon?.["Övriga kortfristiga fordringar"]?.UB || 0
            }
            result={
              props.report.Saldon?.["Övriga kortfristiga fordringar"]
                ?.Förändring || 0
            }
            icon="equal"
          />
          <CashflowReportRow
            title="Leverantörsskulder"
            ib={props.report.Saldon?.["Leverantörsskulder"]?.IB || 0}
            ub={props.report.Saldon?.["Leverantörsskulder"]?.UB || 0}
            result={
              props.report.Saldon?.["Leverantörsskulder"]?.Förändring || 0
            }
            icon="equal"
          />
          <CashflowReportRow
            title="Övriga kortfristiga skulder"
            ib={props.report.Saldon?.["Övriga kortfristiga skulder"]?.IB || 0}
            ub={props.report.Saldon?.["Övriga kortfristiga skulder"]?.UB || 0}
            result={
              props.report.Saldon?.["Övriga kortfristiga skulder"]
                ?.Förändring || 0
            }
            icon="equal"
          />
          <CashflowReportRow
            title="Likviditet från resultatet"
            result={accumulatedLiquidityFromMovement - liquidityFromResult}
            icon="equal"
            isBold
          />
          <CashflowReportRow
            title="Ackumulerad likviditetsförändring"
            result={accumulatedLiquidityFromMovement}
            icon="equal"
            isResult
            isBold
          />
          <CashflowReportHeader
            labels={["Ingående", "Utgående", "Avskrivningar"]}
          />
          <CashflowReportRow
            title="Nettoinvesteringar"
            ib={props.report.Saldon?.["Nettoinvesteringar"]?.IB || 0}
            ub={props.report.Saldon?.["Nettoinvesteringar"]?.UB || 0}
            extraValue={props.report.Resultat?.["Avskrivningar"]?.UB || 0}
            result={
              props.report.Saldon?.["Nettoinvesteringar"]?.Förändring || 0
            }
            icon="equal"
          />
          <CashflowReportRow
            title="Nettoförändring lån"
            ib={props.report.Saldon?.["Nettoförändring lån"]?.IB || 0}
            ub={props.report.Saldon?.["Nettoförändring lån"]?.UB || 0}
            result={
              props.report.Saldon?.["Nettoförändring lån"]?.Förändring || 0
            }
            icon="equal"
          />
          <CashflowReportHeader labels={["Ingående", "Utgående", "Resultat"]} />
          <CashflowReportRow
            title="Nettoförändring ägare"
            ib={props.report.Saldon?.["Nettoförändring ägare"]?.IB || 0}
            ub={props.report.Saldon?.["Nettoförändring ägare"]?.UB || 0}
            result={
              props.report.Saldon?.["Nettoförändring ägare"]?.Förändring || 0
            }
            icon="equal"
          />
          <CashflowReportRow
            title="Likviditet från investeringar och lån"
            result={
              accumulatedLiquidiyChanges - accumulatedLiquidityFromMovement
            }
            icon="equal"
            isBold
          />
          <CashflowReportRow
            title="Ackumulerad likviditetsförändring"
            result={accumulatedLiquidiyChanges}
            icon="equal"
            isResult
            isBold
          />
          <CashflowReportHeader labels={["Ingående", "Utgående", ""]} />
          <CashflowReportRow
            title="UB likvida medel"
            ib={props.report.Saldon?.["UB likvida medel"]?.IB || 0}
            ub={props.report.Saldon?.["UB likvida medel"]?.UB || 0}
            result={props.report.Saldon?.["UB likvida medel"]?.Förändring || 0}
            icon="equal"
            isBold
          />
        </tbody>
      </table>
    </div>
  );
}

export default CashflowReport;
