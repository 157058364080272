import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUnreadMessage } from "../../types/api";
import { IMessageState } from "../../types/redux";

const initialState: IMessageState = {
  unread: [],
  isMessageOpen: false,
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setIsMessageOpen(state, action: PayloadAction<boolean>) {
      state.isMessageOpen = action.payload;
    },

    setUndreadMessages(state, action: PayloadAction<IUnreadMessage[]>) {
      state.unread = action.payload;
    },
    readMessage(state, action: PayloadAction<string>) {
      state.unread = state.unread.filter(
        (message) => message.commentId !== action.payload
      );
    },
  },
});

export const { setUndreadMessages, readMessage, setIsMessageOpen } =
  messageSlice.actions;

export default messageSlice.reducer;
