import { ClientJS } from "clientjs";
import { compareAsc, fromUnixTime, lightFormat } from "date-fns";
import { IFinancialYear } from "../types/api";
import { environment } from "./environment";
import { branches } from "./values";

export function convertToNumberWithSpaces(num: number, ending?: string) {
  return `${Math.round(num)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}${ending ? ending : ""}`;
}

export function convertToNumberWithSpacesDecimals(
  num: number,
  ending?: string
) {
  return `${num
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}${ending ? ending : ""}`;
}

export function shortenNumber(number: number): string {
  if (number >= 1000000 || number <= -1000000) {
    return (number / 1000000).toFixed(1) + " mkr";
  } else if (number >= 100000 || number <= -100000) {
    return (number / 1000).toFixed(0) + " tkr";
  } else {
    return convertToNumberWithSpaces(number);
  }
}

export function isOnDev() {
  return environment.appEnv === "development";
}

export function convertToDropdown<T>({
  list,
  labelKey,
  valueKey,
}: {
  list: T[];
  labelKey: keyof T;
  valueKey: keyof T;
}) {
  return list.map((item) => ({
    label: `${item[labelKey]}`,
    value: `${item[valueKey]}`,
  }));
}

export function monthISO(month: string) {
  return `0${month}`.slice(-2);
}

/**
 * Get a date from a string format. If month date is not specifide it will be set to 1
 * IOS compatible
 * @param dateString Supported formats "2020-1-1" "2020-1"
 * @returns IOS compatible date
 */
export function stringToDate(dateString: string) {
  const dateArr = dateString.split("-");
  const [year, month, day] = dateArr;
  const date = new Date(+year, +month - 1, +day || 1, 0, 0, 0, 0);
  return date;
}

/**
 * Check if a date is after another date
 * @param date The date to check if it is after the compareDate
 * @param compareDate The date to compare to
 * @returns boolean
 */
export function isDateAfter(date: Date, compareDate: Date) {
  const r = compareAsc(date, compareDate);
  return r === 1 || r === 0;
}

export function formatKeyForCompare(dateKey: string, noFormat?: boolean) {
  if (noFormat) return dateKey;

  const splittedKey = dateKey.split("-");
  return `${+splittedKey[0] - 1}-${splittedKey[1]}`;
}

export function getFinancialYearsDropdown(fYears: IFinancialYear[]) {
  return fYears.map((fy) => {
    const fromDate = fromUnixTime(fy.fromDate);
    const toDate = fromUnixTime(fy.toDate);

    const fromString = lightFormat(fromDate, "yyyy/M");
    const toString = lightFormat(toDate, "yyyy/M");

    const label = fromString + " - " + toString;

    return {
      label,
      value: `${fy.financialYear}`,
    };
  });
}

export function isMacUser() {
  const client = new ClientJS();
  return client.isMac();
}

export function isValidPhoneNumber(phoneNr: string) {
  const regexPhoneNr = /^\+?[0-9]{1,3}?[0-9]{8,12}$/;
  return regexPhoneNr.test(phoneNr);
}

export function isValidEmail(email: string) {
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regexEmail.test(email);
}

export function calculatePercentageChange(current: number, prior: number) {
  if (Math.abs(prior) < 1) return 0;

  const difference = current - prior;

  if ((current < 0 && prior > 0) || (current > 0 && prior < 0))
    return (difference / Math.abs(prior)) * 100;

  return (difference / prior) * 100;
}

export function getBranchName(sni: string) {
  return branches.find((b) => b.code === sni)?.name;
}
