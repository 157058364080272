import axios from "../shared/axios";
import { IApiResponse, ILicense } from "../types/api";

const PREFIX = "/license";

export function getLicenses() {
  return axios.get<IApiResponse<ILicense[]>>(`${PREFIX}/list`);
}

export function activateLicense({
  companyId,
  userId,
  licenseId,
  groupId,
}: {
  companyId: string;
  userId: string;
  licenseId: string;
  groupId?: string;
}) {
  return axios.post(`${PREFIX}/activate`, {
    companyId,
    userId,
    licenseId,
    groupId,
  });
}

export function deactivateLicense({
  companyId,
  userId,
  licenseId,
  groupId,
}: {
  companyId: string;
  userId: string;
  licenseId: string;
  groupId?: string;
}) {
  return axios.post(`${PREFIX}/deactivate`, {
    companyId,
    userId,
    licenseId,
    groupId,
  });
}
