import "./LegendText.scss";

export interface ILegendTextProps {
  label: string;
  color: string;
  textColor?: string;
  noDot?: boolean;
  onClick?: () => void;
}

function LegendText(props: ILegendTextProps) {
  return (
    <div
      className="legend-text text-m-r"
      style={{
        color: props.color,
        cursor: props.onClick ? "pointer" : "default",
      }}
      onClick={props.onClick}
    >
      {!props.noDot && (
        <div
          className="dot"
          style={{
            backgroundColor: props.color,
          }}
        />
      )}
      <span
        style={
          props.textColor
            ? {
                color: props.textColor,
              }
            : {}
        }
      >
        {props.label}
      </span>
    </div>
  );
}

export default LegendText;
