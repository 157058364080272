import Select, { components } from "react-select";
import "./Dropdown.scss";

interface IDropdownOption {
  value: string;
  label: string;
}

interface DropdownProps {
  options?: IDropdownOption[];
  groupedOptions?: { label: string; options: IDropdownOption[] }[];
  title: string;
  onSelect: (value: string) => void;
  value: string;
  isLoading?: boolean;
  width?: number | string;
  borderRadius?: string;
  isSearchable?: boolean;
  short?: boolean;
  fixedMenu?: boolean;
}

function Dropdown(props: DropdownProps) {
  const CustomDropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
      <i className="fa-solid fa-caret-down" />
    </components.DropdownIndicator>
  );

  let value: IDropdownOption | undefined | null;
  if (props.options) {
    value = props.options.find((option) => option.value === props.value);
  }
  if (props.groupedOptions) {
    const allOptions: IDropdownOption[] = [];
    for (let i = 0; i < props.groupedOptions.length; i++) {
      const optionArray = props.groupedOptions[i];
      allOptions.push(...optionArray.options);
    }
    value = allOptions.find((option) => option.value === props.value);
  }
  if (props.value === "") {
    value = null;
  }

  return (
    <Select
      options={props.options || props.groupedOptions}
      value={value}
      placeholder={props.title}
      loadingMessage={() => "Laddar..."}
      isLoading={props.isLoading}
      onChange={(option: any) => {
        props.onSelect(option?.value || "");
      }}
      noOptionsMessage={() => "Inga resultat"}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: CustomDropdownIndicator,
      }}
      isSearchable={
        props.isSearchable === undefined ? false : props.isSearchable
      }
      menuPosition={props.fixedMenu ? "fixed" : "absolute"}
      styles={{
        container: (base) => ({
          ...base,
          width: props.width || 125,
        }),
        control: (base) => ({
          ...base,
          width: "100%",
          color: "#000D33",
          borderRadius: props.borderRadius || "40px",
          border: "1px solid #A6A6AF",
          "&:hover": {
            border: "1px solid #A6A6AF",
          },
          minHeight: props.short ? "30px" : "38px",
          fontSize: "13px",
          fontWeight: 400,
          cursor: "pointer",
          boxShadow: "none",
        }),
        option: (base, state) => ({
          ...base,
          backgroundColor: state.isFocused ? "#EFEFFF" : "transparent",
          color: "#000D33",
          fontSize: "13px",
          fontWeight: 400,
          cursor: "pointer",
          ":active": {
            backgroundColor: "#EFEFFF",
          },
        }),
        menu: (base) => ({ ...base, zIndex: 100000 }),
        dropdownIndicator: (base, state) => ({
          ...base,
          transition: "all 200ms ease",
          transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "",
          color: "#000D33",
          "&:hover": {
            color: "#000D33",
          },
        }),
        valueContainer: (base) => ({
          ...base,
          padding: props.short ? "0 8px" : "2px 8px",
        }),
      }}
    />
  );
}

export default Dropdown;
