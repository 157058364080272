import { endOfMonth, getUnixTime, startOfMonth, subMonths } from "date-fns";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getOverview } from "../../api/report";
import ExtendedOverviewRow from "../../components/ExtendedOverviewRow/ExtendedOverviewRow";
import OverviewRow from "../../components/OverviewRow/OverviewRow";
import ShowingAllMessage from "../../components/ShowingAllMessage/ShowingAllMessage";
import Dropdown from "../../components/UI/Dropdown/Dropdown";
import Spinner from "../../components/UI/Spinner/Spinner";
import { getBudgetCost, getBudgetIncome } from "../../shared/budget";
import { API_MAX_PER_PAGE } from "../../shared/enums";
import { stringToDate } from "../../shared/utility";
import { months, years } from "../../shared/values";
import { IOverviewRow } from "../../types/api";
import { IReduxState } from "../../types/redux";
import "./Overview.scss";

interface IOverviewProps {}

function Overview(props: IOverviewProps) {
  const { companyId } = useSelector((state: IReduxState) => state.company);

  const lastMonthDate = subMonths(new Date(), 1);

  const [fromYear, setFromYear] = useState(
    lastMonthDate.getFullYear().toString()
  );
  const [toMonth, setToMonth] = useState(`${lastMonthDate.getMonth() + 1}`);

  const { data: singleOverviewData, isSuccess: isSingleOverviewSuccess } =
    useQuery<
      IOverviewRow,
      unknown,
      IOverviewRow,
      ["extended-overview", string, string, string]
    >({
      queryKey: ["extended-overview", fromYear, toMonth, companyId],
      queryFn: async ({ queryKey: [, fromYear, toMonth, companyId] }) => {
        const res = await getOverview({
          fromString: `${fromYear}-1`,
          toString: `${fromYear}-${toMonth}`,
          page: 1,
          companyId,
        });
        return res.data.payload[0];
      },
    });

  const {
    data,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery<
    IOverviewRow[],
    unknown,
    IOverviewRow[],
    ["overview", string, string]
  >({
    queryKey: ["overview", fromYear, toMonth],
    queryFn: async ({
      queryKey: [, fromYear, toMonth],
      pageParam = 1,
      signal,
    }) => {
      const res = await getOverview({
        fromString: `${fromYear}-1`,
        toString: `${fromYear}-${toMonth}`,
        page: pageParam,
      });

      return res.data.payload;
    },
    getNextPageParam: (lastPage, pages) =>
      lastPage.length < API_MAX_PER_PAGE.Overview
        ? undefined
        : pages.length + 1,
  });

  const from = getUnixTime(startOfMonth(stringToDate(`${fromYear}-1`)));
  const to = getUnixTime(endOfMonth(stringToDate(`${fromYear}-${toMonth}`)));

  return (
    <div className="overview">
      <div className="controls">
        <div className="dropdowns">
          <div>{(isLoading || isFetchingNextPage) && <Spinner />}</div>
          <Dropdown
            title="År"
            options={years()}
            onSelect={setFromYear}
            value={fromYear}
          />
          <Dropdown
            title="Månad"
            options={months}
            onSelect={setToMonth}
            value={toMonth}
          />
        </div>
      </div>
      {isSingleOverviewSuccess && (
        <ExtendedOverviewRow
          name={
            singleOverviewData.companyName ||
            singleOverviewData.concernName ||
            "Namn saknas"
          }
          income={singleOverviewData.reports.Intäkter.Summa}
          incomePrevYear={singleOverviewData.reportsPrevious.Intäkter.Summa}
          expenses={singleOverviewData.reports.Kostnader.Summa * -1}
          expensesPrevYear={
            singleOverviewData.reportsPrevious.Kostnader.Summa * -1
          }
          budgetIncome={getBudgetIncome(singleOverviewData.budget)}
          budgetExpenses={getBudgetCost(singleOverviewData.budget)}
          cashAndBank={singleOverviewData.reports["Kassa och bank"].Summa}
          capital={singleOverviewData.reports["Eget kapital"].Summa}
          capitalPrevYear={
            singleOverviewData.reportsPrevious["Kassa och bank"].Summa
          }
          cashAndBankPrevYear={
            singleOverviewData.reportsPrevious["Eget kapital"].Summa
          }
          latestMessageUnix={
            singleOverviewData.latestComments?.[0]?.created || undefined
          }
          userAvatar={
            singleOverviewData.latestComments?.[0]?.user.avatar || null
          }
          amountVouchers={singleOverviewData.amountVouchers}
          alertsThisMonth={singleOverviewData.latestNotices}
          from={from}
          to={to}
        />
      )}
      {isSuccess && (
        <InfiniteScroll
          dataLength={data.pages.flat().length}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          loader={<Spinner />}
          endMessage={<ShowingAllMessage text="Du visar alla företag" />}
          style={{ overflow: "hidden" }}
          scrollThreshold={0.5}
        >
          {data.pages
            .flat()
            .filter((r) => !r.isConcern && r.companyId !== companyId)
            .map((row) => (
              <OverviewRow
                key={row.companyId || row.concernId}
                name={row.companyName || row.concernName || "Namn saknas"}
                companyId={row.companyId || row.concernId || "Id saknas"}
                income={row.reports.Intäkter.Summa}
                expenses={row.reports.Kostnader.Summa * -1}
                budgetIncome={getBudgetIncome(row.budget)}
                budgetExpenses={getBudgetCost(row.budget)}
                cashAndBank={row.reports["Kassa och bank"].Summa}
                capital={row.reports["Eget kapital"].Summa}
                branch={row.settings.branch}
                employees={row.settings.amountEmployees}
                from={from}
                to={to}
              />
            ))}
        </InfiniteScroll>
      )}
    </div>
  );
}

export default Overview;
