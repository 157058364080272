import { fromUnixTime, isWithinInterval } from "date-fns";
import * as jsondiffpatch from "jsondiffpatch";
import {
  IAccount,
  IBudgetPeriod,
  IPeriodBalances,
  IResultReport,
} from "../types/api";
import { IKeyString } from "../types/internal";
import { ECONOMIC_TERM, LOCAL_STORAGE_KEY } from "./enums";
import { stringToDate } from "./utility";
import { budgetExpenseRowTypes, budgetIncomeRowTypes } from "./values";

export function convertBudgetToPeriodBalances(budget: IBudgetPeriod[]) {
  const budgetPbs: IPeriodBalances = {};
  budget.forEach((budgetPeriod) => {
    budgetPeriod.rows.forEach((row) => {
      if (!budgetPbs[row.accountRange.from])
        budgetPbs[row.accountRange.from] = {};

      if (
        !budgetPbs[row.accountRange.from][
          `${budgetPeriod.year}-${budgetPeriod.month}`
        ]
      )
        budgetPbs[row.accountRange.from][
          `${budgetPeriod.year}-${budgetPeriod.month}`
        ] = 0;

      budgetPbs[row.accountRange.from][
        `${budgetPeriod.year}-${budgetPeriod.month}`
      ] += row.value;
    });
  });
  return budgetPbs;
}

export function getTitleAccountRangeMap(budget: IBudgetPeriod[]) {
  const titleAccountrangeMap: IKeyString<{ from: number; to: number }> = {};
  budget.forEach((budgetPeriod) => {
    budgetPeriod.rows.forEach((row) => {
      titleAccountrangeMap[row.title] = row.accountRange;
    });
  });
  return titleAccountrangeMap;
}

export function getBudgetAccountRangeNameMap() {
  const map: IKeyString<string> = {};
  budgetIncomeRowTypes.forEach((op) => {
    map[op.value] = op.label;
  });
  budgetExpenseRowTypes.forEach((op) => {
    map[op.value] = op.label;
  });
  return map;
}

export function saveBudgetDelta(
  budget: IBudgetPeriod[],
  newBudget: IBudgetPeriod[]
) {
  const deltas: jsondiffpatch.Delta[] = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEY.BudgetDeltas) || "[]"
  );

  const delta = jsondiffpatch.diff(budget, newBudget);
  if (!delta) {
    console.log("No delta");
    return;
  }

  deltas.push(delta);
  localStorage.setItem(LOCAL_STORAGE_KEY.BudgetDeltas, JSON.stringify(deltas));
}

export function undoBudget(budget: IBudgetPeriod[]) {
  const deltas: jsondiffpatch.Delta[] = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEY.BudgetDeltas) || "[]"
  );

  if (deltas.length === 0) {
    return;
  }

  const lastDelta = deltas.pop();

  if (!lastDelta) {
    return;
  }

  const reverseDelta = jsondiffpatch.reverse(lastDelta);

  if (!reverseDelta) {
    return;
  }

  const newBudget: IBudgetPeriod[] = jsondiffpatch.patch(budget, reverseDelta);

  localStorage.setItem(LOCAL_STORAGE_KEY.BudgetDeltas, JSON.stringify(deltas));

  return newBudget;
}

export function canUndoBudget() {
  const deltas: jsondiffpatch.Delta[] = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEY.BudgetDeltas) || "[]"
  );

  return deltas.length > 0;
}

export function convertResultReportToBudgetPeriods({
  report,
  budgetNameMap,
  isAccountLevel,
  accountRecord,
}: {
  report: IResultReport;
  budgetNameMap: IKeyString<string>;
  isAccountLevel?: boolean;
  accountRecord?: Record<string, IAccount>;
}) {
  const newBudget: IBudgetPeriod[] = [];

  const reportKeys = Object.keys(report);
  for (let i = 0; i < reportKeys.length; i++) {
    const reportKey = reportKeys[i];

    const reportSubKeys = Object.keys(report[reportKey]);
    for (let j = 0; j < reportSubKeys.length; j++) {
      const reportSubKey = reportSubKeys[j];

      if (
        reportSubKey === ECONOMIC_TERM.Interval ||
        reportSubKey === ECONOMIC_TERM.Sum
      ) {
        continue;
      }

      const monthKeys = Object.keys(report[reportKey][reportSubKey]);
      for (let k = 0; k < monthKeys.length; k++) {
        const mKey = monthKeys[k];

        if (mKey === ECONOMIC_TERM.Interval) {
          continue;
        }

        const [year, month] = mKey.split("-");

        const insertIndex = newBudget.findIndex(
          (r) => r.year === +year + 1 && r.month === +month
        );

        const value =
          report[reportKey][reportSubKey][mKey] *
          (reportKey === ECONOMIC_TERM.Income ? 1 : -1);

        const accountTitle =
          accountRecord?.[reportSubKey]?.name ||
          accountRecord?.[reportSubKey]?.description;

        if (insertIndex === -1) {
          newBudget.push({
            year: +year + 1,
            month: +month,
            rows: [
              {
                accountRange: {
                  from: report[reportKey][reportSubKey].Intervall[0],
                  to: report[reportKey][reportSubKey].Intervall[1],
                },
                title: isAccountLevel
                  ? accountTitle || reportSubKey
                  : budgetNameMap[
                      report[reportKey][reportSubKey].Intervall.join("-")
                    ] || "Titel saknas",
                value,
              },
            ],
          });
        } else {
          newBudget[insertIndex].rows.push({
            accountRange: {
              from: report[reportKey][reportSubKey].Intervall[0],
              to: report[reportKey][reportSubKey].Intervall[1],
            },
            title: isAccountLevel
              ? accountTitle || reportSubKey
              : budgetNameMap[
                  report[reportKey][reportSubKey].Intervall.join("-")
                ] || "Titel saknas",
            value,
          });
        }
      }
    }
  }

  return newBudget;
}

export function getBudgetIncome(
  budget: IBudgetPeriod[],
  time?: {
    from: number;
    to: number;
  }
) {
  let sum = 0;
  budget
    .filter((p) => {
      if (!time) return true;

      const interval = {
        start: fromUnixTime(time.from),
        end: fromUnixTime(time.to),
      };

      return isWithinInterval(stringToDate(`${p.year}-${p.month}`), interval);
    })
    .forEach((period) => {
      period.rows.forEach((row) => {
        if (row.accountRange.from < 3000 || row.accountRange.to > 3999) return;
        sum += row.value;
      });
    });
  return sum;
}

export function getBudgetCost(
  budget: IBudgetPeriod[],
  time?: {
    from: number;
    to: number;
  }
) {
  let sum = 0;
  budget
    .filter((p) => {
      if (!time) return true;

      const interval = {
        start: fromUnixTime(time.from),
        end: fromUnixTime(time.to),
      };

      return isWithinInterval(stringToDate(`${p.year}-${p.month}`), interval);
    })
    .forEach((period) => {
      period.rows.forEach((row) => {
        if (row.accountRange.from < 4000 || row.accountRange.to > 8899) return;
        sum += row.value;
      });
    });
  return sum;
}

export function getBudgetSum(
  budget: IBudgetPeriod[],
  accountRange: [number, number]
) {
  let sum = 0;
  budget.forEach((period) => {
    period.rows.forEach((row) => {
      if (
        row.accountRange.from < accountRange[0] ||
        row.accountRange.to > accountRange[1]
      )
        return;
      sum += row.value;
    });
  });
  return sum;
}

export function getBudgetResult(budget: IBudgetPeriod[]) {
  let sum = 0;
  budget.forEach((period) => {
    period.rows.forEach((row) => {
      sum += row.value;
    });
  });
  return sum;
}
