import { eachMonthOfInterval, fromUnixTime, lightFormat } from "date-fns";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getBudgetAccountRangeNameMap,
  getTitleAccountRangeMap,
} from "../../shared/budget";
import { months } from "../../shared/values";
import { IAccount, IBudgetPeriod } from "../../types/api";
import { IKeyString, RowType } from "../../types/internal";
import LegendText from "../LegendText/LegendText";
import Button from "../UI/Button/Button";
import Dropdown from "../UI/Dropdown/Dropdown";
import Input from "../UI/Input/Input";
import "./BudgetSheet.scss";
import BudgetSheetRow from "./BudgetSheetRow/BudgetSheetRow";

interface IBudgetSheetProps {
  title: string;
  from: number;
  to: number;
  budget: IBudgetPeriod[];
  filterAccountRange: {
    from: number;
    to: number;
  };
  accountOptions: {
    value: string;
    label: string;
  }[];
  compareNumbers: IKeyString<IKeyString<number>>;
  accountRecord: Record<number, IAccount>;
  type: RowType;

  onBudgetChanged: (data: {
    year: number;
    month: number;
    accountRange: {
      from: number;
      to: number;
    };
    value: number;
    title: string;
  }) => void;
  onTitleChanged: (oldTitle: string, newTitle: string) => void;
  onRowRemoved: (title: string) => void;
  onAddBudgetPeriod: (
    accountRange: {
      from: number;
      to: number;
    },
    title: string
  ) => void;
  onSave: () => void;

  isSaving?: boolean;
  hasAdvancedBudget?: boolean;
  isComparing?: boolean;
  isResultRow?: boolean;
  hasSaved?: boolean;
}

function BudgetSheet(props: IBudgetSheetProps) {
  const [chosenAccountRange, setChosenAccountRange] = useState("");
  const [budgetRowTitle, setBudgetRowTitle] = useState("");
  const [hasEdited, setHasEdited] = useState(false);

  useEffect(() => {
    if (!props.isSaving) {
      setHasEdited(false);
    }
  }, [props.isSaving]);

  function formatBudget(
    budget: IBudgetPeriod[],
    filterAccountRange: { from: number; to: number },
    monthsArray: string[]
  ) {
    const result: IKeyString<IKeyString<number>> = {};

    for (let i = 0; i < budget.length; i++) {
      const period = budget[i];

      for (let j = 0; j < period.rows.length; j++) {
        const row = period.rows[j];

        if (
          row.accountRange.from < filterAccountRange.from ||
          row.accountRange.to > filterAccountRange.to
        )
          continue;

        if (!result[row.title]) {
          result[row.title] = {};
          monthsArray.forEach((mKey) => {
            result[row.title][mKey] = 0;
          });
        }

        result[row.title][`${period.year}-${period.month}`] = row.value;
      }
    }

    return result;
  }

  function getRowOriginalTitle(key: string) {
    if (props.hasAdvancedBudget) {
      return (
        props.accountRecord[titleAccountRangeMap[key].from]?.name ||
        props.accountRecord[titleAccountRangeMap[key].from]?.description ||
        "Titel saknas"
      );
    }
    return (
      budgetTitleMap[
        `${titleAccountRangeMap[key].from}-${titleAccountRangeMap[key].to}`
      ] || "Titel saknas"
    );
  }

  const addBudgetPeriodHandler = () => {
    if (!props.onAddBudgetPeriod) return;

    let [rangeStart, rangeEnd] = chosenAccountRange.split("-");
    if (!rangeEnd) {
      rangeEnd = rangeStart;
    }

    if (
      +rangeStart < props.filterAccountRange.from ||
      +rangeEnd > props.filterAccountRange.to
    ) {
      toast.error(
        `Kontonummeret måste vara mellan ${props.filterAccountRange.from} och ${props.filterAccountRange.to}`
      );
      return;
    }

    props.onAddBudgetPeriod(
      { from: +rangeStart, to: +rangeEnd },
      budgetRowTitle
    );
    setHasEdited(true);
    setChosenAccountRange("");
    setBudgetRowTitle("");
  };

  const monthsArray = eachMonthOfInterval({
    start: fromUnixTime(props.from),
    end: fromUnixTime(props.to),
  }).map((d) => lightFormat(d, "yyyy-M"));

  const formattedBudget = formatBudget(
    props.budget,
    props.filterAccountRange,
    monthsArray
  );

  const budgetTitleMap = getBudgetAccountRangeNameMap();
  const titleAccountRangeMap = getTitleAccountRangeMap(props.budget);

  let budgetResultRow: IKeyString<number> = {};
  for (const key in formattedBudget) {
    for (const mKey in formattedBudget[key]) {
      if (!budgetResultRow[mKey]) {
        budgetResultRow[mKey] = 0;
      }
      budgetResultRow[mKey] += formattedBudget[key][mKey];
    }
  }

  return (
    <div className="budget-sheet">
      <div className="top">
        <span className="text-m-m">{props.title}</span>
        <LegendText label="Årets budget" color="#000D33" />
        <LegendText label="Föregående år" color="#818094" />
      </div>
      <table>
        <thead>
          <tr>
            {monthsArray.map((mKey) => (
              <th key={mKey} className="text-m-m">
                {months[+mKey.split("-")[1] - 1].label.slice(0, 3)}
              </th>
            ))}
            <th className="text-m-m">Sum</th>
          </tr>
        </thead>
        <tbody>
          {props.isResultRow ? (
            <BudgetSheetRow
              title="Resultat"
              orginalTitle="Resultat"
              accountRange={{
                from: 0,
                to: 0,
              }}
              row={budgetResultRow}
              type="result"
              onValueChanged={() => {}}
              onTitleChanged={() => {}}
              onRemove={() => {}}
              isResultRow={true}
              compareRow={{}}
              isComparing={props.isComparing}
            />
          ) : (
            <>
              {Object.keys(formattedBudget)
                .sort(
                  (a, b) =>
                    titleAccountRangeMap[a].from - titleAccountRangeMap[b].from
                )
                .map((key, i) => (
                  <BudgetSheetRow
                    key={i}
                    title={key}
                    orginalTitle={getRowOriginalTitle(key)}
                    accountRange={titleAccountRangeMap[key]}
                    row={formattedBudget[key]}
                    type={props.type}
                    onValueChanged={(data) => {
                      setHasEdited(true);
                      props.onBudgetChanged({
                        ...data,
                        accountRange: titleAccountRangeMap[key],
                      });
                    }}
                    onTitleChanged={(oldTitle, newTitle) => {
                      setHasEdited(true);
                      props.onTitleChanged(oldTitle, newTitle);
                    }}
                    onRemove={(title) => {
                      setHasEdited(true);
                      props.onRowRemoved(title);
                    }}
                    isComparing={props.isComparing}
                    compareRow={props.compareNumbers[key]}
                    hasSaved={props.hasSaved}
                  />
                ))}
              {!!Object.keys(formattedBudget).length && (
                <BudgetSheetRow
                  title={`Summa ${props.title} per månad och totalt`}
                  orginalTitle=""
                  accountRange={{
                    from: 0,
                    to: 0,
                  }}
                  row={budgetResultRow}
                  type={props.type}
                  onValueChanged={() => {}}
                  onTitleChanged={() => {}}
                  onRemove={() => {}}
                  isResultRow={true}
                  compareRow={{}}
                  isComparing={props.isComparing}
                />
              )}
            </>
          )}
          {!props.isResultRow && (
            <tr>
              <td colSpan={100}>
                <div className="bottom">
                  <div className="header text-s-r">Skapa en ny rad</div>
                  <div>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        addBudgetPeriodHandler();
                      }}
                    >
                      {props.hasAdvancedBudget ? (
                        <Input
                          placeholder="Konto"
                          type="number"
                          value={chosenAccountRange}
                          onChange={setChosenAccountRange}
                          short
                        />
                      ) : (
                        <Dropdown
                          options={props.accountOptions}
                          title={`Typ av ${props.title}`}
                          onSelect={(v) => {
                            setChosenAccountRange(v);
                            setBudgetRowTitle(
                              props.accountOptions.find((op) => op.value === v)
                                ?.label || ""
                            );
                          }}
                          value={chosenAccountRange}
                          short
                          width={300}
                          fixedMenu
                        />
                      )}
                      {chosenAccountRange && (
                        <Input
                          placeholder="Titel för raden"
                          type="text"
                          value={budgetRowTitle}
                          onChange={setBudgetRowTitle}
                          width="200px"
                          short
                        />
                      )}
                      {chosenAccountRange && budgetRowTitle && (
                        <Button color="black" label="Lägg till rad" short />
                      )}
                    </form>
                    {hasEdited && (
                      <Button
                        label="Spara budget"
                        color="black"
                        onClick={props.onSave}
                        short
                        dontSubmit
                        isLoading={props.isSaving}
                      />
                    )}
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default BudgetSheet;
