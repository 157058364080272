import { useRef, useState } from "react";
import { useClickOutside } from "../../shared/hooks/useClickOutside";
import DotsButton from "../UI/DotsButton/DotsButton";
import "./DotsModal.scss";

interface IDotsModalProps {
  title: string;
  content: {
    title: string;
    onClick?: () => void;
  }[];
  fixedMenu?: boolean;
}

function DotsModal(props: IDotsModalProps) {
  const dotsModelClasses = ["dots-modal"];
  if (props.fixedMenu) dotsModelClasses.push("fixed-menu");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  useClickOutside([modalRef, buttonRef], () => setIsModalOpen(false));

  return (
    <div className="dots-modal-wrapper">
      <DotsButton
        ref={buttonRef}
        onClick={() => setIsModalOpen((state) => !state)}
      />
      {isModalOpen && (
        <div ref={modalRef} className={dotsModelClasses.join(" ")}>
          <p className="title text-xs-m">{props.title}</p>
          {props.content.map((c) => (
            <p
              key={c.title}
              className="content-row text-m-r"
              onClick={() => {
                if (c.onClick) c.onClick();
                setIsModalOpen(false);
              }}
            >
              {c.title}
            </p>
          ))}
        </div>
      )}
    </div>
  );
}

export default DotsModal;
