import { useEffect } from "react";

export function useClickOutside(
  refs: React.RefObject<HTMLElement>[],
  handler: (event: MouseEvent) => void
) {
  useEffect(() => {
    function listener(event: MouseEvent) {
      for (let i = 0; i < refs.length; i++) {
        const ref = refs[i];

        if (!ref.current || ref.current.contains(event.target as Node)) {
          return;
        }
      }

      handler(event);
    }
    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [refs, handler]);
}
