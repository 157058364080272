import { components } from "react-select";
import AsyncSelect from "react-select/async";
import "./ElasticSearch.scss";

type IElasticSearchProps<T> = {
  handleSearch: (searchString: string) => Promise<T>;
  convertResult: (result: T) => { label: string; value: string }[];
  title: string;
  value: { label: string; value: string };
  onSelect: (value: { label: string; value: string }) => void;
  fixedMenu?: boolean;
  short?: boolean;
  width?: number | string;
  borderRadius?: string;
};

function ElasticSearch<T>(props: IElasticSearchProps<T>) {
  const CustomDropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
      <i className="fa-solid fa-caret-down" />
    </components.DropdownIndicator>
  );

  return (
    <AsyncSelect
      loadOptions={(searchString, callback) => {
        props.handleSearch(searchString).then((res) => {
          callback(props.convertResult(res) as any);
        });
      }}
      defaultOptions={true}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: CustomDropdownIndicator,
      }}
      value={props.value}
      onChange={(v: any) => props.onSelect(v)}
      placeholder={props.title}
      menuPosition={props.fixedMenu ? "fixed" : "absolute"}
      noOptionsMessage={() => "Inga resultat"}
      loadingMessage={() => "Laddar..."}
      styles={{
        container: (base) => ({
          ...base,
          width: props.width || 150,
        }),
        control: (base) => ({
          ...base,
          width: "100%",
          color: "#000D33",
          borderRadius: props.borderRadius || "40px",
          border: "1px solid #A6A6AF",
          "&:hover": {
            border: "1px solid #A6A6AF",
          },
          minHeight: props.short ? "30px" : "38px",
          fontSize: "13px",
          fontWeight: 400,
          cursor: "pointer",
          boxShadow: "none",
        }),
        option: (base, state) => ({
          ...base,
          backgroundColor: state.isFocused ? "#EFEFFF" : "transparent",
          color: "#000D33",
          zIndex: 1000,
          fontSize: "13px",
          fontWeight: 400,
          cursor: "pointer",
          ":active": {
            backgroundColor: "#EFEFFF",
          },
        }),
        menu: (base) => ({ ...base, zIndex: 100000 }),
        dropdownIndicator: (base, state) => ({
          ...base,
          transition: "all 200ms ease",
          transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "",
          color: "#000D33",
          "&:hover": {
            color: "#000D33",
          },
        }),
        valueContainer: (base) => ({
          ...base,
          padding: props.short ? "0 8px" : "2px 8px",
        }),
      }}
    />
  );
}

export default ElasticSearch;
