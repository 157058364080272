import "./UserInfoRow.scss";

interface IUserInfoRowProps {
  title: string;
  value?: string | number | boolean;
}

function UserInfoRow(props: IUserInfoRowProps) {
  function displayValue(value: string | number | boolean) {
    if (typeof value === "boolean") {
      return value ? "Yes" : "No";
    }
    return value;
  }

  return props.value !== undefined ? (
    <div className="user-info-row">
      <span className="title text-m-m">{props.title}</span>
      <span className="value text-m-m">{displayValue(props.value)}</span>
    </div>
  ) : null;
}

export default UserInfoRow;
