import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createCustomVariable } from "../../../api/report";
import { API_MESSAGE } from "../../../shared/enums";
import { IAccount } from "../../../types/api";
import { IFormulaItem } from "../../../types/internal";
import { IReduxState } from "../../../types/redux";
import Button from "../../UI/Button/Button";
import Input from "../../UI/Input/Input";
import AccountRow from "./AccountRow/AccountRow";
import "./CustomAccount.scss";

interface ICustomAccountProps {
  onAddVariable: (fItem: IFormulaItem) => void;
  accounts: IAccount[];
  onNewVariable: (variableId: string) => void;
}

function CustomAccount(props: ICustomAccountProps) {
  const { companyId } = useSelector((state: IReduxState) => state.company);

  const [searchString, setSearchString] = useState("");
  const [selectedAccounts, setSelectedAccounts] = useState<number[]>([]);
  const [variableName, setVariableName] = useState("");
  const [isCreating, setIsCreating] = useState(false);

  function createVariableHandler() {
    if (!variableName) {
      toast.error("Variabelnamnet får inte vara tomt");
      return;
    }
    setIsCreating(true);
    createCustomVariable({
      companyId,
      variableString: constructVariableString(selectedAccounts),
      name: variableName,
      isPercent: false,
      showInTable: false,
    })
      .then((res) => {
        setIsCreating(false);
        toast.success("Variabel skapad");
        props.onNewVariable(res.data.payload.variableId);
      })
      .catch((e) => {
        setIsCreating(false);
        if (e.response.data.message === API_MESSAGE.InvalidVariableName) {
          toast.error(
            "Variabelnamnet får endast innehålla alfanumeriska tecken och mellanslag"
          );
          return;
        }
        toast.error("Något gick fel");
      });
  }

  function filterAccounts(account: IAccount) {
    if (!searchString) return true;
    const accountName = account.description || account.name || "Namn saknas";
    return (
      String(account.account).startsWith(searchString) ||
      accountName.toLowerCase().includes(searchString.toLowerCase())
    );
  }

  function constructVariableString(accounts: number[]) {
    return `[${accounts.join("] + [")}]`;
  }

  return (
    <div className="custom-account">
      <Input
        type="text"
        value={searchString}
        onChange={setSearchString}
        placeholder="Sök på konto"
        width="calc(100% - 30px)"
        short
      />
      <div className="accounts">
        {props.accounts
          .filter(filterAccounts)
          .sort((a, b) => a.account - b.account)
          .map((a) => (
            <AccountRow
              key={a.account}
              accountName={a.description || a.name || "Namn saknas"}
              accountNumber={a.account}
              isActive={selectedAccounts.includes(a.account)}
              onCheck={(checked) => {
                if (checked) {
                  setSelectedAccounts([...selectedAccounts, a.account]);
                  return;
                }
                setSelectedAccounts(
                  selectedAccounts.filter((sa) => sa !== a.account)
                );
              }}
            />
          ))}
      </div>
      <div className="bottom">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            createVariableHandler();
          }}
        >
          <Input
            type="text"
            value={variableName}
            onChange={(v) => {
              setVariableName(v);
            }}
            placeholder="Variabelnamn"
            short
          />
          <Button
            color="black"
            label="Skapa variabel"
            isLoading={isCreating}
            short
          />
        </form>
        <Button
          color="black"
          label="Lägg till i formel"
          short
          onClick={() => {
            selectedAccounts.forEach((sa, i) => {
              props.onAddVariable({
                value: `[${sa}]`,
                type: "account",
              });
              if (i !== selectedAccounts.length - 1) {
                props.onAddVariable({
                  value: "+",
                  type: "arithmetic",
                });
              }
            });
          }}
        />
      </div>
    </div>
  );
}

export default CustomAccount;
