import "./ShowingAllMessage.scss";

interface IShowingAllMessageProps {
  text: string;
  style?: React.CSSProperties;
}

function ShowingAllMessage(props: IShowingAllMessageProps) {
  return (
    <p className="showing-all-message" style={props.style}>
      {props.text}
    </p>
  );
}

export default ShowingAllMessage;
