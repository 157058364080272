import { convertToNumberWithSpaces } from "../../shared/utility";
import "./FigureCard.scss";

interface IFigureCardProps {
  title: string;
  data: {
    label: string;
    value: number;
  }[];
}

function FigureCard(props: IFigureCardProps) {
  return (
    <div className="figure-card">
      <div className="top">
        <span className="text-m-m">{props.title}</span>
      </div>
      <div className="content">
        {props.data.map((item, i) => (
          <div key={i} className="item">
            <div className="title text-m-r">{item.label}</div>
            <div className="value text-l-m">
              {convertToNumberWithSpaces(item.value)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FigureCard;
