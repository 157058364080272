import { endOfMonth, getUnixTime, startOfMonth, subMonths } from "date-fns";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getTags } from "../../api/company";
import {
  deleteCustomVariable,
  generateEconomicAlerts,
  getCustomAlerts,
} from "../../api/report";
import AlertSection from "../../components/AlertSection/AlertSection";
import Button from "../../components/UI/Button/Button";
import Dropdown from "../../components/UI/Dropdown/Dropdown";
import Spinner from "../../components/UI/Spinner/Spinner";
import CustomAlertPopup from "../../containers/CustomAlertPopup/CustomAlertPopup";
import { getAlertDescription } from "../../shared/alertUtility";
import { QUERY_PARAM, SESSION_STORAGE_KEY } from "../../shared/enums";
import { useCounselingValues } from "../../shared/hooks/useCounselingValues";
import { useSearchQuery } from "../../shared/hooks/useSearchQuery";
import { nimyaAlert } from "../../shared/nimyaAlert";
import { months, years } from "../../shared/values";
import { setIsMessageOpen } from "../../store/slices/message";
import { IAlert, IVariable } from "../../types/api";
import { AlertTimePeriod, IReduxState } from "../../types/redux";
import "./Alerts.scss";

interface IAlertsProps {}

function Alerts(props: IAlertsProps) {
  const {
    company: {
      companyId,
      settings: { disabledAlerts },
    },
    extendedFiltering: { tagFilter, alertTimePeriod },
  } = useSelector((state: IReduxState) => state);
  const dispatch = useDispatch();
  const { setControlsComponent } = useCounselingValues();
  const query = useSearchQuery();

  const [fromMonth, setFromMonth] = useState(
    `${subMonths(new Date(), 1).getMonth() + 1}`
  );
  const [fromYear, setFromYear] = useState(new Date().getFullYear().toString());
  const [toMonth, setToMonth] = useState(
    `${subMonths(new Date(), 1).getMonth() + 1}`
  );
  const [toYear, setToYear] = useState(new Date().getFullYear().toString());
  const [isAlertPopupOpen, setIsAlertPopupOpen] = useState(false);
  const [selectedAlerts, setSelectedAlerts] = useState<IAlert[]>([]);
  const [editAlert, setEditAlert] = useState<IAlert | undefined>(undefined);

  useEffect(() => {
    setControlsComponent(
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 20,
        }}
      >
        <Button
          label="Skapa ny notis"
          color="black"
          onClick={() => setIsAlertPopupOpen(true)}
        />
        {alertTimePeriod === "custom" && (
          <>
            <Dropdown
              title="År"
              value={fromYear}
              onSelect={setFromYear}
              options={years()}
            />
            <Dropdown
              title="Månad"
              value={fromMonth}
              onSelect={setFromMonth}
              options={months}
            />
            <p className="text-s-r" style={{ color: "#818094" }}>
              Till
            </p>
          </>
        )}
        <Dropdown
          title="År"
          value={toYear}
          onSelect={setToYear}
          options={years()}
        />
        <Dropdown
          title="Månad"
          value={toMonth}
          onSelect={setToMonth}
          options={months}
        />
      </div>
    );
  }, [
    toMonth,
    setControlsComponent,
    toYear,
    alertTimePeriod,
    fromYear,
    fromMonth,
  ]);

  useEffect(() => {
    const queryVariableId = query.get(QUERY_PARAM.VariableId);
    if (queryVariableId) {
      setIsAlertPopupOpen(true);
    }
  }, [query]);

  const {
    data: alertVariables,
    isSuccess: isVariablesSuccess,
    refetch: refetchVariables,
  } = useQuery({
    queryKey: ["alert-variables", companyId],
    queryFn: async ({ queryKey: [, companyId] }) => {
      const res = await getCustomAlerts(companyId);
      return res.data.payload.variables;
    },
  });

  const {
    data: tags,
    isLoading: isTagsLoading,
    isSuccess: isTagsSuccess,
    refetch: refetchTags,
  } = useQuery({
    queryKey: ["custom-tags", companyId],
    queryFn: async ({ queryKey: [, companyId] }) => {
      const res = await getTags(companyId);
      return res.data.payload.tags;
    },
  });

  const {
    data: alerts,
    isLoading: isAlertsLoading,
    isSuccess: isAlertsSuccess,
    refetch: refetchAlerts,
  } = useQuery<
    IAlert[],
    unknown,
    IAlert[],
    [
      "alerts",
      string,
      IVariable[] | undefined,
      string,
      string,
      string,
      string,
      AlertTimePeriod
    ]
  >({
    queryKey: [
      "alerts",
      companyId,
      alertVariables,
      toMonth,
      toYear,
      fromMonth,
      fromYear,
      alertTimePeriod,
    ],
    queryFn: async ({
      queryKey: [
        ,
        companyId,
        variables,
        toMonth,
        toYear,
        fromMonth,
        fromYear,
        alertTimePeriod,
      ],
    }) => {
      let from = getUnixTime(startOfMonth(new Date(`${toYear}-${toMonth}`)));
      const to = getUnixTime(endOfMonth(new Date(`${toYear}-${toMonth}`)));

      if (alertTimePeriod === "custom") {
        from = getUnixTime(startOfMonth(new Date(`${fromYear}-${fromMonth}`)));
      }

      const res = await generateEconomicAlerts({
        companyId,
        from,
        to,
        variables: variables || [],
      });

      return res;
    },
    enabled: isVariablesSuccess,
  });

  async function editAlertHandler(alert: IAlert) {
    setEditAlert(alert);
    setIsAlertPopupOpen(true);
  }

  async function deleteAlertHandler(alert: IAlert) {
    if (
      !(await nimyaAlert({
        title: "Ta bort notis",
        message: `Är du säker på att du vill ta bort ${alert.name}? (Detta går inte att ångra)`,
      }))
    )
      return;

    try {
      await deleteCustomVariable({
        companyId,
        variableId: alert._id,
        isAlert: true,
      });
      toast.success("Notiser togs bort");
      await refetchVariables();
    } catch (err) {
      toast.error("Något gick fel när notisen skulle tas bort");
    }
  }

  function selectAlertHandler(alert: IAlert) {
    setSelectedAlerts((state) => {
      if (state.includes(alert)) {
        return state.filter((a) => a._id !== alert._id);
      } else {
        return [...state, alert];
      }
    });
  }

  function reportHandler() {
    const reportData = selectedAlerts.map((a) => ({
      _id: a._id,
      description: getAlertDescription(a),
    }));

    sessionStorage.setItem(
      SESSION_STORAGE_KEY.ReportData,
      JSON.stringify(reportData)
    );

    dispatch(setIsMessageOpen(true));
  }

  function refetchData() {
    refetchVariables();
    refetchTags();
    refetchAlerts();
  }

  return (
    <div className="alerts">
      <div className="content">
        {(isTagsLoading || isAlertsLoading) && <Spinner />}
        {isTagsSuccess && isAlertsSuccess && (
          <>
            {tags
              .filter((t) =>
                tagFilter === "all" ? true : tagFilter.includes(t.name)
              )
              .map((t) => (
                <AlertSection
                  key={t._id}
                  title={t.name}
                  alerts={alerts.filter(
                    (a) =>
                      a.tags.includes(t.name) && !disabledAlerts.includes(a._id)
                  )}
                  onAlertSelected={selectAlertHandler}
                  selectedAlertIds={selectedAlerts.map((a) => a._id)}
                  onEdit={editAlertHandler}
                  onDelete={deleteAlertHandler}
                  onToggleAlert={() => {}}
                />
              ))}
            {tagFilter === "all" && (
              <>
                <AlertSection
                  title="Globala"
                  alerts={alerts.filter(
                    (a) => a.global && !disabledAlerts.includes(a._id)
                  )}
                  onAlertSelected={selectAlertHandler}
                  selectedAlertIds={selectedAlerts.map((a) => a._id)}
                  onEdit={editAlertHandler}
                  onDelete={deleteAlertHandler}
                  onToggleAlert={() => {}}
                />
                <AlertSection
                  title="Övriga"
                  alerts={alerts.filter(
                    (a) =>
                      !a.global &&
                      a.tags.length === 0 &&
                      !disabledAlerts.includes(a._id)
                  )}
                  onAlertSelected={selectAlertHandler}
                  selectedAlertIds={selectedAlerts.map((a) => a._id)}
                  onEdit={editAlertHandler}
                  onDelete={deleteAlertHandler}
                  onToggleAlert={() => {}}
                />
              </>
            )}
          </>
        )}
      </div>
      {selectedAlerts.length ? (
        <div className="report-alerts">
          <Button
            color="black"
            label="Förhandsgranska rapport"
            onClick={reportHandler}
          />
        </div>
      ) : null}
      <CustomAlertPopup
        showPopup={isAlertPopupOpen}
        onClose={(hasCreated) => {
          setIsAlertPopupOpen(false);
          setEditAlert(undefined);
          if (hasCreated) {
            refetchData();
          }
        }}
        editAlert={editAlert}
      />
    </div>
  );
}

export default Alerts;
