import "./NoticeRow.scss";

import React from "react";

import Switch from "../UI/Switch/Switch";

function NoticeRow() {
  return (
    <div className="notice-row">
      <div className="left">
        <Switch isActive={true} onChange={() => {}} />
        <p className="title text-m-r">Licenskostander är större än X kronor</p>
      </div>
      <div className="right">
        <p className="title text-m-r">Notis triggas</p>
        <div className="input-wrapper">
          <input className="input-field text-m-m" value="12 000" />
          <span className="text-m-m">kr</span>
        </div>
        <button className="plus-btn">
          <i className="fa-solid fa-plus"></i>
        </button>
        <button className="more-btn">
          <i className="fa-solid fa-ellipsis"></i>
        </button>
      </div>
    </div>
  );
}

export default NoticeRow;
