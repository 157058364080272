import { ALERT_VALUE } from "../../shared/enums";
import { getStatements } from "../../shared/variableUtility";
import { IVariable } from "../../types/api";
import VariableListItem from "../CustomVariableComponents/VariableList/VariableListItem/VariableListItem";
import "./StatementList.scss";

export interface IStatement {
  name: string;
  value: string;
  isBreakValueInPercent?: boolean;
}

interface IStatementListProps {
  variable: IVariable;
  onSelect: (statement: IStatement, hasBreakValue: boolean) => void;
  selectedStatement?: IStatement;
}

function StatementList(props: IStatementListProps) {
  const {
    procentStatements,
    crownsStatements,
    budgetStatements,
    timeStatements,
  } = getStatements(props.variable);

  return (
    <div className="statement-list">
      {props.variable.isPercent && (
        <div className="statement-list--section">
          <p className="title">Procent</p>
          {procentStatements.map((s, i) => (
            <VariableListItem
              key={i}
              name={s.name}
              isGlobal={false}
              isSelected={props.selectedStatement?.value === s.value}
              onClick={() =>
                props.onSelect(
                  s,
                  s.value.includes(ALERT_VALUE.BreakValueCalcSymbol)
                )
              }
            />
          ))}
        </div>
      )}
      {!props.variable.isPercent && (
        <div className="statement-list--section">
          <p className="title">Kronor</p>
          {crownsStatements.map((s, i) => (
            <VariableListItem
              key={i}
              name={s.name}
              isGlobal={false}
              isSelected={props.selectedStatement?.value === s.value}
              onClick={() =>
                props.onSelect(
                  s,
                  s.value.includes(ALERT_VALUE.BreakValueCalcSymbol)
                )
              }
            />
          ))}
        </div>
      )}
      <div className="statement-list--section">
        <p className="title">Budget</p>
        {budgetStatements.map((s, i) => (
          <VariableListItem
            key={i}
            name={s.name}
            isGlobal={false}
            isSelected={props.selectedStatement?.value === s.value}
            onClick={() =>
              props.onSelect(
                s,
                s.value.includes(ALERT_VALUE.BreakValueCalcSymbol)
              )
            }
          />
        ))}
      </div>

      <div className="statement-list--section">
        <p className="title">Tid</p>
        {timeStatements.map((s, i) => (
          <VariableListItem
            key={i}
            name={s.name}
            isGlobal={false}
            isSelected={props.selectedStatement?.value === s.value}
            onClick={() =>
              props.onSelect(
                s,
                s.value.includes(ALERT_VALUE.BreakValueCalcSymbol)
              )
            }
          />
        ))}
      </div>
    </div>
  );
}

export default StatementList;
