import "./Checkbox.scss";

interface ICheckboxProps {
  checked: boolean;
  theme: "light" | "dark";
  onClick?: (checked: boolean) => void;
  label?: string;
  short?: boolean;
}

function Checkbox(props: ICheckboxProps) {
  const checkboxClasses = ["checkbox", "waves-effect"];
  const checkboxWrapperClasses = ["checkbox-wrapper", "text-s-r"];

  if (props.checked) {
    checkboxClasses.push("checked");
    checkboxWrapperClasses.push("checked");
  }
  if (props.short) checkboxClasses.push("short");

  checkboxClasses.push(props.theme);
  checkboxWrapperClasses.push(props.theme);

  return (
    <label className={checkboxWrapperClasses.join(" ")}>
      <div className={checkboxClasses.join(" ")}>
        <div className="circle" />
      </div>
      {props.label}
      <input
        type="checkbox"
        checked={props.checked}
        onChange={
          props.onClick ? () => props.onClick!(!props.checked) : () => {}
        }
        onClick={(e) => e.stopPropagation()}
        style={{ display: "none" }}
      />
    </label>
  );
}

export default Checkbox;
