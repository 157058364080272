import "./DownForMaintenance.scss";

interface IDownForMaintenanceProps {}

function DownForMaintenance(props: IDownForMaintenanceProps) {
  return (
    <div className="down-for-maintenance">
      <div className="maintenance-message">
        <i className="fa-duotone fa-traffic-cone" />
        <h1>Nimya är nere för underhåll</h1>
        <p>
          Har du frågor kan du{" "}
          <a href="https://www.nimya.io/support">Kontakta oss</a>
        </p>
      </div>
    </div>
  );
}

export default DownForMaintenance;
