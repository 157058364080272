import React, { useCallback, useEffect } from "react";
import { toast } from "react-toastify";

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";

interface Props {
  clientSecret: string;
  cb?: () => void;
}

function CompanySettingsAddCard({ clientSecret, cb }: Props) {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = React.useState("");
  const [disabled, setDisabled] = React.useState(true);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!elements || !stripe) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);

    if (!cardNumberElement) {
      return;
    }

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardNumberElement,
        billing_details: {
          name: name,
        },
      },
      return_url: window.location.href,
    });

    if (result.error) {
      console.log(result.error.message);
      return;
    }

    if (
      result.setupIntent &&
      result.setupIntent.status === "succeeded" &&
      result.setupIntent.client_secret
    ) {
      handleConfirmation(result.setupIntent.client_secret);
    }
  }

  const handleConfirmation = useCallback(
    async (clientSecret: string) => {
      if (!stripe) {
        return;
      }

      const retrieve = await stripe.retrieveSetupIntent(clientSecret);

      if (retrieve.error) {
        console.error(retrieve.error.message);
      } else if (
        retrieve.setupIntent &&
        retrieve.setupIntent.status === "succeeded"
      ) {
        console.log("Success");
        toast.success(
          "Kortet är nu sparat och det kommer snart att visas i din kortlista"
        );
        cb && cb();
      }
    },
    [stripe, cb]
  );

  useEffect(() => {
    if (name.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [name]);

  return (
    <>
      <form className="add-card-form" onSubmit={handleSubmit}>
        <div className="input-container">
          <Input
            type="text"
            placeholder="Namn på kort"
            onChange={(v) => setName(v)}
            value={name}
            width="100%"
          />
          <div className="input-group-grid">
            <CardNumberElement
              options={{
                showIcon: true,
                placeholder: "Kortnummer",
              }}
              className="input"
            />

            <CardExpiryElement className="input" />

            <CardCvcElement className="input" />
          </div>
          <p className="text-s-r">
            Dina betalningsuppgifter hanteras säkert av Stripe
          </p>
        </div>
        <div className="button-container">
          <Button
            disabled={disabled}
            label="Spara"
            color="black"
            width="200px"
            short
          />
        </div>
      </form>
    </>
  );
}

export default CompanySettingsAddCard;
