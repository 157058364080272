import "./CashflowReportHeader.scss";

interface ICashflowReportHeaderProps {
  labels: [string, string, string];
}

function CashflowReportHeader(props: ICashflowReportHeaderProps) {
  return (
    <tr className="cashflow-report-header text-s-m">
      <td />
      <td>{props.labels[0]}</td>
      <td>{props.labels[1]}</td>
      <td>{props.labels[2] || ""}</td>
      <td />
      <td />
    </tr>
  );
}

export default CashflowReportHeader;
