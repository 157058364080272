import { NavLink } from "react-router-dom";
import Button from "../UI/Button/Button";
import "./ParentRouteNav.scss";

interface IParentRouteNavProps {
  navItems: { path: string; label: string }[];
  noMargin?: boolean;
}

function ParentRouteNav(props: IParentRouteNavProps) {
  return (
    <div
      className="parent-route-nav"
      style={
        props.noMargin
          ? {
              margin: "0",
            }
          : {}
      }
    >
      {props.navItems.map((nav) => (
        <NavLink
          key={nav.label}
          to={nav.path}
          children={({ isActive }) => (
            <Button label={nav.label} color={isActive ? "black" : "white"} />
          )}
        />
      ))}
    </div>
  );
}

export default ParentRouteNav;
