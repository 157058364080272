import {
  addYears,
  eachMonthOfInterval,
  fromUnixTime,
  getUnixTime,
  lightFormat,
  startOfMonth,
  subYears,
} from "date-fns";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { updateBudget } from "../../api/budget";
import { getLiquidityReportData } from "../../api/report";
import LineChart from "../../components/Charts/LineChart/LineChart";
import LiquidityReport from "../../components/LiquidityReport/LiquidityReport";
import PaymentSheet from "../../components/PaymentSheet/PaymentSheet";
import Button from "../../components/UI/Button/Button";
import Dropdown from "../../components/UI/Dropdown/Dropdown";
import Spinner from "../../components/UI/Spinner/Spinner";
import ChartWrapper from "../../hoc/ChartWrapper/ChartWrapper";
import { convertBudgetToPeriodBalances } from "../../shared/budget";
import clone from "../../shared/clone";
import { useFutureValues } from "../../shared/hooks/useFutureValues";
import {
  calculateLiquidityReport,
  vatPeriodTypes,
} from "../../shared/liquidity";
import {
  months,
  paymentTermsDaysChoices,
  vatPeriods,
} from "../../shared/values";
import { IBudgetPeriod, ILiquidityData } from "../../types/api";
import { IReduxState } from "../../types/redux";
import "./Forecast.scss";

interface IForecastProps {}

function Forecast(props: IForecastProps) {
  const { companyId, financialYear, settings } = useSelector(
    (state: IReduxState) => state.company
  );

  const { setControlsComponent } = useFutureValues();

  const [initialLiquidity, setInitialLiquidity] = useState(0);
  const [vatPeriod, setVatPeriod] = useState<vatPeriodTypes>(
    settings.vatPeriod
  );
  const [paymentsTermsDays, setPaymentsTermsDays] = useState(
    settings.paymentsTermsDays
  );
  const [editBudget, setEditBudget] = useState<IBudgetPeriod[]>([]);

  useEffect(() => {
    setVatPeriod(settings.vatPeriod || "MONTHLY");
    setPaymentsTermsDays(settings.paymentsTermsDays || "30");
  }, [settings.paymentsTermsDays, settings.vatPeriod]);

  useEffect(() => {
    setControlsComponent(
      <>
        <Dropdown
          title="Momsperiod"
          options={vatPeriods}
          onSelect={(value) => setVatPeriod(value as vatPeriodTypes)}
          value={vatPeriod}
        />
        <Dropdown
          options={paymentTermsDaysChoices}
          title="Betalningsvillkor"
          onSelect={(v) => setPaymentsTermsDays(v)}
          value={paymentsTermsDays}
        />
      </>
    );
  }, [paymentsTermsDays, setControlsComponent, vatPeriod]);

  const fromDate = startOfMonth(new Date());

  const from = getUnixTime(fromDate);

  const to = getUnixTime(addYears(fromUnixTime(from), 1)) - 1;

  const {
    data: liquidityData,
    status: dataStatus,
    refetch,
    isFetching,
  } = useQuery<
    ILiquidityData,
    unknown,
    ILiquidityData,
    ["liquidity-data", number, number, string, string]
  >({
    queryKey: ["liquidity-data", from, to, companyId, financialYear.identifier],
    queryFn: async ({ queryKey: [, from, to, companyId, financialYear] }) => {
      const liquidityData = await getLiquidityReportData({
        from,
        to,
        companyId,
        financialYear,
      });

      setInitialLiquidity(liquidityData.initialLiquidity || 0);
      setEditBudget(liquidityData.budget || []);

      return liquidityData;
    },
  });

  async function onClaimsChange(
    pb: {
      month: number;
      year: number;
      accountNr: number;
      value: number;
    },
    title: string
  ) {
    setEditBudget((state) => {
      const newBudget = clone(state || []);

      const insertIndex = newBudget.findIndex(
        (bp) => bp.year === pb.year && bp.month === pb.month
      );

      if (insertIndex === -1) {
        newBudget.push({
          year: pb.year,
          month: pb.month,
          rows: [
            {
              accountRange: {
                from: pb.accountNr,
                to: pb.accountNr,
              },
              value: pb.value,
              title,
            },
          ],
        });
      } else {
        const accountIndex = newBudget[insertIndex].rows.findIndex(
          (r) =>
            r.accountRange.from === pb.accountNr &&
            r.accountRange.to === pb.accountNr
        );

        if (accountIndex === -1) {
          newBudget[insertIndex].rows.push({
            accountRange: {
              from: pb.accountNr,
              to: pb.accountNr,
            },
            value: pb.value,
            title,
          });
        } else {
          newBudget[insertIndex].rows[accountIndex].value = pb.value;
        }
      }

      updateBudget({ companyId, budgetPeriods: newBudget });

      return newBudget;
    });
  }

  function getChartLabel(from: number, to: number) {
    const fromYear = fromUnixTime(from).getFullYear();
    const toYear = fromUnixTime(to).getFullYear();

    if (fromYear === toYear) {
      return fromYear.toString();
    }

    return `${fromYear}/${toYear}`;
  }

  const liquidityReport = calculateLiquidityReport({
    from,
    to,
    initialLiquidity,
    periodBalances: liquidityData?.periodBalances || {},
    budget: liquidityData?.budget || [],
    vatPeriod,
    delayDays: +paymentsTermsDays,
    accounts: liquidityData?.accounts || [],
  });

  const prevYearMonthsArray = eachMonthOfInterval({
    start: subYears(fromUnixTime(from), 1),
    end: subYears(fromUnixTime(to), 1),
  }).map((d) => lightFormat(d, "yyyy-M"));

  const monthsArray = eachMonthOfInterval({
    start: fromUnixTime(from),
    end: fromUnixTime(to),
  }).map((d) => lightFormat(d, "yyyy-M"));

  const budgetPbs = convertBudgetToPeriodBalances(editBudget || []);

  return (
    <div className="forecast">
      {dataStatus === "success" && (
        <ChartWrapper
          leftLegend={[
            {
              label: "Likviditetsprognos",
              color: "#fff",
              textColor: "#fff",
              noDot: true,
            },
          ]}
          rightLegend={[
            {
              label: getChartLabel(from, to),
              color: "#5B93FF",
              textColor: "#fff",
            },
            {
              label: getChartLabel(
                getUnixTime(subYears(fromUnixTime(from), 1)),
                getUnixTime(subYears(fromUnixTime(to), 1))
              ),
              color: "#5B93FF99",
              textColor: "#fff",
            },
          ]}
        >
          <LineChart
            labels={[
              ...prevYearMonthsArray.map((mKey) =>
                months[+mKey.split("-")[1] - 1].label.slice(0, 3)
              ),
              "UB",
            ]}
            datasets={[
              {
                label: getChartLabel(from, to),
                data: [
                  ...Object.entries(liquidityReport.liquidityMonthStart)
                    .filter(([key]) => +key >= 0 && +key <= 12)
                    .map(([, val]) => val),
                ],
                borderColor: "#5B93FF",
                backgroundColor: "transparent",
              },
              {
                label: getChartLabel(
                  getUnixTime(subYears(fromUnixTime(from), 1)),
                  getUnixTime(subYears(fromUnixTime(to), 1))
                ),
                data: [
                  ...prevYearMonthsArray.map(
                    (mKey) =>
                      liquidityData.assets?.["Kassa och bank"][mKey] || 0
                  ),
                  liquidityReport.initialLiquidity || 0,
                ],
                borderColor: "#5B93FF99",
                backgroundColor: "transparent",
              },
            ]}
          />
        </ChartWrapper>
      )}
      {dataStatus === "success" ? (
        <>
          <LiquidityReport
            from={from}
            to={to}
            liquidityReport={liquidityReport}
            initialLiquidity={initialLiquidity}
            onInitialLiquidityChanged={setInitialLiquidity}
          />
          <div className="reload-wrapper">
            <Button
              color="black"
              label="Uppdatera prognos"
              onClick={refetch}
              isLoading={isFetching}
            />
          </div>
          <PaymentSheet
            title="Fordingsbetalningar"
            from={from}
            to={to}
            onEdit={onClaimsChange}
            periodBalances={liquidityData?.periodBalances || {}}
            budgetPbs={budgetPbs}
            accounts={liquidityData?.accounts || []}
            accountRange={{
              from: 1500,
              to: 1700,
            }}
            type="claims"
          />
          <div className="reload-wrapper">
            <Button
              color="black"
              label="Uppdatera prognos"
              onClick={refetch}
              isLoading={isFetching}
            />
          </div>
          <PaymentSheet
            title="Skuldbetalningar"
            from={from}
            to={to}
            onEdit={onClaimsChange}
            periodBalances={liquidityData?.periodBalances || {}}
            budgetPbs={budgetPbs}
            accounts={liquidityData?.accounts || []}
            accountRange={{
              from: 2200,
              to: 2900,
            }}
            type="payments"
          />
          <div className="reload-wrapper">
            <Button
              color="black"
              label="Uppdatera prognos"
              onClick={refetch}
              isLoading={isFetching}
            />
          </div>
          <PaymentSheet
            title="Manuella beräkningar"
            from={from}
            to={to}
            onEdit={onClaimsChange}
            periodBalances={{}}
            budgetPbs={{}}
            accounts={[]}
            accountRange={{
              from: 1,
              to: 3,
            }}
            type="manual"
            manualRows={[
              {
                title: "Övrig fordran",
                accountNr: 1,
                balance: Object.keys(budgetPbs["1"] || {}).reduce(
                  (total, mKey) => total + (budgetPbs["1"][mKey] || 0),
                  0
                ),
                values: monthsArray.map((mKey) => budgetPbs["1"]?.[mKey] || 0),
              },
              {
                title: "Övrig skuld",
                accountNr: 2,
                balance: Object.keys(budgetPbs["2"] || {}).reduce(
                  (total, mKey) => total + (budgetPbs["2"][mKey] || 0),
                  0
                ),
                values: monthsArray.map((mKey) => budgetPbs["2"]?.[mKey] || 0),
              },
            ]}
          />
          <div className="reload-wrapper">
            <Button
              color="black"
              label="Uppdatera prognos"
              onClick={refetch}
              isLoading={isFetching}
            />
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default Forecast;
