import {
    eachMonthOfInterval, endOfMonth, fromUnixTime, getUnixTime, lastDayOfMonth, lightFormat,
    startOfMonth, subYears
} from 'date-fns';

import axios from '../shared/axios';
import { stringToDate } from '../shared/utility';
import {
    IAccount, IAlert, IApiResponse, IBalanceReport, IBudgetPeriod, ICashflowReport, IHealthAlert,
    IKeyValues, ILiquidityData, IOverviewRow, IPeriodBalances, IResultReport, ISetHealthAlert,
    IVariable
} from '../types/api';
import { getBudget } from './budget';
import { getAccounts } from './company';

const PREFIX = "/report";

export function getOverview({
  fromString,
  toString,
  page,
  companyId,
}: {
  fromString: string;
  toString: string;
  page: number;
  companyId?: string;
}) {
  return axios.get<IApiResponse<IOverviewRow[]>>(`${PREFIX}/overview`, {
    params: {
      from: fromString,
      to: toString,
      page,
      filter: companyId || undefined,
    },
  });
}

export function getResultReport({
  from,
  to,
  companyId,
  accountLevel,
  costCenters,
  projects,
}: {
  from: number;
  to: number;
  companyId: string;
  accountLevel?: boolean;
  costCenters?: string[];
  projects?: string[];
}) {
  return axios.get<IApiResponse<IResultReport>>(
    `${PREFIX}/resultReport/${companyId}`,
    {
      params: {
        from,
        to,
        accountLevel,
        costCenters: costCenters ? costCenters.join(",") : undefined,
        projects: projects ? projects.join(",") : undefined,
      },
    }
  );
}

export function getBalanceReport({
  from,
  to,
  companyId,
}: {
  from: number;
  to: number;
  companyId: string;
}) {
  return axios.get<IApiResponse<IBalanceReport>>(
    `${PREFIX}/balanceReport/${companyId}`,
    {
      params: {
        from,
        to,
      },
    }
  );
}

export function getCashflowReport({
  from,
  to,
  companyId,
}: {
  from: number;
  to: number;
  companyId: string;
}) {
  return axios.get<IApiResponse<ICashflowReport>>(
    `${PREFIX}/cashflowReport/${companyId}`,
    {
      params: {
        from,
        to,
      },
    }
  );
}

export function getPeriodBalances({
  from,
  to,
  companyId,
}: {
  from: number;
  to: number;
  companyId: string;
}) {
  return axios.get<IApiResponse<IPeriodBalances>>(
    `${PREFIX}/periodBalances/${companyId}`,
    {
      params: {
        from,
        to,
        budget: false,
      },
    }
  );
}

export async function getLiquidityReportData({
  from,
  to,
  companyId,
  financialYear,
}: {
  from: number;
  to: number;
  companyId: string;
  financialYear: string;
}) {
  let periodBalances: IPeriodBalances = {};
  let budget: IBudgetPeriod[] = [];
  let balanceReport: IBalanceReport | undefined;
  let initialLiquidity: number = 0;
  let accountsData: IAccount[] = [];

  const budgetFromDate = fromUnixTime(from);
  const budgetToDate = fromUnixTime(to);

  const realFrom = getUnixTime(subYears(budgetFromDate, 1));
  const realTo = getUnixTime(subYears(budgetToDate, 1));

  const [pbRes, budgetRes, balanceReportRes, initLiqRes, accountsRes] =
    await Promise.allSettled([
      getPeriodBalances({ from: realFrom, to: realTo, companyId }),
      getBudget({
        fromMonth: lightFormat(budgetFromDate, "M"),
        fromYear: lightFormat(budgetFromDate, "yyyy"),
        toMonth: lightFormat(budgetToDate, "M"),
        toYear: lightFormat(budgetToDate, "yyyy"),
        companyId,
      }),
      getBalanceReport({ from: realFrom, to: realTo, companyId }),
      getBalanceReport({
        from: from,
        to: getUnixTime(lastDayOfMonth(fromUnixTime(from))),
        companyId,
      }),
      getAccounts({
        companyId,
        financialYear,
      }),
    ]);

  if (pbRes.status === "fulfilled") {
    periodBalances = pbRes.value.data.payload;
  }
  if (budgetRes.status === "fulfilled") {
    budget = budgetRes.value.data.payload;
  }
  if (balanceReportRes.status === "fulfilled") {
    balanceReport = balanceReportRes.value.data.payload;
  }
  if (initLiqRes.status === "fulfilled") {
    initialLiquidity =
      initLiqRes.value.data.payload.Tillgångar["Kassa och bank"]?.IB || 0;
  }
  if (accountsRes.status === "fulfilled") {
    accountsData = accountsRes.value.data.payload;
  }

  const result: ILiquidityData = {
    periodBalances,
    budget,
    assets: balanceReport?.Tillgångar,
    initialLiquidity,
    accounts: accountsData,
  };

  return result;
}

export function getCustomVariables(companyId: string) {
  return axios.get<IApiResponse<{ variables: IVariable[] }>>(
    `${PREFIX}/variables`,
    {
      params: { companyId },
    }
  );
}

export function calculateVariables({
  companyId,
  variableStrings,
}: {
  companyId: string;
  variableStrings: string[];
}) {
  return axios.post<IApiResponse<Array<number | boolean | string>>>(
    `${PREFIX}/calculateVariables`,
    {
      companyId,
      variableStrings,
    }
  );
}

export async function getVariablesResult({
  companyId,
  variables,
  from,
  to,
  budget,
}: {
  companyId: string;
  variables: string[];
  from: number;
  to: number;
  budget?: boolean;
}) {
  const result: IKeyValues = {};
  let variableStrings: string[] = [];
  const calcResult: (string | number | boolean)[] = [];
  const promises = [];

  const monthsArray = eachMonthOfInterval({
    start: fromUnixTime(from),
    end: fromUnixTime(to),
  }).map((d) => lightFormat(d, "yyyy-M"));

  for (let i = 0; i < variables.length; i++) {
    const variable = variables[i];

    if (!result[variable]) result[variable] = {};

    for (let j = 0; j < monthsArray.length + 1; j++) {
      if (j === monthsArray.length) {
        if (!result[variable]["Sum"]) result[variable]["Sum"] = 0;
        variableStrings.push(
          `$${variable}${budget ? ".bgt" : ""}{${from}:${to}}`
        );
        continue;
      }

      const mKey = monthsArray[j];
      if (!result[variable][mKey]) result[variable][mKey] = 0;

      const start = getUnixTime(startOfMonth(stringToDate(mKey)));
      const end = getUnixTime(endOfMonth(stringToDate(mKey)));

      variableStrings.push(
        `$${variable}${budget ? ".bgt" : ""}{${start}:${end}}`
      );
    }

    promises.push(
      calculateVariables({
        companyId,
        variableStrings,
      })
    );

    variableStrings = [];
  }

  const res = await Promise.all(promises);

  for (let i = 0; i < res.length; i++) {
    calcResult.push(...res[i].data.payload);
  }

  for (let i = 0; i < variables.length; i++) {
    const variable = variables[i];

    const variableCalcResult = calcResult.splice(0, monthsArray.length + 1);

    const resultKeys = Object.keys(result[variable]);

    for (let j = 0; j < resultKeys.length; j++) {
      const dateString = resultKeys[j];

      result[variable][dateString] = variableCalcResult[j];
    }
  }

  return result;
}

export function createCustomVariable({
  companyId,
  variableString,
  name,
  isPercent,
  showInTable,
  tags,
  global,
  context,
}: {
  companyId: string;
  variableString: string;
  name: string;
  isPercent: boolean;
  showInTable: boolean;
  tags?: string[];
  global?: boolean;
  context?: string;
}) {
  return axios.post<
    IApiResponse<{
      name: string;
      result: number;
      variableId: string;
    }>
  >(`${PREFIX}/createVariable`, {
    companyId,
    variableString,
    name,
    isPercent,
    showInTable,
    global,
    tags,
    context,
  });
}

export function updateCustomVariable({
  variableString,
  name,
  isPercent,
  showInTable,
  variableId,
  tags,
  global,
  context,
}: {
  variableString: string;
  name: string;
  isPercent: boolean;
  showInTable: boolean;
  variableId: string;
  tags?: string[];
  global?: boolean;
  context?: string;
}) {
  return axios.post<
    IApiResponse<{
      name: string;
      result: number;
      variableId: string;
    }>
  >(`${PREFIX}/editVariable`, {
    variableString,
    name,
    isPercent,
    showInTable,
    variableId,
    tags,
    global,
    context,
  });
}

export function updateAlert({
  variableString,
  name,
  isPercent,
  global,
  description,
  tags,
  variableId,
}: {
  variableString: string;
  name: string;
  isPercent: boolean;
  global?: boolean;
  description?: string;
  tags?: string[];
  variableId: string;
}) {
  return axios.post<
    IApiResponse<{
      name: string;
      result: number;
      variableId: string;
    }>
  >(`${PREFIX}/editVariable`, {
    variableString,
    name,
    isPercent,
    global,
    description,
    tags,
    variableId,
    isAlert: true,
  });
}

export function deleteCustomVariable({
  companyId,
  variableId,
  isAlert,
}: {
  companyId: string;
  variableId: string;
  isAlert?: boolean;
}) {
  return axios.delete<IApiResponse<{}>>(`${PREFIX}/variable`, {
    params: {
      companyId,
      variableId,
      alert: isAlert,
    },
  });
}

export function getCustomAlerts(companyId: string) {
  return axios.get<IApiResponse<{ variables: IVariable[] }>>(
    `${PREFIX}/variables`,
    {
      params: { companyId, alert: true },
    }
  );
}

export function genEconomicAlerts({
  companyId,
  from,
  to,
  variableStrings,
}: {
  companyId: string;
  from: number;
  to: number;
  variableStrings?: string[];
}) {
  return axios.post<
    IApiResponse<{ variable: string; value: number | string }[]>
  >(`${PREFIX}/generateAlerts`, {
    from,
    to,
    variableStrings,
    companyId,
  });
}

export async function generateEconomicAlerts({
  companyId,
  from,
  to,
  variables,
}: {
  companyId: string;
  from: number;
  to: number;
  variables: IVariable[];
}) {
  const result: IAlert[] = [];

  const alertStrings: string[] = [];

  for (let i = 0; i < variables.length; i++) {
    const variable = variables[i];

    alertStrings.push(`$${variable.name}{${from}:${to}}`);
  }

  const res = await genEconomicAlerts({
    companyId,
    from,
    to,
    variableStrings: alertStrings,
  });

  for (let i = 0; i < res.data.payload.length; i++) {
    const alertResult = res.data.payload[i];

    result.push({
      ...variables[i],
      result: alertResult.value,
    });
  }

  return result;
}

export function createEconomicAlert({
  companyId,
  variableString,
  name,
  isPercent,
  global,
  description,
  tags,
}: {
  companyId: string;
  variableString: string;
  name: string;
  isPercent: boolean;
  global?: boolean;
  description?: string;
  tags?: string[];
}) {
  return axios.post<IApiResponse<{}>>(`${PREFIX}/createAlert`, {
    companyId,
    variableString,
    name,
    isPercent,
    showInTable: false,
    global,
    description,
    tags,
  });
}

export function updateEconomicAlert({
  companyId,
  variableString,
  name,
  isPercent,
  variableId,
  global,
  description,
}: {
  companyId: string;
  variableString: string;
  name: string;
  isPercent: boolean;
  variableId: string;
  global?: boolean;
  description?: string;
}) {
  return axios.post<
    IApiResponse<{
      name: string;
      result: number;
      variableId: string;
    }>
  >(`${PREFIX}/editVariable`, {
    companyId,
    variableString,
    name,
    isPercent,
    showInTable: false,
    variableId,
    global,
    description,
  });
}

export function setHealthAlert(data: ISetHealthAlert) {
  return axios.post(`${PREFIX}/setHealthAlert`, data);
}

export function getHealthAlerts(companyId: string) {
  return axios.get(`${PREFIX}/healthAlerts/${companyId}`);
}

export function deleteHealthAlert(id: string) {
  return axios.delete(`${PREFIX}/healthAlert/${id}`);
}