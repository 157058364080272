import { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getConsultingClients } from "../../api/consult";
import {
  addCompanyToGroup,
  getCompaniesInGroup,
  getUsersInGroup,
} from "../../api/group";
import Button from "../../components/UI/Button/Button";
import Dropdown from "../../components/UI/Dropdown/Dropdown";
import Input from "../../components/UI/Input/Input";
import { convertToDropdown } from "../../shared/utility";
import "./GroupCompanies.scss";

interface IGroupCompaniesProps {
  groupId: string;
  onSave: (groupId: string) => void;
  bottomLabel?: string;
  agencyId: string;
  saveButtonLabel?: string;
}

function GroupCompanies(props: IGroupCompaniesProps) {
  const [newCompanyId, setNewCompanyId] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [filterString, setFilterString] = useState("");

  const { data, status, refetch } = useQuery({
    queryKey: ["group", props.groupId],
    queryFn: async ({ queryKey: [, groupId] }) => {
      const [cRes, uRes] = await Promise.all([
        getCompaniesInGroup(groupId),
        getUsersInGroup(groupId),
      ]);

      return {
        companies: cRes.data.payload,
        users: uRes.data.payload,
      };
    },
    enabled: !!props.groupId,
  });

  const { data: companies } = useQuery({
    queryKey: ["accounting-firm-customers", props.agencyId],
    queryFn: async ({ queryKey: [, agencyId] }) => {
      const res = await getConsultingClients(agencyId);
      return res.data.payload;
    },
  });

  async function addCompanyToGroupHandler(companyId: string) {
    setIsAdding(true);

    try {
      await addCompanyToGroup({ groupId: props.groupId, companyId });
      await refetch();
      setNewCompanyId("");
      setIsAdding(false);
      toast.success("Företaget lades till i gruppen");
    } catch (err) {
      toast.error("Något gick fel");
      setIsAdding(false);
    }
  }

  async function removeCompanyFromGroupHandler(companyId: string) {}

  return (
    <div className="group-companies">
      {!data?.companies.length && (
        <div className="title text-m-r">Välj företag i gruppen</div>
      )}
      {!!data?.companies.length && (
        <div className="filter">
          <Input
            type="text"
            value={filterString}
            onChange={setFilterString}
            short
            width="50%"
            placeholder="Filtrera företag"
          />
        </div>
      )}
      {status === "success" && (
        <div className="companies">
          {data.companies
            .filter((company) =>
              filterString.length >= 2
                ? company.name
                    .toLowerCase()
                    .includes(filterString.toLowerCase())
                : true
            )
            .map((company) => (
              <div className="group-company text-s-r" key={company._id}>
                {company.name}
                <div
                  className="delete waves-effect"
                  onClick={() => removeCompanyFromGroupHandler(company._id)}
                >
                  <i className="fa-light fa-trash-can" />
                </div>
              </div>
            ))}
        </div>
      )}
      <form
        className="new-company"
        onSubmit={(e) => {
          e.preventDefault();
          addCompanyToGroupHandler(newCompanyId);
        }}
      >
        <Dropdown
          title="Välj företag"
          options={convertToDropdown({
            list: companies?.activeCompanies || [],
            labelKey: "name",
            valueKey: "_id",
          })}
          value={newCompanyId}
          onSelect={setNewCompanyId}
          isSearchable
          short
          width="50%"
        />
        <Button
          color="black"
          label="Lägg till företag"
          isLoading={isAdding}
          short
        />
      </form>
      <div className="group-companies-footer">
        <p className="label text-m-r">{props.bottomLabel}</p>
        <Button
          color="black"
          label={props.saveButtonLabel || "Spara och fortsätt"}
          onClick={() => props.onSave(props.groupId)}
          short
        />
      </div>
    </div>
  );
}

export default GroupCompanies;
