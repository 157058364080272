import { useEffect, useState } from "react";
import { convertToNumberWithSpaces } from "../../../shared/utility";
import PaymentSheetInput from "../PaymentSheetInput/PaymentSheetInput";
import "./PaymentSheetRow.scss";

interface IPaymentSheetRowProps {
  title: string;
  value: number;
  accountNr: number;
  values: number[];
  onEdit: (
    pb: {
      month: number;
      year: number;
      accountNr: number;
      value: number;
    },
    title: string
  ) => void;
  dates: string[];
  backgroundColor?: string;
}

function PaymentSheetRow(props: IPaymentSheetRowProps) {
  const [values, setValues] = useState<number[]>(props.values);

  useEffect(() => {
    setValues(props.values);
  }, [props.values]);

  const inputs = [];

  for (let i = 0; i < props.dates.length; i++) {
    inputs.push(
      <PaymentSheetInput
        key={i}
        value={values[i] || 0}
        onEdit={(value) => {
          const newValues = [...values];
          newValues[i] = value;
          setValues(newValues);
          const [year, month] = props.dates[i].split("-");
          props.onEdit(
            {
              accountNr: props.accountNr,
              value: value,
              month: +month,
              year: +year,
            },
            props.title
          );
        }}
      />
    );
  }

  let changedValue =
    props.value - values.reduce((prev, v) => prev + (v || 0), 0);

  return (
    <>
      <tr
        className="payment-sheet-row-title"
        style={
          props.backgroundColor
            ? { backgroundColor: props.backgroundColor }
            : {}
        }
      >
        <td colSpan={100}>
          <div className="title text-s-r">
            <span>{`${props.accountNr} ${props.title}`}</span>
            <span>
              {`${convertToNumberWithSpaces(
                props.value
              )} / ${convertToNumberWithSpaces(changedValue)}`}
            </span>
          </div>
        </td>
      </tr>
      <tr className="payment-sheet-row">{inputs}</tr>
    </>
  );
}

export default PaymentSheetRow;
