import { lightFormat } from "date-fns";
import { motion, Variants } from "framer-motion";
import { useState } from "react";
import { convertToNumberWithSpacesDecimals } from "../../shared/utility";
import { IVoucherRow } from "../../types/api";
import VoucherRow from "../VoucherRow/VoucherRow";
import "./Voucher.scss";

interface IVoucherProps {
  description: string;
  voucherSerie: string;
  transactionDate: number;
  rows: IVoucherRow[];
}

function Voucher(props: IVoucherProps) {
  const [isOpen, setIsOpen] = useState(false);

  const debetTotal = props.rows.reduce(
    (total, row) => total + (row.removed ? 0 : +row.debit),
    0
  );
  /*const creditTotal = props.rows.reduce((total, row) => total + +row.credit, 0);*/

  const rowTablesVariants: Variants = {
    closed: {
      height: 0,
      transition: {
        duration: 0.3,
      },
    },
    open: {
      height: "auto",
      transition: {
        duration: 0.3,
      },
    },
  };

  return (
    <div className="voucher-wrapper">
      <div className="voucher" onClick={() => setIsOpen(!isOpen)}>
        <div className="serie">{props.voucherSerie}</div>
        <div className="description">{props.description}</div>
        <div className="date">
          {lightFormat(new Date(props.transactionDate * 1000), "yyyy-MM-dd")}
        </div>
        <div className="sum">
          Summa: {convertToNumberWithSpacesDecimals(debetTotal, "kr")}
        </div>
        <div className="arrow">
          <i
            className="fa-solid fa-caret-down"
            style={
              isOpen
                ? { transform: "rotate(180deg)" }
                : { transform: "rotate(0deg)" }
            }
          />
        </div>
      </div>
      <motion.div
        className="voucher-rows"
        variants={rowTablesVariants}
        animate={isOpen ? "open" : "closed"}
      >
        <table className="voucher-rows-table">
          <thead>
            <tr>
              <td className="account">Konto</td>
              <td>KS</td>
              <td>Benämning</td>
              <td>Transaktionsinfo</td>
              <td>Debet</td>
              <td>Kredit</td>
            </tr>
          </thead>
          <tbody>
            {props.rows.map((vRow, i) => (
              <VoucherRow
                key={i}
                accountNr={vRow.account}
                costCenter={vRow.costcenter}
                description={vRow.description}
                transactionInfo={vRow.transactioninformation}
                debit={+vRow.debit}
                credit={+vRow.credit}
                isRemoved={vRow.removed}
              />
            ))}
          </tbody>
        </table>
      </motion.div>
    </div>
  );
}

export default Voucher;
