import adminAxios from "../shared/adminAxios";
import { API_MAX_PER_PAGE } from "../shared/enums";
import {
  IAdminSingleUser,
  IAdminUser,
  IAdminUserFilter,
  IAdminUserSort,
  IApiResponse,
  IConsultAgency,
} from "../types/api";

const PREFIX = "/admin";

export function adminGetUsers({
  filter,
  sort,
  page,
  perPage = API_MAX_PER_PAGE.AdminUsers,
}: {
  filter?: IAdminUserFilter;
  sort?: IAdminUserSort;
  page: number;
  perPage?: number;
}) {
  return adminAxios.post<
    IApiResponse<{
      users: IAdminUser[];
    }>
  >(`${PREFIX}/users`, {
    filter,
    sort,
    page,
    perPage,
  });
}

export function adminGetUser(userId: string) {
  return adminAxios.get<IApiResponse<{ user: IAdminSingleUser }>>(
    `${PREFIX}/user/${userId}`
  );
}

export function adminGetConsultQueue({
  page,
  perPage = API_MAX_PER_PAGE.AdminUsers,
}: {
  page: number;
  perPage?: number;
}) {
  return adminAxios.get<
    IApiResponse<{
      consultingCompanies: {
        _id: string;
        name: string;
        orgNr: string;
        created: number;
        contactPerson: string;
      }[];
    }>
  >(`${PREFIX}/consultQueue`, {
    params: {
      page,
      perPage,
    },
  });
}

export function adminGetConsults({
  page,
  perPage = API_MAX_PER_PAGE.AdminConsults,
}: {
  page: number;
  perPage?: number;
}) {
  return adminAxios.get<
    IApiResponse<{ consultingCompanies: IConsultAgency[] }>
  >(`${PREFIX}/listAgencies`, {
    params: {
      page,
      perPage,
    },
  });
}

export function adminGetConsult(consultId: string) {
  return adminAxios.get<
    IApiResponse<{
      _id: string;
      orgNr: string;
      address: string;
      address2: string;
      city: string;
      contactPerson: {
        email: string;
        firstName: string;
        lastName: string;
        ssn: string;
      };
      country: string;
      created: number;
      createdBy: string;
      isActive: boolean;
      isConsultingAgency: boolean;
      name: string;
      updated: number;
      zipcode: string;
    }>
  >(`${PREFIX}/consultAgency/${consultId}`);
}

export function adminConfirmConsult({
  consultId,
  accept,
}: {
  consultId: string;
  accept: boolean;
}) {
  return adminAxios.post<IApiResponse<{}>>(`${PREFIX}/confirmConsultAgency`, {
    consultId,
    accept,
  });
}
