import axios from "../shared/axios";
import { IApiResponse, ICards } from "../types/api";

const PREFIX = "/payment";

export function getCompanyInfoFilled(companyId: string) {
  return axios.get(`${PREFIX}/company/${companyId}`);
}

export function getCompanyCards(companyId: string) {
  return axios.get<IApiResponse<ICards>>(
    `${PREFIX}/customer/cards/${companyId}`
  );
}

export function customerSetupIntent(companyId: string) {
  const data = { companyId: companyId };
  return axios.post(`${PREFIX}/customer/setupIntent`, data);
}

export function setDefaultCard(companyId: string, paymentMethodId: string) {
  const data = {
    companyId: companyId,
    paymentMethodId: paymentMethodId,
  };
  return axios.post(`${PREFIX}/customer/cards/default`, data);
}

export function deleteCard(companyId: string, paymentMethodId: string) {
  const data = {
    companyId: companyId,
    paymentMethodId: paymentMethodId,
  };
  return axios.post(`${PREFIX}/customer/cards/delete`, data);
}

export function createCustomer(data: {
  companyId: string;
  email: string;
  country?: string;
  city?: string;
  address1?: string;
  address2?: string;
  postalCode?: string;
  reference: string;
  vatCode: string;
}) {
  return axios.post(`${PREFIX}/customer`, data);
}

export function updateCustomer(data: {
  companyId: string;
  email: string;
  country?: string;
  city?: string;
  address1?: string;
  address2?: string;
  postalCode?: string;
  reference: string;
  vatCode: string;
}) {
  return axios.post(`${PREFIX}/customer/update`, data);
}
