import "./MessageListItem.scss";
import defaultProfileImage from "../../assets/images/default-profile-image.svg";

interface IMessageListItemProps {
  title: string;
  name: string;
  companyName: string;
  onClick: () => void;
  base64Image?: string;
  isUnread?: boolean;
  isShowingAll?: boolean;
}

function MessageListItem(props: IMessageListItemProps) {
  return (
    <div className="message-list-item text-m-r" onClick={props.onClick}>
      {props.isUnread && <div className="unread" />}
      {!props.isShowingAll && (
        <img
          src={
            props.base64Image
              ? `data:image/png;base64,${props.base64Image}`
              : defaultProfileImage
          }
          alt="Profil bild"
        />
      )}
      <div className="content">
        <div className="top">
          {props.isShowingAll ? props.companyName : props.name}
        </div>
        <div className="bottom">{props.title}</div>
      </div>
    </div>
  );
}

export default MessageListItem;
