import { useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createTag, deleteTag, getTags } from "../../api/company";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import { useClickOutside } from "../../shared/hooks/useClickOutside";
import { IReduxState } from "../../types/redux";
import Tag from "./Tag/Tag";
import "./TagsSelector.scss";

interface ITagsSelectorProps {
  selectedTags: string[];
  onTagSelected: (name: string, checked: boolean) => void;
}

function TagsSelector(props: ITagsSelectorProps) {
  const { companyId } = useSelector((state: IReduxState) => state.company);
  const selectorRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useClickOutside([selectorRef, buttonRef], onClickOutside);

  function onClickOutside() {
    setIsOpen(false);
  }

  const [isOpen, setIsOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [newTagName, setNewTagName] = useState("");

  const { data, isSuccess, refetch } = useQuery({
    queryKey: ["custom-tags", companyId],
    queryFn: async ({ queryKey: [, companyId] }) => {
      const res = await getTags(companyId);
      return res.data.payload.tags;
    },
  });

  async function createTagHandler() {
    if (!newTagName) {
      toast.error("Du måste ange ett namn för taggen");
      return;
    }

    setIsAdding(true);
    try {
      await createTag({ companyId, name: newTagName });
      setNewTagName("");
      setIsAdding(false);
      refetch();
    } catch (error) {}
  }

  async function deleteTagHandler(tagId: string) {
    await deleteTag(tagId);
    await refetch();
    toast.success("Taggen har tagits bort");
  }

  return (
    <div className="tags-selector-wrapper">
      <button
        className="tags-button text-m-r"
        onClick={() => setIsOpen((state) => !state)}
        ref={buttonRef}
      >
        <span
          style={{
            color: props.selectedTags.length > 0 ? "#fff" : "#A6A6AF",
          }}
        >
          #{" "}
          {props.selectedTags.length > 0
            ? props.selectedTags.join(", ")
            : "Välj taggar"}
        </span>
        <i className={`fa-solid fa-caret-${isOpen ? "up" : "down"}`} />
      </button>
      {isOpen && isSuccess && (
        <div className="tag-selector" ref={selectorRef}>
          {!!data.length && (
            <p className="text-xs-m title"># Befintliga taggar</p>
          )}
          <div className="tags">
            {data.map((t) => (
              <Tag
                key={t._id}
                name={t.name}
                isActive={props.selectedTags.includes(t.name)}
                onCheck={(checked) => props.onTagSelected(t.name, checked)}
                onDelete={() => deleteTagHandler(t._id)}
              />
            ))}
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              createTagHandler();
            }}
          >
            <p className="text-xs-m title"># Skapa ny tagg</p>
            <div>
              <Input
                type="text"
                value={newTagName}
                onChange={setNewTagName}
                width="100%"
                placeholder="Namn"
                short
              />
              <Button
                color="black"
                label="Skapa"
                isLoading={isAdding}
                width="80px"
                short
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default TagsSelector;
