import { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  addUserToGroup,
  getCompaniesInGroup,
  getUsersInGroup,
  removeUserFromGroup,
  updateUserGroupRole,
} from "../../api/group";
import Dot from "../../components/Dot/Dot";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import { API_MESSAGE } from "../../shared/enums";
import { nimyaAlert } from "../../shared/nimyaAlert";
import "./GroupUsers.scss";

interface IGroupUsersProps {
  groupId: string;
  onSave: (groupId: string) => void;
  bottomLabel?: string;
  isEditing?: boolean;
}

function GroupUsers(props: IGroupUsersProps) {
  const [isAddingMemeber, setIsAddingMemeber] = useState(false);
  const [newMemberEmail, setNewMemberEmail] = useState("");

  const { data, status, refetch } = useQuery({
    queryKey: ["group", props.groupId],
    queryFn: async ({ queryKey: [, groupId] }) => {
      const [cRes, uRes] = await Promise.all([
        getCompaniesInGroup(groupId),
        getUsersInGroup(groupId),
      ]);

      return {
        companies: cRes.data.payload,
        users: uRes.data.payload,
      };
    },
    enabled: !!props.groupId,
  });

  async function addMemberToGroupHandler(email: string) {
    if (!newMemberEmail) {
      toast.error("Du måste ange en email för användaren");
      return;
    }

    setIsAddingMemeber(true);

    try {
      await addUserToGroup({ groupId: props.groupId, email });
      await refetch();
      setNewMemberEmail("");
      setIsAddingMemeber(false);
      toast.success("Användare tillagd");
    } catch (error: any) {
      if (error.response.data.message === API_MESSAGE.UserNotFound) {
        toast.error("Användaren hittades inte");
      } else {
        toast.error("Något gick fel");
      }
      setIsAddingMemeber(false);
    }
  }

  async function removeMemberFromGroupHandler(userId: string) {
    try {
      await removeUserFromGroup({ groupId: props.groupId, userId });
      await refetch();
      toast.success("Användare borttagen");
    } catch (error) {
      toast.error("Något gick fel");
    }
  }

  const adminClickHandler = async (
    groupId: string,
    userId: string,
    shouldPromote: boolean
  ) => {
    const confirmText = shouldPromote
      ? "Är du säker på att du vill tilldela adminroll för denna användare?"
      : "Är du säker på att du vill ta bort adminroll för denna användare?";

    if (
      !(await nimyaAlert({
        title: "Adminroll",
        message: confirmText,
      }))
    )
      return;

    await updateUserGroupRole({
      groupId,
      userId,
      role: shouldPromote ? "admin" : "user",
    });

    const sucessText = shouldPromote
      ? "Användare uppgraderas admin"
      : "Användare degraderad till användare";

    toast.success(sucessText);

    await refetch();
  };

  return (
    <div className="group-users">
      {status === "success" && (
        <>
          <p className="title text-m-m">E-postadresser i gruppen</p>
          {data.users.map((user) => (
            <div key={user.userId} className="group-member text-s-r">
              <span>{user.email}</span>
              <Dot
                active={false}
                label=""
                icon="fa-solid fa-trash"
                onClick={() => removeMemberFromGroupHandler(user.userId)}
              />
              <Dot
                active={user.role === "admin"}
                onClick={() =>
                  adminClickHandler(
                    props.groupId,
                    user.userId,
                    user.role === "user"
                  )
                }
                label="AD"
              />
            </div>
          ))}
          <form
            className="new-member"
            onSubmit={(e) => {
              e.preventDefault();
              addMemberToGroupHandler(newMemberEmail);
            }}
          >
            <Input
              type="text"
              value={newMemberEmail}
              onChange={setNewMemberEmail}
              placeholder="Ange e-postadress"
              short
              width="50%"
            />
            <Button
              color="black"
              label="Lägg till person"
              isLoading={isAddingMemeber}
              short
            />
          </form>
          <div className="group-users-footer">
            <p className="label text-m-r">{props.bottomLabel}</p>
            <Button
              color="black"
              label={props.isEditing ? "Spara" : "Nästa"}
              onClick={() => props.onSave(props.groupId)}
              short
            />
          </div>
        </>
      )}
    </div>
  );
}

export default GroupUsers;
