import axios from "../shared/axios";
import {
  IAccount,
  IApiResponse,
  ICompany,
  ICompanySettings,
  ICompanySingle,
  ICompanyUser,
  IFinancialYear,
  ITag,
} from "../types/api";

const PREFIX = "/company";

export function getCompanies() {
  return axios.get<IApiResponse<ICompany[]>>(`${PREFIX}/list`);
}

export function getCompany(companyId: string) {
  return axios.get<IApiResponse<ICompanySingle>>(`${PREFIX}/get/${companyId}`);
}

export function getCompanySettings(companyId: string) {
  return axios.get<IApiResponse<ICompanySettings>>(
    `${PREFIX}/settings/${companyId}`
  );
}

export function updateCompanySettings({
  companyId,
  data,
}: {
  companyId: string;
  data: {
    paymentsTermsDays?: string;
    vatPeriod?: string;
    amountEmployees?: number;
    branch?: string;
    advancedBudget?: boolean;
    disabledAlerts?: string[];
  };
}) {
  return axios.post<IApiResponse<{}>>(`${PREFIX}/settings`, {
    companyId,
    settings: data,
  });
}

export function getFinancialYears({
  companyId,
  range,
}: {
  companyId: string;
  range?: { from: number; to: number };
}) {
  if (!range) {
    return axios.get<IApiResponse<IFinancialYear[]>>(
      `${PREFIX}/financialYears/${companyId}`
    );
  }
  return axios.get<IApiResponse<IFinancialYear[]>>(
    `${PREFIX}/financialYears/range/${companyId}`,
    {
      params: {
        from: range.from,
        to: range.to,
      },
    }
  );
}

export function getAccounts({
  companyId,
  financialYear,
}: {
  companyId: string;
  financialYear: string;
}) {
  return axios.get<IApiResponse<IAccount[]>>(
    `${PREFIX}/accounts/${companyId}`,
    {
      params: { year: financialYear },
    }
  );
}

export function getMyCompany({
  companyId,
  showGroups,
  groupId,
}: {
  companyId: string;
  showGroups?: boolean;
  groupId?: string;
}) {
  return axios.get<IApiResponse<ICompanyUser[]>>(`${PREFIX}/users`, {
    params: {
      companyId,
      showGroups,
      groupId,
    },
  });
}

export function resendVerificationEmail({
  email,
  companyId,
  companyName,
}: {
  email: string;
  companyId: string;
  companyName: string;
}) {
  return axios.post(`${PREFIX}/resendVerification`, {
    email,
    companyId,
    companyName,
  });
}

export function getTags(companyId: string) {
  return axios.get<IApiResponse<{ tags: ITag[] }>>(
    `${PREFIX}/tags/${companyId}`
  );
}

export function createTag({
  companyId,
  name,
}: {
  companyId: string;
  name: string;
}) {
  return axios.post(`${PREFIX}/tag`, {
    companyId,
    name,
  });
}

export function deleteTag(tagId: string) {
  return axios.delete(`${PREFIX}/tag/${tagId}`);
}

export function inviteUser({
  companyId,
  email,
}: {
  companyId: string;
  email: string;
}) {
  return axios.post<IApiResponse<{}>>(`${PREFIX}/users`, {
    companyId,
    email,
  });
}

export function deleteCompanyUser({
  companyId,
  userId,
}: {
  companyId: string;
  userId: string;
}) {
  return axios.delete(`${PREFIX}/users`, {
    params: {
      companyId,
      userId,
    },
  });
}

export function setConsult({
  companyId,
  consultId,
}: {
  companyId: string;
  consultId: string;
}) {
  return axios.post(`${PREFIX}/setConsult`, {
    companyId,
    consultId,
  });
}

export function setResponsibleEmployee({
  companyId,
  userId,
}: {
  companyId: string;
  userId: string;
}) {
  return axios.post(`${PREFIX}/setResponsibleEmployee`, {
    companyId,
    userId,
  });
}

export function deleteCompany(companyId: string) {
  return axios.delete<IApiResponse<{}>>(`${PREFIX}/delete/${companyId}`);
}
