import { useEffect, useState } from "react";
import "./Switch.scss";

interface ISwitchProps {
  isActive: boolean;
  onChange: (value: boolean) => void;
}

function Switch(props: ISwitchProps) {
  const [isActive, setIsActive] = useState(props.isActive);

  useEffect(() => {
    setIsActive(props.isActive);
  }, [props.isActive]);

  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={isActive}
        onChange={(e) => {
          setIsActive(e.target.checked);
          props.onChange(e.target.checked);
        }}
      />
      <span className="slider" />
    </label>
  );
}

export default Switch;
