import axios from "../shared/axios";
import { IApiResponse, IGroup, IGroupCompany, IGroupUser } from "../types/api";

const PREFIX = "/group";

export function createGroup({
  name,
  alias,
  description,
  consultId,
}: {
  name: string;
  alias?: string;
  description?: string;
  consultId: string;
}) {
  return axios.post<IApiResponse<{ groupId: string }>>(`${PREFIX}/create`, {
    name,
    alias,
    description,
    consultId,
  });
}

export function deleteGroup(groupId: string) {
  return axios.delete(`${PREFIX}/delete/${groupId}`);
}

export function updateGroup({
  groupId,
  updateData,
}: {
  groupId: string;
  updateData: {
    name?: string;
    alias?: string;
    description?: string;
  };
}) {
  return axios.post(`${PREFIX}/update`, { groupId, ...updateData });
}

export function getGroups(consultId: string) {
  return axios.get<IApiResponse<IGroup[]>>(`${PREFIX}/get`, {
    params: { consultId },
  });
}

export function transferGroupOwnership({
  groupId,
  userId,
}: {
  groupId: string;
  userId: string;
}) {
  return axios.post(`${PREFIX}/transferOwnership`, { groupId, userId });
}

export function addCompanyToGroup({
  groupId,
  companyId,
}: {
  groupId: string;
  companyId: string;
}) {
  return axios.post(`${PREFIX}/addCompany`, { groupId, companyId });
}

export function removeCompanyFromGroup({
  groupId,
  companyId,
}: {
  groupId: string;
  companyId: string;
}) {
  return axios.delete(`${PREFIX}/removeCompany/${groupId}/${companyId}`);
}

export function getCompaniesInGroup(groupId: string) {
  return axios.get<IApiResponse<IGroupCompany[]>>(
    `${PREFIX}/companies/${groupId}`
  );
}

export function addUserToGroup({
  groupId,
  email,
}: {
  groupId: string;
  email: string;
}) {
  return axios.post(`${PREFIX}/addUser`, { groupId, email });
}

export function removeUserFromGroup({
  groupId,
  userId,
}: {
  groupId: string;
  userId: string;
}) {
  return axios.delete(`${PREFIX}/removeUser`, {
    params: { groupId, userId },
  });
}

export function removeMeFromGroup(groupId: string) {
  return axios.delete(`${PREFIX}/removeUser`, {
    params: { groupId },
  });
}

export function updateUserGroupRole({
  groupId,
  userId,
  role,
}: {
  groupId: string;
  userId: string;
  role: "admin" | "user";
}) {
  return axios.post(`${PREFIX}/updateRole`, { groupId, userId, role });
}

export function getUsersInGroup(groupId: string) {
  return axios.get<IApiResponse<IGroupUser[]>>(`${PREFIX}/users/${groupId}`);
}
