import { useRef, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getTags } from "../../api/company";
import Checkbox from "../../components/UI/Checkbox/Checkbox";
import Spinner from "../../components/UI/Spinner/Spinner";
import TextButton from "../../components/UI/TextButton/TextButton";
import { ROUTE } from "../../shared/enums";
import { useClickOutside } from "../../shared/hooks/useClickOutside";
import {
  setAlertTimePeriod,
  setCompareWith,
  setPresetMonth,
  setTagFilter,
  setTimePeriod,
} from "../../store/slices/extendedFiltering";
import { IReduxState } from "../../types/redux";
import "./ExtendedFiltering.scss";

interface IExtendedFilteringProps {}

function ExtendedFiltering(props: IExtendedFilteringProps) {
  const {
    company: { companyId },
    extendedFiltering: {
      compareWith,
      timePeriod,
      presetMonth,
      tagFilter,
      alertTimePeriod,
    },
  } = useSelector((state: IReduxState) => state);
  const dispatch = useDispatch();
  const location = useLocation();
  const filterRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useClickOutside([filterRef, buttonRef], onClickOutside);

  function onClickOutside() {
    setIsOpen(false);
  }

  const [isOpen, setIsOpen] = useState(false);

  const {
    data: tags,
    isLoading: isTagsLoading,
    isSuccess: isTagsSuccess,
  } = useQuery({
    queryKey: ["custom-tags", companyId],
    queryFn: async ({ queryKey: [, companyId] }) => {
      const res = await getTags(companyId);
      return res.data.payload.tags;
    },
  });

  const alertFiltering = location.pathname === ROUTE.Alerts;

  return (
    <div className="extended-filtering-wrapper">
      <div className="button-wrapper">
        <TextButton
          label={isOpen ? "Stäng filtrering" : "Utökad filtrering"}
          onClick={() => setIsOpen((state) => !state)}
          icon={isOpen ? "caret-up" : "caret-down"}
          ref={buttonRef}
        />
      </div>
      {isOpen && (
        <div className="extended-filtering" ref={filterRef}>
          {!alertFiltering && (
            <div className="section">
              <p className="title text-xs-m">Jämförelsesiffror</p>
              <div
                className="row"
                onClick={() => dispatch(setCompareWith("budget"))}
              >
                <span className="text-m-r">Jämför med budget (om möjligt)</span>
                <Checkbox
                  checked={compareWith === "budget"}
                  theme="dark"
                  short
                />
              </div>
              <div
                className="row"
                onClick={() => dispatch(setCompareWith("previous-year"))}
              >
                <span className="text-m-r">Jämför med föregående år</span>
                <Checkbox
                  checked={compareWith === "previous-year"}
                  theme="dark"
                  short
                />
              </div>
            </div>
          )}
          {!alertFiltering && (
            <div className="section">
              <p className="title text-xs-m">Tidsperiod</p>
              <div
                className="row"
                onClick={() => dispatch(setTimePeriod("financial-year"))}
              >
                <span className="text-m-r">Företagets räkenskapsår</span>
                <Checkbox
                  checked={timePeriod === "financial-year"}
                  theme="dark"
                  short
                />
              </div>
              <div
                className="row"
                onClick={() => dispatch(setTimePeriod("custom"))}
              >
                <span className="text-m-r">
                  Valfri tidsperiod ej räkenskapsår
                </span>
                <Checkbox
                  checked={timePeriod === "custom"}
                  theme="dark"
                  short
                />
              </div>
            </div>
          )}
          {alertFiltering && (
            <div className="section">
              <p className="title text-xs-m">Tidsperiod</p>
              <div
                className="row"
                onClick={() => dispatch(setAlertTimePeriod("month-year"))}
              >
                <span className="text-m-r">År och månad</span>
                <Checkbox
                  checked={alertTimePeriod === "month-year"}
                  theme="dark"
                  short
                />
              </div>
              <div
                className="row"
                onClick={() => dispatch(setAlertTimePeriod("custom"))}
              >
                <span className="text-m-r">Valfri tidsperiod</span>
                <Checkbox
                  checked={alertTimePeriod === "custom"}
                  theme="dark"
                  short
                />
              </div>
            </div>
          )}
          {!alertFiltering && (
            <div className="section">
              <p className="title text-xs-m">Förinställd månad</p>
              <div
                className="row"
                onClick={() => dispatch(setPresetMonth("current"))}
              >
                <span className="text-m-r">Aktuell månad</span>
                <Checkbox
                  checked={presetMonth === "current"}
                  theme="dark"
                  short
                />
              </div>
              <div
                className="row"
                onClick={() => dispatch(setPresetMonth("previous"))}
              >
                <span className="text-m-r">Föregående månad</span>
                <Checkbox
                  checked={presetMonth === "previous"}
                  theme="dark"
                  short
                />
              </div>
            </div>
          )}
          <div className="section">
            <p className="title text-xs-m"># Taggar</p>
            <div className="row" onClick={() => dispatch(setTagFilter("all"))}>
              <span className="text-m-r"># Alla taggar</span>
              <Checkbox checked={tagFilter === "all"} theme="dark" short />
            </div>
            {isTagsLoading && (
              <div className="row">
                <Spinner />
              </div>
            )}
            {isTagsSuccess &&
              tags.map((t) => (
                <div
                  key={t._id}
                  className="row"
                  onClick={() => dispatch(setTagFilter(t.name))}
                >
                  <span className="text-m-r"># {t.name}</span>
                  <Checkbox
                    checked={tagFilter.includes(t.name)}
                    theme="dark"
                    short
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default ExtendedFiltering;
