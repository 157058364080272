import { fromUnixTime } from "date-fns";
import { Link } from "react-router-dom";
import { QUERY_PARAM, ROUTE } from "../../shared/enums";
import { months } from "../../shared/values";
import Button from "../UI/Button/Button";
import "./MessageView.scss";

interface IMessageViewProps {
  messageId: string;
  title: string;
  content: string;
  from: number;
  to: number;
  companyId: string;
}

function MessageView(props: IMessageViewProps) {
  const year = fromUnixTime(props.from).getFullYear();
  const month = months[fromUnixTime(props.from).getMonth()].label;

  return (
    <div className="message-view text-m-r">
      <p className="title">{props.title}</p>
      <div className="content ">{props.content}</div>
      <div className="bottom">
        <span>{`${year} ${month}`}</span>

        <Link
          to={{
            pathname: ROUTE.Result,
            search: `${QUERY_PARAM.From}=${props.from}&${QUERY_PARAM.To}=${props.to}&${QUERY_PARAM.CompanyId}=${props.companyId}&${QUERY_PARAM.MessageId}=${props.messageId}`,
          }}
        >
          <Button label="Till rapport" color="black" short />
        </Link>
      </div>
    </div>
  );
}

export default MessageView;
