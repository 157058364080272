import { IStatement } from "../components/StatementList/StatementList";
import { IAlert, IVariable } from "../types/api";
import { IFormulaItem } from "../types/internal";
import {
  ALERT_VALUE,
  VARIABLE_CONTEXT_COLOR,
  VARIABLE_CONTEXT_TEXT_COLOR,
  VARIABLE_VALUE,
} from "./enums";

export function convertToFormulaArray(variableString: string) {
  const formula: IFormulaItem[] = [];

  //Regex for turning "$Varukostnad i procent / $VeryCoolVariable" to ["$Varukostnad i procent", "/", "$VeryCoolVariable"]
  const regex =
    /(\$[a-zA-Z0-9 åäöÅÄÖ_]+)|(\[[0-9]+])|(\[[0-9]+:[0-9]+.ib\]|\[[0-9]+:[0-9]+.ub\]|\[[0-9]+:[0-9]+\])|(\+|-|\*|\/|\(|\))|([0-9.]+)/g;
  const matches = variableString.match(regex);
  if (matches) {
    matches.forEach((match) => {
      if (match.startsWith("$")) {
        formula.push({
          type: "variable",
          value: match.trim(),
        });
      } else if (match.startsWith("[")) {
        if (match.includes(":")) {
          formula.push({
            type: "account-span",
            value: match,
          });
        } else {
          formula.push({
            type: "account",
            value: match,
          });
        }
      } else if (!isNaN(+match)) {
        formula.push({
          type: "number",
          value: match,
        });
      } else {
        formula.push({
          type: "arithmetic",
          value: match,
        });
      }
    });
  }

  return formula;
}

export function getStatements(variable: IVariable) {
  const procentStatements: IStatement[] = [
    {
      name: `är större än ${ALERT_VALUE.BreakValueTitleSymbol} procent`,
      value: `$${variable.name} > ( ${ALERT_VALUE.BreakValueCalcSymbol} / 100 ) ?: $${variable.name}`,
      isBreakValueInPercent: true,
    },
    {
      name: `är mindre än ${ALERT_VALUE.BreakValueTitleSymbol} procent`,
      value: `$${variable.name} < ( ${ALERT_VALUE.BreakValueCalcSymbol} / 100 ) ?: $${variable.name}`,
      isBreakValueInPercent: true,
    },
  ];

  const crownsStatements: IStatement[] = [
    {
      name: `är större än ${ALERT_VALUE.BreakValueTitleSymbol} kronor`,
      value: `$${variable.name} > ${ALERT_VALUE.BreakValueCalcSymbol} ?: $${variable.name}`,
    },
    {
      name: `är mindre än ${ALERT_VALUE.BreakValueTitleSymbol} kronor`,
      value: `$${variable.name} < ${ALERT_VALUE.BreakValueCalcSymbol} ?: $${variable.name}`,
    },
  ];

  const budgetStatements: IStatement[] = [
    {
      name: `är ${ALERT_VALUE.BreakValueTitleSymbol} procent högre än budget`,
      value: `( $${variable.name} / $${variable.name}.bgt ) - 1 > ( ${ALERT_VALUE.BreakValueCalcSymbol} / 100 ) ?: $${variable.name}`,
      isBreakValueInPercent: true,
    },
    {
      name: `är ${ALERT_VALUE.BreakValueTitleSymbol} procent lägre än budget`,
      value: `( $${variable.name} / $${variable.name}.bgt ) - 1 < ( ${ALERT_VALUE.BreakValueCalcSymbol} / 100 ) ?: $${variable.name}`,
      isBreakValueInPercent: true,
    },
    {
      name: `är ${ALERT_VALUE.BreakValueTitleSymbol} kronor högre än budget`,
      value: `$${variable.name} - $${variable.name}.bgt > ${ALERT_VALUE.BreakValueCalcSymbol} ?: $${variable.name}`,
    },
    {
      name: `är ${ALERT_VALUE.BreakValueTitleSymbol} kronor lägre än budget`,
      value: `$${variable.name} - $${variable.name}.bgt < ${ALERT_VALUE.BreakValueCalcSymbol} ?: $${variable.name}`,
    },
  ];

  const timeStatements: IStatement[] = [
    /*{
      name: `är större än ${ALERT_VALUE.BreakValueTitleSymbol} procent sen tidigare månad`,
      value: `( $${variable.name} / $${variable.name}{PreviousMonthRelative} ) - 1 > ( ${ALERT_VALUE.BreakValueCalcSymbol} / 100 ) ?: $${variable.name}`,
    },
    {
      name: `är mindre än ${ALERT_VALUE.BreakValueTitleSymbol} procent sen tidigare månad`,
      value: `( $${variable.name} / $${variable.name}{PreviousMonthRelative} ) - 1 < ( ${ALERT_VALUE.BreakValueCalcSymbol} / 100 ) ?: $${variable.name}`,
    },
    {
      name: `är större än ${ALERT_VALUE.BreakValueTitleSymbol} procent sen tidigare år`,
      value: `( $${variable.name} / $${variable.name}{PreviousYearRelative} ) - 1 > ( ${ALERT_VALUE.BreakValueCalcSymbol} / 100 ) ?: $${variable.name}`,
    },
    {
      name: `är mindre än ${ALERT_VALUE.BreakValueTitleSymbol} procent sen tidigare år`,
      value: `( $${variable.name} / $${variable.name}{PreviousYearRelative} ) - 1 < ( ${ALERT_VALUE.BreakValueCalcSymbol} / 100 ) ?: $${variable.name}`,
    },*/
    {
      name: variable.isPercent
        ? `är ${ALERT_VALUE.BreakValueTitleSymbol} procent denna månad`
        : `är ${ALERT_VALUE.BreakValueTitleSymbol} kronor denna månad`,
      value: `0 == 0 ?: $${variable.name}`,
    },
  ];

  return {
    procentStatements,
    crownsStatements,
    budgetStatements,
    timeStatements,
    all: [
      ...procentStatements,
      ...crownsStatements,
      ...budgetStatements,
      ...timeStatements,
    ],
  };
}

export function findVariableInAlert(alert: IAlert, variables: IVariable[]) {
  const variableArray = alert.value.match(/\$[a-zA-Z0-9 åäöÅÄÖ_]+/);

  if (variableArray) {
    const variableString = variableArray[0];

    const variable = variables.find(
      (v) =>
        v.name.replace("_", " ") ===
        variableString.replace("$", "").replace("_", " ").trimEnd()
    );

    return variable;
  }
}

export function findStatementInAlert(alert: IAlert, variable: IVariable) {
  const { all: statements } = getStatements(variable);

  const alertCompareString = alert.name
    .replace(/\d+/, "X")
    .replace(variable.name.replace(VARIABLE_VALUE.GlobalPrefix, ""), "")
    .trimStart();
  const statement = statements.find((s) => s.name === alertCompareString);

  return statement;
}

export function getAlertBreakValue(alert: IAlert) {
  const breakArray = alert.name.match(/\d+/);

  if (breakArray) {
    const breakValue = breakArray[0];

    return breakValue;
  }
}

export function getVariableRowColor(variableContext: string) {
  switch (variableContext) {
    case "income":
      return VARIABLE_CONTEXT_COLOR.Income;
    case "expense":
      return VARIABLE_CONTEXT_COLOR.Expense;
    case "result":
      return VARIABLE_CONTEXT_COLOR.Result;
    default:
      return VARIABLE_CONTEXT_COLOR.Default;
  }
}

export function getVariableTextColor(variableContext: string) {
  switch (variableContext) {
    case "income":
      return VARIABLE_CONTEXT_TEXT_COLOR.Income;
    case "expense":
      return VARIABLE_CONTEXT_TEXT_COLOR.Expense;
    case "result":
      return VARIABLE_CONTEXT_TEXT_COLOR.Result;
    default:
      return VARIABLE_CONTEXT_TEXT_COLOR.Default;
  }
}
