import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getConsultingAgencies, updateConsulting } from "../../api/consult";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import { setAgency } from "../../store/slices/consult";
import { IReduxState } from "../../types/redux";
import "./AccountingFirmProfile.scss";

interface IAccountingFirmProfileProps {}

function AccountingFirmProfile(props: IAccountingFirmProfileProps) {
  const { agency } = useSelector((state: IReduxState) => state.consult);
  const dispatch = useDispatch();

  const [isUpdating, setIsUpdating] = useState(false);

  const [accountingFirmData, setAccountingFirmData] = useState<{
    name: string;
    orgNr: string;
    address: string;
    address2: string;
    zipcode: string;
    country: string;
    city: string;
    contactPersonEmail: string;
    contactPersonFirstName: string;
    contactPersonLastName: string;
    contactPersonSsn: string;
  }>({
    name: "",
    orgNr: "",
    address: "",
    address2: "",
    zipcode: "",
    country: "",
    city: "",
    contactPersonEmail: "",
    contactPersonFirstName: "",
    contactPersonLastName: "",
    contactPersonSsn: "",
  });

  useEffect(() => {
    if (agency) {
      setAccountingFirmData({
        name: agency.name,
        orgNr: agency.orgNr,
        address: agency.address,
        address2: agency.address2,
        zipcode: agency.zipcode,
        country: agency.country,
        city: agency.city,
        contactPersonEmail: agency.contactPerson.email,
        contactPersonFirstName: agency.contactPerson.firstName,
        contactPersonLastName: agency.contactPerson.lastName,
        contactPersonSsn: agency.contactPerson.ssn,
      });
    }
  }, [agency]);

  async function profileSubmitHandler() {
    setIsUpdating(true);

    try {
      await updateConsulting({
        consultId: agency!._id,
        name: accountingFirmData.name,
        address: accountingFirmData.address,
        address2: accountingFirmData.address2,
        zipcode: accountingFirmData.zipcode,
        country: accountingFirmData.country,
        city: accountingFirmData.city,
        contactPersonEmail: accountingFirmData.contactPersonEmail,
        contactPersonFirstName: accountingFirmData.contactPersonFirstName,
        contactPersonLastName: accountingFirmData.contactPersonLastName,
        contactPersonSsn: accountingFirmData.contactPersonSsn,
      });

      const res = await getConsultingAgencies();

      const newAgency = res.data.payload.find((a) => a._id === agency!._id);

      if (newAgency) {
        dispatch(setAgency(newAgency));
        toast.success("Uppgifterna har uppdaterats");
      } else {
        toast.error("Ingen redovisningsbyrå hittades");
      }

      setIsUpdating(false);
    } catch (error) {
      toast.error("Något gick fel");
      setIsUpdating(false);
    }
  }

  return (
    <div className="accounting-firm-profile">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          profileSubmitHandler();
        }}
      >
        <div className="top">
          <span className="text-m-m">Företagsuppgifter</span>
        </div>
        <div className="profile-title">
          <span className="text-m-m">Organisation</span>
        </div>
        <div className="profile-section">
          <span className="text-s-r">Organisationsnummer</span>
          <Input
            type="text"
            value={accountingFirmData.orgNr}
            onChange={(v) =>
              setAccountingFirmData({ ...accountingFirmData, orgNr: v })
            }
            short
            placeholder="Organisationsnummer"
            width="70%"
          />
        </div>
        <div className="profile-section">
          <span className="text-s-r">Namn på företag</span>
          <Input
            type="text"
            value={accountingFirmData.name}
            onChange={(v) =>
              setAccountingFirmData({ ...accountingFirmData, name: v })
            }
            short
            placeholder="Namn på företag"
            width="70%"
          />
        </div>
        <div className="profile-section">
          <span className="text-s-r">Adress</span>
          <Input
            type="text"
            value={accountingFirmData.address}
            onChange={(v) =>
              setAccountingFirmData({ ...accountingFirmData, address: v })
            }
            short
            placeholder="Adress"
            width="35%"
          />
          <Input
            type="text"
            value={accountingFirmData.address2}
            onChange={(v) =>
              setAccountingFirmData({ ...accountingFirmData, address2: v })
            }
            short
            placeholder="Adress 2"
            width="calc(70% - 15px - 35%)"
          />
        </div>
        <div className="profile-section">
          <span className="text-s-r">Postnummer & Stad</span>
          <Input
            type="text"
            value={accountingFirmData.zipcode}
            onChange={(v) =>
              setAccountingFirmData({ ...accountingFirmData, zipcode: v })
            }
            short
            placeholder="Postnummer"
            width="100px"
          />
          <Input
            type="text"
            value={accountingFirmData.city}
            onChange={(v) =>
              setAccountingFirmData({ ...accountingFirmData, city: v })
            }
            short
            placeholder="Stad"
            width="calc(70% - 15px - 100px)"
          />
        </div>
        <div className="profile-section">
          <span className="text-s-r">Land</span>
          <Input
            type="text"
            value={accountingFirmData.country}
            onChange={(v) =>
              setAccountingFirmData({ ...accountingFirmData, country: v })
            }
            short
            placeholder="Land"
            width="70%"
          />
        </div>
        <div className="profile-title">
          <span className="text-m-m">Kontaktperson</span>
        </div>
        <div className="profile-section">
          <span className="text-s-r">Kontaktperson e-post</span>
          <Input
            type="text"
            value={accountingFirmData.contactPersonEmail}
            onChange={(v) =>
              setAccountingFirmData({
                ...accountingFirmData,
                contactPersonEmail: v,
              })
            }
            short
            placeholder="Kontaktperson e-post"
            width="70%"
          />
        </div>
        <div className="profile-section">
          <span className="text-s-r">Kontaktperson förnamn</span>
          <Input
            type="text"
            value={accountingFirmData.contactPersonFirstName}
            onChange={(v) =>
              setAccountingFirmData({
                ...accountingFirmData,
                contactPersonFirstName: v,
              })
            }
            short
            placeholder="Kontaktperson förnamn"
            width="70%"
          />
        </div>
        <div className="profile-section">
          <span className="text-s-r">Kontaktperson efternamn</span>
          <Input
            type="text"
            value={accountingFirmData.contactPersonLastName}
            onChange={(v) =>
              setAccountingFirmData({
                ...accountingFirmData,
                contactPersonLastName: v,
              })
            }
            short
            placeholder="Kontaktperson efternamn"
            width="70%"
          />
        </div>
        <div className="profile-section">
          <span className="text-s-r">Kontaktperson personnummer</span>
          <Input
            type="text"
            value={accountingFirmData.contactPersonSsn}
            onChange={(v) =>
              setAccountingFirmData({
                ...accountingFirmData,
                contactPersonSsn: v,
              })
            }
            short
            placeholder="Kontaktperson personnummer"
            width="70%"
          />
        </div>
        <div className="profile-save-wrapper">
          <Button
            color="black"
            label="Spara inställningar"
            isLoading={isUpdating}
            short
          />
        </div>
      </form>
      {false && (
        <div className="profile-logo">
          <div className="top">
            <span className="text-m-m">Företagets logotyp</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default AccountingFirmProfile;
