import { fromUnixTime, lightFormat } from "date-fns";
import { motion, Variants } from "framer-motion";
import { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { adminConfirmConsult, adminGetConsult } from "../../api/admin";
import AccountingFirmInfoRow from "./AccountingFirmInfoRow/AccountingFirmInfoRow";
import "./AccountingFirmRow.scss";

interface IAccountingFirmRowProps {
  consultId: string;
  name: string;
  orgNr: string;
  createdAt: number;
  onAction: (confirmed: boolean) => void;
  isQueue?: boolean;
}

function AccountingFirmRow(props: IAccountingFirmRowProps) {
  const [isOpen, setIsOpen] = useState(false);

  const { data, isSuccess, isLoading } = useQuery({
    queryKey: ["admin-consult", props.consultId],
    queryFn: async () => {
      const res = await adminGetConsult(props.consultId);
      return res.data.payload;
    },
    enabled: isOpen,
  });

  async function confirmAccountingFirmHandler(confirm: boolean) {
    try {
      await adminConfirmConsult({
        consultId: props.consultId,
        accept: confirm,
      });

      if (confirm) {
        toast.success("Företag accepterat");
      } else {
        toast.success("Företag nekat");
      }

      props.onAction(confirm);
    } catch (error) {
      toast.error("Något gick fel");
    }
  }

  const rowVariants: Variants = {
    offscreen: {
      y: 20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
      },
    },
  };

  const rowTablesVariants: Variants = {
    closed: {
      height: 0,
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
    open: {
      height: "auto",
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  };
  return (
    <motion.div
      className="accounting-firm-row"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.1 }}
      variants={rowVariants}
      transition={{ ease: "easeInOut" }}
    >
      <div
        className="row text-m-m"
        onClick={() => setIsOpen((state) => !state)}
      >
        <span>{props.name}</span>
        <span>Org-nummer: {props.orgNr}</span>
        <span className="created">
          Skapad:{" "}
          {lightFormat(fromUnixTime(props.createdAt), "yyyy-MM-dd HH:mm")}
        </span>
        <i
          className="fa-solid fa-caret-down"
          style={isOpen ? { transform: "rotate(180deg)" } : {}}
        />
      </div>
      <motion.div
        className="accounting-firm-info"
        variants={rowTablesVariants}
        animate={isOpen && !isLoading ? "open" : "closed"}
      >
        {isSuccess && (
          <div>
            <AccountingFirmInfoRow title="Namn" value={data.name} />
            <AccountingFirmInfoRow title="Organisation" value="" />
            <AccountingFirmInfoRow title="Org-nummer" value={data.orgNr} />
            <AccountingFirmInfoRow title="Adress" value={data.address} />
            <AccountingFirmInfoRow title="Adress 2" value={data.address2} />
            <AccountingFirmInfoRow title="Stad" value={data.city} />
            <AccountingFirmInfoRow title="Postkod" value={data.zipcode} />
            <AccountingFirmInfoRow title="Land" value={data.country} />
            <AccountingFirmInfoRow title="Kontaktperson" value="" />
            <AccountingFirmInfoRow
              title="E-post"
              value={data.contactPerson.email}
            />
            <AccountingFirmInfoRow
              title="Förnamn"
              value={data.contactPerson.firstName}
            />
            <AccountingFirmInfoRow
              title="Efternamn"
              value={data.contactPerson.lastName}
            />
            <AccountingFirmInfoRow
              title="Personnummer"
              value={data.contactPerson.ssn}
            />
            {props.isQueue && (
              <div className="accounting-firm-actions">
                <span className="text-m-m">Acceptera eller neka</span>
                <button
                  className="confirm-button waves-effect"
                  onClick={() => confirmAccountingFirmHandler(true)}
                >
                  <i className="fa-solid fa-check" />
                </button>
                <button
                  className="deny-button waves-effect"
                  onClick={() => confirmAccountingFirmHandler(false)}
                >
                  <i className="fa-solid fa-x" />
                </button>
              </div>
            )}
          </div>
        )}
      </motion.div>
    </motion.div>
  );
}

export default AccountingFirmRow;
