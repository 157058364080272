import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import BetaFeature from "../../components/BetaFeature/BetaFeature";
import ParentRouteNav from "../../components/ParentRouteNav/ParentRouteNav";
import ExtendedFiltering from "../../containers/ExtendedFiltering/ExtendedFiltering";
import { ROUTE, ROUTE_LABEL } from "../../shared/enums";
import "./Counseling.scss";

interface ICounselingProps {}

function Counseling(props: ICounselingProps) {
  const location = useLocation();

  const [controlsComponent, setControlsComponent] = useState<
    React.ReactNode | undefined
  >(undefined);

  const navItems = [
    { path: ROUTE.Alerts, label: ROUTE_LABEL.Alerts },
    { path: ROUTE.AlertSettings, label: ROUTE_LABEL.AlertSettings },
    //{ path: ROUTE.Insights, label: ROUTE_LABEL.Insights },
  ];

  return (
    <div className="counseling">
      <div className="controls">
        <ParentRouteNav navItems={navItems} noMargin />
        <BetaFeature
          style={{
            marginRight: "auto",
          }}
        />
        {controlsComponent}
        {location.pathname === ROUTE.Alerts && <ExtendedFiltering />}
      </div>
      <Outlet
        context={{
          setControlsComponent,
        }}
      />
    </div>
  );
}

export default Counseling;
