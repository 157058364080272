import { getReportTextColor } from "../../../shared/reportUtility";
import { convertToNumberWithSpaces } from "../../../shared/utility";
import { RowType } from "../../../types/internal";
import "./ResultReportCell.scss";

interface IResultReportCellProps {
  value: number;
  compareValue: number;
  rowType: RowType;
  isAfterBreak?: boolean;
  isBreakMonth?: boolean;
}

function ResultReportCell(props: IResultReportCellProps) {
  return (
    <td
      className="result-report-cell"
      style={
        props.isBreakMonth
          ? {
              borderRight: `1px solid ${getReportTextColor(props.rowType)}`,
            }
          : {}
      }
    >
      <div className="content">
        <p className="text-s-r">
          {convertToNumberWithSpaces(props.isAfterBreak ? 0 : props.value)}
        </p>
        <p
          className="compare-value text-xs-m"
          style={
            props.isAfterBreak
              ? { color: getReportTextColor(props.rowType) }
              : {}
          }
        >
          {convertToNumberWithSpaces(props.compareValue)}
        </p>
      </div>
    </td>
  );
}

export default ResultReportCell;
