import { Link } from "react-router-dom";
import nimyaLogoLight from "../../assets/images/nimya-logo-light.svg";
import { ROUTE, ROUTE_LABEL } from "../../shared/enums";
import "./Footer.scss";

interface IFooterProps {}

function Footer(props: IFooterProps) {
  const footerSections: {
    title: string;
    links: { title: string; url: string; outsideLink?: boolean }[];
  }[] = [
    {
      title: "Översikt",
      links: [{ title: "Företag", url: ROUTE.Index }],
    },
    {
      title: "Rådgivning",
      links: [
        {
          title: "Skapa notiser",
          url: ROUTE.Alerts,
        },
      ],
    },
    {
      title: ROUTE_LABEL.Reports,
      links: [
        {
          title: ROUTE_LABEL.Result,
          url: ROUTE.Result,
        },
        {
          title: ROUTE_LABEL.Balance,
          url: ROUTE.Balance,
        },
        {
          title: ROUTE_LABEL.CashFlow,
          url: ROUTE.CashFlow,
        },
        {
          title: ROUTE_LABEL.KeyValues,
          url: ROUTE.KeyValues,
        },
      ],
    },
    {
      title: ROUTE_LABEL.Future,
      links: [
        {
          title: ROUTE_LABEL.Budget,
          url: ROUTE.Budget,
        },
        {
          title: ROUTE_LABEL.Forecast,
          url: ROUTE.Forecast,
        },
      ],
    },
    {
      title: ROUTE_LABEL.Settings,
      links: [
        {
          title: ROUTE_LABEL.CompanySettings,
          url: ROUTE.CompanySettings,
        },
        /*{
          title: ROUTE_LABEL.ConcernSettings,
          url: ROUTE.ConcernSettings,
        },*/
      ],
    },
    {
      title: "Rapportering",
      links: [
        {
          title: "Feedback",
          url: "https://app.startinfinity.com/form/a70ec1a3-67ed-4f45-b738-c2ab2131d431",
          outsideLink: true,
        },
        {
          title: "Buggar",
          url: "https://app.startinfinity.com/form/cb33c633-19db-4040-a911-c8baa00f6dc1",
          outsideLink: true,
        },
        {
          title: "Kontakta oss",
          url: "https://www.nimya.io/support",
          outsideLink: true,
        },
      ],
    },
  ];

  return (
    <footer className="footer">
      <div className="top">
        <img src={nimyaLogoLight} alt="" />
        <span className="text-s-r">Förvandla siffror till insikter</span>
      </div>
      <div className="content">
        {footerSections.map((section) => (
          <section key={section.title}>
            <p className="text-m-m">{section.title}</p>
            {section.links.map((link) =>
              link.outsideLink ? (
                <a
                  key={link.title}
                  className="link text-m-r"
                  href={link.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {link.title}
                </a>
              ) : (
                <Link key={link.title} className="link text-m-r" to={link.url}>
                  {link.title}
                </Link>
              )
            )}
          </section>
        ))}
      </div>
      <div className="bottom text-m-r">
        <a href="https://www.nimya.io" target="_blank" rel="noreferrer">
          Besök vår hemsida
        </a>
        <a href="mailto:hello@nimya.io">hello@nimya.io</a>
        <a href="https://www.nimya.io/policy" target="_blank" rel="noreferrer">
          Intigritetspolicy
        </a>
        <address>Folkungagatan 108 | Stockholm</address>
        <Link to={ROUTE.CompanySettings}>Bjud in ett företag</Link>
        <a href="https://www.nimya.io/" target="_blank" rel="noreferrer">
          Följ oss på Linkedin
        </a>
      </div>
    </footer>
  );
}

export default Footer;
