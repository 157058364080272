import { toast } from "react-toastify";
import "./Dot.scss";

interface IDotProps {
  active: boolean;
  onClick: () => void;
  label: string;
  icon?: string;
  title?: string;
  locked?: boolean;
  align?: "left" | "right";
}

function Dot(props: IDotProps) {
  const dotClasses = ["dot", "waves-effect"];
  if (props.active) dotClasses.push("active");

  function lockedClickHandler() {
    toast.info("Denna licens är låst");
  }

  return (
    <div className="dot-wrapper">
      <div
        className={dotClasses.join(" ")}
        onClick={props.locked ? lockedClickHandler : props.onClick}
        style={{
          backgroundColor: props.active ? "#030229" : "#fff",
          marginRight: props.align === "left" ? "auto" : "0",
          marginLeft: props.align === "right" ? "auto" : "0",
        }}
        title={props.title}
      >
        {props.icon ? <i className={props.icon} /> : props.label.toUpperCase()}
      </div>
      {props.locked && (
        <div className="lock">
          <i className="fas fa-lock" />
        </div>
      )}
    </div>
  );
}

export default Dot;
