import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { generateFortnoxUrl } from "../../api/fortnox";
import { getMe, updateProfileImage, updateProfileInfo } from "../../api/user";
import { generateVismaUrl } from "../../api/visma";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import SettingsRow from "../../containers/SettingsRow/SettingsRow";
import { useSettingsValues } from "../../shared/hooks/useSettingsValues";
import { isValidPhoneNumber } from "../../shared/utility";
import { logout } from "../../store/thunks/auth";
import { IReduxState } from "../../types/redux";
import "./CompanySettings.scss";

interface ICompanySettingsProps {}

function CompanySettings(props: ICompanySettingsProps) {
  const { companyId, companies } = useSelector(
    (state: IReduxState) => state.company
  );

  const [searchParams] = useSearchParams();

  const { licenses, setControlsComponent } = useSettingsValues();

  const [profile, setProfile] = useState<{
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    avatar: string;
  }>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    avatar: "",
  });
  const [avatarBlob, setAvatarBlob] = useState<Blob | null>(null);
  const [isSavingProfile, setIsSavingProfile] = useState(false);
  const [isFortnoxLoading, setIsFortnoxLoading] = useState(false);
  const [isVismaLoading, setIsVismaLoading] = useState(false);

  useEffect(() => {
    setControlsComponent(
      <div className="add-company">
        <span className="text-m-r">Lägg till ett nytt företag</span>
        <Button
          label="Fortnox"
          color="black"
          onClick={addFortnoxCompanyHandler}
          isLoading={isFortnoxLoading}
        />
        <Button
          label="Visma"
          color="black"
          onClick={addVismaCompanyHandler}
          isLoading={isVismaLoading}
        />
      </div>
    );
  }, [isFortnoxLoading, isVismaLoading, setControlsComponent]);

  useEffect(() => {
    return () => toast.dismiss();
  }, []);

  useEffect(() => {
    showQueryToast(searchParams.get("error"), searchParams.get("message"));
  }, [searchParams]);

  const showQueryToast = (
    errorQuery: string | null,
    messageQuery: string | null
  ) => {
    if (!errorQuery || !messageQuery) return;

    const hasError = errorQuery === "true";

    if (!hasError) {
      toast.success("Företaget lades till");
      return;
    }

    let errorText = "";
    switch (messageQuery) {
      case "error_missing_license":
        errorText =
          "Detta företag saknar licens för att lägga till integrationer";
        break;
      case "error_insufficient_permissions":
        errorText = "Du saknar rätt behörigheter i företaget";
        break;
      default:
        errorText = "Ett okänt fel har inträffat";
        break;
    }
    toast.error(errorText);
  };

  useEffect(() => {
    getMe().then((res) => {
      setProfile({
        firstName: res.data.payload.firstName || "",
        lastName: res.data.payload.lastName || "",
        email: res.data.payload.email || "",
        phone: res.data.payload.phoneNr || "",
        avatar: res.data.payload.avatar || "",
      });
    });
  }, []);

  function avatarHandler() {
    getMe().then((res) => {
      setProfile((state) => ({
        ...state,
        avatar: res.data.payload.avatar || "",
      }));
    });
  }

  async function profileSubmitHandler() {
    if (profile.phone && !isValidPhoneNumber(profile.phone)) {
      toast.error("Telefonnummer är inte giltigt");
      return;
    }
    if (profile.firstName.length > 50 || profile.lastName.length > 50) {
      toast.error("Namnet är för långt. Det får max vara 50 tecken");
      return;
    }

    setIsSavingProfile(true);

    let hasUpdatedImage = false;

    if (avatarBlob) {
      try {
        await updateProfileImage(
          new File([avatarBlob], "profile.png", { type: "image/png" })
        );
        hasUpdatedImage = true;
      } catch (error) {
        setIsSavingProfile(false);
        toast.error("Kunde inte ladda upp bild");
        return;
      }
    }

    try {
      await updateProfileInfo({
        firstName: profile.firstName,
        lastName: profile.lastName,
        phoneNr: profile.phone,
      });
    } catch (e) {
      setIsSavingProfile(false);
      if (!hasUpdatedImage) {
        toast.error("Kunde inte spara profil");
        return;
      }
    }

    const res = await getMe();

    setProfile({
      firstName: res.data.payload.firstName || "",
      lastName: res.data.payload.lastName || "",
      email: res.data.payload.email || "",
      phone: res.data.payload.phoneNr || "",
      avatar: res.data.payload.avatar || "",
    });
    setIsSavingProfile(false);
    toast.success("Profil uppdaterad");
  }

  async function addFortnoxCompanyHandler() {
    setIsFortnoxLoading(true);

    try {
      const res = await generateFortnoxUrl();
      setIsFortnoxLoading(false);
      window.location.href = res.data.payload.url;
    } catch (error) {
      setIsFortnoxLoading(false);
      toast.error("Kunde inte skapa fortnox länk");
    }
  }

  async function addVismaCompanyHandler() {
    setIsVismaLoading(true);
    try {
      const res = await generateVismaUrl();
      setIsVismaLoading(false);
      window.location.href = res.data.payload.url;
    } catch (error) {
      setIsVismaLoading(false);
      toast.error("Kunde inte skapa visma länk");
    }
  }

  async function leaveCompanyHandler() {
    window.location.reload();
  }
  async function removeCompanyHandler() {
    window.location.reload();
  }

  const chosenCompany = companies.find((c) => c.companyId === companyId);

  return (
    <div className="company-settings">
      <div className="list">
        {chosenCompany && !!chosenCompany.hasIndependantAccess && (
          <SettingsRow
            key={chosenCompany.companyId}
            companyId={chosenCompany.companyId}
            name={chosenCompany.name}
            isRedoSetup={chosenCompany.redoSetup}
            createdAt={chosenCompany.created}
            shouldOpen={true}
            onRemoveCompany={removeCompanyHandler}
            userEmail={profile.email}
            isUserAdmin={chosenCompany.role === "admin"}
            licenses={licenses}
            onLeaveCompany={leaveCompanyHandler}
          />
        )}
        {companies
          .filter(
            (c) =>
              !c.isConcern &&
              c.companyId !== companyId &&
              (c.hasIndependantAccess === undefined
                ? true
                : c.hasIndependantAccess)
          )
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((company) => (
            <SettingsRow
              key={company.companyId}
              companyId={company.companyId}
              name={company.name}
              isRedoSetup={company.redoSetup}
              createdAt={company.created}
              shouldOpen={false}
              onRemoveCompany={removeCompanyHandler}
              userEmail={profile.email}
              isUserAdmin={company.role === "admin"}
              licenses={licenses}
              onLeaveCompany={leaveCompanyHandler}
            />
          ))}
      </div>
      <div className="profile-settings">
        <div className="card">
          <ImageUpload
            onImageUploaded={avatarHandler}
            imageBase64={profile.avatar}
            onChange={(ib) => {
              setAvatarBlob(ib);
            }}
          />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              profileSubmitHandler();
            }}
          >
            <Input
              type="text"
              value={profile.firstName}
              onChange={(value) =>
                setProfile((state) => ({ ...state, firstName: value }))
              }
              label="Förnamn"
              placeholder="Förnamn"
              width="100%"
              short
            />
            <Input
              type="text"
              value={profile.lastName}
              onChange={(value) =>
                setProfile((state) => ({ ...state, lastName: value }))
              }
              label="Efternamn"
              placeholder="Efternamn"
              width="100%"
              short
            />
            <Input
              type="phone"
              value={profile.phone}
              onChange={(value) =>
                setProfile((state) => ({ ...state, phone: value }))
              }
              label="Telefon"
              placeholder="Telefon"
              width="100%"
              short
            />
            <Button
              color="black"
              label="Spara inställningar"
              isLoading={isSavingProfile}
              width="40%"
              short
            />
          </form>
        </div>
        <div className="logout text-m-m" onClick={logout}>
          Logga ut
          <i className="fa-solid fa-arrow-right-from-bracket" />
        </div>
      </div>
    </div>
  );
}

export default CompanySettings;
