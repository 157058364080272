import { fromUnixTime, getUnixTime, lightFormat, subYears } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getBudget, updateBudget } from "../../api/budget";
import { getAccounts } from "../../api/company";
import { getResultReport } from "../../api/report";
import BudgetSheet from "../../components/BudgetSheet/BudgetSheet";
import Spinner from "../../components/UI/Spinner/Spinner";
import TextButton from "../../components/UI/TextButton/TextButton";
import {
  canUndoBudget,
  convertResultReportToBudgetPeriods,
  getBudgetAccountRangeNameMap,
  saveBudgetDelta,
  undoBudget,
} from "../../shared/budget";
import clone from "../../shared/clone";
import { LOCAL_STORAGE_KEY } from "../../shared/enums";
import { useFutureValues } from "../../shared/hooks/useFutureValues";
import { nimyaAlert } from "../../shared/nimyaAlert";
import { convertResultReportToAccountRangeMap } from "../../shared/reportUtility";
import { isMacUser } from "../../shared/utility";
import { budgetRowTypes } from "../../shared/values";
import { IAccount, IBudgetPeriod, IResultReport } from "../../types/api";
import { RowType } from "../../types/internal";
import { IReduxState } from "../../types/redux";
import "./Budget.scss";

interface IBudgetProps {}

function Budget(props: IBudgetProps) {
  const { companyId, financialYear, settings } = useSelector(
    (state: IReduxState) => state.company
  );

  const { setControlsComponent } = useFutureValues();

  const [budget, setBudget] = useState<IBudgetPeriod[]>([]);
  const [result, setResult] = useState<IResultReport | null>(null);
  const [accountRecord, setAccountRecord] = useState<Record<number, IAccount>>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isClearing, setIsClearing] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [isSavingLoading, setIsSavingLoading] = useState(false);

  const from = financialYear.from;
  const to = financialYear.to;

  function getBudgetFromTo(from: number, to: number) {
    const [fromYear, fromMonth] = lightFormat(
      fromUnixTime(from),
      "yyyy-M"
    ).split("-");
    const [toYear, toMonth] = lightFormat(fromUnixTime(to), "yyyy-M").split(
      "-"
    );
    return { fromYear, fromMonth, toYear, toMonth };
  }

  useEffect(() => {
    setIsLoading(true);

    const { fromYear, fromMonth, toYear, toMonth } = getBudgetFromTo(from, to);

    const resultFrom = getUnixTime(subYears(fromUnixTime(from), 1));
    const resultTo = getUnixTime(subYears(fromUnixTime(to), 1));

    Promise.all([
      getBudget({
        fromMonth,
        fromYear,
        toMonth,
        toYear,
        companyId,
      }),
      getResultReport({
        from: resultFrom,
        to: resultTo,
        companyId,
        accountLevel: settings.advancedBudget,
      }),
      getAccounts({
        financialYear: financialYear.identifier,
        companyId,
      }),
    ]).then(([budgetRes, resultRes, accountsRes]) => {
      setBudget(budgetRes.data.payload);
      setResult(resultRes.data.payload);
      const accountRecord: Record<number, IAccount> = {};
      for (const account of accountsRes.data.payload || []) {
        accountRecord[account.account] = account;
      }
      setAccountRecord(accountRecord);
      setIsLoading(false);
    });
  }, [companyId, from, to, financialYear.identifier, settings.advancedBudget]);

  function budgetChangedHandler({
    year,
    month,
    accountRange,
    value,
    title,
  }: {
    year: number;
    month: number;
    accountRange: {
      from: number;
      to: number;
    };
    value: number;
    title: string;
  }) {
    setBudget((prevBudget) => {
      const newBudget = clone(prevBudget);
      const insertIndex = newBudget.findIndex(
        (r) => r.month === month && r.year === year
      );
      if (insertIndex === -1) {
        newBudget.push({
          year: year,
          month: month,
          rows: [
            {
              accountRange: accountRange,
              title: title,
              value: value,
            },
          ],
        });
      } else {
        const accountIndex = newBudget[insertIndex].rows.findIndex(
          (r) => r.title === title
        );
        if (accountIndex === -1) {
          newBudget[insertIndex].rows = [
            ...newBudget[insertIndex].rows,
            {
              accountRange: accountRange,
              title: title,
              value: value,
            },
          ];
        } else {
          newBudget[insertIndex].rows[accountIndex].value = value;
        }
      }
      saveBudgetDelta(budget, newBudget);
      return newBudget;
    });
  }

  function budgetRowTitleChangedHandler(oldTitle: string, newTitle: string) {
    const newBudget = clone(budget);
    for (let i = 0; i < newBudget.length; i++) {
      for (let j = 0; j < newBudget[i].rows.length; j++) {
        if (newBudget[i].rows[j].title === oldTitle) {
          newBudget[i].rows[j].title = newTitle;
        }
      }
    }
    saveBudgetDelta(budget, newBudget);
    setBudget(newBudget);
  }

  function budgetRowRemovedHandler(title: string) {
    const newBudget = clone(budget);
    for (let i = 0; i < newBudget.length; i++) {
      newBudget[i].rows = newBudget[i].rows.filter((r) => r.title !== title);
    }
    saveBudgetDelta(budget, newBudget);
    setBudget(newBudget);
  }

  function addBudgetPeriodHandler(
    accountRange: { from: number; to: number },
    title: string
  ) {
    const newBudget = clone(budget);

    const isAlreadyInBudget = newBudget.find((r) =>
      r.rows.find((r) => r.title === title)
    );

    if (isAlreadyInBudget) {
      toast.error("Det finns redan en rad med denna titel");
      return;
    }

    const { fromYear, fromMonth } = getBudgetFromTo(from, to);

    if (!newBudget[0]) {
      newBudget.push({
        year: +fromYear,
        month: +fromMonth,
        rows: [
          {
            accountRange: accountRange,
            title: title,
            value: 0,
          },
        ],
      });
    } else {
      newBudget[0].rows.push({
        accountRange: accountRange,
        title: title,
        value: 0,
      });
    }

    saveBudgetDelta(budget, newBudget);
    setBudget(newBudget);
  }

  async function saveHandler() {
    setIsSavingLoading(true);
    await updateBudget({ companyId, budgetPeriods: budget });
    localStorage.removeItem(LOCAL_STORAGE_KEY.BudgetDeltas);
    setIsSavingLoading(false);
    toast.success("Budget sparad");
  }

  const clearBudgetHandler = useCallback(async () => {
    const confirmText =
      "Är du säker på att du vill radera budgeten? Denna åtgärd går inte att ångra.";
    if (
      !(await nimyaAlert({
        title: "Rensa budget",
        message: confirmText,
      }))
    )
      return;

    const promptText = "Ange 'Nollställ budget' för att rensa budgeten";
    if (
      !(await nimyaAlert({
        title: "Rensa budget",
        message: promptText,
        conifrmText: "Nollställ budget",
      }))
    )
      return;

    setIsClearing(true);

    const clearedBudget: IBudgetPeriod[] = clone(budget);
    for (let i = 0; i < clearedBudget.length; i++) {
      clearedBudget[i].rows = [];
    }

    await updateBudget({ companyId, budgetPeriods: clearedBudget });

    const { fromYear, fromMonth, toYear, toMonth } = getBudgetFromTo(from, to);
    const budgetRes = await getBudget({
      fromMonth,
      fromYear,
      toMonth,
      toYear,
      companyId,
    });
    setBudget(budgetRes.data.payload);
    setIsClearing(false);
    toast.success("Budgeten har rensats");
  }, [budget, companyId, from, to]);

  const importBudgetHandler = useCallback(
    async ({
      budgetFrom,
      budgetTo,
      isAccountLevel,
      accountRecord,
    }: {
      budgetFrom: number;
      budgetTo: number;
      isAccountLevel?: boolean;
      accountRecord?: Record<string, IAccount>;
    }) => {
      const confirmText =
        "Är du säker på att du vill importera föregående år? Detta kommer överskrida din gamla budget.";

      if (
        !(await nimyaAlert({
          title: "Importera budget",
          message: confirmText,
        }))
      )
        return;

      setIsImporting(true);

      const resultFrom = getUnixTime(subYears(fromUnixTime(budgetFrom), 1));
      const resultTo = getUnixTime(subYears(fromUnixTime(budgetTo), 1));

      const budgetNameMap = getBudgetAccountRangeNameMap();

      const resultRes = await getResultReport({
        from: resultFrom,
        to: resultTo,
        companyId,
        accountLevel: settings.advancedBudget,
      });

      const newBudget = convertResultReportToBudgetPeriods({
        report: resultRes.data.payload,
        budgetNameMap,
        isAccountLevel,
        accountRecord,
      });

      await updateBudget({ companyId, budgetPeriods: newBudget });

      const { fromYear, fromMonth, toYear, toMonth } = getBudgetFromTo(
        from,
        to
      );
      const budgetRes = await getBudget({
        fromMonth,
        fromYear,
        toMonth,
        toYear,
        companyId,
      });
      setBudget(budgetRes.data.payload);
      setIsImporting(false);
      toast.success("Budgeten har importerats");
    },
    [companyId, from, settings.advancedBudget, to]
  );

  const undoBudgetHandler = useCallback(() => {
    const budgetClone = clone(budget);

    const newBudget = undoBudget(budgetClone);
    if (!newBudget) {
      toast.error("Inga ändringar att ångra");
      return;
    }
    setBudget(newBudget);
  }, [budget]);

  useHotkeys("ctrl+z,meta+z", undoBudgetHandler, [budget]);

  useEffect(() => {
    setControlsComponent(
      <div className="budget-top">
        <TextButton
          label="Skapa en budget baserat på föregående år"
          isLoading={isImporting}
          onClick={() =>
            importBudgetHandler({
              budgetFrom: from,
              budgetTo: to,
              isAccountLevel: settings.advancedBudget,
              accountRecord,
            })
          }
        />
        <div
          title={`Ångra ändringar ${isMacUser() ? "cmd+z" : "ctrl+z"}`}
          className="budget-action waves-effect"
          onClick={
            canUndoBudget()
              ? undoBudgetHandler
              : () => toast.error("Inga ändringar att ångra")
          }
          style={!canUndoBudget() ? { opacity: 0.5 } : {}}
        >
          <i className="fa-solid fa-rotate-left" />
        </div>
        <div
          title="Rensa budget"
          className="budget-action waves-effect"
          onClick={clearBudgetHandler}
        >
          {isClearing ? <Spinner /> : <i className="fa-regular fa-trash-can" />}
        </div>
      </div>
    );
  }, [
    accountRecord,
    clearBudgetHandler,
    from,
    importBudgetHandler,
    isClearing,
    isImporting,
    setControlsComponent,
    settings.advancedBudget,
    to,
    undoBudgetHandler,
  ]);

  const budgetSections: {
    title: string;
    accountOptions: { value: string; label: string }[];
    accountRange: { from: number; to: number };
    type: RowType;
    isResultRow?: boolean;
  }[] = [
    {
      title: "Intäkter",
      accountOptions: budgetRowTypes.intäkter,
      accountRange: { from: 3000, to: 3999 },
      type: "income",
    },
    {
      title: "Varuinköp",
      accountOptions: budgetRowTypes.varuinköp,
      accountRange: { from: 4000, to: 4999 },
      type: "expense",
    },
    {
      title: "Övriga Rörelsekostnader",
      accountOptions: budgetRowTypes.övrigaRörelsekostnader,
      accountRange: { from: 5000, to: 6999 },
      type: "expense",
    },
    {
      title: "Personalkostnader",
      accountOptions: budgetRowTypes.personalkostnader,
      accountRange: { from: 7000, to: 7699 },
      type: "expense",
    },
    {
      title: "Avskrivningar",
      accountOptions: budgetRowTypes.avskrivningar,
      accountRange: { from: 7700, to: 7899 },
      type: "expense",
    },
    {
      title: "Finansiella intäkter och kostnader",
      accountOptions: budgetRowTypes.finansiellaIntäkterOchKostnader,
      accountRange: { from: 7900, to: 8990 },
      type: "expense",
    },
    {
      title: "Budgeterat result",
      accountRange: { from: 3000, to: 8990 },
      accountOptions: [],
      type: "result",
      isResultRow: true,
    },
  ];

  const lastYearsFigures = convertResultReportToAccountRangeMap(result || {});

  return (
    <div className="budget">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {budgetSections.map((bs) => (
            <BudgetSheet
              key={bs.title}
              title={bs.title}
              from={from}
              to={to}
              budget={budget}
              filterAccountRange={bs.accountRange}
              accountOptions={bs.accountOptions}
              accountRecord={accountRecord}
              compareNumbers={lastYearsFigures}
              type={bs.type}
              onBudgetChanged={budgetChangedHandler}
              onTitleChanged={budgetRowTitleChangedHandler}
              onRowRemoved={budgetRowRemovedHandler}
              onAddBudgetPeriod={addBudgetPeriodHandler}
              onSave={saveHandler}
              isResultRow={bs.isResultRow}
              hasAdvancedBudget={settings.advancedBudget}
              isComparing={true}
              isSaving={isSavingLoading}
            />
          ))}
        </>
      )}
    </div>
  );
}

export default Budget;
