import "./CompanySettingsPopup.scss";

import { useCallback, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import {
  deleteCompany,
  getCompany,
  getCompanySettings,
  getMyCompany,
  setConsult,
  setResponsibleEmployee,
  updateCompanySettings,
} from "../../api/company";
import { getConsultingEmployees, searchConsulting } from "../../api/consult";
import {
  createCustomer,
  customerSetupIntent,
  deleteCard,
  getCompanyCards,
  getCompanyInfoFilled,
  setDefaultCard,
  updateCustomer,
} from "../../api/payment";
import BankCard from "../../components/BankCard/BankCard";
import Button from "../../components/UI/Button/Button";
import Checkbox from "../../components/UI/Checkbox/Checkbox";
import Dropdown from "../../components/UI/Dropdown/Dropdown";
import ElasticSearch from "../../components/UI/ElasticSearch/ElasticSearch";
import Input from "../../components/UI/Input/Input";
import Spinner from "../../components/UI/Spinner/Spinner";
import Popup from "../../hoc/Popup/Popup";
import { ROUTE } from "../../shared/enums";
import { vatPeriodTypes } from "../../shared/liquidity";
import { nimyaAlert } from "../../shared/nimyaAlert";
import { convertToDropdown } from "../../shared/utility";
import {
  branches,
  paymentTermsDaysChoices,
  vatPeriods,
} from "../../shared/values";
import {
  setCompany,
  setCompanyAsCustomerById,
} from "../../store/thunks/company";
import { ICard, IConsultSearchResult, IFortnoxScopes } from "../../types/api";
import {
  CompanySettingsNavigationItem,
  PaymentTermsDays,
} from "../../types/internal";
import { IReduxState } from "../../types/redux";
import CompanySettingsAddCard from "./CompanySettingsCard/AddCard";

interface ICompanySettingsPopupProps {
  showPopup: boolean;
  onClose: () => void;
  companyId: string;
  onDelete: () => void;
  setNavigation?: CompanySettingsNavigationItem;
}

// const stripePromise = loadStripe(environment.stripePublishableKey as string);
const stripePromise = loadStripe(
  "pk_test_51MjJ4UIOyMP8Yl8CnYJdtBIamprBMsJw4mqIR6nGBY6d6uJ0Ir95C8wdHbjz6L1BQnmrVbCYbXc9SgeRHEkHNYeg00rqyNTE9Z"
);

function CompanySettingsPopup(props: ICompanySettingsPopupProps) {
  const { companyId } = useSelector((state: IReduxState) => state.company);

  const [activeNavigation, setActiveNavigation] =
    useState<CompanySettingsNavigationItem>("Företagsinformation");
  const [isAddingAccountingFirm, setIsAddingAccountingFirm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [cards, setCards] = useState<ICard[]>([]);
  const [cardsIsLoading, setCardsIsLoading] = useState(false);
  const [paymentsTermsDays, setPaymentsTermsDays] =
    useState<PaymentTermsDays>("0");
  const [vatPeriod, setVatPeriod] = useState<vatPeriodTypes>("MONTHLY");
  const [hasClientSecret, setHasClientSecret] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [shouldCardsTabBeDisabled, setShouldCardsTabBeDisabled] =
    useState(false);
  const [showAddCard, setShowAddCard] = useState(false);

  const [amountEmployees, setAmountEmployees] = useState(0);
  const [paymentEmail, setPaymentEmail] = useState("");
  const [paymentVAT, setPaymentVAT] = useState("");
  const [paymentReceiptReferenceName, setPaymentReceiptReferenceName] =
    useState("");
  const [paymentAddress, setPaymentAddress] = useState<{
    street?: string;
    city?: string;
    country?: string;
    zip?: string;
  }>({
    street: "",
    city: "",
    country: "",
    zip: "",
  });
  const [branch, setBranch] = useState("");
  const [advancedBudget, setAdvancedBudget] = useState(false);
  const [responsibleEmployeeId, setResponsibleEmployeeId] = useState("");
  const [accountingFirm, setAccountingFirm] = useState<{
    label: string;
    value: string;
  }>({
    label: "",
    value: "",
  });

  useEffect(() => {
    if (props.setNavigation) {
      setActiveNavigation(props.setNavigation);
    } else {
      setActiveNavigation("Företagsinformation");
    }
  }, [props.setNavigation]);

  useEffect(() => {
    if (activeNavigation === "Kort") {
      (async () => {
        try {
          const result = await customerSetupIntent(props.companyId);
          if (result && result.data && result.data.payload) {
            if (result.data.payload.clientSecret) {
              setHasClientSecret(true);
              setClientSecret(result.data.payload.clientSecret);
            }
          }
        } catch (error) {
          toast.error("Något gick fel med stripe, försök igen senare");
        }
      })();
    }
  }, [activeNavigation, companyId, props.companyId]);

  const { data, isLoading, isSuccess, refetch } = useQuery({
    queryKey: ["getCompany", props.companyId],
    queryFn: async ({ queryKey: [, companyId] }) => {
      const [userRes, settingsRes, cRes, companyInfoFilled] = await Promise.all(
        [
          getMyCompany({
            companyId,
          }),
          getCompanySettings(companyId),
          getCompany(companyId),
          getCompanyInfoFilled(companyId),
        ]
      );

      return {
        companyData: cRes.data.payload,
        users: userRes.data.payload,
        settings: settingsRes.data.payload,
        companyInfoFilled: companyInfoFilled.data.payload,
      };
    },
    enabled: props.showPopup,
  });

  // const {
  //   data: cardsData,
  //   isLoading: cardsIsLoading,
  //   isSuccess: cardsIsSuccess,
  //   refetch: cardsRefetch,
  //   isRefetching,
  // } = useQuery({
  //   queryKey: ["getCustomerCards", props.companyId],
  //   queryFn: async ({ queryKey: [, companyId] }) => {
  //     const result = await getCompanyCards(companyId);
  //     console.log("getCards");
  //     return {
  //       cards: result.data.payload.cards,
  //     };
  //   },
  //   enabled: activeNavigation === "Kort" && !shouldCardsTabBeDisabled,
  // });

  const fetchCards = useCallback(async () => {
    try {
      setCardsIsLoading(true);
      const result = await getCompanyCards(props.companyId);
      if (result && result.data && result.data.payload) {
        setCards(result.data.payload.cards);
        setCardsIsLoading(false);
      }
    } catch (error) {
      toast.error("Något gick fel med att hämta kort");
    }
  }, [props.companyId]);

  useEffect(() => {
    if (activeNavigation === "Kort" && !shouldCardsTabBeDisabled) {
      (async () => {
        await fetchCards();
      })();
    }
  }, [activeNavigation, fetchCards, shouldCardsTabBeDisabled]);

  const { data: accountingFirmEmployees } = useQuery({
    queryKey: [
      "accounting-firm-employees",
      data?.companyData.company.consultingAgency || "",
      props.companyId,
    ],
    queryFn: async ({ queryKey: [, consultId, companyId] }) => {
      const res = await getConsultingEmployees({ consultId, companyId });

      return res.data.payload.employees;
    },
    enabled: !!data?.companyData.company.consultingAgency,
  });

  useEffect(() => {
    if (isSuccess) {
      const { settings, companyData, companyInfoFilled } = data;
      setPaymentsTermsDays(settings.paymentsTermsDays as PaymentTermsDays);
      setVatPeriod(settings.vatPeriod);
      setAmountEmployees(settings.amountEmployees);
      setBranch(settings.branch);
      setAccountingFirm({
        label: companyData.company.consultingAgencyInfo?.name || "",
        value: companyData.company.consultingAgency || "",
      });
      setAdvancedBudget(settings.advancedBudget);
      setResponsibleEmployeeId("");

      if (
        "isCustomer" in companyData.company &&
        !companyData.company.isCustomer
      ) {
        setShouldCardsTabBeDisabled(true);
      }

      if (companyInfoFilled) {
        console.log("companyInfoFilled", companyInfoFilled);
        setPaymentEmail(companyInfoFilled.email);
        setPaymentVAT(companyInfoFilled.vatCode);
        setPaymentReceiptReferenceName(companyInfoFilled.reference);
        setPaymentAddress({
          street: companyInfoFilled.address1,
          city: companyInfoFilled.city,
          country: companyInfoFilled.country,
          zip: companyInfoFilled.postalCode,
        });
      }
    }
  }, [isSuccess, data]);

  async function savePaymentInfo() {
    const values = {
      companyId: props.companyId,
      email: paymentEmail,
      country: paymentAddress.country,
      city: paymentAddress.city,
      address1: paymentAddress.street,
      postalCode: paymentAddress.zip,
      reference: paymentReceiptReferenceName,
      vatCode: paymentVAT,
    };

    try {
      if (data?.companyData.company.isCustomer) {
        await updateCustomer(values);
        toast.success("Betalningsinformation uppdaterad");
      }

      if (!data?.companyData.company.isCustomer) {
        await createCustomer(values);
        setCompanyAsCustomerById(props.companyId);
        shouldCardsTabBeDisabled && setShouldCardsTabBeDisabled(false);
        toast.success("Betalningsinformation sparad");
      }
    } catch (error) {
      toast.error("Något gick fel med att spara betalningsinformation");
    }
  }

  async function handleAccountingFirmSearch(searchString: string) {
    const res = await searchConsulting(searchString);
    return res.data.payload.hits;
  }

  async function addAccountingFirmHanlder(consultId: string) {
    setIsAddingAccountingFirm(true);

    try {
      await setConsult({
        companyId: props.companyId,
        consultId: consultId,
      });
      toast.success("Redovisningsbyrå tillagd");
      setIsAddingAccountingFirm(false);
      refetch();
    } catch (error) {
      toast.error("Något gick fel med att lägga till redovisningsbyrå");
      setIsAddingAccountingFirm(false);
    }
  }

  async function saveSettingsHandler() {
    setIsSaving(true);

    try {
      await updateCompanySettings({
        companyId: props.companyId,
        data: {
          paymentsTermsDays,
          vatPeriod,
          amountEmployees,
          branch,
          advancedBudget,
        },
      });
      toast.success("Inställningar sparade");

      //For updating the company settings in the redux store
      if (props.companyId === companyId) {
        setCompany(companyId);
      }

      refetch();

      setIsSaving(false);
    } catch (error) {
      toast.error("Något gick fel");

      setIsSaving(false);
    }
  }

  async function saveAccountingFirmSettings() {
    setIsSaving(true);

    if (!responsibleEmployeeId) {
      toast.error("Du måste välja en redovisningskonsult");
      return;
    }

    try {
      await setResponsibleEmployee({
        companyId: props.companyId,
        userId: responsibleEmployeeId,
      });

      toast.success("Redovisningskonsult tilldelad");
      setIsSaving(false);
    } catch (error) {
      toast.error("Något gick fel med att tilldela redovisningskonsult");
      setIsSaving(false);
    }
  }

  async function removeCompanyHandler() {
    const confirmText = `Är du säker på att du vill ta bort ${
      data?.companyData.company.name || "företaget"
    }? Denna åtgärd går inte att ångra.`;
    if (
      !(await nimyaAlert({
        title: "Ta bort företag",
        message: confirmText,
      }))
    )
      return;

    const promptText = `Ange 'Radera' för att ta bort ${
      data?.companyData.company.name || "företaget"
    }`;
    if (
      !(await nimyaAlert({
        title: "Ta bort företag",
        message: promptText,
        conifrmText: "Radera",
      }))
    )
      return;
    setIsDeleting(true);

    try {
      await deleteCompany(props.companyId);
      setIsDeleting(false);
      toast.success(
        `${data?.companyData.company.name || "företaget"} raderades`
      );
      props.onDelete();
    } catch (error) {
      toast.error("Något gick fel");
      setIsDeleting(false);
    }
  }

  async function removeCard(paymentMethodId: string) {
    try {
      await deleteCard(companyId, paymentMethodId);
      // remove this card from cards
      // await fetchCards();
      setCards(
        cards.filter((card) => card.paymentMethodId !== paymentMethodId)
      );

      toast.success(`Kortet raderades`);
    } catch (error) {
      toast.error("Något gick fel med att radera kortet");
    }
  }

  async function setAsDefaultCard(paymentMethodId: string) {
    try {
      await setDefaultCard(companyId, paymentMethodId);
      // await fetchCards();
      setCards(
        cards.map((card) => {
          if (card.paymentMethodId === paymentMethodId) {
            return {
              ...card,
              isDefault: true,
            };
          }
          return {
            ...card,
            isDefault: false,
          };
        })
      );
      toast.success(`Kortet var inställt som standardkort`);
    } catch (error) {
      toast.error("Något gick fel med att sätta kortet som standardkort");
    }
  }

  function getScopeName(key: keyof IFortnoxScopes) {
    switch (key) {
      case "companyInformation":
        return "Företagsinformation";
      case "bookKeeping":
        return "Bokföring";
      case "invoice":
        return "Faktura";
      case "payment":
        return "Betalning";
      case "supplierInvoice":
        return "Leverantörsfaktura";
      case "customer":
        return "Kund";
      case "salary":
        return "Lön";
      case "costCenter":
        return "Kostnadsställe";
      case "article":
        return "Artikel";
      case "project":
        return "Projekt";
    }
  }

  const navigationItems: CompanySettingsNavigationItem[] = [
    "Företagsinformation",
    "Redovisningsbyrå",
    "Fortnox moduler",
    "Betalning",
    "Kort",
    "Danger Zone",
  ];

  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={
        <div className="company-settings-title">
          <p className="company-name text-m-m">
            Inställningar {data?.companyData.company.name}
          </p>
          <p className="company-number text-s-m">
            Org. nummer: {data?.companyData.company.orgNr}
          </p>
        </div>
      }
      style={{ overflow: "hidden" }}
    >
      {isLoading || !isSuccess ? (
        <Spinner padding="10px" />
      ) : (
        <div className="company-settings-popup">
          <div className="navigation">
            {navigationItems
              .filter((ni) =>
                data.companyData.company.isVisma
                  ? ni !== "Fortnox moduler"
                  : true
              )
              .map((ni) => (
                <button
                  id={`company-settings-nav-${ni}`}
                  key={ni}
                  className={`nav-item text-m-r${
                    activeNavigation === ni ? " active" : ""
                  }`}
                  onClick={() => {
                    if (ni !== "Kort") {
                      setShowAddCard(false);
                    }
                    if (ni === "Kort" && shouldCardsTabBeDisabled) {
                      toast.error(
                        "Du måste fylla i betalningsinformationen innan du kan använda den här funktionen"
                      );
                      return;
                    }
                    setActiveNavigation(ni);
                  }}
                >
                  {ni}{" "}
                  {ni === "Betalning" && shouldCardsTabBeDisabled && (
                    <i
                      className="fa-solid fa-circle-exclamation"
                      style={{ fill: "red", color: "red", marginRight: "5px" }}
                    ></i>
                  )}
                </button>
              ))}
          </div>
          <div
            className={`content${
              activeNavigation === "Företagsinformation" ? " active" : ""
            }`}
          >
            <div className="company-settings-row">
              <span className="text-s-r">Antal anställda</span>
              <Input
                type="number"
                placeholder="Antal anställda"
                onChange={(v) => setAmountEmployees(+v)}
                value={`${amountEmployees}`}
                width="200px"
                short
              />
            </div>
            <div className="company-settings-row">
              <span className="text-s-r">Bransch</span>
              <Dropdown
                title="Branch"
                onSelect={setBranch}
                value={branch}
                options={convertToDropdown({
                  list: branches,
                  valueKey: "code",
                  labelKey: "name",
                })}
                width="200px"
                isSearchable
                short
              />
            </div>
            <div className="company-settings-row">
              <span className="text-s-r">
                Antal betalningsvillkorliga dagar
              </span>
              <Dropdown
                title="Betaldagar"
                onSelect={(v) => setPaymentsTermsDays(v as PaymentTermsDays)}
                value={paymentsTermsDays}
                options={paymentTermsDaysChoices}
                width="200px"
                short
              />
            </div>
            <div className="company-settings-row">
              <span className="text-s-r">Momsperiod</span>
              <Dropdown
                title="Momsperiod"
                onSelect={(v) => setVatPeriod(v as vatPeriodTypes)}
                value={vatPeriod}
                options={vatPeriods}
                width="200px"
                short
              />
            </div>
            <div className="company-settings-row">
              <span className="text-s-r">
                Redovisas budget och resultat på kontonivå?
              </span>
              <div className="checkboxes">
                <Checkbox
                  label="Ja"
                  checked={advancedBudget}
                  theme="dark"
                  onClick={() => setAdvancedBudget(true)}
                  short
                />
                <Checkbox
                  label="Nej"
                  checked={!advancedBudget}
                  theme="dark"
                  onClick={() => setAdvancedBudget(false)}
                  short
                />
              </div>
            </div>
            <div className="company-settings-row">
              <span className="text-s-r">Visa verifikationer</span>

              <Link
                to={ROUTE.Vouchers}
                onClick={() => setCompany(props.companyId)}
              >
                <Button
                  label="Till verifikationer"
                  color="black"
                  width="200px"
                  short
                />
              </Link>
            </div>
            <div className="save-settings">
              <Button
                label="Spara"
                color="black"
                onClick={saveSettingsHandler}
                width="200px"
                isLoading={isSaving}
                short
              />
            </div>
          </div>
          <div
            className={`content${
              activeNavigation === "Redovisningsbyrå" ? " active" : ""
            }`}
          >
            <div className="company-settings-row">
              <span className="text-s-r">
                {data?.companyData.company.consultingAgencyConfirmed === false
                  ? `Inväntar bekräftelse från ${
                      data?.companyData.company.consultingAgencyInfo?.name ||
                      "redovisningsbyrån"
                    }`
                  : "Företagets redovisningsbyrå"}
              </span>
              <div className="accounting-firm">
                <ElasticSearch<IConsultSearchResult[]>
                  title="Välj konsultbolag"
                  handleSearch={handleAccountingFirmSearch}
                  value={accountingFirm}
                  onSelect={setAccountingFirm}
                  convertResult={(result) =>
                    convertToDropdown({
                      list: result,
                      labelKey: "name",
                      valueKey: "id",
                    })
                  }
                  width="200px"
                  short
                />
                <Button
                  label={
                    data?.companyData.company.consultingAgencyInfo?.name
                      ? "Uppdatera"
                      : "Lägg till"
                  }
                  color="black"
                  onClick={() => addAccountingFirmHanlder(accountingFirm.value)}
                  width="100px"
                  isLoading={isAddingAccountingFirm}
                  short
                />
              </div>
            </div>
            <div className="company-settings-row">
              <span className="text-s-r">Redovisningskonsult</span>
              <Dropdown
                title="Konsult"
                options={accountingFirmEmployees?.map((emp) => ({
                  label: `${emp.user.first_name || emp.user.email} ${
                    emp.user.last_name || ""
                  }`,
                  value: emp.user._id,
                }))}
                onSelect={setResponsibleEmployeeId}
                value={responsibleEmployeeId}
                width="310px"
                short
              />
            </div>
            <div className="save-settings">
              <Button
                label="Spara"
                color="black"
                onClick={saveAccountingFirmSettings}
                width="200px"
                isLoading={isSaving}
                short
              />
            </div>
          </div>
          <div
            className={`content${
              activeNavigation === "Fortnox moduler" ? " active" : ""
            }`}
          >
            {Object.keys(data.settings.fortnoxScopes || {}).map((key) => (
              <div className="company-settings-row" key={key}>
                <span className="text-s-r">
                  {getScopeName(key as keyof IFortnoxScopes)}
                </span>
                <span className="text-s-r">
                  {data.settings.fortnoxScopes?.[key as keyof IFortnoxScopes]
                    ? "Aktiv"
                    : "Inaktiv"}
                </span>
              </div>
            ))}
          </div>
          <div
            className={`content${
              activeNavigation === "Betalning" ? " active" : ""
            }`}
          >
            <div className="header">
              <p className="text-m-r">Skapa profil för betalningar</p>
            </div>
            <form className="company-settings-col">
              <Input
                type="email"
                placeholder="Email adress"
                onChange={(v) => setPaymentEmail(v)}
                value={paymentEmail}
                label="Email adress"
                width="100%"
              />
              <Input
                type="text"
                placeholder="VAT nummer"
                onChange={(v) => setPaymentVAT(v)}
                value={paymentVAT}
                label="*VAT nummer"
                width="100%"
              />
              <Input
                type="email"
                placeholder="Namn (referens på kvitto)"
                onChange={(v) => setPaymentReceiptReferenceName(v)}
                value={paymentReceiptReferenceName}
                label="Namn (referens på kvitto)"
                width="100%"
              />

              <div className="input-group">
                <Input
                  type="text"
                  placeholder="Adress"
                  onChange={(v) =>
                    setPaymentAddress({ ...paymentAddress, street: v })
                  }
                  value={paymentAddress?.street || ""}
                  label="Address"
                  width="100%"
                />
                <Input
                  type="text"
                  placeholder="Postnummer"
                  onChange={(v) =>
                    setPaymentAddress({ ...paymentAddress, zip: v })
                  }
                  value={paymentAddress?.zip || ""}
                  width="25%"
                />
                <Input
                  type="text"
                  placeholder="Land"
                  onChange={(v) =>
                    setPaymentAddress({ ...paymentAddress, country: v })
                  }
                  value={paymentAddress?.country || ""}
                  width="25%"
                />
              </div>
            </form>
            <div
              className="save-settings"
              style={{ backgroundColor: "#F8F8F8" }}
            >
              <Button
                label="Spara"
                color="black"
                onClick={savePaymentInfo}
                width="200px"
                isLoading={isSaving}
                short
              />
            </div>
          </div>
          <div className={`content${showAddCard ? " active" : ""}`}>
            {hasClientSecret && clientSecret != null ? (
              <Elements
                stripe={stripePromise}
                options={{ clientSecret: clientSecret }}
              >
                <div className="header">
                  <div className="actions">
                    {showAddCard && (
                      <button
                        className="back-button"
                        onClick={() => setShowAddCard(false)}
                      >
                        <i className="fa-regular fa-arrow-left"></i>
                      </button>
                    )}
                    <p className="text-m-r">Lägg till nya kort</p>
                  </div>
                </div>

                <CompanySettingsAddCard
                  clientSecret={clientSecret}
                  cb={() => {
                    setShowAddCard(false);
                    fetchCards();
                  }}
                />
              </Elements>
            ) : (
              <Spinner padding="10px" />
            )}
          </div>
          <div
            className={`content${
              activeNavigation === "Kort" && !showAddCard ? " active" : ""
            }`}
          >
            <div className="header">
              <p className="text-m-r">Skapa profil för betalningar</p>
            </div>
            {cardsIsLoading ? (
              <Spinner padding="10px" />
            ) : (
              <>
                {!cards ? (
                  <div className="company-settings-row">
                    <span className="text-s-r">Inga kort är tillgängliga</span>
                  </div>
                ) : (
                  <div className="card-list">
                    {cards.length === 0 ? (
                      <span className="text-m-r">Inget kort</span>
                    ) : (
                      <span className="text-m-r">Standardkort</span>
                    )}
                    {cards
                      .filter((item) => item.isDefault === true)
                      .map((card) => (
                        <BankCard
                          key={card.paymentMethodId}
                          deleteCard={() => removeCard(card.paymentMethodId)}
                          setAsDefaultCard={() =>
                            setAsDefaultCard(card.paymentMethodId)
                          }
                          cardNumber={card.last4}
                          cardType={card.brand}
                          expMonth={card.expMonth}
                          expYear={card.expYear}
                          isDefault={card.isDefault}
                        />
                      ))}
                    {cards.length > 1 && (
                      <>
                        <span className="text-m-r">Övriga betalkort</span>
                        {cards
                          .filter((item) => item.isDefault === false)
                          .map((card) => (
                            <BankCard
                              key={card.paymentMethodId}
                              deleteCard={() =>
                                removeCard(card.paymentMethodId)
                              }
                              setAsDefaultCard={() =>
                                setAsDefaultCard(card.paymentMethodId)
                              }
                              cardNumber={card.last4}
                              cardType={card.brand}
                              expMonth={card.expMonth}
                              expYear={card.expYear}
                              isDefault={card.isDefault}
                            />
                          ))}
                      </>
                    )}
                  </div>
                )}
              </>
            )}
            <div
              className="save-settings"
              style={{ backgroundColor: "#F8F8F8" }}
            >
              <Button
                label="Lägg till betalmetod"
                color="black"
                onClick={() => setShowAddCard(true)}
                width="200px"
                short
              />
            </div>
          </div>
          <div
            className={`content${
              activeNavigation === "Danger Zone" ? " active" : ""
            }`}
          >
            <div className="company-settings-row">
              <span className="text-s-r">Ta bort företag</span>
              <Button
                label="Radera"
                color="red"
                onClick={removeCompanyHandler}
                width="200px"
                isLoading={isDeleting}
                short
              />
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}

export default CompanySettingsPopup;
