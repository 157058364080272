import { IAlert } from "../types/api";
import { ALERT_VALUE } from "./enums";
import { convertToNumberWithSpaces } from "./utility";

export function getAlertDescription(alert: IAlert) {
  if (typeof alert.result === "string") {
    return alert.result;
  }

  if (alert.description) {
    return alert.description.replaceAll(
      ALERT_VALUE.DescriptionValue,
      convertToNumberWithSpaces(
        alert.isPercent ? alert.result * 100 : alert.result
      )
    );
  }

  return `${convertToNumberWithSpaces(
    alert.result,
    alert.isPercent ? "%" : "kr"
  )}`;
}
