import { motion, Variants } from "framer-motion";
import { convertToNumberWithSpaces } from "../../shared/utility";
import CircleChart from "../CircleChart/CircleChart";
import Button from "../UI/Button/Button";
import defaultProfileImage from "../../assets/images/default-profile-image.svg";
import "./ExtendedOverviewRow.scss";
import { Link } from "react-router-dom";
import { QUERY_PARAM, ROUTE } from "../../shared/enums";
import { setIsMessageOpen } from "../../store/slices/message";
import { useDispatch } from "react-redux";
import { fromUnixTime, lightFormat } from "date-fns";
import { months } from "../../shared/values";

interface IExtendedOverviewRowProps {
  name: string;
  income: number;
  incomePrevYear: number;
  expenses: number;
  expensesPrevYear: number;
  budgetIncome: number;
  budgetExpenses: number;
  cashAndBank: number;
  cashAndBankPrevYear: number;
  capital: number;
  capitalPrevYear: number;
  latestMessageUnix: number | undefined;
  userAvatar: string | null;
  amountVouchers: number;
  alertsThisMonth: number;
  from: number;
  to: number;
}

function ExtendedOverviewRow(props: IExtendedOverviewRowProps) {
  const dispatch = useDispatch();

  const rowVariants: Variants = {
    offscreen: {
      y: 20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
      },
    },
  };

  const result = props.income + props.expenses;
  const resultPrevYear = props.incomePrevYear + props.expensesPrevYear;
  const budgetResult = props.budgetIncome + props.budgetExpenses;

  const spannText = `(${months[fromUnixTime(props.from).getMonth()].label.slice(
    0,
    3
  )} - ${months[fromUnixTime(props.to).getMonth()].label.slice(0, 3)})`;

  return (
    <motion.div
      className="extended-overview-row"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.1 }}
      variants={rowVariants}
      transition={{ ease: "easeInOut" }}
    >
      <div className="top text-m-m">{props.name}</div>
      <div className="content">
        <div className="section-row">
          <Link
            to={{
              pathname: ROUTE.Result,
              search: `${QUERY_PARAM.Graph}=income`,
            }}
            className="section"
          >
            <div className="values">
              <div className="value">
                <p className="title text-m-r">Intäkter {spannText}</p>
                <p className="text-l-m">
                  {convertToNumberWithSpaces(props.income)}
                </p>
              </div>
              <div className="value">
                <p className="title text-m-r">Budget</p>
                <p className="text-l-m">
                  {convertToNumberWithSpaces(props.budgetIncome)}
                </p>
              </div>
            </div>
            <CircleChart
              value={props.income}
              compareValue={props.budgetIncome || props.income}
              color="#5B93FF"
              backgroundColor="#DEEAFF"
            />
          </Link>
          <Link
            to={{
              pathname: ROUTE.Result,
              search: `${QUERY_PARAM.Graph}=expense`,
            }}
            className="section"
          >
            <div className="values">
              <div className="value">
                <p className="title text-m-r">Kostnader {spannText}</p>
                <p className="text-l-m">
                  {convertToNumberWithSpaces(props.expenses)}
                </p>
              </div>
              <div className="value">
                <p className="title text-m-r">Budget</p>
                <p className="text-l-m">
                  {convertToNumberWithSpaces(props.budgetExpenses)}
                </p>
              </div>
            </div>
            <CircleChart
              value={props.expenses}
              compareValue={props.budgetExpenses || props.expenses}
              color="#EF60FC"
            />
          </Link>
          <Link
            to={{
              pathname: ROUTE.Result,
              search: `${QUERY_PARAM.Graph}=result`,
            }}
            className="section"
          >
            <div className="values">
              <div className="value">
                <p className="title text-m-r">Resultat {spannText}</p>
                <p className="text-l-m">{convertToNumberWithSpaces(result)}</p>
              </div>
              <div className="value">
                <p className="title text-m-r">Budget</p>
                <p className="text-l-m">
                  {convertToNumberWithSpaces(budgetResult)}
                </p>
              </div>
            </div>
            <CircleChart
              value={result}
              compareValue={budgetResult || result}
              color="#00B448"
              backgroundColor="#E8F8F9"
            />
          </Link>
        </div>
        <div className="section-row">
          <Link to={ROUTE.CashFlow} className="section">
            <div className="values">
              <div className="value">
                <p className="title text-m-r">Kassa & Bank</p>
                <p className="text-l-m">
                  {convertToNumberWithSpaces(props.cashAndBank)}
                </p>
              </div>
              <div className="value">
                <p className="title text-m-r">Föregående år</p>
                <p className="text-l-m">
                  {convertToNumberWithSpaces(props.cashAndBankPrevYear)}
                </p>
              </div>
            </div>
            <CircleChart
              value={props.cashAndBank}
              compareValue={props.cashAndBankPrevYear || props.cashAndBank}
            />
          </Link>
          <Link to={ROUTE.Balance} className="section">
            <div className="values">
              <div className="value">
                <p className="title text-m-r">Eget kapital</p>
                <p className="text-l-m">
                  {convertToNumberWithSpaces(props.capital)}
                </p>
              </div>
              <div className="value">
                <p className="title text-m-r">Föregående år</p>
                <p className="text-l-m">
                  {convertToNumberWithSpaces(props.capitalPrevYear)}
                </p>
              </div>
            </div>
            <CircleChart
              value={props.capital}
              compareValue={props.capitalPrevYear || props.capital}
            />
          </Link>
          <Link to={ROUTE.Balance} className="section">
            <div className="values">
              <div className="value">
                <p className="title text-m-r">Eget kapital + årets resultat</p>
                <p className="text-l-m">
                  {convertToNumberWithSpaces(props.capital + result)}
                </p>
              </div>
              <div className="value">
                <p className="title text-m-r">Föregående år</p>
                <p className="text-l-m">
                  {convertToNumberWithSpaces(
                    props.capitalPrevYear + resultPrevYear
                  )}
                </p>
              </div>
            </div>
            <CircleChart
              value={props.capital + result}
              compareValue={props.capitalPrevYear + resultPrevYear}
            />
          </Link>
        </div>
        <div className="section-row">
          <div className="section">
            <div className="values">
              <div className="value">
                <p className="title text-m-r">Meddelanden</p>
                {props.latestMessageUnix ? (
                  <p className="text-m-m">
                    Senast skickat{" "}
                    {lightFormat(
                      fromUnixTime(props.latestMessageUnix),
                      "yyyy-MM-dd"
                    )}
                  </p>
                ) : (
                  <p className="text-m-m">Inga meddelanden</p>
                )}
              </div>
              <div className="value">
                <Button
                  color="black"
                  label="Till meddelanden"
                  short
                  onClick={() => dispatch(setIsMessageOpen(true))}
                />
              </div>
            </div>
            {props.latestMessageUnix && (
              <img
                src={
                  props.userAvatar
                    ? `data:image/png;base64,${props.userAvatar}`
                    : defaultProfileImage
                }
                alt="Profil bild"
              />
            )}
          </div>
          <div className="section">
            <div className="values">
              <div className="value">
                <p className="title text-m-r">Notiser</p>
                <p className="text-m-m">
                  Antal genererade{" "}
                  {lightFormat(fromUnixTime(props.to), "yyyy-MM")}
                </p>
              </div>
              <div className="value">
                <Link to={ROUTE.Alerts}>
                  <Button color="black" label="Till notiser" short />
                </Link>
              </div>
            </div>
            <CircleChart
              value={props.alertsThisMonth}
              compareValue={0}
              showDiffInNumber
              color="#EFEFFF"
            />
          </div>

          <div className="section">
            <div className="values">
              <div className="value">
                <p className="title text-m-r">Verifikationer</p>
                <p className="text-m-m">
                  Mellan {lightFormat(fromUnixTime(props.from), "yyyy-MM-dd")} -{" "}
                  {lightFormat(fromUnixTime(props.to), "yyyy-MM-dd")}
                </p>
              </div>
              <div className="value">
                <Link to={ROUTE.Vouchers}>
                  <Button color="black" label="Till verifikationer" short />
                </Link>
              </div>
            </div>
            <CircleChart
              value={props.amountVouchers}
              compareValue={0}
              showDiffInNumber
              color="#EFEFFF"
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default ExtendedOverviewRow;
