import ga4 from "react-ga4";

export function init(trackingId: string) {
  ga4.initialize(trackingId);
}

export function sendPageview(path: string) {
  ga4.send({
    hitType: "pageview",
    page: path,
  });
}
