import { PaymentTermsDays } from "../types/internal";

export const months = [
  { label: "Januari", value: "1" },
  { label: "Februari", value: "2" },
  { label: "Mars", value: "3" },
  { label: "April", value: "4" },
  { label: "Maj", value: "5" },
  { label: "Juni", value: "6" },
  { label: "Juli", value: "7" },
  { label: "Augusti", value: "8" },
  { label: "September", value: "9" },
  { label: "Oktober", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

export function years() {
  const years: { label: string; value: string }[] = [];
  const date = new Date(new Date().getFullYear() - 5, 1, 1);
  for (let i = 0; i < 7; i++) {
    years.push({
      label: `${date.getFullYear()}`,
      value: `${date.getFullYear()}`,
    });
    date.setFullYear(date.getFullYear() + 1);
  }
  return years.reverse();
}

export function brokenYears() {
  const years: { label: string; value: string }[] = [];
  const date = new Date(new Date().getFullYear() - 5, 1, 1);
  for (let i = 0; i < 7; i++) {
    years.push({
      label: `${date.getFullYear()}/${date.getFullYear() + 1}`,
      value: `${date.getFullYear()}`,
    });
    date.setFullYear(date.getFullYear() + 1);
  }
  return years.reverse();
}

export const vatPeriods = [
  {
    label: "Månadsmoms",
    value: "MONTHLY",
  },
  {
    label: "Kvartalsmoms",
    value: "QUARTERLY",
  },
  {
    label: "Helårsmoms",
    value: "YEARLY",
  },
];

export const paymentTermsDaysChoices: {
  label: PaymentTermsDays;
  value: PaymentTermsDays;
}[] = [
  {
    label: "0",
    value: "0",
  },
  {
    label: "20",
    value: "20",
  },
  {
    label: "30",
    value: "30",
  },
  {
    label: "60",
    value: "60",
  },
  {
    label: "90",
    value: "90",
  },
];

export const branches = [
  { code: "01", name: "Jordbruksföretag och serviceföretag till jordbruk" },
  { code: "02", name: "Skogsbruksföretag" },
  { code: "03", name: "Fiskare och vattenbrukare" },
  { code: "07", name: "Metallmalmsgruvor" },
  { code: "08", name: "Annan industri för mineralutvinning" },
  { code: "09", name: "Serviceföretag till utvinning" },
  { code: "10", name: "Livsmedelsindustri" },
  { code: "11", name: "Dryckesvaruindustri" },
  { code: "12", name: "Tobaksindustri" },
  { code: "13", name: "Textilindustri" },
  { code: "14", name: "Beklädnadsindustri" },
  { code: "15", name: "Industri för läder och lädervaror" },
  {
    code: "16",
    name: "Industri för trä och varor av trä, kork och rotting o.d. utom möbler",
  },
  { code: "17", name: "Massa-, pappers- och pappersvaruindustri" },
  { code: "18", name: "Grafisk och annan reproduktionsindustri" },
  {
    code: "19",
    name: "Industri för stenkolsprodukter och raffinerade petroleumprodukter",
  },
  { code: "20", name: "Kemisk industri" },
  {
    code: "21",
    name: "Industri för farmaceutiska basprodukter och läkemedel",
  },
  { code: "22", name: "Gummi- och plastvaruindustri" },
  {
    code: "23",
    name: "Industri för andra icke-metalliska mineraliska produkter",
  },
  { code: "24", name: "Stål- och metallverk" },
  {
    code: "25",
    name: "Industri för metallvaror utom maskiner och apparater",
  },
  { code: "26", name: "Industri för datorer, elektronikvaror och optik" },
  { code: "27", name: "Industri för elapparatur" },
  { code: "28", name: "Övrig maskinindustri" },
  {
    code: "29",
    name: "Industri för motorfordon, släpfordon och påhängsvagnar",
  },
  { code: "30", name: "Annan transportmedelsindustri" },
  { code: "31", name: "Möbelindustri" },
  { code: "32", name: "Annan industri" },
  {
    code: "33",
    name: "Reparationsverkstäder och installationsföretag för maskiner och apparater",
  },
  { code: "35", name: "El-, gas- och värmeverk" },
  { code: "36", name: "Vattenverk" },
  { code: "37", name: "Reningsverk" },
  {
    code: "38",
    name: "Avfallshanteringsanläggningar, återvinningsanläggningar",
  },
  {
    code: "39",
    name: "Anläggningar för sanering, efterbehandling av jord och vatten samt annan verksamhet för föroreningsbekämpning",
  },
  { code: "41", name: "Byggentreprenörer" },
  { code: "42", name: "Anläggningsentreprenörer" },
  { code: "43", name: "Specialiserade bygg- och anläggningsentreprenörer" },
  {
    code: "45",
    name: "Handel med och serviceverkstäder för motorfordon och motorcyklar",
  },
  { code: "46", name: "Parti - och provisionshandel utom med motorfordon" },
  { code: "47", name: "Detaljhandel utom med motorfordon och motorcyklar" },
  { code: "49", name: "Landtransportföretag, rörtransportföretag" },
  { code: "50", name: "Rederier" },
  { code: "51", name: "Flygbolag" },
  { code: "52", name: "Magasin och serviceföretag till transport" },
  { code: "53", name: "Post- och kurirföretag" },
  {
    code: "55",
    name: "Hotell, semesterbostäder, vandrarhem, campingplatser m.m.",
  },
  { code: "56", name: "Restauranger, cateringföretag, barer och pubar" },
  { code: "58", name: "Förlag" },
  {
    code: "59",
    name: "Film-, video och TV-programföretag, ljudinspelningsstudior och fonogramutgivare",
  },
  { code: "60", name: "Radio- och TV-bolag" },
  { code: "61", name: "Telekommunikationsbolag" },
  { code: "62", name: "Programvaruproducenter, datakonsulter o.d." },
  { code: "63", name: "Informationstjänstföretag" },
  { code: "68", name: "Fastighetsbolag och fastighetsförvaltare" },
  { code: "69", name: "Juridiska och ekonomiska konsultbyråer" },
  {
    code: "70",
    name: "Huvudkontor, konsultbyråer inom PR och kommunikation samt företags organisation",
  },
  {
    code: "71",
    name: "Arkitektkontor, tekniska konsultbyråer, tekniska provnings- och analysföretag",
  },
  { code: "72", name: "Forsknings- och utvecklingsinstitutioner" },
  {
    code: "73",
    name: "Reklam- och marknadsföringsbyråer, marknadsundersökningsbyråer o.d.",
  },
  {
    code: "74",
    name: "Andra företag inom juridik, ekonomi, vetenskap och teknik",
  },
  { code: "75", name: "Veterinärkliniker" },
  { code: "77", name: "Uthyrningsfirmor" },
  {
    code: "78",
    name: "Arbetsförmedlingar, rekryteringsföretag, personaluthyrningsföretag o.d.",
  },
  { code: "79", name: "Resebyråer och researrangörer, turistbyråer" },
  {
    code: "80",
    name: "Företag för bevakning och säkerhetstjänst, detektivbyråer",
  },
  {
    code: "81",
    name: "Företag för fastighetsservice samt skötsel och underhåll av grönytor",
  },
  { code: "82", name: "Kontors- och andra företagstjänstföretag" },
  { code: "85", name: "Utbildningsväsende" },
  { code: "86", name: "Enheter för hälso- och sjukvård" },
  { code: "87", name: "Vårdhem och bostäder med omsorg" },
  { code: "88", name: "Sociala öppenvårdsenheter, socialkontor" },
  {
    code: "90",
    name: "Enheter för konstnärlig och kulturell verksamhet samt underhållningsverksamhet",
  },
  { code: "91", name: "Bibliotek, arkiv och museer m.m." },
  { code: "92", name: "Spel- och vadhållningsföretag" },
  { code: "93", name: "Sport-, fritids- och nöjesanläggningar" },
  {
    code: "95",
    name: "Reparationsverkstäder för datorer, hushållsartiklar och personliga artiklar",
  },
  { code: "96", name: "Andra konsumenttjänstföretag" },
];

export const budgetIncomeRowTypes = [
  { label: "Nettoomsättning", value: "3000-3799" },
  { label: "Övriga intäkter", value: "3800-3999" },
];

export const budgetVaruinköpRowTypes = [
  { label: "Varuinköp", value: "4000-4999" },
];

export const budgetÖvrigaRörelsekostnaderRowTypes = [
  { label: "Lokalkostnader", value: "5000-5199" },
  { label: "Hyra av anläggningstillgångar", value: "5200-5399" },
  {
    label: "Förbrukningsinventarier och förbrukningsmaterial",
    value: "5400-5599",
  },
  { label: "Kostnader för transportmedel", value: "5600-5699" },
  { label: "Frakter och transporter", value: "5700-5799" },
  { label: "Resekostnader", value: "5800-5899" },
  { label: "Reklam och PR", value: "5900-5999" },
  { label: "Övriga försäljningskostnader", value: "6000-6099" },
  { label: "Kontorsmaterial och trycksaker", value: "6100-6199" },
  { label: "Tele och post", value: "6200-6299" },
  {
    label: "Företagsförsäkringar och övriga riskkostnader",
    value: "6300-6399",
  },
  { label: "Förvaltningskostnader", value: "6400-6499" },
  { label: "Övriga externa tjänster", value: "6500-6599" },
  { label: "Övriga externa kostnade", value: "6600-6999" },
];

export const budgetPersonalkostnaderRowTypes = [
  { label: "Löner till kollektivanställda", value: "7000-7399" },
  { label: "Pensionskostnader", value: "7400-7499" },
  { label: "Sociala avgifter", value: "7500-7599" },
  { label: "Övriga personalkostnader", value: "7600-7699" },
];

export const budgetAvskrivningarRowTypes = [
  { label: "Avskrivningar", value: "7700-7899" },
];

export const budgetFinansiellaIntäkterOchKostnaderRowTypes = [
  { label: "Finansiella intäkter och kostnader", value: "7900-8899" },
  { label: "Skatt årets resultat", value: "8900, 8990" },
];

export const budgetRowTypes = {
  intäkter: budgetIncomeRowTypes,
  varuinköp: budgetVaruinköpRowTypes,
  övrigaRörelsekostnader: budgetÖvrigaRörelsekostnaderRowTypes,
  personalkostnader: budgetPersonalkostnaderRowTypes,
  avskrivningar: budgetAvskrivningarRowTypes,
  finansiellaIntäkterOchKostnader:
    budgetFinansiellaIntäkterOchKostnaderRowTypes,
};

export const budgetExpenseRowTypes = [
  { label: "Varuinköp", value: "4000-4999" },
  { label: "Lokalkostnader", value: "5000-5199" },
  { label: "Hyra av anläggningstillgångar", value: "5200-5399" },
  {
    label: "Förbrukningsinventarier och förbrukningsmaterial",
    value: "5400-5599",
  },
  { label: "Kostnader för transportmedel", value: "5600-5699" },
  { label: "Frakter och transporter", value: "5700-5799" },
  { label: "Resekostnader", value: "5800-5899" },
  { label: "Reklam och PR", value: "5900-5999" },
  { label: "Övriga försäljningskostnader", value: "6000-6099" },
  { label: "Kontorsmaterial och trycksaker", value: "6100-6199" },
  { label: "Tele och post", value: "6200-6299" },
  {
    label: "Företagsförsäkringar och övriga riskkostnader",
    value: "6300-6399",
  },
  { label: "Förvaltningskostnader", value: "6400-6499" },
  { label: "Övriga externa tjänster", value: "6500-6599" },
  { label: "Övriga externa kostnade", value: "6600-6999" },
  { label: "Löner till kollektivanställda", value: "7000-7399" },
  { label: "Pensionskostnader", value: "7400-7499" },
  { label: "Sociala avgifter", value: "7500-7599" },
  { label: "Övriga personalkostnader", value: "7600-7699" },
  { label: "Avskrivningar", value: "7700-7899" },
  { label: "Finansiella intäkter och kostnader", value: "7900-8899" },
  { label: "Skatt årets resultat", value: "8900-8990" },
];
