import store from "..";
import { readNotice } from "../../api/notice";
import { readMessage as readMess } from "../slices/message";

const { getState, dispatch } = store;

export async function readMessage(messageId: string) {
  const { unread } = getState().message;

  const unreadMessage = unread.find(
    (message) => message.commentId === messageId
  );

  if (unreadMessage) {
    await readNotice({ noticeId: unreadMessage._id, commentId: messageId });
    dispatch(readMess(messageId));
  }
}
