import { IBalanceReport } from "../../types/api";
import "./BalanceReport.scss";
import BalanceReportSection from "./BalanceReportSection/BalanceReportSection";

interface IBalanceReportProps {
  report: IBalanceReport;
  from: number;
  to: number;
}

function BalanceReport(props: IBalanceReportProps) {
  return (
    <div className="balance-report">
      <div className="top">
        <p className="text-m-m">Balansrapport</p>
      </div>
      <BalanceReportSection
        title="Tillgångar"
        rows={props.report.Tillgångar}
        from={props.from}
        to={props.to}
      />
      <BalanceReportSection
        title="Eget kapital och skulder"
        rows={{
          ...props.report["Eget kapital"],
          ...props.report.Skulder,
        }}
        from={props.from}
        to={props.to}
      />
    </div>
  );
}

export default BalanceReport;
