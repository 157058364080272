import { isOnDev } from "../../shared/utility";
import "./DevBanner.scss";

interface IDevBannerProps {}

function DevBanner(props: IDevBannerProps) {
  return isOnDev() ? <div className="dev-banner">ON DEVELOPMENT</div> : null;
}

export default DevBanner;
