import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteCustomVariable,
  updateCustomVariable,
} from "../../../api/report";
import VariableInformation from "../../../hoc/VariableInformation/VariableInformation";
import { VARIABLE_VALUE } from "../../../shared/enums";
import { convertToFormulaArray } from "../../../shared/variableUtility";
import { IVariable, VariableType } from "../../../types/api";
import { IFormulaItem } from "../../../types/internal";
import { IReduxState } from "../../../types/redux";
import Button from "../../UI/Button/Button";
import Input from "../../UI/Input/Input";
import KeyValueFormula from "../KeyValueFormula/KeyValueFormula";
import "./CustomVariable.scss";

interface ICustomVariableProps {
  variable?: IVariable;
  onAddVariable: (fItem: IFormulaItem) => void;
  onDelete: (vId: string) => void;
  onClickEditVariable: (v: IVariable) => void;
  onEditVariable: (vId: string) => void;
}

const CustomVariable = (props: ICustomVariableProps) => {
  const { companyId } = useSelector((state: IReduxState) => state.company);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [accountFrom, setAccountFrom] = useState("");
  const [accountTo, setAccountTo] = useState("");
  const [variableName, setVariableName] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setIsEditing(false);
  }, [props.variable]);

  function getVariableTypeName(type: VariableType) {
    switch (type) {
      case "KeyFigure":
        return "Nyckeltal";
      case "Number":
        return "Nummer";
      case "Date":
        return "Datum";
      case "Account":
        return "Konto";
      case "Alert":
        return "Notifikation";
      case "AccountRange":
        return "Kontospann";
      case "Range":
        return "Spann";
    }
  }

  function getFormulaTitle(type: VariableType) {
    switch (type) {
      case "KeyFigure":
        return "Uträkning";
      case "Number":
        return "Siffra";
      case "Date":
        return "Datum";
      case "Account":
        return "Konto som används";
      case "Alert":
        return "Notifikation";
      case "AccountRange":
        return "Konton som används";
      case "Range":
        return "Spann";
    }
  }

  function variableDeleteHandler(vId: string) {
    setIsDeleting(true);
    deleteCustomVariable({ companyId, variableId: vId }).then((res) => {
      setIsDeleting(false);
      props.onDelete(vId);
    });
  }

  function onEditHandler() {
    if (
      props.variable?.variableType !== "Account" &&
      props.variable?.variableType !== "AccountRange"
    ) {
      props.onClickEditVariable(props.variable!);
      return;
    }
    setIsEditing(true);
    const accountString = props.variable?.value.replace(/[[\]]+/g, "");
    const accounts = accountString.split(":");
    setVariableName(props.variable?.name!);
    setAccountFrom(accounts[0]);
    setAccountTo(accounts[1] || "");
  }

  function saveEditHanlder() {
    if (!variableName) {
      toast.error("Variabelnamnet får inte vara tomt");
      return;
    }

    setIsUpdating(true);
    let value = "";
    if (props.variable?.variableType === "Account") {
      value = `[${accountFrom}]`;
    } else if (props.variable?.variableType === "AccountRange") {
      value = `[${accountFrom}:${accountTo}]`;
    }

    updateCustomVariable({
      variableString: value,
      name: variableName,
      isPercent: !!props.variable?.isPercent,
      showInTable: !!props.variable?.showInTable,
      variableId: props.variable?._id!,
    }).then((res) => {
      setIsUpdating(false);
      setIsEditing(false);
      props.onEditVariable(res.data.payload.variableId);
    });
  }

  return props.variable ? (
    <VariableInformation
      type={getVariableTypeName(props.variable.variableType)}
      name={props.variable.name.replace(VARIABLE_VALUE.GlobalPrefix, "")}
      description=""
      formulaTitle={getFormulaTitle(props.variable.variableType)}
    >
      <div className="variable-value">
        <KeyValueFormula
          formula={convertToFormulaArray(props.variable.value)}
          onSetInsertIndex={() => {}}
        />
      </div>
      <div className="variable-buttons">
        <Button
          color="black"
          label="Lägg till i formel"
          onClick={() =>
            props.onAddVariable({
              value: `$${props.variable!.name}`,
              type: "variable",
            })
          }
          short
        />
        {!props.variable.global && (
          <>
            <Button
              color="white"
              label="Redigera variabel"
              onClick={onEditHandler}
              short
            />
            <Button
              color="white"
              label="Ta bort variabel"
              onClick={() => variableDeleteHandler(props.variable!._id)}
              short
              isLoading={isDeleting}
            />
          </>
        )}
      </div>
      {isEditing && (
        <form
          className="variable-edit-form"
          onSubmit={(e) => {
            e.preventDefault();
            saveEditHanlder();
          }}
        >
          <div className="variable-edit-form--section">
            <Input
              type="text"
              value={accountFrom}
              onChange={setAccountFrom}
              width="90px"
              label={
                props.variable.variableType === "AccountRange"
                  ? "Från konto"
                  : "Konto"
              }
              short
            />
            {props.variable.variableType === "AccountRange" && (
              <Input
                type="text"
                value={accountTo}
                onChange={setAccountTo}
                width="90px"
                label="Till konto"
                short
              />
            )}
          </div>
          <div className="variable-edit-form--section">
            <Input
              type="text"
              value={variableName}
              onChange={(v) => {
                setVariableName(v);
              }}
              placeholder="Variabelnamn"
              label="Namn"
              short
            />
            <Button
              color="black"
              label="Uppdatera variabel"
              isLoading={isUpdating}
              short
            />
          </div>
        </form>
      )}
    </VariableInformation>
  ) : (
    <VariableInformation
      type={"Välj en variabel i sidomenyn"}
      name=""
      description=""
      formulaTitle=""
    >
      <></>
    </VariableInformation>
  );
};

export default CustomVariable;
