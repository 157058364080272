import { useCallback, useEffect } from "react";
import Spinner from "../../components/UI/Spinner/Spinner";
import { QUERY_PARAM } from "../../shared/enums";
import { useSearchQuery } from "../../shared/hooks/useSearchQuery";
import { login } from "../../store/thunks/auth";
import "./OAuthLoading.scss";

interface OAuthLoadingProps {}

function OAuthLoading(props: OAuthLoadingProps) {
  const query = useSearchQuery();

  const loginWithToken = useCallback(async () => {
    const accessToken = query.get(QUERY_PARAM.AccessToken);
    const refreshToken = query.get(QUERY_PARAM.RefreshToken);

    if (!accessToken || !refreshToken) return;

    await login({
      accessToken,
      refreshToken,
    });
  }, [query]);

  useEffect(() => {
    loginWithToken();
  }, [loginWithToken]);

  return (
    <div className="o-auth-loading">
      <Spinner />
    </div>
  );
}

export default OAuthLoading;
