import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { changePassword } from "../../api/auth";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import OnbordingLayout from "../../hoc/OnbordingLayout/OnbordingLayout";
import { ROUTE } from "../../shared/enums";
import { login } from "../../store/thunks/auth";
import "./ForgotPassword.scss";

interface IForgotPasswordProps {}

function ForgotPassword(props: IForgotPasswordProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit() {
    if (newPassword !== confirmPassword) {
      toast.error("Lösenorden måste vara lika");
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    const secret = queryParams.get("secret");
    const hash = queryParams.get("hash");

    if (!secret || !hash) {
      toast.error("Din länk är felaktig");
      return;
    }
    setIsLoading(true);

    try {
      const res = await changePassword({ password: newPassword, secret, hash });

      await login({
        accessToken: res.data.payload.accessToken,
        refreshToken: res.data.payload.refreshToken,
      });

      setIsLoading(false);
      navigate(ROUTE.Index);
    } catch (error) {
      setIsLoading(false);
      toast.error("Något gick fel");
    }
  }

  const isDone = confirmPassword && newPassword === confirmPassword;

  return (
    <OnbordingLayout>
      <p className="forgot-password-title space-grotesk">Ändra lösenord</p>
      <form
        className="forgot-password-form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Input
          type="password"
          value={newPassword}
          onChange={setNewPassword}
          placeholder="Lösenord"
          label="Lösenord *"
          autoComplete="new-password"
        />
        <Input
          type="password"
          value={confirmPassword}
          onChange={setConfirmPassword}
          placeholder="Bekräfta lösenord"
          label="Bekräfta lösenord *"
          autoComplete="new-password"
        />
        <Button
          label="Spara"
          color={isDone ? "black" : "white"}
          isLoading={isLoading}
        />
      </form>
    </OnbordingLayout>
  );
}

export default ForgotPassword;
