import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAccounts } from "../../api/company";
import {
  createCustomVariable,
  getCustomVariables,
  updateCustomVariable,
} from "../../api/report";
import CustomAccount from "../../components/CustomVariableComponents/CustomAccount/CustomAccount";
import CustomAccountSpan from "../../components/CustomVariableComponents/CustomAccountSpan/CustomAccountSpan";
import CustomNumber from "../../components/CustomVariableComponents/CustomNumber/CustomNumber";
import CustomVariable from "../../components/CustomVariableComponents/CustomVariable/CustomVariable";
import KeyValueArithmetic from "../../components/CustomVariableComponents/KeyValueArithmetic/KeyValueArithmetic";
import KeyValueFormula from "../../components/CustomVariableComponents/KeyValueFormula/KeyValueFormula";
import VariableList from "../../components/CustomVariableComponents/VariableList/VariableList";
import Button from "../../components/UI/Button/Button";
import Checkbox from "../../components/UI/Checkbox/Checkbox";
import Input from "../../components/UI/Input/Input";
import Popup from "../../hoc/Popup/Popup";
import { convertToFormulaArray } from "../../shared/variableUtility";
import { IAccount, IVariable } from "../../types/api";
import { IFormulaItem } from "../../types/internal";
import { IReduxState } from "../../types/redux";
import TagsSelector from "../TagsSelector/TagsSelector";
import "./CustomKeyValuesPopup.scss";

interface ICustomKeyValuesPopupProps {
  showPopup: boolean;
  onClose: (hasCreated: boolean) => void;
  editVariable?: IVariable;
  onEditVariableFromList: (v: IVariable) => void;
}

export type VariableInformationType = "account" | "account-span" | "number";

function CustomKeyValuesPopup(props: ICustomKeyValuesPopupProps) {
  const { companyId, financialYear } = useSelector(
    (state: IReduxState) => state.company
  );

  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [formula, setFormula] = useState<IFormulaItem[]>([]);
  const [insertIndex, setInsertIndex] = useState(0);
  const [keyValueName, setKeyValueName] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [selectedVariableId, setSelectedVariableId] = useState("");
  const [variableInformationMode, setVariableInformationMode] = useState<
    VariableInformationType | ""
  >("");
  const [isPercent, setIsPercent] = useState(true);
  const [showInTable, setShowInTable] = useState(true);

  const {
    data: variables,
    isSuccess: isVariablesSuccess,

    refetch,
  } = useQuery<IVariable[], unknown, IVariable[], ["variables", string]>({
    queryKey: ["variables", companyId],
    queryFn: async ({ queryKey: [, companyId] }) => {
      const res = await getCustomVariables(companyId);

      return res.data.payload.variables;
    },
  });

  const { data: accounts, isSuccess: isAccountsSuccess } = useQuery<
    IAccount[],
    unknown,
    IAccount[],
    ["accounts", string, string]
  >({
    queryKey: ["accounts", companyId, financialYear.identifier],
    queryFn: async ({ queryKey: [, companyId, fId] }) => {
      const res = await getAccounts({ companyId, financialYear: fId });

      return res.data.payload;
    },
  });

  useEffect(() => {
    if (props.editVariable) {
      setKeyValueName(props.editVariable.name);
      setIsPercent(props.editVariable.isPercent || false);
      setShowInTable(props.editVariable.showInTable || false);
      const editFomrula = convertToFormulaArray(props.editVariable.value);
      setFormula(editFomrula);
      setSelectedTags(props.editVariable.tags || []);
      setInsertIndex(editFomrula.length);
    }
  }, [props.editVariable]);

  useHotkeys(
    "*",
    (e) => {
      if (e.key === "ArrowLeft") {
        if (insertIndex > 0) {
          setInsertIndex((state) => state - 1);
        }
      }
      if (e.key === "ArrowRight") {
        if (insertIndex < formula.length) {
          setInsertIndex((state) => state + 1);
        }
      }

      if (e.key === "Backspace") {
        removeVariableHandler(insertIndex - 1);
      }

      if (["+", "-", "*", "/", "(", ")"].includes(e.key)) {
        const fItem: IFormulaItem = {
          type: "arithmetic",
          value: e.key,
        };
        addVariableHandler(fItem);
      }
    },
    { preventDefault: true },
    [insertIndex, formula]
  );

  function addVariableHandler(variable: IFormulaItem) {
    setFormula((state) => {
      const newFormula = state.slice(0);
      if (insertIndex === -1) {
        newFormula.push(variable);
      } else {
        newFormula.splice(insertIndex, 0, variable);
        setInsertIndex((state) => state + 1);
      }

      return newFormula;
    });
  }

  function removeVariableHandler(removeIndex: number) {
    const newFormula = formula.filter((_, i) => i !== removeIndex);
    setFormula(newFormula);
    setInsertIndex((state) => state - 1);
  }

  async function createKeyValueHandler() {
    if (!keyValueName) {
      toast.error("Nyckeltalet måste ha ett namn");
      return;
    }

    setIsCreating(true);

    const formulaString = formula.map((f) => f.value).join(" ");

    try {
      await createCustomVariable({
        companyId,
        variableString: formulaString,
        name: keyValueName,
        isPercent,
        showInTable,
        tags: selectedTags,
      });
      refetch();
      setIsCreating(false);
      setFormula([]);
      setKeyValueName("");
      toast.success("Nyckeltal skapat");
      closeHandler(true);
    } catch (error: any) {
      setIsCreating(false);
      toast.error("Något gick fel");
      toast.error(error.response.data.message, {
        autoClose: false,
      });
    }
  }

  async function updateKeyValueHandler() {
    if (!keyValueName) {
      toast.error("Nyckeltalet måste ha ett namn");
      return;
    }

    if (!props.editVariable) {
      toast.error("Du har inte valt en variabel att uppdatera");
      return;
    }

    setIsCreating(true);

    const formulaString = formula.map((f) => f.value).join(" ");

    try {
      await updateCustomVariable({
        variableString: formulaString,
        name: keyValueName,
        isPercent,
        showInTable,
        variableId: props.editVariable._id,
        tags: selectedTags,
      });
      refetch();
      setIsCreating(false);
      setFormula([]);
      setKeyValueName("");
      toast.success("Nyckeltal uppdaterat");
      closeHandler(true);
    } catch (error: any) {
      setIsCreating(false);
      toast.error("Något gick fel");
      toast.error(error.response.data.message, {
        autoClose: false,
      });
    }
  }

  function variableSelectorHandler(
    vId: string,
    type?: VariableInformationType
  ) {
    if (type) {
      setVariableInformationMode(type);
      setSelectedVariableId("");
      return;
    }
    setVariableInformationMode("");
    setSelectedVariableId(vId);
  }

  async function variableCreatedHandler(vId: string) {
    await refetch();
    setVariableInformationMode("");
    setSelectedVariableId(vId);
  }

  async function variableUpdatedHandler() {
    await refetch();
    setVariableInformationMode("");
    setSelectedVariableId("");
  }

  async function variableDeletedHandler() {
    await refetch();
    setVariableInformationMode("");
    setSelectedVariableId("");
  }

  function closeHandler(hasCreated: boolean) {
    setFormula([]);
    setKeyValueName("");
    setVariableInformationMode("");
    setSelectedVariableId("");
    setInsertIndex(0);
    setIsPercent(true);
    setShowInTable(true);
    setSelectedTags([]);

    props.onClose(hasCreated);
  }

  const shouldAddArithmetic =
    formula[formula.length - 1]?.type !== "arithmetic" && formula.length > 0;
  const hideArtithmetic = formula.length === 0;

  return (
    <Popup
      className="custom-key-values-popup"
      showPopup={props.showPopup}
      onClose={() => closeHandler(false)}
    >
      {formula.length ? (
        <div className="header">
          <Input
            type="text"
            value={keyValueName}
            onChange={setKeyValueName}
            placeholder="Namn på nyckeltal"
            width="300px"
            transparent
            short
          />
          <TagsSelector
            selectedTags={selectedTags}
            onTagSelected={(name, checked) => {
              if (checked) {
                setSelectedTags((state) => [...state, name]);
              } else {
                setSelectedTags((state) => state.filter((t) => t !== name));
              }
            }}
          />
          <Checkbox
            label="Visa i tabell"
            checked={showInTable}
            onClick={setShowInTable}
            theme="light"
            short
          />
          <Checkbox
            label="Procent"
            checked={isPercent}
            onClick={setIsPercent}
            theme="light"
            short
          />
          <Button
            color="black"
            label={props.editVariable ? "Spara ändringar" : "Skapa nyckeltal"}
            onClick={
              props.editVariable ? updateKeyValueHandler : createKeyValueHandler
            }
            isLoading={isCreating}
            short
          />
        </div>
      ) : (
        <div className="header">
          <p className="your-formula text-m-r">Uträkningen kommer visas här</p>
        </div>
      )}
      <div className="formula">
        <KeyValueFormula
          formula={formula}
          onSetInsertIndex={setInsertIndex}
          insertIndex={insertIndex}
        />
        {!hideArtithmetic && (
          <div
            className="arithmetics"
            style={{
              opacity: shouldAddArithmetic ? 1 : 0.5,
            }}
          >
            <span className="text-m-r">Välj uträkning</span>
            <KeyValueArithmetic type="+" onClick={addVariableHandler} />
            <KeyValueArithmetic type="-" onClick={addVariableHandler} />
            <KeyValueArithmetic type="*" onClick={addVariableHandler} />
            <KeyValueArithmetic type="/" onClick={addVariableHandler} />
            <KeyValueArithmetic type="(" onClick={addVariableHandler} />
            <KeyValueArithmetic type=")" onClick={addVariableHandler} />
          </div>
        )}
      </div>
      <div
        className="content"
        style={{
          opacity: shouldAddArithmetic ? 0.5 : 1,
        }}
      >
        <VariableList
          variables={isVariablesSuccess ? variables : []}
          onSelect={variableSelectorHandler}
          selectedVariableId={variableInformationMode || selectedVariableId}
        />
        {variableInformationMode === "account" ? (
          <CustomAccount
            accounts={isAccountsSuccess ? accounts : []}
            onAddVariable={addVariableHandler}
            onNewVariable={variableCreatedHandler}
          />
        ) : variableInformationMode === "account-span" ? (
          <CustomAccountSpan
            accounts={isAccountsSuccess ? accounts : []}
            onAddVariable={addVariableHandler}
            onNewVariable={variableCreatedHandler}
          />
        ) : variableInformationMode === "number" ? (
          <CustomNumber
            onAddVariable={addVariableHandler}
            onNewVariable={variableCreatedHandler}
          />
        ) : (
          <CustomVariable
            variable={
              selectedVariableId && isVariablesSuccess
                ? variables.find((v) => v._id === selectedVariableId)
                : undefined
            }
            onAddVariable={addVariableHandler}
            onDelete={() => variableDeletedHandler()}
            onClickEditVariable={props.onEditVariableFromList}
            onEditVariable={variableUpdatedHandler}
          />
        )}
      </div>
    </Popup>
  );
}

export default CustomKeyValuesPopup;
