import { useState } from "react";
import { convertToNumberWithSpaces } from "../../../shared/utility";
import "./LiquidityReportRow.scss";

interface ILiquidityReportRowProps {
  title: string;
  data: number[];
  onInitialLiquidityChanged?: (initialLiquidity: number) => void;
  initialLiquidity?: number;
  backgroundColor?: string;
  onCellHover?: (cellIndex: number, entering: boolean) => void;
}

function LiquidityReportRow(props: ILiquidityReportRowProps) {
  const [isFocusing, setIsFocusing] = useState(false);

  function initLiqChangedHandler(changedValue: string) {
    if (+changedValue > 1000000000000000) {
      changedValue = "1000000000000000";
    }
    if (+changedValue < -100000000000000) {
      changedValue = "-100000000000000";
    }
    if (!isNaN(+changedValue)) {
      props.onInitialLiquidityChanged!(+changedValue);
    }
  }

  return (
    <>
      <tr
        className="liquidity-report-row-title"
        style={
          props.backgroundColor
            ? { backgroundColor: props.backgroundColor }
            : {}
        }
      >
        <td className="text-s-r" colSpan={100}>
          {props.title}
        </td>
      </tr>
      <tr className="liquidity-report-row">
        {props.data.map((d, i) => (
          <td
            key={i}
            className="text-s-r"
            onMouseEnter={() => props.onCellHover && props.onCellHover(i, true)}
            onMouseLeave={() =>
              props.onCellHover && props.onCellHover(i, false)
            }
          >
            {props.initialLiquidity !== undefined &&
            props.onInitialLiquidityChanged &&
            i === 0 ? (
              <input
                type="tel"
                value={
                  !isFocusing
                    ? convertToNumberWithSpaces(props.initialLiquidity)
                    : props.initialLiquidity.toFixed(0)
                }
                onChange={(e) => initLiqChangedHandler(e.target.value)}
                onFocus={() => setIsFocusing(true)}
                onBlur={() => setIsFocusing(false)}
              />
            ) : (
              convertToNumberWithSpaces(d)
            )}
          </td>
        ))}
      </tr>
    </>
  );
}

export default LiquidityReportRow;
