import { useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useDropzone } from "react-dropzone";
import "./ImageUpload.scss";

interface IImageUploadProps {
  onImageUploaded: () => void;
  imageBase64: string;
  onChange: (imageBlob: Blob) => void;
}

function ImageUpload(props: IImageUploadProps) {
  const editorRef = useRef(null);
  const [zoom, setZoom] = useState(1);
  const [imageFile, setImageFile] = useState<File | null>(null);

  useEffect(() => {
    setImageFile(null);
  }, [props.imageBase64]);

  async function imageChangedHandler() {
    if (!editorRef.current) return;

    const canvas: HTMLCanvasElement = (
      editorRef.current as any
    ).getImageScaledToCanvas();

    canvas.toBlob(async (blob) => {
      if (!blob) return;

      props.onChange(blob);
    }, "image/png");
  }

  function handleDrop(dropped: File[]) {
    if (!dropped.length) return;
    const file = dropped[0];
    setImageFile(file);
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
  });

  return (
    <div {...getRootProps()} className="image-upload">
      {imageFile && (
        <AvatarEditor
          ref={editorRef}
          image={imageFile}
          width={130}
          height={130}
          borderRadius={100}
          scale={zoom}
          onImageChange={imageChangedHandler}
          onImageReady={imageChangedHandler}
        />
      )}
      {imageFile && (
        <input
          type="range"
          onChange={(e) => setZoom(+e.target.value)}
          value={zoom}
          step={0.1}
          min={1}
          max={3}
        />
      )}
      {!imageFile && (
        <div className="inputs-wrapper">
          <label
            htmlFor="imagefile-input"
            style={{
              backgroundImage: `url("data:image/png;base64,${props.imageBase64}")`,
            }}
          >
            <div
              className="camera-icon"
              style={{ opacity: props.imageBase64 ? 0 : 1 }}
            >
              <i className="fa-solid fa-camera" />
            </div>
          </label>
          <input
            {...getInputProps()}
            style={{ display: "none" }}
            id="imagefile-input"
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={(e) => {
              if (!e.target?.files?.[0]) {
                return;
              }
              setImageFile(e.target.files[0]);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default ImageUpload;
