import { convertToNumberWithSpaces } from "../../../shared/utility";
import "./KeyValuesCell.scss";

interface IKeyValuesCellProps {
  value: number | string | boolean;
  compareValue: number | string | boolean;
  isPercent?: boolean;
  isAfterBreak?: boolean;
  isBreakMonth?: boolean;
  breakBorderColor?: string;
}

function KeyValuesCell(props: IKeyValuesCellProps) {
  let displayValue = "-";
  let displayCompareValue = "-";

  let errorTitle = undefined;
  let compareErrorTitle = undefined;

  if (props.value && typeof props.value === "number") {
    if (props.isPercent) {
      displayValue = convertToNumberWithSpaces(props.value * 100, "%");
    } else {
      displayValue = convertToNumberWithSpaces(props.value);
    }
  }
  if (props.compareValue && typeof props.compareValue === "number") {
    if (props.isPercent) {
      displayCompareValue = convertToNumberWithSpaces(
        props.compareValue * 100,
        "%"
      );
    } else {
      displayCompareValue = convertToNumberWithSpaces(props.compareValue);
    }
  }

  if (props.value && typeof props.value === "string") {
    displayValue = "Fel";
    errorTitle = props.value;
  }
  if (props.compareValue && typeof props.compareValue === "string") {
    displayCompareValue = "Fel";
    compareErrorTitle = props.compareValue;
  }

  return (
    <td
      className="key-values-cell"
      style={
        props.isBreakMonth
          ? {
              borderRight: `1px solid ${
                props.breakBorderColor ? props.breakBorderColor : "#374263"
              }`,
            }
          : {}
      }
    >
      <div>
        <p className="value text-s-r" title={errorTitle}>
          {displayValue}
        </p>
        <p
          className="compare text-xs-m"
          title={compareErrorTitle}
          style={
            props.isAfterBreak
              ? {
                  color: props.breakBorderColor
                    ? props.breakBorderColor
                    : "#374263",
                }
              : {}
          }
        >
          {displayCompareValue}
        </p>
      </div>
    </td>
  );
}

export default KeyValuesCell;
