import { useState } from "react";
import { convertToNumberWithSpaces } from "../../../shared/utility";
import { IBalanceReportRow } from "../../../types/api";
import BalanceReportSubrow from "../BalanceReportSubrow/BalanceReportSubrow";
import "./BalanceReportRow.scss";

interface IBalanceReportRowProps {
  title: string;
  rows: IBalanceReportRow;
  keys: string[];
}

function BalanceReportRow(props: IBalanceReportRowProps) {
  const [isOpen, setIsOpen] = useState(false);

  const ibSum = props.keys.reduce((acc, key) => acc + props.rows[key].IB, 0);
  const ubSum = props.keys.reduce((acc, key) => acc + props.rows[key].UB, 0);

  return (
    <>
      <tr
        className="balance-report-row text-s-r"
        onClick={() => setIsOpen(!isOpen)}
      >
        <td className="title">
          <div>
            <i
              className={isOpen ? "fas fa-chevron-up" : "fas fa-chevron-down"}
            />
            <span>{props.title}</span>
          </div>
        </td>
        <td>{convertToNumberWithSpaces(ibSum)}</td>
        <td>{convertToNumberWithSpaces(ubSum - ibSum)}</td>
        <td>{convertToNumberWithSpaces(ubSum)}</td>
      </tr>
      {isOpen &&
        props.keys.map((key) => (
          <BalanceReportSubrow
            key={key}
            title={key}
            ib={props.rows[key].IB}
            ub={props.rows[key].UB}
          />
        ))}
    </>
  );
}

export default BalanceReportRow;
