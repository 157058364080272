import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { verifyEmail, verifyVerificationKey } from "../../api/auth";
import Button from "../../components/UI/Button/Button";
import Checkbox from "../../components/UI/Checkbox/Checkbox";
import Input from "../../components/UI/Input/Input";
import OnbordingLayout from "../../hoc/OnbordingLayout/OnbordingLayout";
import { API_MESSAGE, QUERY_PARAM, ROUTE } from "../../shared/enums";
import { useSearchQuery } from "../../shared/hooks/useSearchQuery";
import { login } from "../../store/thunks/auth";
import "./InitialSetup.scss";

interface IInitialSetupProps {}

function InitialSetup(props: IInitialSetupProps) {
  const navigate = useNavigate();
  const query = useSearchQuery();

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [hasAccepted, setHasAccepted] = useState(false);
  const [isKeyActive, setIsKeyActive] = useState(true);

  useEffect(() => {
    const authKey = query.get(QUERY_PARAM.AuthKey);
    if (!authKey) {
      setIsKeyActive(false);
      return;
    }

    verifyVerificationKey(authKey)
      .then((res) => {
        setEmail(res.data.payload.email);
      })
      .catch((err) => {
        if (
          err.response.data.message === API_MESSAGE.AuthKeyExpired ||
          err.response.data.message === API_MESSAGE.AuthKeyInvalid
        ) {
          setIsKeyActive(false);
          return;
        }
        toast.error("Ett okänt fel inträffade");
      });
  }, [query]);

  function submitHandler() {
    if (!password) {
      toast.error("Du måste fylla i ett lösenord");
      return;
    }

    if (!hasAccepted) {
      toast.error("Vänligen acceptera Nimyas integritetspolicy");
      return;
    }

    const authKey = query.get(QUERY_PARAM.AuthKey);
    if (!authKey) {
      toast.error("Du saknar en nyckel från registreringen");
      return;
    }
    verifyEmail({ authKey, password, firstName, lastName })
      .then((res) => {
        login({
          accessToken: res.data.payload.access_token,
          refreshToken: res.data.payload.refresh_token,
        });
        navigate("/");
      })
      .catch(() => {
        toast.error("Något gick fel");
      });
  }

  return (
    <OnbordingLayout>
      <p className="initial-setup--title space-grotesk">Sätt upp ditt konto</p>

      {isKeyActive ? (
        <form
          className="initial-setup--form"
          onSubmit={(e) => {
            e.preventDefault();
            submitHandler();
          }}
        >
          <Input
            type="text"
            value={email}
            onChange={setFirstName}
            placeholder="E-postadress"
            label="E-postadress"
            autoComplete="off"
            disabled
          />
          <Input
            type="text"
            value={firstName}
            onChange={setFirstName}
            placeholder="Förnamn"
            label="Förnamn"
            autoComplete="off"
          />
          <Input
            type="text"
            value={lastName}
            onChange={setLastName}
            placeholder="Efternamn"
            label="Efternamn"
            autoComplete="off"
          />
          <Input
            type="password"
            value={password}
            onChange={setPassword}
            placeholder="Lösenord"
            label="Lösenord *"
            autoComplete="new-password"
          />
          <div className="initial-setup--form__terms">
            <Checkbox
              checked={hasAccepted}
              onClick={setHasAccepted}
              theme="dark"
            />
            <p>Jag har läst och accepterat Nimyas</p>
            <a
              href="https://www.nimya.io/policy"
              target="_blank"
              rel="noreferrer"
            >
              Integritetspolicy
            </a>
          </div>
          <Button label="Skapa konto" color="black" />
        </form>
      ) : (
        <div className="initial-setup--inactive">
          Länken har gått ut eller är ogiltig
          <NavLink to={ROUTE.SignUp}>
            <Button label="Registrera dig här" color="black" />
          </NavLink>
        </div>
      )}
    </OnbordingLayout>
  );
}

export default InitialSetup;
