import { VariableInformationType } from "../../../containers/CustomKeyValuesPopup/CustomKeyValuesPopup";
import { VARIABLE_VALUE } from "../../../shared/enums";
import { IVariable, VariableType } from "../../../types/api";
import "./VariableList.scss";
import VariableListItem from "./VariableListItem/VariableListItem";

interface IVariableListProps {
  variables: IVariable[];
  onSelect: (variableId: string, type?: VariableInformationType) => void;
  selectedVariableId?: string;
  noCustom?: boolean;
}

function VariableList(props: IVariableListProps) {
  function filterVariables(variables: IVariable[], type: VariableType) {
    return variables
      .filter((v) => v.variableType === type)
      .sort((a, b) =>
        a.name
          .replace(VARIABLE_VALUE.GlobalPrefix, "")
          .localeCompare(b.name.replace(VARIABLE_VALUE.GlobalPrefix, ""))
      );
  }

  function globalAccountRangeSortFunction(a: IVariable, b: IVariable) {
    const aIndex = globalAccountRangeSort.indexOf(
      a.name.replace(VARIABLE_VALUE.GlobalPrefix, "")
    );
    const bIndex = globalAccountRangeSort.indexOf(
      b.name.replace(VARIABLE_VALUE.GlobalPrefix, "")
    );
    return aIndex - bIndex;
  }

  const keyFigureVariables = filterVariables(props.variables, "KeyFigure");
  const numberVariables = filterVariables(props.variables, "Number");
  const dateVariables = filterVariables(props.variables, "Date");
  const accountVariables = filterVariables(props.variables, "Account");
  const accountRangeVariables = filterVariables(
    props.variables,
    "AccountRange"
  );
  const rangeVariables = filterVariables(props.variables, "Range");

  const globalAccountRangeSort = [
    "Intäkter",
    "Varuinköp",
    "Övriga rörelsekostnader",
    "Lokalkostnader",
    "Personalkostnader",
    "Avskrivningar",
    "Finansiella Intäkter och Kostnader",
    "Kostnader",
  ];

  return (
    <div className="variable-list">
      {!props.noCustom && (
        <div className="section">
          <p className="title text-s-r">Räkna med ett anpassat värde</p>
          <VariableListItem
            name="Eget kontospann"
            onClick={() => props.onSelect("", "account-span")}
            isSelected={props.selectedVariableId === "account-span"}
          />
          <VariableListItem
            name="Ett eller flera konton"
            onClick={() => props.onSelect("", "account")}
            isSelected={props.selectedVariableId === "account"}
          />
          <VariableListItem
            name="Ett enskilt nummer"
            onClick={() => props.onSelect("", "number")}
            isSelected={props.selectedVariableId === "number"}
          />
        </div>
      )}
      <div className="section">
        <p className="title text-s-r">Räkna med ett kontospann</p>
        {accountRangeVariables
          .filter((v) => v.global)
          .sort(globalAccountRangeSortFunction)
          .map((v, i) => (
            <VariableListItem
              key={v._id}
              isSelected={props.selectedVariableId === v._id}
              name={v.name}
              isGlobal={v.global}
              onClick={() => props.onSelect(v._id)}
            />
          ))}
        {accountRangeVariables
          .filter((v) => !v.global)
          .map((v, i) => (
            <VariableListItem
              key={v._id}
              isSelected={props.selectedVariableId === v._id}
              name={v.name}
              isGlobal={v.global}
              onClick={() => props.onSelect(v._id)}
            />
          ))}
      </div>
      {!!accountVariables.length && (
        <div className="section">
          <p className="title text-s-r">Räkna med ett Konto</p>
          {accountVariables
            .filter((v) => v.global)
            .map((v, i) => (
              <VariableListItem
                key={v._id}
                isSelected={props.selectedVariableId === v._id}
                name={v.name}
                isGlobal={v.global}
                onClick={() => props.onSelect(v._id)}
              />
            ))}
          {accountVariables
            .filter((v) => !v.global)
            .map((v, i) => (
              <VariableListItem
                key={v._id}
                isSelected={props.selectedVariableId === v._id}
                name={v.name}
                isGlobal={v.global}
                onClick={() => props.onSelect(v._id)}
              />
            ))}
        </div>
      )}
      {!!keyFigureVariables.length && (
        <div className="section">
          <p className="title text-s-r">Befintliga nyckeltal</p>
          {keyFigureVariables
            .filter((v) => v.global)
            .map((v, i) => (
              <VariableListItem
                key={v._id}
                isSelected={props.selectedVariableId === v._id}
                name={v.name}
                isGlobal={v.global}
                onClick={() => props.onSelect(v._id)}
              />
            ))}
          {keyFigureVariables
            .filter((v) => !v.global)
            .map((v, i) => (
              <VariableListItem
                key={v._id}
                isSelected={props.selectedVariableId === v._id}
                name={v.name}
                isGlobal={v.global}
                onClick={() => props.onSelect(v._id)}
              />
            ))}
        </div>
      )}
      {!!numberVariables.length && (
        <div className="section">
          <p className="title text-s-r">Nummer</p>
          {numberVariables.map((v, i) => (
            <VariableListItem
              key={v._id}
              isSelected={props.selectedVariableId === v._id}
              name={v.name}
              isGlobal={v.global}
              onClick={() => props.onSelect(v._id)}
            />
          ))}
        </div>
      )}
      {!!dateVariables.length && (
        <div className="section">
          <p className="title text-s-r">Datum</p>
          {dateVariables.map((v, i) => (
            <VariableListItem
              key={v._id}
              isSelected={props.selectedVariableId === v._id}
              name={v.name}
              isGlobal={v.global}
              onClick={() => props.onSelect(v._id)}
            />
          ))}
        </div>
      )}
      {!!rangeVariables.length && (
        <div className="section">
          <p className="title text-s-r">Spann</p>
          {rangeVariables.map((v, i) => (
            <VariableListItem
              key={v._id}
              isSelected={props.selectedVariableId === v._id}
              name={v.name}
              isGlobal={v.global}
              onClick={() => props.onSelect(v._id)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default VariableList;
