import "./Messages.scss";

import { set } from "date-fns";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  createComment,
  deleteComment,
  getComments,
  updateComment,
} from "../../api/comment";
import { getMe } from "../../api/user";
import MessageEdit from "../../components/MessageEdit/MessageEdit";
import MessageListItem from "../../components/MessageListItem/MessageListItem";
import MessageView from "../../components/MessageView/MessageView";
import Checkbox from "../../components/UI/Checkbox/Checkbox";
import Input from "../../components/UI/Input/Input";
import Spinner from "../../components/UI/Spinner/Spinner";
import TextButton from "../../components/UI/TextButton/TextButton";
import UnreadIndicator from "../../components/UI/UnreadIndicator/UnreadIndicator";
import {
  API_MAX_PER_PAGE,
  API_MESSAGE,
  QUERY_PARAM,
  SESSION_STORAGE_KEY,
} from "../../shared/enums";
import { useSearchQuery } from "../../shared/hooks/useSearchQuery";
import { isMessageUnread } from "../../shared/messageUtility";
import { setIsMessageOpen } from "../../store/slices/message";
import { readMessage } from "../../store/thunks/message";
import { IMessage, IMeUser } from "../../types/api";
import { IKeyString } from "../../types/internal";
import { IReduxState } from "../../types/redux";

interface IMessagesProps {}

function Messages(props: IMessagesProps) {
  const {
    company: {
      companyId,
      companies,
      companyData: { currentUserPermission },
    },
    message: { unread, isMessageOpen },
    consult: { agency },
  } = useSelector((state: IReduxState) => state);

  const dispatch = useDispatch();
  const query = useSearchQuery();

  const [mode, setMode] = useState<"view-all" | "view-company">("view-company");
  const [searchString, setSearchString] = useState("");
  const [chosenMessageId, setChosenMessageId] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [currentUser, setCurrentUser] = useState<IMeUser | null>(null);

  useEffect(() => {
    if (!currentUser) {
      getMe()
        .then((res) => {
          setCurrentUser(res.data.payload);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  }, [currentUser]);

  useEffect(() => {
    const reportData = sessionStorage.getItem(SESSION_STORAGE_KEY.ReportData);

    if (reportData) {
      setChosenMessageId("");
      setIsCreating(true);
    }
  }, [isMessageOpen]);

  useEffect(() => {
    const queryMessageId = query.get(QUERY_PARAM.MessageId);
    if (queryMessageId) {
      setChosenMessageId(queryMessageId);
      dispatch(setIsMessageOpen(true));
    }
  }, [dispatch, query]);

  const {
    data,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery<
    IMessage[],
    unknown,
    IMessage[],
    ["messages", string, "view-all" | "view-company"]
  >({
    queryKey: ["messages", companyId, mode],
    queryFn: async ({
      queryKey: [, companyId, mode],
      pageParam = 1,
      signal,
    }) => {
      const res = await getComments({
        page: pageParam,
        companyId: mode === "view-company" ? companyId : undefined,
      });

      if (res.data.message === API_MESSAGE.NoNotificationsFound) {
        return [];
      }

      return res.data.payload;
    },
    getNextPageParam: (lastPage, pages) =>
      lastPage.length < API_MAX_PER_PAGE.Messages
        ? undefined
        : pages.length + 1,
  });

  async function afterCreationHandler(newMessageId: string) {
    await refetch();
    setIsCreating(false);
    setMode("view-company");
  }

  async function editHandler({
    id,
    title,
    comment,
    from,
    to,
  }: {
    id: string;
    title: string;
    comment: string;
    from: number;
    to: number;
  }) {
    setIsSaving(true);

    const res = await updateComment({
      commentId: id,
      companyId,
      commentTitle: title,
      comment: comment,
      from: from,
      to: to,
    });

    toast.success("Meddelandet har uppdaterats", {
      position: toast.POSITION.BOTTOM_LEFT,
    });

    setIsSaving(false);

    await afterCreationHandler(res.data.payload.commentId);
  }

  async function createHandler({
    title,
    comment,
    from,
    to,
  }: {
    title: string;
    comment: string;
    from: number;
    to: number;
  }) {
    setIsSaving(true);

    const res = await createComment({
      companyId,
      commentTitle: title,
      comment: comment,
      from: from,
      to: to,
    });

    toast.success("Meddelandet har skapats", {
      position: toast.POSITION.BOTTOM_LEFT,
    });

    setIsSaving(false);

    await afterCreationHandler(res.data.payload.commentId);
  }

  async function deleteHandler(id: string) {
    setIsDeleting(true);

    await deleteComment({
      commentId: id,
    });
    await refetch();

    toast.success("Meddelandet har tagits bort");

    setIsDeleting(false);
    setChosenMessageId("");
    setIsCreating(false);
    setShowDeleteBtn(false);
  }

  const companyNameMap: IKeyString<string> = {};
  for (let i = 0; i < companies.length; i++) {
    const company = companies[i];
    companyNameMap[company.companyId] = company.name;
  }

  const canUserCreate = !!agency;

  const unreadAll = unread.length;
  const unreadCompany = unread.filter(
    (um) => um.companyId === companyId
  ).length;

  const chosenMessage = data?.pages
    .flat()
    .find((m) => m._id === chosenMessageId);

  const canUserDelete = () => {
    if (!chosenMessage) {
      return false;
    }

    // if use is admin they can delete any message in the company they are in

    if (currentUserPermission === "admin") return true;

    // if user is the one who created the message they can delete it
    if (
      chosenMessage.user.email === currentUser?.email &&
      chosenMessage.user.firstName === currentUser?.firstName
    )
      return true;
  };

  return (
    <div className="messages-wrapper">
      {isMessageOpen && (
        <div className="messages">
          <div className="top">
            {isSearching ? (
              <Input
                type="text"
                value={searchString}
                onChange={setSearchString}
                placeholder="Sök"
                transparent
                width="70%"
                short
              />
            ) : chosenMessage || isCreating ? (
              <TextButton
                label="Tillbaka"
                color="#fff"
                onClick={() => {
                  setChosenMessageId("");
                  setIsCreating(false);
                  setShowDeleteBtn(false);
                }}
              />
            ) : (
              <div className="buttons">
                <div className="message-checkbox-wrapper">
                  <Checkbox
                    theme="light"
                    checked={mode === "view-company"}
                    label="Företaget"
                    onClick={() => setMode("view-company")}
                    short
                  />
                  <UnreadIndicator
                    amount={unreadCompany}
                    position={{
                      top: -10,
                      right: -15,
                    }}
                  />
                </div>
                <div className="message-checkbox-wrapper">
                  <Checkbox
                    theme="light"
                    checked={mode === "view-all"}
                    label="Alla"
                    onClick={() => setMode("view-all")}
                    short
                  />
                  <UnreadIndicator
                    amount={unreadAll}
                    position={{
                      top: -10,
                      right: -15,
                    }}
                  />
                </div>
                {isFetchingNextPage && <Spinner color="#fff" />}
              </div>
            )}
            {!isCreating && (
              <div className="buttons">
                {!chosenMessage && (
                  <div
                    className="messages-button waves-effect"
                    onClick={() => setIsSearching((state) => !state)}
                  >
                    <i
                      className={
                        isSearching ? "fa-solid fa-xmark" : "fa-solid fa-search"
                      }
                    />
                  </div>
                )}
                {canUserDelete() && showDeleteBtn && (
                  <div
                    className="messages-button waves-effect"
                    onClick={() => deleteHandler(chosenMessageId)}
                  >
                    <i className="fa-solid fa-trash-can" />
                  </div>
                )}
                {canUserCreate && (
                  <>
                    <div
                      className="messages-button waves-effect"
                      onClick={() => setIsCreating((state) => !state)}
                    >
                      <i
                        className={
                          chosenMessage ? "fa-solid fa-pen" : "fa-solid fa-plus"
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="content" id="messages-scroll">
            {isCreating ? (
              <MessageEdit
                message={chosenMessage}
                onSave={editHandler}
                onCreate={createHandler}
                onDelete={deleteHandler}
                isSaving={isSaving}
                isDeleting={isDeleting}
              />
            ) : chosenMessage ? (
              <MessageView
                messageId={chosenMessage._id}
                title={chosenMessage.title}
                content={chosenMessage.comment}
                from={chosenMessage.range?.from || 0}
                to={chosenMessage.range?.to || 0}
                companyId={chosenMessage.companyId}
              />
            ) : isSuccess ? (
              <InfiniteScroll
                dataLength={data.pages.flat().length}
                next={fetchNextPage}
                hasMore={!!hasNextPage}
                loader={<Spinner padding="10px" />}
                endMessage={
                  <p className="showing-all text-m-r">
                    Du visar alla meddelanden
                  </p>
                }
                scrollThreshold={0.5}
                scrollableTarget="messages-scroll"
              >
                {data.pages.flat().map((message) => (
                  <MessageListItem
                    key={message._id}
                    title={message.title}
                    name={`${message.user.firstName || message.user.email} ${
                      message.user.lastName || ""
                    }`}
                    companyName={
                      companyNameMap[message.companyId] || "Namn saknas"
                    }
                    onClick={() => {
                      setChosenMessageId(message._id);
                      readMessage(message._id);
                      setShowDeleteBtn(true);
                    }}
                    base64Image={message.user.avatar || undefined}
                    isShowingAll={mode === "view-all"}
                    isUnread={isMessageUnread(unread, message._id)}
                  />
                ))}
              </InfiniteScroll>
            ) : (
              <Spinner padding="10px" />
            )}
          </div>
        </div>
      )}
      <div className="toggle-wrapper">
        <UnreadIndicator
          amount={unread.length}
          position={{
            top: -5,
            right: -5,
          }}
        />
        <div
          className={"toggle waves-effect" + (isMessageOpen ? " open" : "")}
          onClick={() => dispatch(setIsMessageOpen(!isMessageOpen))}
        >
          <i
            className={
              isMessageOpen ? "fa-solid fa-xmark" : "fa-solid fa-envelope"
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Messages;
