import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { createGroup, getGroups, updateGroup } from "../../api/group";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import { API_MESSAGE } from "../../shared/enums";
import "./GroupInfo.scss";

interface IGroupInfoProps {
  groupId?: string;
  bottomLabel?: string;
  onSave: (groupId: string) => void;
  onDelete?: () => void;
  agencyId: string;
}

function GroupInfo(props: IGroupInfoProps) {
  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [hasUpdatedName, setHasUpdatedName] = useState(false);

  const { data: groups, isSuccess } = useQuery({
    queryKey: ["accounting-firm-groups", props.agencyId],
    queryFn: async ({ queryKey: [, agencyId] }) => {
      const res = await getGroups(agencyId);
      if (res.data.message === API_MESSAGE.NoGroups) return [];

      return res.data.payload;
    },
  });

  useEffect(() => {
    if (!props.groupId) return;
    if (!isSuccess) return;

    const group = groups.find((group) => group.groupId === props.groupId);
    if (!group) return;

    setGroupName(group.name);
    setGroupDescription(group.description);
  }, [groups, isSuccess, props.groupId]);

  async function saveHandler() {
    if (!groupName) {
      toast.error("Du måste ange ett namn på gruppen");
      return;
    }

    setIsSaving(true);

    if (props.groupId) {
      try {
        await updateGroup({
          groupId: props.groupId,
          updateData: {
            name: hasUpdatedName ? groupName : undefined,
            description: groupDescription || undefined,
          },
        });
        setIsSaving(false);
        props.onSave(props.groupId!);
      } catch (error: any) {
        setIsSaving(false);
        if (error.response.data.message === API_MESSAGE.GroupNameTaken) {
          toast.error("Gruppnamnet är upptaget");
          return;
        }
        toast.error("Något gick fel");
      }
      return;
    }

    try {
      const res = await createGroup({
        name: groupName,
        alias: undefined,
        description: groupDescription || undefined,
        consultId: props.agencyId,
      });
      setIsSaving(false);
      props.onSave(res.data.payload.groupId);
    } catch (error: any) {
      setIsSaving(false);
      if (error.response.data.message === API_MESSAGE.GroupNameTaken) {
        toast.error("Gruppnamnet är upptaget");
        return;
      }
      toast.error("Något gick fel");
    }

    setIsSaving(false);
  }

  return (
    <form
      className="group-info"
      onSubmit={(e) => {
        e.preventDefault();
        saveHandler();
      }}
    >
      <Input
        type="text"
        label="Namnge gruppen*"
        placeholder="Namn på gruppen"
        value={groupName}
        onChange={(v) => {
          if (!hasUpdatedName) setHasUpdatedName(true);
          setGroupName(v);
        }}
        short
        width="100%"
      />
      <label className="textarea-label text-m-r">
        Beskrivning
        <textarea
          placeholder="Beskrivning av gruppen"
          value={groupDescription}
          onChange={(e) => setGroupDescription(e.target.value)}
        />
      </label>
      <div className="group-info-footer">
        <p className="label text-m-r">{props.bottomLabel}</p>
        <Button
          color="black"
          label={props.groupId ? "Spara" : "Spara och fortsätt"}
          isLoading={isSaving}
          short
        />
      </div>
    </form>
  );
}

export default GroupInfo;
