import { ILicense } from "../../types/api";
import Dot from "../Dot/Dot";
import "./LicenseDots.scss";

interface ILicenseDotsProps {
  licenses: ILicense[];
  activeLicenses: {
    active?: boolean;
    created?: number;
    licenseId: string;
    updated?: number;
  }[];
  onClick: (licenseId: string, shouldActivate: boolean) => void;
  lockedNames?: string[];
}

function LicenseDots(props: ILicenseDotsProps) {
  function isLicenseActive(licenseId: string) {
    return (
      props.activeLicenses.find(
        (activeLicense) => activeLicense.licenseId === licenseId
      )?.active || false
    );
  }

  return (
    <div className="license-dots">
      {props.licenses.map((license) => (
        <Dot
          key={license._id}
          label={license.name.slice(0, 2)}
          active={isLicenseActive(license._id)}
          onClick={() =>
            props.onClick(license._id, !isLicenseActive(license._id))
          }
          title={license.name}
          locked={props.lockedNames?.includes(license.name)}
        />
      ))}
    </div>
  );
}

export default LicenseDots;
