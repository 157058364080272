import { fromUnixTime, isSameMonth } from "date-fns";
import { useState } from "react";
import DotsButton from "../../../components/UI/DotsButton/DotsButton";
import Popup from "../../../hoc/Popup/Popup";
import { VARIABLE_VALUE } from "../../../shared/enums";
import {
  formatKeyForCompare,
  isDateAfter,
  stringToDate,
} from "../../../shared/utility";
import {
  getVariableRowColor,
  getVariableTextColor,
} from "../../../shared/variableUtility";
import { IKeyValuesRow } from "../../../types/api";
import { RowType } from "../../../types/internal";
import { CompareWith } from "../../../types/redux";
import KeyValuesCell from "../KeyValuesCell/KeyValuesCell";
import "./KeyValuesRow.scss";

interface IKeyValuesRowProps {
  name: string;
  row: IKeyValuesRow;
  compareRow: IKeyValuesRow;
  monthKeys: string[];
  breakUnix: number;
  compareWith: CompareWith;
  variableContext?: string;
  isPercent?: boolean;
  isGlobal?: boolean;
  rowType?: RowType;
  onEdit?: () => void;
  onArchive?: () => void;
  onDelete?: () => void;
  onCopy?: () => void;
  onAlertCreate?: () => void;
}

function KeyValuesRow(props: IKeyValuesRowProps) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const popupActions = [
    {
      title: "Redigera nyckeltal",
      onClick: props.onEdit,
    },
    {
      title: "Arkivera nyckeltal",
      onClick: props.onArchive,
    },
    {
      title: "Radera nyckeltal",
      onClick: props.onDelete,
    },
    {
      title: "Använd samma nyckeltal för annat företag",
      onClick: props.onCopy,
    },
    {
      title: "Skapa en notis av nyckeltalet",
      onClick: props.onAlertCreate,
    },
  ];

  return (
    <>
      <tr className="key-values-row-title text-s-r">
        <td
          colSpan={100}
          style={{
            backgroundColor: getVariableRowColor(props.variableContext || ""),
          }}
        >
          <div>
            {props.name.replace(VARIABLE_VALUE.GlobalPrefix, "")}
            {!props.isGlobal && (
              <>
                <DotsButton onClick={() => setIsPopupOpen(true)} />
                <Popup
                  showPopup={isPopupOpen}
                  onClose={() => setIsPopupOpen(false)}
                  width="500px"
                  leftTopElement={
                    <p className="text-m-m" style={{ color: "#fff" }}>
                      Redigera nyckeltalet{" "}
                      {props.name.replace(VARIABLE_VALUE.GlobalPrefix, "")}
                    </p>
                  }
                >
                  <div className="key-values-row-popup">
                    {popupActions.map((pa) => (
                      <button
                        key={pa.title}
                        className="row-button"
                        onClick={() => {
                          setIsPopupOpen(false);
                          if (pa.onClick) pa.onClick();
                        }}
                      >
                        {pa.title}
                      </button>
                    ))}
                  </div>
                </Popup>
              </>
            )}
          </div>
        </td>
      </tr>
      <tr className="key-values-row">
        {props.monthKeys.map((mKey) => (
          <KeyValuesCell
            key={mKey}
            value={props.row[mKey] || 0}
            compareValue={
              props.compareRow[
                formatKeyForCompare(mKey, props.compareWith === "budget")
              ]
            }
            isPercent={props.isPercent}
            isBreakMonth={isSameMonth(
              stringToDate(mKey),
              fromUnixTime(props.breakUnix)
            )}
            isAfterBreak={isDateAfter(
              stringToDate(mKey),
              fromUnixTime(props.breakUnix)
            )}
            breakBorderColor={getVariableTextColor(props.variableContext || "")}
          />
        ))}
        <KeyValuesCell
          value={props.row["Sum"]}
          compareValue={props.compareRow["Sum"]}
          isPercent={props.isPercent}
          breakBorderColor={getVariableTextColor(props.variableContext || "")}
        />
      </tr>
    </>
  );
}

export default KeyValuesRow;
