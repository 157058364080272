import loginImage from "../../assets/images/login-image.png";
import "./LoginBackground.scss";

interface ILoginBackgroundProps {}

function LoginBackground(props: ILoginBackgroundProps) {
  return <img className="login-background" src={loginImage} alt="Nimya" />;
}

export default LoginBackground;
