import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createCustomVariable } from "../../../api/report";
import VariableInformation from "../../../hoc/VariableInformation/VariableInformation";
import { API_MESSAGE } from "../../../shared/enums";
import { IAccount } from "../../../types/api";
import { IFormulaItem } from "../../../types/internal";
import { IReduxState } from "../../../types/redux";
import AccountSearch from "../../AccountSearch/AccountSearch";
import Button from "../../UI/Button/Button";
import Input from "../../UI/Input/Input";
import "./CustomAccountSpan.scss";

interface ICustomAccountSpanProps {
  onAddVariable: (fItem: IFormulaItem) => void;
  accounts: IAccount[];
  onNewVariable: (variableId: string) => void;
}

function CustomAccountSpan(props: ICustomAccountSpanProps) {
  const { companyId } = useSelector((state: IReduxState) => state.company);

  const [accountFrom, setAccountFrom] = useState("");
  const [accountTo, setAccountTo] = useState("");
  const [searchType, setSearchType] = useState<"from" | "to">("from");
  const [variableName, setVariableName] = useState("");
  const [isCreating, setIsCreating] = useState(false);

  function isAccountSpanValid() {
    if (+accountFrom > 8999 || +accountFrom < 1000 || isNaN(+accountFrom)) {
      toast.error("Från kontot måste vara ett nummer mellan 1000 och 8999");
      return false;
    }

    if (+accountTo > 8999 || +accountTo < 1000 || isNaN(+accountTo)) {
      toast.error("Till kontot måste vara ett nummer mellan 1000 och 8999");
      return false;
    }

    if (+accountFrom > +accountTo) {
      toast.error("Från kontot måste vara lägre än till kontot");
      return false;
    }

    return true;
  }

  async function createVariableHandler() {
    if (!isAccountSpanValid()) return;

    if (!variableName) {
      toast.error("Variabelnamnet får inte vara tomt");
      return;
    }

    setIsCreating(true);

    try {
      const res = await createCustomVariable({
        companyId,
        variableString: `[${accountFrom}:${accountTo}]`,
        name: variableName,
        isPercent: false,
        showInTable: false,
      });
      setIsCreating(false);
      toast.success("Variabel skapad");
      props.onNewVariable(res.data.payload.variableId);
    } catch (error: any) {
      setIsCreating(false);
      if (error.response.data.message === API_MESSAGE.InvalidVariableName) {
        toast.error(
          "Variabelnamnet får endast innehålla alfanumeriska tecken och mellanslag"
        );
        return;
      }
      toast.error("Något gick fel");
    }
  }

  return (
    <VariableInformation
      type="Kontospann"
      name="Räkna med ett kontospann"
      description=""
      formulaTitle="Välj kontospann"
    >
      <form
        className="custom-account-span"
        onSubmit={(e) => {
          e.preventDefault();
          if (!isAccountSpanValid()) return;
          props.onAddVariable({
            value: `[${accountFrom}:${accountTo}]`,
            type: "account-span",
          });
        }}
      >
        <Input
          type="text"
          value={accountFrom}
          onChange={setAccountFrom}
          width="90px"
          onFocus={() => setSearchType("from")}
          short
        />
        -
        <Input
          type="text"
          value={accountTo}
          onChange={setAccountTo}
          width="90px"
          onFocus={() => setSearchType("to")}
          short
        />
        <Button color="black" label="Lägg till" short />
      </form>
      <form
        className="custom-account-span"
        onSubmit={(e) => {
          e.preventDefault();
          createVariableHandler();
        }}
      >
        <Input
          type="text"
          value={variableName}
          onChange={(v) => {
            setVariableName(v);
          }}
          placeholder="Variabelnamn"
          short
        />
        <Button
          color="black"
          label="Skapa variabel"
          isLoading={isCreating}
          short
        />
      </form>
      <AccountSearch
        query={searchType === "from" ? accountFrom : accountTo}
        accounts={props.accounts}
        onAccountClick={(a) =>
          searchType === "from" ? setAccountFrom(a) : setAccountTo(a)
        }
      />
    </VariableInformation>
  );
}

export default CustomAccountSpan;
