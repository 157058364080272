import "./VariableInformation.scss";

interface IVariableInformationProps {
  type: string;
  name: string;
  description: string;
  formulaTitle: string;
  children: React.ReactNode;
}

function VariableInformation(props: IVariableInformationProps) {
  return (
    <div className="variable-information">
      <p className="type">{props.type}</p>
      <p className="name">{props.name}</p>
      <p className="description">{props.description}</p>
      <p className="formula-title">{props.formulaTitle}</p>
      {props.children}
    </div>
  );
}

export default VariableInformation;
