import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getOauthGoogleUrl, registerEmail } from "../../api/auth";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import OnbordingLayout from "../../hoc/OnbordingLayout/OnbordingLayout";
import { API_MESSAGE, ROUTE } from "../../shared/enums";
import "./Signup.scss";

interface ISignUpProps {}

function SignUp(props: ISignUpProps) {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  function oAuthGoogleLoginHandler() {
    window.location.href = getOauthGoogleUrl();
  }

  function registerAccountHandler() {
    if (!email) {
      toast.error("Du måste fylla i en e-postadress");
      return;
    }

    setIsLoading(true);
    registerEmail(email)
      .then(() => {
        setIsLoading(false);
        navigate(ROUTE.Verify);
      })
      .catch((e) => {
        setIsLoading(false);
        let errorMessage;
        switch (e.response.data.message) {
          case API_MESSAGE.UserAlreadyExists:
            errorMessage = "Användaren finns redan";
            break;
          case API_MESSAGE.UserVerificationAlreadySent:
            errorMessage = "Verifieringsmail har redan skickats";
            break;
          default:
            errorMessage = "Något gick fel";
            break;
        }

        toast.error(errorMessage);
      });
  }

  return (
    <OnbordingLayout>
      <p className="signup--title space-grotesk">Registrera konto</p>
      <div className="signup--oauth-buttons">
        <Button
          color="white"
          label="Google"
          icon="google"
          width="100%"
          onClick={oAuthGoogleLoginHandler}
        />
      </div>
      <p className="signup--divider">Eller</p>
      <form
        className="signup--form"
        onSubmit={(e) => {
          e.preventDefault();
          registerAccountHandler();
        }}
      >
        <Input
          type="email"
          placeholder="E-postadress"
          label="E-postadress"
          value={email}
          onChange={(e) => {
            setEmail(e);
          }}
        />

        <Button color="black" label="Skapa konto" isLoading={isLoading} />
      </form>
      <p className="signup--login">
        Har du redan ett konto?{" "}
        <NavLink to={ROUTE.Login} className="sign-up-link">
          Logga in
        </NavLink>
      </p>
    </OnbordingLayout>
  );
}

export default SignUp;
