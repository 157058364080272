import { ChartDataset, ChartData, ChartOptions } from "chart.js";
import { Bar } from "react-chartjs-2";
import "./BarChart.scss";

interface IBarChartProps {
  labels: string[];
  datasets: ChartDataset<"bar">[];
  stacked?: boolean;
}

function BarChart(props: IBarChartProps) {
  const options: ChartOptions<"bar"> = {
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    plugins: {
      tooltip: {
        backgroundColor: "#030229",
      },
      legend: {
        display: false,
      },
    },
  };

  const data: ChartData<"bar"> = {
    labels: props.labels,
    datasets: props.datasets.map((ds) => ({
      ...ds,
      categoryPercentage: 0.6,
      barPercentage: 0.7,
      borderRadius: 5,
    })),
  };

  return (
    <div className="bar-chart">
      <Bar data={data} options={options} height="400px" />
    </div>
  );
}

export default BarChart;
