import { forwardRef, MouseEventHandler, Ref } from "react";
import Spinner from "../Spinner/Spinner";
import "./TextButton.scss";

interface ITextButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  label: string;
  className?: string;
  isLoading?: boolean;
  short?: boolean;
  disabled?: boolean;
  color?: string;
  title?: string;
  icon?: "caret-up" | "caret-down";
}

const TextButton = forwardRef(
  (props: ITextButtonProps, ref: Ref<HTMLButtonElement>) => {
    const buttonClasses = ["text-button", "text-m-m"];
    if (props.className) {
      buttonClasses.push(props.className);
    }
    if (props.isLoading) {
      buttonClasses.push("loading");
    }
    if (props.disabled) {
      buttonClasses.push("disabled");
    }

    let faClass = "";

    switch (props.icon) {
      case "caret-up":
        faClass = "fa-solid fa-caret-up";
        break;

      case "caret-down":
        faClass = "fa-solid fa-caret-down";
        break;
    }

    return (
      <button
        className={buttonClasses.join(" ")}
        style={{ color: props.color }}
        onClick={props.disabled ? undefined : props.onClick}
        title={props.title}
        ref={ref}
      >
        {props.isLoading ? <Spinner /> : props.label}
        {props.icon && <i className={faClass} />}
      </button>
    );
  }
);

export default TextButton;
