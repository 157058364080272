import { useEffect, useState } from "react";
import { getReportTextColor } from "../../../shared/reportUtility";
import { convertToNumberWithSpaces } from "../../../shared/utility";
import { RowType } from "../../../types/internal";
import "./BudgetSheetCell.scss";

interface IBudgetSheetCellProps {
  value: number;
  isEditable: boolean;
  isInThePast?: boolean;
  isCurrentMonth?: boolean;
  onEdit?: (value: number) => void;
  compareValue?: number;
  hasSaved?: boolean;
  rowType: RowType;
}

function BudgetSheetCell(props: IBudgetSheetCellProps) {
  const budgetCellClasses = ["budget-sheet-cell"];
  if (props.isInThePast) {
    budgetCellClasses.push("in-the-past");
  }
  if (props.isCurrentMonth) {
    budgetCellClasses.push("current-month");
  }

  const [value, setValue] = useState(Math.round(props.value).toString());
  const [isFocusing, setIsFocusing] = useState(false);
  const [hasEdited, setHasEdited] = useState(false);

  useEffect(() => {
    setValue(Math.round(props.value).toString());
  }, [props.value]);

  useEffect(() => {
    if (props.hasSaved) {
      setHasEdited(false);
    }
  }, [props.hasSaved]);

  function inputHandler(changedValue: string) {
    if (+changedValue > 1000000000000000) {
      changedValue = "1000000000000000";
    }
    if (+changedValue < -100000000000000) {
      changedValue = "-100000000000000";
    }
    setValue(changedValue);

    if (!isNaN(+changedValue)) {
      if (!hasEdited) setHasEdited(true);
      props.onEdit!(+changedValue);
    }
  }

  const isInvalidValue = isNaN(+value) && value !== "";

  return (
    <td
      className={budgetCellClasses.join(" ")}
      style={{
        borderRight: isInvalidValue
          ? "1px solid red"
          : hasEdited
          ? "1px solid purple"
          : props.isCurrentMonth
          ? `1px solid ${getReportTextColor(props.rowType)}`
          : undefined,
      }}
    >
      <input
        type="tel"
        className="text-s-r"
        value={
          !isFocusing && !isNaN(+value)
            ? convertToNumberWithSpaces(+value)
            : value
        }
        onChange={(e) => inputHandler(e.target.value)}
        onFocus={() => {
          setIsFocusing(true);
          if (value === "0") {
            setValue("");
          }
        }}
        onBlur={() => setIsFocusing(false)}
        disabled={!props.isEditable}
      />
      {props.compareValue !== undefined && (
        <p className="text-xs-m">
          {convertToNumberWithSpaces(props.compareValue)}
        </p>
      )}
    </td>
  );
}

export default BudgetSheetCell;
