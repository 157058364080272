import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICompany, ICompanySettings, IFinancialYear } from '../../types/api';
import { IKeyString } from '../../types/internal';
import { ICompanyState } from '../../types/redux';

interface ICompanyPayload {
  companyId: string;
  settings: ICompanySettings;
  licenses: IKeyString<boolean>;
  companyData: {
    currentUserPermission?: string;
    isConcern: boolean;
    isRedoSetup: boolean;
    hasIndependantAccess: boolean;
    dataSource: "fortnox" | "visma" | "";
  };
  financialYear: {
    identifier: string;
    from: number;
    to: number;
  };
  financialYears: IFinancialYear[];
  isCustomer: boolean;
  subscription?: {
    type?: string;
    period?: number[];
  };
}

const initialState: ICompanyState = {
  companies: [],
  companyId: "",
  loading: false,
  settings: {
    paymentsTermsDays: "",
    vatPeriod: "MONTHLY",
    amountEmployees: 0,
    branch: "",
    advancedBudget: false,
    disabledAlerts: [],
  },
  licenses: {},
  companyData: {
    isConcern: false,
    isRedoSetup: false,
    hasIndependantAccess: false,
    dataSource: "",
  },
  financialYear: {
    identifier: "",
    from: 0,
    to: 0,
  },
  financialYears: [],
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompanyData(state, action: PayloadAction<ICompanyPayload>) {
      state.companyId = action.payload.companyId;
      state.loading = false;
      state.settings = action.payload.settings;
      state.licenses = action.payload.licenses;
      state.companyData = action.payload.companyData;
      state.financialYear = action.payload.financialYear;
      state.financialYears = action.payload.financialYears;
    },
    setCompanies(state, action: PayloadAction<ICompany[]>) {
      state.companies = action.payload;
    },
    setCompanyAsCustomer(state, action: PayloadAction<string>) {
      const company = state.companies.find(
        (c) => c.companyId === action.payload
      );

      if (company) company.isCustomer = true;
    },
    setCompanySettings(state, action: PayloadAction<ICompanySettings>) {
      state.settings = action.payload;
    },
    setCompanyStart(state) {
      state.loading = true;
    },
    setCompanyLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setFinancialYear(state, action: PayloadAction<string>) {
      const financialYear = state.financialYears.find(
        (fy) => `${fy.financialYear}` === action.payload
      );

      if (!financialYear) return;

      state.financialYear = {
        identifier: action.payload,
        from: financialYear.fromDate,
        to: financialYear.toDate,
      };
    },
  },
});

export const {
  setCompanyData,
  setCompanyAsCustomer,
  setCompanies,
  setCompanySettings,
  setCompanyStart,
  setCompanyLoading,
  setFinancialYear,
} = companySlice.actions;

export default companySlice.reducer;
