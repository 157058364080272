import { VARIABLE_VALUE } from "../../../shared/enums";
import { IFormulaItem } from "../../../types/internal";
import KeyValueArithmetic from "../KeyValueArithmetic/KeyValueArithmetic";
import "./KeyValueFormula.scss";

interface IKeyValueFormulaProps {
  formula: IFormulaItem[];
  onSetInsertIndex: (index: number) => void;
  insertIndex?: number;
}

interface IFormulaItemProps {
  value: string;
  onClick: () => void;
}

function VariableF(props: IFormulaItemProps) {
  return (
    <div className="variable text-m-m" onClick={props.onClick}>
      {props.value.replace("$", "").replace(VARIABLE_VALUE.GlobalPrefix, "")}
    </div>
  );
}

function AccountSpanF(props: IFormulaItemProps) {
  return (
    <div className="account-span text-m-m" onClick={props.onClick}>
      {props.value.replace(/[[\]']+/g, "").replace(/:+/g, " - ")}
    </div>
  );
}

function AccountF(props: IFormulaItemProps) {
  return (
    <div className="account text-m-m" onClick={props.onClick}>
      {props.value.replace(/[[\]']+/g, "")}
    </div>
  );
}

function NumberF(props: IFormulaItemProps) {
  return (
    <div className="number text-m-m" onClick={props.onClick}>
      {props.value}
    </div>
  );
}

function KeyValueFormula(props: IKeyValueFormulaProps) {
  return (
    <div className="key-value-formula">
      {!props.formula.length ? (
        <p className="empty text-m-r">Din formel här</p>
      ) : (
        <>
          {props.formula.map((f, i) => (
            <div className="key-value-formula-item text-m-m" key={i}>
              {i === props.insertIndex && <div className="line">|</div>}
              {f.type === "variable" ? (
                <VariableF
                  value={f.value}
                  onClick={() => props.onSetInsertIndex(i)}
                />
              ) : f.type === "account-span" ? (
                <AccountSpanF
                  value={f.value}
                  onClick={() => props.onSetInsertIndex(i)}
                />
              ) : f.type === "account" ? (
                <AccountF
                  value={f.value}
                  onClick={() => props.onSetInsertIndex(i)}
                />
              ) : f.type === "number" ? (
                <NumberF
                  value={f.value}
                  onClick={() => props.onSetInsertIndex(i)}
                />
              ) : (
                <KeyValueArithmetic
                  type={f.value as any}
                  onClick={() => props.onSetInsertIndex(i)}
                />
              )}
            </div>
          ))}
          {props.insertIndex === props.formula.length && (
            <div className="line">|</div>
          )}
          <div
            className="hidden-item"
            onClick={() => props.onSetInsertIndex(props.formula.length)}
          />
        </>
      )}
    </div>
  );
}

export default KeyValueFormula;
