import "./SettingsRow.scss";

import { addHours, fromUnixTime, getUnixTime } from "date-fns";
import { motion, Variants } from "framer-motion";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  deleteCompanyUser,
  getCompany,
  getCompanySettings,
  getMyCompany,
  inviteUser,
} from "../../api/company";
import { generateFortnoxUrl } from "../../api/fortnox";
import { activateLicense, deactivateLicense } from "../../api/license";
import { promoteUserToAdmin } from "../../api/user";
import { generateVismaUrl } from "../../api/visma";
import Dot from "../../components/Dot/Dot";
import LicenseDots from "../../components/LicenseDots/LicenseDots";
import Button from "../../components/UI/Button/Button";
import Countdown from "../../components/UI/CountDown/Countdown";
import Input from "../../components/UI/Input/Input";
import Spinner from "../../components/UI/Spinner/Spinner";
import TextButton from "../../components/UI/TextButton/TextButton";
import { API_MESSAGE, ERROR_TEXT } from "../../shared/enums";
import { nimyaAlert } from "../../shared/nimyaAlert";
import { getBranchName, isDateAfter } from "../../shared/utility";
import { vatPeriods } from "../../shared/values";
import { setCompany } from "../../store/thunks/company";
import { ICompanyUser, ILicense } from "../../types/api";
import { CompanySettingsNavigationItem } from "../../types/internal";
import { IReduxState } from "../../types/redux";
import CompanySettingsPopup from "../CompanySettingsPopup/CompanySettingsPopup";
import ResendUserVerification from "../ResendUserVerification/ResendUserVerification";

interface ISettingsRowProps {
  companyId: string;
  name: string;
  isRedoSetup: boolean;
  createdAt: number;
  shouldOpen: boolean;
  isUserAdmin: boolean;
  onRemoveCompany: () => void;
  onLeaveCompany: () => void;
  userEmail: string;
  licenses: ILicense[];
}

function SettingsRow(props: ISettingsRowProps) {
  const { companyId: userCompanyId, companies } = useSelector(
    (state: IReduxState) => state.company
  );

  const [isOpen, setIsOpen] = useState(props.shouldOpen);
  const [addEmail, setAddEmail] = useState("");
  const [subscriptionType, setSubscriptionType] = useState("Standard");
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
  const [activePopupNavigation, setActivePopupNavigation] = useState<
    CompanySettingsNavigationItem | undefined
  >(undefined);

  const { data, isLoading, isSuccess, refetch } = useQuery({
    queryKey: ["getCompany", props.companyId],
    queryFn: async ({ queryKey: [, companyId] }) => {
      const [userRes, settingsRes, cRes] = await Promise.all([
        getMyCompany({
          companyId,
        }),
        getCompanySettings(companyId),
        getCompany(companyId),
      ]);

      return {
        companyData: cRes.data.payload,
        users: userRes.data.payload,
        settings: settingsRes.data.payload,
      };
    },
    enabled: isOpen,
  });

  useEffect(() => {
    const data = companies.find((c) => c.companyId === props.companyId);
    if (data && "isCustomer" in data) {
      setShowUpdateBtn(!data.isCustomer);
    }
    if (data && "subscription" in data) {
      if (data.subscription?.type === "none") {
        setSubscriptionType("Standard");
      } else {
        setSubscriptionType(data.subscription?.type || "Standard");
      }
    }
  }, [companies, props.companyId]);

  function userFilterFunc(user: ICompanyUser, onlyVerified?: boolean): boolean {
    return !user.isGroup && (onlyVerified ? user.isVerified : !user.isVerified);
  }

  async function adminClickHandler(
    companyId: string,
    userId: string,
    shouldPromote: boolean
  ) {
    if (!props.isUserAdmin) {
      toast.error("Du måste vara admin för att ändra adminrollen");
      return;
    }

    const confirmText = shouldPromote
      ? "Är du säker på att du vill tilldela adminroll för denna användare?"
      : "Är du säker på att du vill ta bort adminroll för denna användare?";

    if (
      !(await nimyaAlert({
        title: "Adminroll",
        message: confirmText,
      }))
    )
      return;

    try {
      await promoteUserToAdmin({
        companyId,
        userId,
        role: shouldPromote ? "admin" : "user",
      });
      const sucessText = shouldPromote
        ? "Användare uppgraderas admin"
        : "Användare degraderad till användare";
      toast.success(sucessText);
    } catch (error) {
      toast.error("Något gick fel");
    }

    await refetch();
  }

  const licenseDotClickHandler = async (
    companyId: string,
    userId: string,
    licenseId: string,
    shouldActivate: boolean
  ) => {
    if (!props.isUserAdmin) {
      toast.error("Du måste vara admin för att ändra licenser");
      return;
    }

    try {
      if (shouldActivate) {
        await activateLicense({
          companyId,
          userId,
          licenseId,
        });
        toast.success("Licens aktiverad");
      } else {
        await deactivateLicense({
          companyId,
          userId,
          licenseId,
        });
        toast.success("Licens avaktiverad");
      }
    } catch (error) {
      toast.error("Något gick fel");
    }

    await refetch();

    if (userCompanyId === companyId) {
      setCompany(companyId);
    }
  };

  async function inviteUserHandler(email: string) {
    setIsAddingUser(true);
    try {
      await inviteUser({ companyId: props.companyId, email });
    } catch (error: any) {
      if (error.response.data.message === API_MESSAGE.UserAlreadyInCompany) {
        toast.error("Användare är redan med i företaget");
        setIsAddingUser(false);
        return;
      }
      toast.error("Något gick fel");
      setIsAddingUser(false);
      return;
    }

    setIsAddingUser(false);
    setAddEmail("");
    toast.success("Användare inbjuden");
    await refetch();
  }

  async function removeUserHandler(userId: string, isLeaving: boolean) {
    const confirmText = isLeaving
      ? "Är du säker på att du vill lämna företaget?"
      : "Är du säker på att du vill ta bort användaren?";

    const confirmTitle = isLeaving ? "Lämna företaget" : "Ta bort användare";

    if (!(await nimyaAlert({ title: confirmTitle, message: confirmText })))
      return;

    try {
      await deleteCompanyUser({
        companyId: props.companyId,
        userId,
      });
      toast.success("Användare borttagen");
      if (isLeaving) props.onLeaveCompany();
    } catch (error) {
      toast.error("Något gick fel");
    }

    await refetch();
  }

  async function redoSetup(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.stopPropagation();
    setIsOpen(true);
    const result = await refetch();

    const companyDataSource = result.data?.companyData.company.isVisma
      ? "visma"
      : result.data?.companyData.company.isFortnox
      ? "fortnox"
      : "";

    if (!companyDataSource) return;

    let redoUrl = "";

    try {
      if (companyDataSource === "fortnox") {
        const res = await generateFortnoxUrl();
        redoUrl = res.data.payload.url;
      } else if (companyDataSource === "visma") {
        const res = await generateVismaUrl();
        redoUrl = res.data.payload.url;
      }
    } catch (error) {
      toast.error(`Kunde inte skapa ${companyDataSource} länk`);
    }

    if (!redoUrl) return;

    window.location.href = redoUrl;
  }

  const rowVariants: Variants = {
    offscreen: {
      y: 20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
      },
    },
  };

  const rowTablesVariants: Variants = {
    closed: {
      height: 0,
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
    open: {
      height: "auto",
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  };

  let unverifiedUsers: ICompanyUser[] = [];

  if (isSuccess) {
    unverifiedUsers = data.users.filter((u) => userFilterFunc(u, false));
  }

  return (
    <>
      <motion.div
        className="settings-row"
        id={props.companyId}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.1 }}
        variants={rowVariants}
        transition={{ ease: "easeInOut" }}
      >
        <div
          className="row text-m-m"
          onClick={() => setIsOpen((state) => !state)}
        >
          <div className="row-section">
            <span className="name">{props.name}</span>
            {isDateAfter(
              addHours(fromUnixTime(props.createdAt), 5),
              new Date()
            ) && (
              <Countdown
                title="Det kan ta upp till 5 timmar att ladda in datan"
                duration={Math.floor(
                  getUnixTime(addHours(fromUnixTime(props.createdAt), 5)) -
                    getUnixTime(new Date())
                )}
              />
            )}
            {props.isRedoSetup && (
              <TextButton
                label={ERROR_TEXT.RedoSetupClickHere}
                onClick={redoSetup}
                color="red"
              />
            )}
            {isLoading && (
              <div>
                <Spinner />
              </div>
            )}
          </div>
          <div className="row-section">
            <div className="buttons">
              {showUpdateBtn && (
                <button
                  className="update-button text-m-r"
                  style={{ width: "100px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsSettingsPopupOpen(true);
                    setActivePopupNavigation("Betalning");
                  }}
                >
                  Uppdatera
                </button>
              )}
              <button
                disabled
                className="type-button text-m-r"
                style={{ width: "100px", textTransform: "capitalize" }}
              >
                {subscriptionType}
              </button>
            </div>
            {props.isUserAdmin && (
              <i
                className="fa-solid fa-gear"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsSettingsPopupOpen(true);
                }}
              />
            )}
            <i
              className="fa-solid fa-caret-down"
              style={isOpen ? { transform: "rotate(180deg)" } : {}}
            />
          </div>
        </div>
        <motion.div
          className="company-settings"
          variants={rowTablesVariants}
          animate={isOpen && !isLoading ? "open" : "closed"}
        >
          {!isLoading && isSuccess && isOpen && (
            <>
              <table>
                <thead>
                  <tr className="text-m-m">
                    <th className="text-head">Namn</th>
                    <th className="text-head">E-post</th>
                    <th>Licenser</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {data.users
                    .filter((u) => userFilterFunc(u, true))
                    .map((user) => (
                      <tr key={user.userId} className="text-s-r">
                        <td>
                          {user.firstName || ""} {user.lastName || ""}
                        </td>
                        <td>{user.email}</td>
                        <td>
                          <div className="settings-licenses">
                            <LicenseDots
                              licenses={props.licenses}
                              activeLicenses={user.licenses}
                              lockedNames={["Standard"]}
                              onClick={(lId, shouldActivate) =>
                                licenseDotClickHandler(
                                  props.companyId,
                                  user.userId,
                                  lId,
                                  shouldActivate
                                )
                              }
                            />
                            {(props.isUserAdmin || user.role === "admin") && (
                              <Dot
                                active={user.role === "admin"}
                                label="AD"
                                onClick={() =>
                                  adminClickHandler(
                                    props.companyId,
                                    user.userId,
                                    user.role !== "admin"
                                  )
                                }
                              />
                            )}
                          </div>
                        </td>
                        <td className="admin">
                          {(props.isUserAdmin ||
                            props.userEmail === user.email) && (
                            <div className="admin-actions">
                              <Dot
                                active={false}
                                label=""
                                onClick={() =>
                                  removeUserHandler(
                                    user.userId,
                                    user.email === props.userEmail
                                  )
                                }
                                icon="fa-solid fa-trash"
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  {!!unverifiedUsers.length && (
                    <tr className="text-m-m">
                      <td>Inbjuden e-post</td>
                      <td>Länk är aktiv till</td>
                      <td>Licenser</td>
                      <td />
                    </tr>
                  )}
                  {unverifiedUsers.map((user) => (
                    <tr key={user.userId} className="text-s-r">
                      <td>{user.email}</td>
                      <td>
                        <ResendUserVerification
                          email={user.email}
                          companyId={props.companyId}
                          companyName={props.name}
                        />
                      </td>
                      <td>
                        <div className="settings-licenses">
                          <LicenseDots
                            licenses={props.licenses}
                            activeLicenses={user.licenses}
                            lockedNames={["Standard"]}
                            onClick={(lId, shouldActivate) =>
                              licenseDotClickHandler(
                                props.companyId,
                                user.userId,
                                lId,
                                shouldActivate
                              )
                            }
                          />
                          {props.isUserAdmin && (
                            <Dot
                              active={user.role === "admin"}
                              label="AD"
                              onClick={() =>
                                adminClickHandler(
                                  props.companyId,
                                  user.userId,
                                  user.role !== "admin"
                                )
                              }
                            />
                          )}
                        </div>
                      </td>
                      <td className="admin">
                        {props.isUserAdmin && (
                          <div className="admin-actions">
                            <Dot
                              active={false}
                              label=""
                              onClick={() =>
                                removeUserHandler(
                                  user.userId,
                                  user.email === props.userEmail
                                )
                              }
                              icon="fa-solid fa-trash"
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                  {props.isUserAdmin && (
                    <tr className="form-row">
                      <td>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            inviteUserHandler(addEmail);
                          }}
                        >
                          <Input
                            type="email"
                            value={addEmail}
                            onChange={setAddEmail}
                            placeholder="Ange e-post att bjuda in"
                            short
                            width="100%"
                          />
                        </form>
                      </td>
                      <td colSpan={100}>
                        <Button
                          label="Skicka"
                          color="black"
                          short
                          isLoading={isAddingUser}
                          onClick={() => inviteUserHandler(addEmail)}
                        />
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="company-settings-cell" colSpan={100}>
                      <div className="company-settings-display">
                        <p className="title text-m-m">Företagsinformation</p>
                        <div className="row">
                          <p className="title text-s-r">Antal anställda</p>
                          <span className="value text-m-r">
                            {data.settings.amountEmployees
                              ? `${data.settings.amountEmployees} st`
                              : "Inte angivet"}
                          </span>
                          <i
                            className="fa-light fa-pen-to-square"
                            onClick={() => setIsSettingsPopupOpen(true)}
                          />
                        </div>
                        <div className="row">
                          <p className="title text-s-r">Bransch</p>
                          <span className="value text-m-r">
                            {getBranchName(data.settings.branch) ||
                              "Inte angivet"}
                          </span>
                          <i
                            className="fa-light fa-pen-to-square"
                            onClick={() => setIsSettingsPopupOpen(true)}
                          />
                        </div>
                        <div className="row">
                          <p className="title text-s-r">Momsperiod</p>
                          <span className="value text-m-r">
                            {vatPeriods.find(
                              (vp) => vp.value === data.settings.vatPeriod
                            )?.label || "Inte angivet"}
                          </span>
                          <i
                            className="fa-light fa-pen-to-square"
                            onClick={() => setIsSettingsPopupOpen(true)}
                          />
                        </div>
                        <div className="row">
                          <p className="title text-s-r">Betaldagar</p>
                          <span className="value text-m-r">
                            {data.settings.paymentsTermsDays
                              ? `${data.settings.paymentsTermsDays} dagar`
                              : "Inte angivet"}
                          </span>
                          <i
                            className="fa-light fa-pen-to-square"
                            onClick={() => setIsSettingsPopupOpen(true)}
                          />
                        </div>
                        <div className="divider" />
                        <p className="title text-m-m">Redovisningsbyrå</p>
                        <div className="row">
                          <p className="title text-s-r">Byråns namn</p>
                          <span className="value text-m-r">
                            {data.companyData.company.consultingAgencyInfo
                              ?.name || "Inte angivet"}
                          </span>
                          <i
                            className="fa-light fa-pen-to-square"
                            onClick={() => {
                              setActivePopupNavigation("Redovisningsbyrå");
                              setIsSettingsPopupOpen(true);
                            }}
                          />
                        </div>
                        <div className="row">
                          <p className="title text-s-r">Konsult</p>
                          <span className="value text-m-r">
                            {data.companyData.company.responsibleEmployee
                              ?.email || "Inte angivet"}
                          </span>
                          <i
                            className="fa-light fa-pen-to-square"
                            onClick={() => {
                              setActivePopupNavigation("Redovisningsbyrå");
                              setIsSettingsPopupOpen(true);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </motion.div>
      </motion.div>
      <CompanySettingsPopup
        showPopup={isSettingsPopupOpen}
        onClose={() => {
          setIsSettingsPopupOpen(false);
          setActivePopupNavigation(undefined);
        }}
        companyId={props.companyId}
        onDelete={() => {
          setIsSettingsPopupOpen(false);
          props.onRemoveCompany();
        }}
        setNavigation={activePopupNavigation}
      />
    </>
  );
}

export default SettingsRow;
