import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import TextButton from "../../components/UI/TextButton/TextButton";
import Popup from "../../hoc/Popup/Popup";
import { convertToNumberWithSpaces } from "../../shared/utility";
import { months } from "../../shared/values";
import { IKeyString } from "../../types/internal";
import "./BudgetBulkPopup.scss";

interface IBudgetBulkPopupProps {
  showPopup: boolean;
  onClose: () => void;
  budgetRow: IKeyString<number>;
  onUpdate: (row: IKeyString<number>) => void;
}

function BudgetBulkPopup(props: IBudgetBulkPopupProps) {
  const [wholeRowProcent, setWholeRowProcent] = useState("0");
  const [perRowProcent, setPerRowProcent] = useState<IKeyString<string>>({});
  const [mode, setMode] = useState<"row" | "section">("row");

  useEffect(() => {
    const rows = Object.fromEntries(
      Object.keys(props.budgetRow).map((mKey) => [mKey, "0"])
    );
    setPerRowProcent(rows);
  }, [props.budgetRow]);

  useEffect(() => {
    setPerRowProcent((state) => {
      const newState = { ...state };
      Object.keys(newState).forEach((mKey) => {
        newState[mKey] = wholeRowProcent;
      });
      return newState;
    });
  }, [wholeRowProcent]);

  function convertToNumber(num: string) {
    if (isNaN(+num)) {
      return 0;
    }
    return +num;
  }

  function updateHandler() {
    if (mode === "row") {
      const updatedRow = Object.fromEntries(
        Object.keys(props.budgetRow).map((mKey) => [
          mKey,
          props.budgetRow[mKey] *
            (1 + convertToNumber(perRowProcent[mKey]) / 100),
        ])
      );
      props.onUpdate(updatedRow);
    }
  }

  const rowSum = Object.values(props.budgetRow).reduce(
    (total, value) => total + value,
    0
  );

  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={
        <div className="budget-bulk-popup-top">
          <TextButton
            label="Enskild rad"
            onClick={() => setMode("row")}
            color={mode === "row" ? "#fff" : "#A6A6AF"}
          />
          {false && (
            <TextButton
              label={`Alla [intäkter]`}
              onClick={() =>
                /*setMode("section")*/ toast.info("Inte implementerad")
              }
              color={mode === "section" ? "#fff" : "#A6A6AF"}
            />
          )}
          <p className="text-m-r">
            Öka/minska din budget genom att lägga till en procentsats
          </p>
        </div>
      }
    >
      <div className="budget-bulk">
        <div className="budget-bulk-row whole-row">
          <span className="text-m-r">Hela raden</span>
          <div className="values text-s-r">
            <span className="original">
              {convertToNumberWithSpaces(rowSum)}
            </span>
            /
            <span className="changed-value">
              {convertToNumberWithSpaces(
                rowSum * (1 + convertToNumber(wholeRowProcent) / 100)
              )}
            </span>
          </div>
          <Input
            type="text"
            value={`${wholeRowProcent}`}
            className="procent-input"
            onChange={(v) => {
              setWholeRowProcent(v);
            }}
            width="83px"
            short
          />
          <span className="procent text-m-r">%</span>
        </div>
        <div className="budget-bulk-rows">
          {Object.keys(props.budgetRow).map((mKey) => (
            <div key={mKey} className="budget-bulk-row">
              <span className="text-m-r">
                {months[+mKey.split("-")[1] - 1].label.slice(0, 3)}
              </span>
              <div className="values text-s-r">
                <span className="original">
                  {convertToNumberWithSpaces(props.budgetRow[mKey])}
                </span>
                /
                <span className="changed-value">
                  {convertToNumberWithSpaces(
                    props.budgetRow[mKey] *
                      (1 + convertToNumber(perRowProcent[mKey]) / 100)
                  )}
                </span>
              </div>
              <Input
                type="text"
                value={`${perRowProcent[mKey] || 0}`}
                className="procent-input"
                onChange={(v) => {
                  setPerRowProcent((state) => ({ ...state, [mKey]: v }));
                }}
                width="83px"
                short
              />
              <span className="procent text-m-r">%</span>
            </div>
          ))}
        </div>
        <div className="bottom">
          <Button
            color="black"
            label="Uppdatera budget"
            short
            onClick={updateHandler}
          />
        </div>
      </div>
    </Popup>
  );
}

export default BudgetBulkPopup;
