import { useOutletContext } from "react-router-dom";

interface ContextType {
  from: number;
  to: number;
  breakUnix: number;
  setControlsComponent: (component?: JSX.Element) => void;
}

export function useReportValues() {
  return useOutletContext<ContextType>();
}
