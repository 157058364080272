import LegendText, {
  ILegendTextProps,
} from "../../components/LegendText/LegendText";
import "./ChartWrapper.scss";

interface IChartWrapperProps {
  leftLegend?: ILegendTextProps[];
  rightLegend?: ILegendTextProps[];
  children: React.ReactNode;
}

function ChartWrapper(props: IChartWrapperProps) {
  return (
    <div className="chart-wrapper">
      <div className="top">
        <div className="legend-wrapper">
          {props.leftLegend?.map((li) => (
            <LegendText key={li.label} {...li} />
          ))}
        </div>
        <div className="legend-wrapper">
          {props.rightLegend?.map((li) => (
            <LegendText key={li.label} {...li} />
          ))}
        </div>
      </div>
      <div className="content">{props.children}</div>
    </div>
  );
}

export default ChartWrapper;
