import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import BetaFeature from "../../components/BetaFeature/BetaFeature";
import ParentRouteNav from "../../components/ParentRouteNav/ParentRouteNav";
import Dropdown from "../../components/UI/Dropdown/Dropdown";
import { ROUTE, ROUTE_LABEL } from "../../shared/enums";
import { getFinancialYearsDropdown } from "../../shared/utility";
import { setFinancialYear } from "../../store/slices/company";
import { IReduxState } from "../../types/redux";
import "./Future.scss";

interface IFutureProps {}

function Future(props: IFutureProps) {
  const dispatch = useDispatch();

  const { financialYear, financialYears } = useSelector(
    (state: IReduxState) => state.company
  );

  const { pathname } = useLocation();

  const [controlsComponent, setControlsComponent] = useState<
    React.ReactNode | undefined
  >(undefined);

  const navItems = [
    { path: ROUTE.Budget, label: ROUTE_LABEL.Budget },
    { path: ROUTE.Forecast, label: ROUTE_LABEL.Forecast },
  ];

  return (
    <div className="future">
      <div className="controls">
        <ParentRouteNav navItems={navItems} noMargin />
        <BetaFeature
          style={{
            marginRight: "auto",
          }}
        />
        {controlsComponent}
        {pathname === ROUTE.Budget && (
          <Dropdown
            title="År"
            options={getFinancialYearsDropdown(financialYears)}
            onSelect={(v) => dispatch(setFinancialYear(v))}
            value={financialYear.identifier}
            width="200px"
          />
        )}
      </div>
      <Outlet
        context={{
          setControlsComponent,
        }}
      />
    </div>
  );
}

export default Future;
