import "./Spinner.scss";

interface SpinnerProps {
  padding?: string;
  color?: string;
}

function Spinner(props: SpinnerProps) {
  return (
    <div
      className="spinner"
      style={{
        padding: props.padding,
        color: props.color,
      }}
    >
      <i className="far fa-spinner-third" />
    </div>
  );
}

export default Spinner;
