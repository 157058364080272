import { motion, Variants } from "framer-motion";
import { useState } from "react";
import { IGroupUser } from "../../types/api";
import "./GroupSettingsRow.scss";

interface IGroupSettingsRowProps {
  companyId: string;
  name: string;
  users: IGroupUser[];
}

function GroupSettingsRow(props: IGroupSettingsRowProps) {
  const [isOpen, setIsOpen] = useState(false);

  const rowVariants: Variants = {
    offscreen: {
      y: 20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
      },
    },
  };

  const rowTablesVariants: Variants = {
    closed: {
      height: 0,
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
    open: {
      height: "auto",
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <motion.div
      className="group-settings-row"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.1 }}
      variants={rowVariants}
      transition={{ ease: "easeInOut" }}
    >
      <div
        className="title text-m-m"
        onClick={() => setIsOpen((state) => !state)}
      >
        <span>{props.name}</span>
        <i
          className="fa-solid fa-caret-down"
          style={isOpen ? { transform: "rotate(180deg)" } : {}}
        />
      </div>
      <motion.div
        className="group-company-settings"
        variants={rowTablesVariants}
        animate={isOpen ? "open" : "closed"}
      >
        <table>
          <thead>
            <tr className="text-m-m">
              <th>Namn</th>
              <th>E-post</th>
              <th>Redigera</th>
            </tr>
          </thead>
          <tbody>
            {props.users.map((user) => (
              <tr key={user.userId} className="text-s-r">
                <td>{`${user.first_name} ${user.last_name}`}</td>
                <td>{user.email}</td>
                <td>{user.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </motion.div>
    </motion.div>
  );
}

export default GroupSettingsRow;
