import { useSelector } from "react-redux";
import { getAlertDescription } from "../../shared/alertUtility";
import { ALERT_VALUE, VARIABLE_VALUE } from "../../shared/enums";
import { IAlert } from "../../types/api";
import { IReduxState } from "../../types/redux";
import TextButton from "../UI/TextButton/TextButton";
import AlertRow from "./AlertRow/AlertRow";
import "./AlertSection.scss";

interface IAlertSectionProps {
  title: string;
  alerts: IAlert[];
  onAlertSelected: (alert: IAlert) => void;
  selectedAlertIds: string[];
  editMode?: boolean;
  onEdit: (alert: IAlert) => void;
  onDelete: (alert: IAlert) => void;
  onToggleAlert: (alert: IAlert, shouldActivate: boolean) => void;
}

function AlertSection(props: IAlertSectionProps) {
  const {
    company: { settings },
  } = useSelector((state: IReduxState) => state);

  const activeAlerts = props.alerts.filter(
    (a) => a.result !== ALERT_VALUE.AlertFalseResult
  );

  const isAllSelected = activeAlerts.every((a) =>
    props.selectedAlertIds.includes(a._id)
  );
  const isAllDeselected = activeAlerts.every(
    (a) => !props.selectedAlertIds.includes(a._id)
  );

  function toggleAllAlertsHandler() {
    if (isAllSelected || isAllDeselected) {
      activeAlerts.forEach((a) => props.onAlertSelected(a));
      return;
    }

    activeAlerts
      .filter((a) => !props.selectedAlertIds.includes(a._id))
      .forEach((a) => props.onAlertSelected(a));
  }

  const isGlobal = props.title === "Globala";

  return activeAlerts.length ? (
    <div className="alert-section">
      <div className="top">
        {isGlobal ? (
          <span className="text-m-m">
            <i className="fa-light fa-globe" />
            {props.title}
          </span>
        ) : (
          <span className="text-m-m">{`# ${props.title}`}</span>
        )}
        {!props.editMode && (
          <TextButton
            label={isAllSelected ? "Avmarkera notiser" : "Rapportera notiser"}
            className="text-m-r"
            onClick={toggleAllAlertsHandler}
            disabled={props.editMode}
          />
        )}
      </div>
      {activeAlerts
        .filter((a) => a.result !== ALERT_VALUE.AlertFalseResult)
        .map((a) => (
          <AlertRow
            key={a._id}
            title={a.name.replace(VARIABLE_VALUE.GlobalPrefix, "")}
            description={getAlertDescription(a)}
            onSelect={() => props.onAlertSelected(a)}
            isSelected={props.selectedAlertIds.includes(a._id)}
            editMode={props.editMode}
            onEdit={() => props.onEdit(a)}
            onDelete={() => props.onDelete(a)}
            onToggleAlert={(shouldActivate) =>
              props.onToggleAlert(a, shouldActivate)
            }
            isActive={!settings.disabledAlerts.includes(a._id)}
            isGlobal={a.global}
          />
        ))}
    </div>
  ) : null;
}

export default AlertSection;
