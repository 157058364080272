import { fromUnixTime } from "date-fns";
import {
  IAccount,
  IBudgetPeriod,
  ILiquidityArray,
  ILiquidityReport,
  IPeriodBalances,
} from "../types/api";
import { IKeyString } from "../types/internal";
import { convertBudgetToPeriodBalances } from "./budget";
import { months } from "./values";

export type vatPeriodTypes = "YEARLY" | "QUARTERLY" | "MONTHLY";

const vatCodes: IKeyString<number> = {
  U1: 0.25,
  U2: 0.12,
  U3: 0.06,
  MP1: 0.25,
  MP2: 0.12,
  MP3: 0.06,
  IVEU: 0.25,
  ITEU: 0.25,
  ITGLOB: 0.25,
  BI: 0.25,
  UI25: 0.25,
  UI12: 0.12,
  UI6: 0.06,
  MF: 0,
  A: 0,
  HFS: 0.25,
  ÖTEU: 0,
};

function delay(input: ILiquidityArray, delay: number) {
  const result: ILiquidityArray = getEmptyLiquidityArray();
  const ratio = (delay / 30) % 1;
  const monthDelay = 1 + Math.floor(delay / 30);
  for (let month in input) {
    const monthNum = parseInt(month);

    //so nothing is undefiend
    result[monthNum + monthDelay - 1] = result[monthNum + monthDelay - 1] || 0;
    result[monthNum + monthDelay] = result[monthNum + monthDelay] || 0;

    result[monthNum + monthDelay - 1] += input[monthNum] * (1 - ratio);
    result[monthNum + monthDelay] += input[monthNum] * ratio;
  }
  return result;
}

function getEmptyLiquidityArray(): ILiquidityArray {
  return {
    "-12": 0,
    "-11": 0,
    "-10": 0,
    "-9": 0,
    "-8": 0,
    "-7": 0,
    "-6": 0,
    "-5": 0,
    "-4": 0,
    "-3": 0,
    "-2": 0,
    "-1": 0,
    "0": 0,
    "1": 0,
    "2": 0,
    "3": 0,
    "4": 0,
    "5": 0,
    "6": 0,
    "7": 0,
    "8": 0,
    "9": 0,
    "10": 0,
    "11": 0,
  };
}

export function getMonthObject(from: number) {
  const monthObject: IKeyString<string> = {
    "-12": "",
    "-11": "",
    "-10": "",
    "-9": "",
    "-8": "",
    "-7": "",
    "-6": "",
    "-5": "",
    "-4": "",
    "-3": "",
    "-2": "",
    "-1": "",
    "0": "",
    "1": "",
    "2": "",
    "3": "",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
    "8": "",
    "9": "",
    "10": "",
    "11": "",
  };

  const fromDate = fromUnixTime(from);

  let monthIndex = fromDate.getMonth();
  let year = fromDate.getFullYear() - 1;

  Object.keys(monthObject)
    .sort((a, b) => +a - +b)
    .forEach((key) => {
      monthObject[key] = `${year.toString()}-${months[monthIndex].value}`;
      monthIndex++;
      if (monthIndex > 11) {
        year++;
        monthIndex = 0;
      }
    });
  return monthObject;
}

function getIngoingVat({
  periodBalances,
  monthObject,
  accountMap,
  delayDays,
}: {
  periodBalances: IPeriodBalances;
  monthObject: IKeyString<string>;
  accountMap: Record<number, IAccount>;
  delayDays: number;
}) {
  const result = getEmptyLiquidityArray();

  for (const [accountNr, pbs] of Object.entries(periodBalances)) {
    if (3000 <= +accountNr && +accountNr < 4000) {
      Object.keys(pbs).forEach((monthKey) => {
        const monthIndex = Object.keys(monthObject).find(
          (key) => monthObject[key] === monthKey
        );
        if (!monthIndex) {
          return;
        }

        if (!result[+monthIndex]) {
          result[+monthIndex] = 0;
        }
        const vc = accountMap[+accountNr]?.vatCode;
        const vatRate = vc === null ? 0.25 : vatCodes[vc];
        if (!isNaN(vatRate)) {
          result[+monthIndex] += pbs[monthKey] * vatRate;
        }
      });
    }
  }

  if (delayDays === 0) {
    return result;
  }

  const delayedResult = delay(result, delayDays);

  if (delayDays >= 30) {
    delayedResult[0] = 0;
  }
  if (delayDays >= 60) {
    delayedResult[1] = 0;
  }
  if (delayDays >= 90) {
    delayedResult[2] = 0;
  }
  return delayedResult;
}

function getOutgoingVat({
  periodBalances,
  monthObject,
}: {
  periodBalances: IPeriodBalances;
  monthObject: IKeyString<string>;
}) {
  const result: ILiquidityArray = getEmptyLiquidityArray();
  for (const [accountNr, pbs] of Object.entries(periodBalances)) {
    if (
      (4000 <= +accountNr && +accountNr < 7000) ||
      (7600 <= +accountNr && +accountNr < 7700)
    ) {
      Object.keys(pbs).forEach((monthKey) => {
        const monthIndex = Object.keys(monthObject).find(
          (key) => monthObject[key] === monthKey
        );
        if (!monthIndex) {
          return;
        }
        if (!result[+monthIndex]) {
          result[+monthIndex] = 0;
        }
        result[+monthIndex] += pbs[monthKey] * 0.25;
        if (monthIndex === "0") {
          result[+monthIndex] = 0;
        }
      });
    }
  }
  return result;
}

function getVatDept({
  monthObject,
  vatPeriod,
  vatIncome,
  vatCost,
}: {
  monthObject: IKeyString<string>;
  vatPeriod: vatPeriodTypes;
  vatIncome: ILiquidityArray;
  vatCost: ILiquidityArray;
}) {
  const result = getEmptyLiquidityArray();

  for (const [monthIndexStr, dateString] of Object.entries(monthObject)) {
    const monthIndex = parseInt(monthIndexStr);
    const monthNumber = parseInt(dateString.split("-")[1]);

    switch (vatPeriod) {
      case "YEARLY":
        result[monthIndex] = 0;
        if (monthNumber === 8) {
          for (let i = 0; i < 12; i++) {
            result[monthIndex] +=
              (vatIncome[monthIndex - 2 - i] + vatCost[monthIndex - 2 - i]) *
              -1;
          }
        }
        break;

      case "QUARTERLY":
        result[monthIndex] = 0;
        if ([2, 5, 8, 11].includes(monthNumber)) {
          for (let i = 0; i < 3; i++) {
            result[monthIndex] +=
              (vatIncome[monthIndex - 1 - i] + vatCost[monthIndex - 1 - i]) *
              -1;
          }
        }
        break;

      case "MONTHLY":
      default:
        result[monthIndex + 1] =
          (vatIncome[monthIndex] + vatCost[monthIndex]) * -1;
        break;
    }
  }

  result[0] = 0;
  if (vatPeriod === "QUARTERLY") {
    result[1] = 0;
  }
  return result;
}

function getPersonalCost({
  periodBalances,
  monthObject,
}: {
  periodBalances: IPeriodBalances;
  monthObject: IKeyString<string>;
}) {
  const result = getEmptyLiquidityArray();

  for (const [accountNr, pbs] of Object.entries(periodBalances)) {
    if (+accountNr < 7300 && +accountNr >= 7000) {
      Object.keys(pbs).forEach((monthKey) => {
        const monthIndex = Object.keys(monthObject).find(
          (key) => monthObject[key] === monthKey
        );
        if (!monthIndex) {
          return;
        }
        if (+monthIndex + 1 !== 0) {
          result[+monthIndex + 1] += pbs[monthKey] * 0.3;
        }
        result[+monthIndex] += pbs[monthKey] * 0.7;
      });
    }
    if (+accountNr < 7600 && +accountNr >= 7300) {
      Object.keys(pbs).forEach((monthKey) => {
        const monthIndex = Object.keys(monthObject).find(
          (key) => monthObject[key] === monthKey
        );
        if (!monthIndex) {
          return;
        }
        if (+monthIndex + 1 !== 0) {
          result[+monthIndex + 1] += pbs[monthKey];
        }
      });
    }
  }

  return result;
}

function getClaimIncome({
  periodBalances,
  monthObject,
}: {
  periodBalances: IPeriodBalances;
  monthObject: IKeyString<string>;
}) {
  const result = getEmptyLiquidityArray();

  for (const [accountNr, pbs] of Object.entries(periodBalances)) {
    if ((1500 <= +accountNr && +accountNr < 1700) || +accountNr === 1) {
      Object.keys(pbs).forEach((monthKey) => {
        const monthIndex = Object.keys(monthObject).find(
          (key) => monthObject[key] === monthKey
        );
        if (!monthIndex) {
          return;
        }
        if (!result[+monthIndex]) {
          result[+monthIndex] = 0;
        }
        result[+monthIndex] += pbs[monthKey];
      });
    }
  }
  return result;
}

function getClaimCost({
  periodBalances,
  monthObject,
}: {
  periodBalances: IPeriodBalances;
  monthObject: IKeyString<string>;
}) {
  const result = getEmptyLiquidityArray();

  for (const [accountNr, pbs] of Object.entries(periodBalances)) {
    if ((2200 <= +accountNr && +accountNr < 2900) || +accountNr === 2) {
      Object.keys(pbs).forEach((monthKey) => {
        const monthIndex = Object.keys(monthObject).find(
          (key) => monthObject[key] === monthKey
        );
        if (!monthIndex) {
          return;
        }
        if (!result[+monthIndex]) {
          result[+monthIndex] = 0;
        }
        result[+monthIndex] += pbs[monthKey];
      });
    }
  }

  return result;
}

function getRevenueSum({
  periodBalances,
  monthObject,
  delayDays,
}: {
  periodBalances: IPeriodBalances;
  monthObject: IKeyString<string>;
  delayDays: number;
}) {
  const result = getEmptyLiquidityArray();

  for (const [accountNr, pbs] of Object.entries(periodBalances)) {
    if (3000 <= +accountNr && +accountNr < 4000) {
      Object.keys(pbs).forEach((monthKey) => {
        const monthIndex = Object.keys(monthObject).find(
          (key) => monthObject[key] === monthKey
        );
        if (!monthIndex) {
          return;
        }
        if (!result[+monthIndex]) {
          result[+monthIndex] = 0;
        }
        result[+monthIndex] += pbs[monthKey];
      });
    }
  }

  if (delayDays === 0) {
    return result;
  }

  const delayedResult = delay(result, delayDays);

  if (delayDays >= 30) {
    delayedResult[0] = 0;
  }
  if (delayDays >= 60) {
    delayedResult[1] = 0;
  }
  if (delayDays >= 90) {
    delayedResult[2] = 0;
  }
  return delayedResult;
}

function getFinancialCosts({
  periodBalances,
  monthObject,
}: {
  periodBalances: IPeriodBalances;
  monthObject: IKeyString<string>;
}) {
  const result: ILiquidityArray = getEmptyLiquidityArray();
  for (const [accountNr, pbs] of Object.entries(periodBalances)) {
    if (8000 <= +accountNr && +accountNr < 8500) {
      Object.keys(pbs).forEach((monthKey) => {
        const monthIndex = Object.keys(monthObject).find(
          (key) => monthObject[key] === monthKey
        );
        if (!monthIndex) {
          return;
        }
        if (!result[+monthIndex]) {
          result[+monthIndex] = 0;
        }
        result[+monthIndex] += pbs[monthKey];
      });
    }
  }
  return result;
}

function getCostSum({
  periodBalances,
  monthObject,
}: {
  periodBalances: IPeriodBalances;
  monthObject: IKeyString<string>;
}) {
  const result: ILiquidityArray = getEmptyLiquidityArray();

  for (const [accountNr, pbs] of Object.entries(periodBalances)) {
    if (
      (4000 <= +accountNr && +accountNr < 7000) ||
      (7600 <= +accountNr && +accountNr < 7700) ||
      (8500 <= +accountNr && +accountNr < 8800)
    ) {
      Object.keys(pbs).forEach((monthKey) => {
        const monthIndex = Object.keys(monthObject).find(
          (key) => monthObject[key] === monthKey
        );
        if (!monthIndex) {
          return;
        }
        if (!result[+monthIndex + 1]) {
          result[+monthIndex + 1] = 0;
        }

        result[+monthIndex + 1] += pbs[monthKey];

        if (monthIndex === "-1") {
          result[+monthIndex + 1] = 0;
        }
      });
    }
  }
  return result;
}

function getIncomeSum({
  revenueSum,
  vatIncome,
  claimIncome,
}: {
  revenueSum: ILiquidityArray;
  vatIncome: ILiquidityArray;
  claimIncome: ILiquidityArray;
}) {
  const result: ILiquidityArray = getEmptyLiquidityArray();
  Object.keys(revenueSum).forEach((key) => {
    result[key] = revenueSum[key] + vatIncome[key] + claimIncome[key];
  });
  return result;
}

function getOutgoingSum({
  costSum,
  vatCost,
  personalCost,
  financialCost,
  claimCost,
}: {
  costSum: ILiquidityArray;
  vatCost: ILiquidityArray;
  personalCost: ILiquidityArray;
  financialCost: ILiquidityArray;
  claimCost: ILiquidityArray;
}) {
  const result: ILiquidityArray = getEmptyLiquidityArray();

  Object.keys(costSum).forEach((key) => {
    result[key] =
      costSum[key] +
      vatCost[key] +
      personalCost[key] +
      financialCost[key] +
      claimCost[key];
  });

  return result;
}

function getLiquidityMonthStart({
  initialLiquidity,
  incomeSum,
  outgoingSum,
  vatDept,
}: {
  initialLiquidity: number;
  incomeSum: ILiquidityArray;
  outgoingSum: ILiquidityArray;
  vatDept: ILiquidityArray;
}) {
  const result: ILiquidityArray = getEmptyLiquidityArray();

  Object.keys(result).forEach((month) => {
    if (month === "-1") {
      result[month] = initialLiquidity;
    } else {
      result[+month + 1] =
        incomeSum[+month] + outgoingSum[+month] + vatDept[+month] || 0;
    }
  });
  Object.keys(result).forEach((month) => {
    if (month !== "-1") {
      result[month] += result[+month - 1] || 0;
    }
  });
  return result;
}

function getLiquidityMonthEnd({
  liquidityMonthStart,
  incomeSum,
  outgoingSum,
  vatDept,
}: {
  liquidityMonthStart: ILiquidityArray;
  incomeSum: ILiquidityArray;
  outgoingSum: ILiquidityArray;
  vatDept: ILiquidityArray;
}) {
  const result: ILiquidityArray = getEmptyLiquidityArray();

  Object.keys(result).forEach((month) => {
    result[+month] =
      liquidityMonthStart[+month] +
      incomeSum[+month] +
      outgoingSum[+month] +
      vatDept[+month];
  });

  return result;
}

function getLiquidityDifference({
  liquidityMonthStart,
  liquidityMonthEnd,
}: {
  liquidityMonthStart: ILiquidityArray;
  liquidityMonthEnd: ILiquidityArray;
}) {
  const result: ILiquidityArray = getEmptyLiquidityArray();

  for (const month in result) {
    result[+month] = liquidityMonthEnd[+month] - liquidityMonthStart[+month];
  }

  return result;
}

function getIncome({
  revenueSum,
  vatIncome,
}: {
  revenueSum: ILiquidityArray;
  vatIncome: ILiquidityArray;
}) {
  const result: ILiquidityArray = getEmptyLiquidityArray();

  for (const month in result) {
    result[+month] = revenueSum[+month] + vatIncome[+month];
  }

  return result;
}

function getExpenses({
  costSum,
  vatCost,
}: {
  costSum: ILiquidityArray;
  vatCost: ILiquidityArray;
}) {
  const result: ILiquidityArray = getEmptyLiquidityArray();

  for (const month in result) {
    result[+month] = costSum[+month] + vatCost[+month];
  }

  return result;
}

export function calculateLiquidityReport({
  from,
  to,
  initialLiquidity,
  periodBalances,
  budget,
  vatPeriod,
  delayDays,
  accounts,
}: {
  from: number;
  to: number;
  initialLiquidity: number;
  periodBalances: IPeriodBalances;
  budget: IBudgetPeriod[];
  vatPeriod: vatPeriodTypes;
  delayDays: number;
  accounts: IAccount[];
}) {
  const monthObject = getMonthObject(from);

  const accountMap: Record<number, IAccount> = {};
  for (const account of accounts) {
    accountMap[account.account] = account;
  }

  const budgetPbs = convertBudgetToPeriodBalances(budget);
  Object.keys(budgetPbs).forEach((key) => {
    Object.keys(budgetPbs[key]).forEach((monthKey) => {
      if (!periodBalances[key]) periodBalances[key] = {};
      periodBalances[key][monthKey] = budgetPbs[key][monthKey];
    });
  });

  const vatIncome = getIngoingVat({
    periodBalances,
    monthObject,
    accountMap,
    delayDays,
  });
  const vatCost = getOutgoingVat({ periodBalances, monthObject });
  const vatDept = getVatDept({
    monthObject,
    vatPeriod,
    vatIncome,
    vatCost,
  });

  const claimIncome = getClaimIncome({ periodBalances, monthObject });
  const claimCost = getClaimCost({ periodBalances, monthObject });

  const revenueSum = getRevenueSum({
    periodBalances,
    monthObject,
    delayDays,
  });

  const personalCost = getPersonalCost({ periodBalances, monthObject });
  const financialCost = getFinancialCosts({ periodBalances, monthObject });

  const costSum = getCostSum({ periodBalances, monthObject });
  const incomeSum = getIncomeSum({
    revenueSum,
    vatIncome,
    claimIncome,
  });

  const outgoingSum = getOutgoingSum({
    costSum,
    vatCost,
    personalCost,
    financialCost,
    claimCost,
  });

  const liquidityMonthStart = getLiquidityMonthStart({
    initialLiquidity,
    incomeSum,
    outgoingSum,
    vatDept,
  });

  const liquidityMonthEnd = getLiquidityMonthEnd({
    liquidityMonthStart,
    incomeSum,
    outgoingSum,
    vatDept,
  });

  const liquidityDifference = getLiquidityDifference({
    liquidityMonthStart,
    liquidityMonthEnd,
  });

  const income = getIncome({
    revenueSum,
    vatIncome,
  });

  const expenses = getExpenses({
    costSum,
    vatCost,
  });

  const result: ILiquidityReport = {
    vatDept,
    personalCost,
    claimIncome,
    claimCost,
    initialLiquidity,
    income,
    expenses,
    liquidityMonthStart,
    liquidityMonthEnd,
    liquidityDifference,
  };

  return result;
}
