import { fromUnixTime, isSameMonth } from "date-fns";
import { ECONOMIC_TERM } from "../../../shared/enums";
import {
  getReportTextColor,
  getResultRowHybridSum,
  getResultRowSum,
} from "../../../shared/reportUtility";
import {
  convertToNumberWithSpaces,
  formatKeyForCompare,
  isDateAfter,
  stringToDate,
} from "../../../shared/utility";
import { IAccount, IResultReportSubrow } from "../../../types/api";
import { RowType } from "../../../types/internal";
import { CompareWith } from "../../../types/redux";
import ResultReportCell from "../ResultReportCell/ResultReportCell";
import "./ResultReportSubrow.scss";

interface IResultReportSubrowProps {
  title: string;
  rowType: RowType;
  row: IResultReportSubrow;
  compareRow?: IResultReportSubrow;
  monthsArray: string[];
  breakUnix: number;
  compareWith: CompareWith;
  accountRecord?: Record<number, IAccount>;
}

function ResultReportSubrow(props: IResultReportSubrowProps) {
  const rowSum = getResultRowSum(props.row, props.breakUnix);
  const compareRowSum = getResultRowSum(props.compareRow || {});

  const hybridSum = getResultRowHybridSum({
    row: props.row,
    compareRow: props.compareRow || {},
    compareWith: props.compareWith,
    breakUnix: props.breakUnix,
    monthsArray: props.monthsArray,
  });

  const showSpan =
    props.row[ECONOMIC_TERM.Interval][0] !==
    props.row[ECONOMIC_TERM.Interval][1];

  return (
    <>
      <tr
        className="result-report-subrow-title"
        style={{
          backgroundColor: "#EFEFFF",
        }}
      >
        <td className="text-s-r" colSpan={100}>
          <div className="title-wrapper">
            {showSpan && (
              <span>{props.row[ECONOMIC_TERM.Interval].join("-")}</span>
            )}
            <span>{props.title}</span>
            {props.accountRecord && !isNaN(+props.title) && (
              <span>
                {props.accountRecord[+props.title].description ||
                  props.accountRecord[+props.title].name}
              </span>
            )}
            <span
              className="hybrid-sum text-xs-m"
              style={{
                color: getReportTextColor(props.rowType),
              }}
            >
              {convertToNumberWithSpaces(hybridSum)}
            </span>
          </div>
        </td>
      </tr>
      <tr className="result-report-subrow">
        {props.monthsArray.map((mKey) => (
          <ResultReportCell
            key={mKey}
            value={props.row[mKey] || 0}
            compareValue={
              props.compareRow?.[
                formatKeyForCompare(mKey, props.compareWith === "budget")
              ] || 0
            }
            isAfterBreak={isDateAfter(
              stringToDate(mKey),
              fromUnixTime(props.breakUnix)
            )}
            isBreakMonth={isSameMonth(
              stringToDate(mKey),
              fromUnixTime(props.breakUnix)
            )}
            rowType={props.rowType}
          />
        ))}
        <ResultReportCell
          value={rowSum}
          compareValue={compareRowSum}
          rowType={props.rowType}
        />
      </tr>
    </>
  );
}

export default ResultReportSubrow;
