import { forwardRef, Ref } from "react";
import "./Input.scss";

interface InputProps {
  type: "text" | "password" | "email" | "number" | "phone" | "color";
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  label?: string;
  short?: boolean;
  minMax?: { min: number; max: number };
  width?: string;
  disabled?: boolean;
  autoComplete?: "off" | "new-password";
  onFocus?: () => void;
  transparent?: boolean;
}

const Input = forwardRef((props: InputProps, ref: Ref<HTMLInputElement>) => {
  const inputWrapperClasses = ["input-wrapper", "text-m-r"];
  if (props.short) {
    inputWrapperClasses.push("short");
  }

  const inputClasses = ["input", "text-m-r"];
  if (props.className) {
    inputClasses.push(props.className);
  }
  if (props.short) {
    inputClasses.push("short");
  }
  if (props.transparent) {
    inputClasses.push("transparent");
  }

  return (
    <label
      className={inputWrapperClasses.join(" ")}
      style={{
        width: props.width,
      }}
    >
      {props.label}
      <input
        ref={ref}
        className={inputClasses.join(" ")}
        placeholder={props.placeholder}
        type={props.type}
        onChange={({ target: { value } }) => props.onChange(value)}
        min={props.minMax?.min}
        max={props.minMax?.max}
        value={props.value}
        disabled={props.disabled}
        autoComplete={props.autoComplete}
        onFocus={props.onFocus}
      />
    </label>
  );
});

export default Input;
