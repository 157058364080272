import OnbordingLayout from "../../hoc/OnbordingLayout/OnbordingLayout";
import Button from "../UI/Button/Button";
import "./BackendIsDown.scss";

interface IBackendIsDownProps {}

const BackendIsDown = (props: IBackendIsDownProps) => {
  return (
    <OnbordingLayout>
      <p className="backend-is-down--title space-grotesk">
        Ingen koppling till server
      </p>
      <p className="backend-is-down--sub-title">
        Kolla din internetuppkoppling och försök igen.
      </p>
      <div className="backend-is-down--buttons">
        <a href="https://www.nimya.io/">
          <Button color="black" label="Till hemsidan" />
        </a>
        <Button
          color="white"
          label="Ladda om Nimya"
          onClick={() => window.location.reload()}
        />
      </div>
    </OnbordingLayout>
  );
};

export default BackendIsDown;
