import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createCustomVariable } from "../../../api/report";
import VariableInformation from "../../../hoc/VariableInformation/VariableInformation";
import { API_MESSAGE } from "../../../shared/enums";
import { IFormulaItem } from "../../../types/internal";
import { IReduxState } from "../../../types/redux";
import Button from "../../UI/Button/Button";
import Input from "../../UI/Input/Input";

import "./CustomNumber.scss";

interface ICustomNumberProps {
  onAddVariable: (fItem: IFormulaItem) => void;
  onNewVariable: (variableId: string) => void;
}

function CustomNumber(props: ICustomNumberProps) {
  const { companyId } = useSelector((state: IReduxState) => state.company);

  const [value, setValue] = useState("");
  const [variableName, setVariableName] = useState("");
  const [isCreating, setIsCreating] = useState(false);

  function createVariableHandler() {
    if (isNaN(+value)) {
      toast.error("Värdet måste vara ett nummer");
      return;
    }

    if (!variableName) {
      toast.error("Variabelnamnet får inte vara tomt");
      return;
    }
    setIsCreating(true);
    createCustomVariable({
      companyId,
      variableString: `${value}`,
      name: variableName,
      isPercent: false,
      showInTable: false,
    })
      .then((res) => {
        setIsCreating(false);
        toast.success("Variabel skapad");
        props.onNewVariable(res.data.payload.variableId);
      })
      .catch((e) => {
        setIsCreating(false);
        if (e.response.data.message === API_MESSAGE.InvalidVariableName) {
          toast.error(
            "Variabelnamnet får endast innehålla alfanumeriska tecken och mellanslag"
          );
          return;
        }
        toast.error("Något gick fel");
      });
  }

  return (
    <VariableInformation
      type="Nummer"
      name="Räkna med ett nummer"
      description=""
      formulaTitle="Välj nummer"
    >
      <form
        className="custom-number"
        onSubmit={(e) => {
          e.preventDefault();
          if (isNaN(+value)) return;
          props.onAddVariable({
            value: `${value}`,
            type: "number",
          });
        }}
      >
        <Input
          type="text"
          value={value}
          onChange={setValue}
          width="90px"
          short
        />
        <Button color="black" label="Lägg till" short />
      </form>
      <form
        className="custom-number"
        onSubmit={(e) => {
          e.preventDefault();
          createVariableHandler();
        }}
      >
        <Input
          type="text"
          value={variableName}
          onChange={(v) => {
            setVariableName(v);
          }}
          placeholder="Variabelnamn"
          short
        />
        <Button
          color="black"
          label="Skapa variabel"
          isLoading={isCreating}
          short
        />
      </form>
    </VariableInformation>
  );
}

export default CustomNumber;
