import { fromUnixTime, getUnixTime, lightFormat } from "date-fns";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getRemainingTtl } from "../../api/auth";
import { resendVerificationEmail } from "../../api/company";
import Button from "../../components/UI/Button/Button";
import Spinner from "../../components/UI/Spinner/Spinner";
import "./ResendUserVerification.scss";

interface IResendUserVerificationProps {
  email: string;
  companyId: string;
  companyName: string;
}

function ResendUserVerification(props: IResendUserVerificationProps) {
  const [expireUnix, setExpireUnix] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    getRemainingTtl(props.email).then((res) => {
      setExpireUnix(getUnixTime(new Date()) + res.data.payload.remainingTtl);
      setIsLoading(false);
    });
  }, [props.email]);

  async function resendEmailHandler(email: string) {
    setIsSending(true);

    try {
      await resendVerificationEmail({
        email,
        companyId: props.companyId,
        companyName: props.companyName,
      });

      const res = await getRemainingTtl(email);
      toast.success("Verifieringsmail skickat");
      setExpireUnix(getUnixTime(new Date()) + res.data.payload.remainingTtl);
      setIsSending(false);
    } catch (error) {
      toast.error("Kunde inte skicka e-post");
      setIsLoading(false);
    }
  }

  const expireDate = fromUnixTime(expireUnix);
  const expireString = lightFormat(expireDate, "yyyy-M-d HH:mm");

  const nowUnix = getUnixTime(new Date());

  return (
    <div className="resend-user-verification">
      <div className="unverified-user-clock">
        {isLoading ? (
          <Spinner />
        ) : (
          <p>
            {expireUnix > nowUnix ? `${expireString}` : "Inbjudan har gått ut"}
          </p>
        )}
      </div>
      <Button
        color="black"
        label="Skicka igen"
        short
        onClick={() => resendEmailHandler(props.email)}
        isLoading={isSending}
      />
    </div>
  );
}

export default ResendUserVerification;
