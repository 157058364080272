import { eachMonthOfInterval, fromUnixTime, lightFormat } from "date-fns";
import clone from "../../shared/clone";
import { months } from "../../shared/values";
import { IAccount, IPeriodBalances } from "../../types/api";
import "./PaymentSheet.scss";
import PaymentSheetRow from "./PaymentSheetRow/PaymentSheetRow";

interface IPaymentSheetProps {
  title: string;
  from: number;
  to: number;
  onEdit: (
    pb: {
      month: number;
      year: number;
      accountNr: number;
      value: number;
    },
    title: string
  ) => void;
  periodBalances: IPeriodBalances;
  budgetPbs: IPeriodBalances;
  accounts: IAccount[];
  accountRange: {
    from: number;
    to: number;
  };
  type: "claims" | "payments" | "manual";
  manualRows?: {
    title: string;
    balance: number;
    accountNr: number;
    values: number[];
  }[];
}

function PaymentSheet(props: IPaymentSheetProps) {
  const dates = eachMonthOfInterval({
    start: fromUnixTime(props.from),
    end: fromUnixTime(props.to),
  }).map((d) => lightFormat(d, "yyyy-M"));

  const periodBalances = clone(props.periodBalances);
  Object.keys(props.budgetPbs).forEach((key) => {
    Object.keys(props.budgetPbs[key]).forEach((mKey) => {
      if (!periodBalances[key]) periodBalances[key] = {};
      periodBalances[key][mKey] = props.budgetPbs[key][mKey];
    });
  });

  const accountMap: Record<number, IAccount> = {};
  for (const account of props.accounts) {
    accountMap[account.account] = account;
  }

  let rows = Object.keys(periodBalances)
    .filter(
      (key) =>
        +key >= props.accountRange.from &&
        +key < props.accountRange.to &&
        Math.round(periodBalances[key].UB) !== 0
    )
    .map((key) => ({
      title: accountMap[+key]?.name || accountMap[+key]?.description || key,
      balance: periodBalances[key].UB,
      accountNr: +key,
      values: dates.map((mKey) => periodBalances[key]?.[mKey] || 0),
    }));

  if (props.manualRows) {
    rows = props.manualRows;
  }

  const rowBackgrounds = {
    claims: "#E8CCFF",
    payments: "#FFCCCC",
    manual: "#EFEFFF",
  };

  return (
    <div className="payment-sheet">
      <div className="top">
        <p className="title text-m-m">{props.title}</p>
      </div>
      <table>
        <thead>
          <tr>
            {dates.map((mKey) => (
              <th key={mKey} className="text-m-m">
                {months[+mKey.split("-")[1] - 1].label.slice(0, 3)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <PaymentSheetRow
              key={row.accountNr}
              title={row.title}
              value={row.balance}
              accountNr={row.accountNr}
              values={row.values}
              onEdit={props.onEdit}
              dates={dates}
              backgroundColor={rowBackgrounds[props.type]}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PaymentSheet;
