import Checkbox from "../../../components/UI/Checkbox/Checkbox";
import "./Tag.scss";

interface ITagProps {
  name: string;
  isActive: boolean;
  onCheck: (checked: boolean) => void;
  onDelete: () => void;
}

function Tag(props: ITagProps) {
  return (
    <div
      className="tag text-m-r"
      onClick={() => props.onCheck(!props.isActive)}
    >
      <span>{`# ${props.name}`}</span>
      <i
        className="fa-light fa-trash-can"
        onClick={(e) => {
          e.stopPropagation();
          props.onDelete();
        }}
      />
      <Checkbox checked={props.isActive} theme="dark" short />
    </div>
  );
}

export default Tag;
