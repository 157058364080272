import { useEffect, useState } from "react";
import { convertToNumberWithSpaces } from "../../../shared/utility";
import "./PaymentSheetInput.scss";

interface IPaymentSheetInputProps {
  onEdit: (value: number) => void;
  value: number;
}

function PaymentSheetInput(props: IPaymentSheetInputProps) {
  const [value, setValue] = useState((props.value || 0).toString());
  const [isFocusing, setIsFocusing] = useState(false);

  useEffect(() => {
    setValue(props.value.toString());
  }, [props.value]);

  function inputHandler(changedValue: string) {
    if (+changedValue > 1000000000000000) {
      changedValue = "1000000000000000";
    }
    if (+changedValue < -100000000000000) {
      changedValue = "-100000000000000";
    }
    setValue(changedValue);
    if (!isNaN(+changedValue)) {
      props.onEdit(+changedValue);
    }
  }

  return (
    <td className="payment-sheet-input text-s-r">
      <input
        value={
          !isFocusing && !isNaN(+value)
            ? convertToNumberWithSpaces(+value)
            : value
        }
        onChange={(e) => inputHandler(e.target.value)}
        onFocus={() => {
          setIsFocusing(true);
          if (value === "0") {
            setValue("");
          }
        }}
        onBlur={() => setIsFocusing(false)}
      />
    </td>
  );
}

export default PaymentSheetInput;
