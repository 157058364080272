import { convertToNumberWithSpaces } from "../../../shared/utility";
import "./CashflowReportRow.scss";

interface ICashflowReportRowProps {
  title: string;
  ib?: number;
  ub?: number;
  extraValue?: number;
  result: number;
  icon: "plus" | "minus" | "equal" | "indiffer";
  isResult?: boolean;
  isBold?: boolean;
}

function CashflowReportRow(props: ICashflowReportRowProps) {
  const rowClasses = ["cashflow-report-row"];

  if (props.isResult) {
    rowClasses.push("result-row");
  }
  if (props.isBold) {
    rowClasses.push("text-s-m");
  } else {
    rowClasses.push("text-s-r");
  }

  let icon;
  switch (props.icon) {
    case "plus":
      icon = "+";
      break;
    case "minus":
      icon = "-";
      break;
    case "equal":
      icon = "=";
      break;
    case "indiffer":
      icon = "+/-";
      break;
  }
  return (
    <tr className={rowClasses.join(" ")}>
      <td className="title text-m-m">{props.title}</td>
      <td>
        {props.ib !== undefined ? convertToNumberWithSpaces(props.ib) : ""}
      </td>
      <td>
        {props.ub !== undefined ? convertToNumberWithSpaces(props.ub) : ""}
      </td>
      <td>
        {props.extraValue !== undefined
          ? convertToNumberWithSpaces(props.extraValue)
          : ""}
      </td>
      <td>{icon}</td>
      <td>{convertToNumberWithSpaces(props.result)}</td>
    </tr>
  );
}

export default CashflowReportRow;
