import { getUnixTime, startOfMonth, endOfMonth, subMonths } from "date-fns";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getCompanySettings,
  getTags,
  updateCompanySettings,
} from "../../api/company";
import {
  deleteCustomVariable,
  generateEconomicAlerts,
  getCustomAlerts,
} from "../../api/report";
import AlertSection from "../../components/AlertSection/AlertSection";
import Button from "../../components/UI/Button/Button";
import Spinner from "../../components/UI/Spinner/Spinner";
import CustomAlertPopup from "../../containers/CustomAlertPopup/CustomAlertPopup";
import { ROUTE } from "../../shared/enums";
import { useCounselingValues } from "../../shared/hooks/useCounselingValues";
import { nimyaAlert } from "../../shared/nimyaAlert";
import { setCompanySettings } from "../../store/slices/company";
import { IAlert, IVariable } from "../../types/api";
import { AlertTimePeriod, IReduxState } from "../../types/redux";
import "./AlertSettings.scss";

interface IAlertSettingsProps {}

function AlertSettings(props: IAlertSettingsProps) {
  const {
    company: { companyId, settings },
    extendedFiltering: { alertTimePeriod },
  } = useSelector((state: IReduxState) => state);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setControlsComponent } = useCounselingValues();

  const [isAlertPopupOpen, setIsAlertPopupOpen] = useState(false);
  const [editAlert, setEditAlert] = useState<IAlert | undefined>(undefined);

  useEffect(() => {
    setControlsComponent(
      <Button
        label="Skapa ny notis"
        color="black"
        onClick={() => setIsAlertPopupOpen(true)}
      />
    );
  }, [setControlsComponent]);

  const {
    data: alertVariables,
    isSuccess: isVariablesSuccess,
    refetch: refetchVariables,
  } = useQuery({
    queryKey: ["alert-variables", companyId],
    queryFn: async ({ queryKey: [, companyId] }) => {
      const res = await getCustomAlerts(companyId);
      return res.data.payload.variables;
    },
  });

  const {
    data: tags,
    isLoading: isTagsLoading,
    isSuccess: isTagsSuccess,
    refetch: refetchTags,
  } = useQuery({
    queryKey: ["custom-tags", companyId],
    queryFn: async ({ queryKey: [, companyId] }) => {
      const res = await getTags(companyId);
      return res.data.payload.tags;
    },
  });

  const toMonth = `${subMonths(new Date(), 1).getMonth() + 1}`;
  const toYear = new Date().getFullYear().toString();
  const fromMonth = `${subMonths(new Date(), 1).getMonth() + 1}`;
  const fromYear = new Date().getFullYear().toString();

  const {
    data: alerts,
    isLoading: isAlertsLoading,
    isSuccess: isAlertsSuccess,
    refetch: refetchAlerts,
  } = useQuery<
    IAlert[],
    unknown,
    IAlert[],
    [
      "alerts",
      string,
      IVariable[] | undefined,
      string,
      string,
      string,
      string,
      AlertTimePeriod
    ]
  >({
    queryKey: [
      "alerts",
      companyId,
      alertVariables,
      toMonth,
      toYear,
      fromMonth,
      fromYear,
      alertTimePeriod,
    ],
    queryFn: async ({
      queryKey: [
        ,
        companyId,
        variables,
        toMonth,
        toYear,
        fromMonth,
        fromYear,
        alertTimePeriod,
      ],
    }) => {
      let from = getUnixTime(startOfMonth(new Date(`${toYear}-${toMonth}`)));
      const to = getUnixTime(endOfMonth(new Date(`${toYear}-${toMonth}`)));

      if (alertTimePeriod === "custom") {
        from = getUnixTime(startOfMonth(new Date(`${fromYear}-${fromMonth}`)));
      }

      const res = await generateEconomicAlerts({
        companyId,
        from,
        to,
        variables: variables || [],
      });

      return res;
    },
    enabled: isVariablesSuccess,
  });

  async function editAlertHandler(alert: IAlert) {
    setEditAlert(alert);
    setIsAlertPopupOpen(true);
  }

  async function deleteAlertHandler(alert: IAlert) {
    if (
      !(await nimyaAlert({
        title: "Ta bort notis",
        message: `Är du säker på att du vill ta bort ${alert.name}? (Detta går inte att ångra)`,
      }))
    )
      return;

    try {
      await deleteCustomVariable({
        companyId,
        variableId: alert._id,
        isAlert: true,
      });
      toast.success("Notiser togs bort");
      await refetchVariables();
    } catch (err) {
      toast.error("Något gick fel när notisen skulle tas bort");
    }
  }

  async function toggleAlertHandler(alert: IAlert, shouldActivate: boolean) {
    const newDisabledAlerts = shouldActivate
      ? settings.disabledAlerts.filter((aId) => alert._id !== aId)
      : [...settings.disabledAlerts, alert._id];

    try {
      await updateCompanySettings({
        companyId,
        data: {
          disabledAlerts: newDisabledAlerts,
        },
      });
      const settingsRes = await getCompanySettings(companyId);
      dispatch(setCompanySettings(settingsRes.data.payload));
    } catch (error) {
      toast.error("Något gick fel");
    }
  }

  async function refetchData() {
    await Promise.all([refetchVariables(), refetchTags(), refetchAlerts()]);
  }

  return (
    <div className="alert-settings">
      <div className="content">
        {(isTagsLoading || isAlertsLoading) && <Spinner />}
        {isTagsSuccess && isAlertsSuccess && (
          <>
            {tags.map((t) => (
              <AlertSection
                key={t._id}
                title={t.name}
                alerts={alerts.filter((a) => a.tags.includes(t.name))}
                onAlertSelected={() => {}}
                selectedAlertIds={[]}
                onEdit={editAlertHandler}
                onDelete={deleteAlertHandler}
                onToggleAlert={toggleAlertHandler}
                editMode={true}
              />
            ))}
            <AlertSection
              title="Övriga"
              alerts={alerts.filter((a) => a.tags.length === 0)}
              onAlertSelected={() => {}}
              selectedAlertIds={[]}
              onEdit={editAlertHandler}
              onDelete={deleteAlertHandler}
              onToggleAlert={toggleAlertHandler}
              editMode={true}
            />
          </>
        )}
      </div>
      <CustomAlertPopup
        showPopup={isAlertPopupOpen}
        onClose={(hasCreated) => {
          setIsAlertPopupOpen(false);
          setEditAlert(undefined);
          if (hasCreated) {
            refetchData().then(() => navigate(ROUTE.Alerts));
          }
        }}
        editAlert={editAlert}
      />
    </div>
  );
}

export default AlertSettings;
