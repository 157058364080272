import { eachMonthOfInterval, fromUnixTime, lightFormat } from "date-fns";
import { getReportTextColor } from "../../shared/reportUtility";
import { months } from "../../shared/values";
import {
  IAccount,
  IResultReport,
  IResultReportRow,
  IResultReportSubrow,
} from "../../types/api";
import { CompareWith } from "../../types/redux";
import LegendText from "../LegendText/LegendText";
import ResultReportRow from "./ResultReportRow/ResultReportRow";
import "./ResultsReport.scss";

interface IResultsReportProps {
  from: number;
  to: number;
  breakUnix: number;
  report: IResultReport;
  compareReport: IResultReport;
  compareWith: CompareWith;
  accounts?: IAccount[];
}

function ResultsReport(props: IResultsReportProps) {
  //Array with months as 2020-1, 2020-2, ..., 2020-11, etc.
  const monthsArray = eachMonthOfInterval({
    start: fromUnixTime(props.from),
    end: fromUnixTime(props.to),
  }).map((d) => lightFormat(d, "yyyy-M"));

  const accountRecord: Record<number, IAccount> = {};
  if (props.accounts) {
    for (let i = 0; i < props.accounts.length; i++) {
      const account = props.accounts[i];
      accountRecord[account.account] = account;
    }
  }

  function getResultRow(report: IResultReport) {
    const resultRowData: IResultReportSubrow = {};

    Object.keys(report).forEach((key) => {
      Object.keys(report[key].Summa).forEach((mKey) => {
        if (!resultRowData[mKey]) resultRowData[mKey] = 0;
        resultRowData[mKey] += report[key].Summa[mKey];
      });
    });

    const resultRow: IResultReportRow = { Summa: resultRowData };
    return resultRow;
  }

  function getExpensesRow(report: IResultReport) {
    const expensesRowData: IResultReportSubrow = {};

    Object.keys(report).forEach((key) => {
      if (key === "Intäkter") return;
      Object.keys(report[key].Summa).forEach((mKey) => {
        if (!expensesRowData[mKey]) expensesRowData[mKey] = 0;
        expensesRowData[mKey] += report[key].Summa[mKey];
      });
    });
    const expensesRow: IResultReportRow = {
      Summa: { ...expensesRowData },
    };
    return expensesRow;
  }

  const spannText = `(${months[fromUnixTime(props.from).getMonth()].label.slice(
    0,
    3
  )} - ${months[fromUnixTime(props.breakUnix).getMonth()].label.slice(0, 3)})`;

  const hasForecast = props.breakUnix !== props.to;

  const forecastSpannText = `(${months[
    (fromUnixTime(props.breakUnix).getMonth() + 1) % 12
  ].label.slice(0, 3)} - ${months[
    fromUnixTime(props.to).getMonth()
  ].label.slice(0, 3)})`;

  return (
    <div className="results-report">
      <div className="top">
        <span className="title text-m-m">Resultatrapport</span>
        <div className="legend-wrapper">
          <LegendText label={`Verklighet ${spannText}`} color="#000D33" />
          <LegendText
            label={`${
              props.compareWith === "budget" ? "Budget" : "Föregående år"
            } ${spannText}`}
            color="#818094"
          />
        </div>
        <div className="legend-wrapper">
          {hasForecast && (
            <LegendText
              label={`Prognos ${forecastSpannText}`}
              color="#818094"
              noDot
            />
          )}
          <LegendText label="Intäkter" color={getReportTextColor("income")} />
          <LegendText label="Kostnader" color={getReportTextColor("expense")} />
          <LegendText label="Resultat" color={getReportTextColor("result")} />
        </div>
      </div>
      <div className="report-wrapper">
        <table>
          <thead>
            <tr>
              {monthsArray.map((mKey) => (
                <th key={mKey} className="text-m-m">
                  {months[+mKey.split("-")[1] - 1].label.slice(0, 3)}
                </th>
              ))}
              <th className="text-m-m">Sum</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(props.report).map((key) => (
              <ResultReportRow
                key={key}
                title={key}
                rowType={key === "Intäkter" ? "income" : "expense"}
                row={props.report[key]}
                compareRow={props.compareReport[key]}
                monthsArray={monthsArray}
                breakUnix={props.breakUnix}
                compareWith={props.compareWith}
                accountRecord={accountRecord}
              />
            ))}
            <ResultReportRow
              title="Summa kostnader"
              rowType="expense"
              row={getExpensesRow(props.report)}
              compareRow={getExpensesRow(props.compareReport)}
              monthsArray={monthsArray}
              breakUnix={props.breakUnix}
              noSubrows
              compareWith={props.compareWith}
            />
            <ResultReportRow
              title="Resultat"
              rowType="result"
              row={getResultRow(props.report)}
              compareRow={getResultRow(props.compareReport)}
              monthsArray={monthsArray}
              breakUnix={props.breakUnix}
              noSubrows
              compareWith={props.compareWith}
            />
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ResultsReport;
