import axios from "../shared/axios";
import { IApiResponse, IBudgetPeriod } from "../types/api";

const PREFIX = "/budget";

export function getBudget({
  fromMonth,
  fromYear,
  toMonth,
  toYear,
  companyId,
}: {
  fromMonth: string;
  fromYear: string;
  toMonth: string;
  toYear: string;
  companyId: string;
}) {
  return axios.get<IApiResponse<IBudgetPeriod[]>>(
    `${PREFIX}/budget/${companyId}`,
    {
      params: {
        fromMonth,
        fromYear,
        toMonth,
        toYear,
      },
    }
  );
}

export function updateBudget({
  companyId,
  budgetPeriods,
}: {
  companyId: string;
  budgetPeriods: IBudgetPeriod[];
}) {
  return axios.post<IApiResponse<[]>>(
    `${PREFIX}/budget/${companyId}`,
    budgetPeriods
  );
}
