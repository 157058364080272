import Chart from "chart.js/auto";
import { useCallback, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import { getCompanies } from "./api/company";
import { getConsultingAgencies } from "./api/consult";
import { getUnreadComments } from "./api/notice";
import BackendIsDown from "./components/BackendIsDown/BackendIsDown";
import DownForMaintenance from "./components/DownForMaintenance/DownForMaintenance";
import ErrorComponent from "./components/ErrorComponent/ErrorComponent";
import Spinner from "./components/UI/Spinner/Spinner";
import Layout from "./hoc/Layout/Layout";
import NonAuthLayout from "./hoc/NonAuthLayout/NonAuthLayout";
import AccountingFirmCustomers from "./pages/AccountingFirmCustomers/AccountingFirmCustomers";
import AccountingFirmEmployees from "./pages/AccountingFirmEmployees/AccountingFirmEmployees";
import AccountingFirmGroups from "./pages/AccountingFirmGroups/AccountingFirmGroups";
import AccountingFirmProfile from "./pages/AccountingFirmProfile/AccountingFirmProfile";
import AccountingFirmSettings from "./pages/AccountingFirmSettings/AccountingFirmSettings";
import Admin from "./pages/Admin/Admin";
import AdminAccountingFirms from "./pages/AdminAccountingFirms/AdminAccountingFirms";
import AdminLogin from "./pages/AdminLogin/AdminLogin";
import AdminUsers from "./pages/AdminUsers/AdminUsers";
import Alerts from "./pages/Alerts/Alerts";
import AlertSettings from "./pages/AlertSettings/AlertSettings";
import Balance from "./pages/Balance/Balance";
import Budget from "./pages/Budget/Budget";
import Cashflow from "./pages/Cashflow/Cashflow";
import CompanySettings from "./pages/CompanySettings/CompanySettings";
import ConcernSettings from "./pages/ConcernSettings/ConcernSettings";
import Counseling from "./pages/Counseling/Counseling";
import FirstCompany from "./pages/FirstCompany/FirstCompany";
import Forecast from "./pages/Forecast/Forecast";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Future from "./pages/Future/Future";
import GroupSettings from "./pages/GroupSettings/GroupSettings";
import InitialSetup from "./pages/InitialSetup/InitialSetup";
import Insights from "./pages/Insights/Insights";
import KeyValues from "./pages/KeyValues/KeyValues";
import Login from "./pages/Login/Login";
import OAuthLoading from "./pages/OAuthLoading/OAuthLoading";
import Overview from "./pages/Overview/Overview";
import Reports from "./pages/Reports/Reports";
import Result from "./pages/Result/Result";
import Settings from "./pages/Settings/Settings";
import Signup from "./pages/Signup/Signup";
import VerifyPage from "./pages/VerifyPage/VerifyPage";
import Vouchers from "./pages/Vouchers/Vouchers";
import {
  API_MESSAGE,
  LOCAL_STORAGE_KEY,
  QUERY_PARAM,
  ROUTE,
} from "./shared/enums";
import useAnalytics from "./shared/hooks/useAnalytics";
import { useSearchQuery } from "./shared/hooks/useSearchQuery";
import { authSetRedirectPath } from "./store/slices/auth";
import { setCompanies, setCompanyLoading } from "./store/slices/company";
import { setAgency, setConsults } from "./store/slices/consult";
import { setUndreadMessages } from "./store/slices/message";
import { checkAuthState } from "./store/thunks/auth";
import { setCompany } from "./store/thunks/company";
import { ICompany } from "./types/api";
import { IReduxState } from "./types/redux";
import Health from "./pages/Health/Health";

function App() {
  Chart.register();

  const query = useSearchQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useAnalytics();

  const {
    auth: { accessToken, authRedirectPath, isBackendDown, adminAccessToken },
    company: { companies },
    extendedFiltering,
  } = useSelector((state: IReduxState) => state);

  const [isLoading, setIsLoading] = useState(true);
  const [hasRedirected, setHasRedirected] = useState(false);
  const [fatalError, setFatalError] = useState<Error>();

  const initialCompanySetup = useCallback(async () => {
    const companyIdQuery = query.get(QUERY_PARAM.CompanyId);

    setIsLoading(true);

    let companies: ICompany[] = [];

    try {
      const res = await getCompanies();
      companies = res.data.payload;
    } catch (error: any) {
      if (error.response.data.message !== API_MESSAGE.FailedToValidateToken) {
        toast.error(
          "Fel vid inhämtningen av företag. Vänligen uppdatera sidan"
        );
      }
    }

    if (companies.length === 0) {
      dispatch(setCompanyLoading(false));
      setIsLoading(false);
      return;
    }

    let companyId = companyIdQuery
      ? companyIdQuery
      : localStorage.getItem(LOCAL_STORAGE_KEY.CompanyId);

    const exists = companies.some((c) => c.companyId === companyId);

    if (!exists || !companyId) {
      companyId = companies[0].companyId;
    }

    dispatch(
      setCompanies(companies.sort((a, b) => a.name.localeCompare(b.name)))
    );

    try {
      await setCompany(companyId);
    } catch (error) {
      setIsLoading(false);
      dispatch(setCompanyLoading(false));
      return;
    }

    setIsLoading(false);
  }, [dispatch, query]);

  const initialConsultSetup = useCallback(async () => {
    const {
      data: { payload: consults },
    } = await getConsultingAgencies();

    dispatch(setConsults(consults));

    if (consults[0]) {
      dispatch(setAgency(consults[0]));
    }
  }, [dispatch]);

  const setCompanyFromQuery = useCallback(async () => {
    const companyIdQuery = query.get(QUERY_PARAM.CompanyId);
    if (!companyIdQuery) return;
    const exist = companies.some((c) => c.companyId === companyIdQuery);
    if (exist) await setCompany(companyIdQuery);
  }, [companies, query]);

  const checkUnreadMessages = useCallback(async () => {
    const res = await getUnreadComments();

    if (res.data.message === API_MESSAGE.NoNotificationsFound) {
      dispatch(setUndreadMessages([]));
    } else {
      dispatch(setUndreadMessages(res.data.payload));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(authSetRedirectPath(location.pathname + location.search));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_KEY.ExtendedFilter,
      JSON.stringify(extendedFiltering)
    );
  }, [extendedFiltering]);

  useEffect(() => {
    if (!accessToken || isLoading || hasRedirected) return;
    navigate(authRedirectPath);
    setHasRedirected(true);
  }, [accessToken, authRedirectPath, hasRedirected, isLoading, navigate]);

  useEffect(() => {
    checkAuthState();

    if (!accessToken) return;

    initialCompanySetup();

    initialConsultSetup();

    const unreadInterval = setInterval(checkUnreadMessages, 60 * 1000);

    return () => clearInterval(unreadInterval);
  }, [
    accessToken,
    initialCompanySetup,
    initialConsultSetup,
    checkUnreadMessages,
  ]);

  useEffect(() => {
    if (!accessToken) return;

    setCompanyFromQuery();
  }, [accessToken, setCompanyFromQuery]);

  const isLoggedin = !!accessToken && !isLoading;
  const hasNoCompanies = !companies.length && isLoggedin;
  const isLoggedOut =
    !accessToken && !localStorage.getItem(LOCAL_STORAGE_KEY.Token);
  const isLoggedInAsAdmin = !!adminAccessToken;
  const downForMaintenance = false;

  let routes = (
    <div className="app-loading">
      <Spinner padding="15px" />
    </div>
  );

  if (isLoggedin) {
    routes = (
      <Layout>
        <ErrorBoundary
          fallback={<ErrorComponent error={fatalError} />}
          onError={(e) => setFatalError(e)}
        >
          <Routes>
            <Route path="*" element={<Navigate replace to={ROUTE.Index} />} />
            <Route path={ROUTE.Counseling} element={<Counseling />}>
              <Route
                path={ROUTE.Counseling}
                element={<Navigate replace to={ROUTE.Alerts} />}
              />
              <Route path={ROUTE.Alerts} element={<Alerts />} />
              <Route path={ROUTE.AlertSettings} element={<AlertSettings />} />
              <Route path={ROUTE.Insights} element={<Insights />} />
            </Route>
            <Route path={ROUTE.Reports} element={<Reports />}>
              <Route
                path={ROUTE.Reports}
                element={<Navigate replace to={ROUTE.Result} />}
              />
              <Route path={ROUTE.Result} element={<Result />} />
              <Route path={ROUTE.Balance} element={<Balance />} />
              <Route path={ROUTE.CashFlow} element={<Cashflow />} />
          
            </Route>
            <Route path={ROUTE.KeyValues} element={<KeyValues />} />
            <Route path={ROUTE.Health} element={<Health />} />
            <Route path={ROUTE.Future} element={<Future />}>
              <Route
                path={ROUTE.Future}
                element={<Navigate replace to={ROUTE.Budget} />}
              />
              <Route path={ROUTE.Budget} element={<Budget />} />
              <Route path={ROUTE.Forecast} element={<Forecast />} />
            </Route>
            <Route path={ROUTE.Settings} element={<Settings />}>
              <Route
                path={ROUTE.Settings}
                element={<Navigate replace to={ROUTE.CompanySettings} />}
              />
              <Route
                path={ROUTE.CompanySettings}
                element={<CompanySettings />}
              />
              <Route
                path={ROUTE.AccountingFirmSettings}
                element={<AccountingFirmSettings />}
              >
                <Route
                  path={ROUTE.AccountingFirmSettings}
                  element={
                    <Navigate replace to={ROUTE.AccountingFirmEmployees} />
                  }
                />
                <Route
                  path={ROUTE.AccountingFirmEmployees}
                  element={<AccountingFirmEmployees />}
                />
                <Route
                  path={ROUTE.AccountingFirmCustomers}
                  element={<AccountingFirmCustomers />}
                />
                <Route
                  path={ROUTE.AccountingFirmGroups}
                  element={<AccountingFirmGroups />}
                />
                <Route
                  path={ROUTE.AccountingFirmProfile}
                  element={<AccountingFirmProfile />}
                />
              </Route>
              <Route
                path={ROUTE.ConcernSettings}
                element={<ConcernSettings />}
              />
              <Route path={ROUTE.GroupSettings} element={<GroupSettings />} />
            </Route>
            <Route path={ROUTE.Vouchers} element={<Vouchers />} />
            {isLoggedInAsAdmin && (
              <Route path={ROUTE.Admin} element={<Admin />}>
                <Route
                  path={ROUTE.Admin}
                  element={<Navigate replace to={ROUTE.AdminUsers} />}
                />
                <Route path={ROUTE.AdminUsers} element={<AdminUsers />} />
                <Route
                  path={ROUTE.AdminAccountingFirms}
                  element={<AdminAccountingFirms />}
                />
              </Route>
            )}
            <Route path={ROUTE.Index} element={<Overview />} />
          </Routes>
        </ErrorBoundary>
      </Layout>
    );
  }

  if (hasNoCompanies) {
    routes = (
      <Layout>
        <Routes>
          <Route
            path="*"
            element={<Navigate replace to={ROUTE.NoCompanies} />}
          />
          <Route path={ROUTE.NoCompanies} element={<FirstCompany />} />
        </Routes>
      </Layout>
    );
  }

  if (isLoggedOut) {
    routes = (
      <NonAuthLayout>
        <Routes>
          <Route
            path="*"
            element={
              <Navigate
                replace
                state={{
                  from: window.location.pathname + window.location.search,
                }}
                to={ROUTE.Login}
              />
            }
          />
          <Route path={ROUTE.Setup} element={<InitialSetup />} />
          <Route path={ROUTE.Verify} element={<VerifyPage />} />
          <Route path={ROUTE.ForgotPassword} element={<ForgotPassword />} />
          <Route path={ROUTE.OAuthCallback} element={<OAuthLoading />} />
          <Route path={ROUTE.SignUp} element={<Signup />} />
          <Route path={ROUTE.Login} element={<Login />} />
          <Route path={ROUTE.AdminLogin} element={<AdminLogin />} />
        </Routes>
      </NonAuthLayout>
    );
  }

  if (isBackendDown) {
    routes = (
      <NonAuthLayout>
        <BackendIsDown />
      </NonAuthLayout>
    );
  }

  if (downForMaintenance) {
    routes = (
      <Routes>
        <Route
          path="*"
          element={<Navigate replace to={ROUTE.DownForMaintenance} />}
        />
        <Route
          path={ROUTE.DownForMaintenance}
          element={<DownForMaintenance />}
        />
      </Routes>
    );
  }

  return (
    <>
      {routes}
      <ToastContainer
        position={toast.POSITION.BOTTOM_RIGHT}
        style={{
          zIndex: 1000000,
        }}
      />
    </>
  );
}

export default App;
