import { endOfMonth, getUnixTime, startOfMonth } from "date-fns";
import { useState } from "react";
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import MonthYearDropdown from "../../../components/UI/MonthYearDropdown/MonthYearDropdown";
import { stringToDate } from "../../../shared/utility";
import { IVoucherFilter } from "../../../types/api";
import "./VoucherFilter.scss";

interface IVoucherFilterProps {
  onFilter: (filter: IVoucherFilter) => void;
}

function VoucherFilter(props: IVoucherFilterProps) {
  const [hasMoreOptions, setHasMoreOptions] = useState(false);

  const [fromMonth, setFromMonth] = useState("");
  const [fromYear, setFromYear] = useState("");
  const [toMonth, setToMonth] = useState("");
  const [toYear, setToYear] = useState("");
  const [description, setDescription] = useState("");
  const [voucherSeries, setVoucherSeries] = useState("");
  const [voucherNumber, setVoucherNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountFrom, setAccountFrom] = useState("");
  const [accountTo, setAccountTo] = useState("");

  const [rowDescription, setRowDescription] = useState("");
  const [debit, setDebit] = useState("");
  const [credit, setCredit] = useState("");

  function clearHandler() {
    setFromMonth("");
    setFromYear("");
    setToMonth("");
    setToYear("");
    setDescription("");
    setVoucherSeries("");
    setVoucherNumber("");
    setAccountNumber("");
    setAccountFrom("");
    setAccountTo("");
    setRowDescription("");
    setDebit("");
    setCredit("");
    props.onFilter(getVoucherFilter());
  }

  function getVoucherFilter() {
    let fromString: string | undefined;
    let toString: string | undefined;
    if (fromYear) {
      fromString = `${fromYear}-${fromMonth || 1}`;
    }
    if (toYear) {
      toString = `${toYear}-${toMonth || 12}`;
    }

    const filter: IVoucherFilter = {
      from: fromString
        ? getUnixTime(startOfMonth(stringToDate(fromString)))
        : undefined,
      to: toString
        ? getUnixTime(endOfMonth(stringToDate(toString)))
        : undefined,
      description: description
        ? {
            regex: description,
            options: "g",
          }
        : undefined,
      voucherSeries: voucherSeries ? voucherSeries : undefined,
      voucherNumber: voucherNumber ? voucherNumber : undefined,
      account: accountNumber ? +accountNumber : undefined,
      accountRange:
        accountFrom && accountTo ? [+accountFrom, +accountTo] : undefined,
      descriptionRow: rowDescription
        ? {
            regex: rowDescription,
            options: "g",
          }
        : undefined,
      debit: debit ? +debit : undefined,
      credit: credit ? +credit : undefined,
    };

    return filter;
  }

  return (
    <form
      className="voucher-filter"
      onSubmit={(e) => {
        e.preventDefault();
        props.onFilter(getVoucherFilter());
      }}
    >
      <div className="voucher-filter--group">
        <div className="voucher-filter--group__input">
          <div className="dates">
            <p className="title">Från datum</p>
            <MonthYearDropdown
              monthValue={fromMonth}
              yearValue={fromYear}
              onSelect={(month, year) => {
                setFromMonth(month);
                setFromYear(year);
              }}
              short
              width="100%"
            />
          </div>
        </div>
        <div className="voucher-filter--group__input">
          <div className="dates">
            <p className="title">Till datum</p>
            <MonthYearDropdown
              monthValue={toMonth}
              yearValue={toYear}
              onSelect={(month, year) => {
                setToMonth(month);
                setToYear(year);
              }}
              short
              width="100%"
            />
          </div>
        </div>
        <div className="voucher-filter--group__input">
          <Input
            type="text"
            label="Verifikations serie"
            placeholder="Skriv verifikations serie"
            value={voucherSeries}
            onChange={setVoucherSeries}
            short
            width="100%"
          />
        </div>
        <div className="voucher-filter--group__input">
          <Input
            type="text"
            label="Verifikations text"
            placeholder="Skriv verifikations text"
            value={description}
            onChange={(v) => setDescription(v)}
            short
            width="100%"
          />
        </div>
      </div>
      {hasMoreOptions && (
        <>
          <div className="voucher-filter--group">
            <div className="voucher-filter--group__input">
              <Input
                type="text"
                label="Verifikations nummer"
                placeholder="Skriv verifikations nummer"
                value={voucherNumber}
                onChange={setVoucherNumber}
                short
                width="100%"
              />
            </div>
            <div className="voucher-filter--group__input">
              <Input
                type="text"
                label="Kontonummer"
                placeholder="Skriv Kontonummer"
                value={accountNumber}
                onChange={(v) => setAccountNumber(v)}
                short
                width="100%"
              />
            </div>
            <div className="voucher-filter--group__input">
              <Input
                type="text"
                label="Från kontonummer"
                placeholder="Skriv från kontonummer"
                value={accountFrom}
                onChange={(v) => setAccountFrom(v)}
                short
                width="100%"
              />
            </div>
            <div className="voucher-filter--group__input">
              <Input
                type="text"
                label="Till kontonummer"
                placeholder="Skriv till kontonummer"
                value={accountTo}
                onChange={(v) => setAccountTo(v)}
                short
                width="100%"
              />
            </div>
          </div>
          <div className="voucher-filter--group">
            <div className="voucher-filter--group__input">
              <Input
                type="text"
                label="Verifikationsrad text"
                placeholder="Skriv verifikationsrad text"
                value={rowDescription}
                onChange={(v) => setRowDescription(v)}
                short
                width="100%"
              />
            </div>
            <div className="voucher-filter--group__input">
              <Input
                type="text"
                label="Total debet"
                placeholder="Skriv total debet"
                value={debit}
                onChange={(v) => setDebit(v)}
                short
                width="100%"
              />
            </div>
            <div className="voucher-filter--group__input">
              <Input
                type="text"
                label="Total kredit"
                placeholder="Skriv total kredit"
                value={credit}
                onChange={(v) => setCredit(v)}
                short
                width="100%"
              />
            </div>
            <div className="voucher-filter--group__input"></div>
          </div>
        </>
      )}

      <div className="voucher-filter--buttons">
        <Button label="Filtrera" color="black" width="200px" short />
        <Button
          label="Rensa filter"
          color="white"
          width="200px"
          onClick={clearHandler}
          dontSubmit
          short
        />
        <div
          className="voucher-filter--buttons__more-options"
          onClick={() => setHasMoreOptions((state) => !state)}
        >
          {hasMoreOptions ? "Komprimera filtrering" : "Utöka filtrering"}
        </div>
      </div>
    </form>
  );
}

export default VoucherFilter;
