import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { ROUTE, ROUTE_LABEL } from "../../shared/enums";
import { IReduxState } from "../../types/redux";
import CompanySelector from "../CompanySelector/CompanySelector";
import "./Navbar.scss";

interface INavbarProps {}

function Navbar(props: INavbarProps) {
  const { adminAccessToken } = useSelector((state: IReduxState) => state.auth);

  const navItems = [
    {
      label: ROUTE_LABEL.Index,
      path: ROUTE.Index,
    },
    {
      label: ROUTE_LABEL.Reports,
      path: ROUTE.Reports,
    },
    {
      label: ROUTE_LABEL.KeyValues,
      path: ROUTE.KeyValues,
    }, 
    {
      label: ROUTE_LABEL.Health,
      path: ROUTE.Health,
    },
    {
      label: ROUTE_LABEL.Counseling,
      path: ROUTE.Counseling,
    },
    {
      label: ROUTE_LABEL.Future,
      path: ROUTE.Future,
    },
    {
      label: ROUTE_LABEL.Admin,
      path: ROUTE.Admin,
    },
  ];

  return (
    <nav className="navbar">
      <CompanySelector />
      <div className="nav-items">
        {navItems
          .filter((nav) =>
            !!adminAccessToken ? true : nav.path !== ROUTE.Admin
          )
          .map((nav) => (
            <NavLink
              key={nav.label}
              to={nav.path}
              end={nav.path === ROUTE.Index}
              className="nav-item text-m-m"
            >
              {nav.label}
            </NavLink>
          ))}
        <NavLink
          to={ROUTE.Settings}
          className="nav-item nav-item-settings waves-effect"
        >
          <i className="fa-solid fa-gear" />
        </NavLink>
      </div>
    </nav>
  );
}

export default Navbar;
