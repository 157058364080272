import LoginBackground from "../../components/LoginBackground/LoginBackground";
import "./OnbordingLayout.scss";

interface IOnbordingLayoutProps {
  children: React.ReactNode;
}

function OnbordingLayout(props: IOnbordingLayoutProps) {
  return (
    <div className="onbording-layout">
      <div className="content">{props.children}</div>
      <LoginBackground />
    </div>
  );
}

export default OnbordingLayout;
