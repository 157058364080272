import { convertToNumberWithSpaces, shortenNumber } from "../../shared/utility";
import "./CircleChart.scss";

interface ICircleChartProps {
  value: number;
  compareValue: number;
  showDiffInNumber?: boolean;
  color?: string;
  backgroundColor?: string;
}

function CircleChart(props: ICircleChartProps) {
  const canCalculateProcentDiff =
    Math.sign(props.value) === Math.sign(props.compareValue) &&
    !(props.value === 0 || props.compareValue === 0);

  let procentDiff = canCalculateProcentDiff
    ? props.value / props.compareValue
    : 1;

  if (isNaN(procentDiff)) {
    procentDiff = 0;
  }

  const diff = procentDiff * 100;
  const isDiffNegative = diff < 0;
  const amountDiff = props.value - props.compareValue;

  return (
    <div className="circle-chart">
      <svg viewBox="0 0 36 36" className="circular-chart">
        <path
          className="circle-bg"
          stroke={props.backgroundColor || "#EFEFFF"}
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className="circle-chart-inner"
          strokeDasharray={`${procentDiff * 100}, 100`}
          stroke={props.color || "#000D33"}
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      </svg>
      {!props.showDiffInNumber && (
        <span className="procent text-l-m">
          {convertToNumberWithSpaces(
            Math.abs(diff) > 10000 ? (isDiffNegative ? -10000 : 10000) : diff,
            Math.abs(diff) > 10000 ? (isDiffNegative ? "-%" : "+%") : "%"
          )}
        </span>
      )}
      <span className={props.showDiffInNumber ? "text-l-m" : "amount text-l-m"}>
        {shortenNumber(amountDiff)}
      </span>
    </div>
  );
}

export default CircleChart;
