import "./NonAuthLayout.scss";
import "../Layout/Layout.scss";
import nimyaLogo from "../../assets/images/nimya-logo.svg";
import EarlyAccess from "../../components/EarlyAccess/EarlyAccess";
import DevBanner from "../../components/DevBanner/DevBanner";

interface INonAuthLayoutProps {
  children: React.ReactNode;
}

function NonAuthLayout(props: INonAuthLayoutProps) {
  return (
    <div className="app-wrapper non-auth">
      <div className="non-auth-top">
        <a href="https://www.nimya.io/" target="_blank" rel="noreferrer">
          <img src={nimyaLogo} alt="Nimya" style={{ width: 90 }} />
        </a>
        <EarlyAccess />
        <div className="dev-banner-wrapper">
          <DevBanner />
        </div>
      </div>
      <div className="content">{props.children}</div>
    </div>
  );
}

export default NonAuthLayout;
