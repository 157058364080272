import { ClientJS } from "clientjs";
import axios from "../shared/axios";

const PREFIX = "/log";

export function sendFingerprint() {
  const client = new ClientJS();
  return axios.post(`${PREFIX}/checkFingerprint`, {
    fingerprint: client.getFingerprint(),
    os: `${client.getOS()} ${client.getOSVersion()}`,
    browser: `${client.getBrowser()} ${client.getBrowserVersion()}`,
  });
}
