import { VARIABLE_VALUE } from "../../../../shared/enums";
import "./VariableListItem.scss";

interface IVariableListItemProps {
  name: string;
  isGlobal?: boolean;
  isSelected?: boolean;
  onClick: () => void;
}

function VariableListItem(props: IVariableListItemProps) {
  const variableListItemClasses = ["variable-list-item", "text-m-r"];
  if (props.isSelected) {
    variableListItemClasses.push("selected");
  }

  return (
    <div
      title={props.isGlobal ? "Global variabel" : ""}
      className={variableListItemClasses.join(" ")}
      onClick={props.onClick}
    >
      {props.isGlobal && <i className="fa-light fa-globe" />}
      {props.name.replace(VARIABLE_VALUE.GlobalPrefix, "")}
    </div>
  );
}

export default VariableListItem;
