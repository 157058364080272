import OnbordingLayout from "../../hoc/OnbordingLayout/OnbordingLayout";
import "./VerifyPage.scss";

interface IVerifyPageProps {}

function VerifyPage(props: IVerifyPageProps) {
  return (
    <OnbordingLayout>
      <div className="verify-page--title space-grotesk">
        Verifiera e-postadress
      </div>
      <p className="verify-page--subtitle">
        Vänligen följ länken i mailet vi skickade till dig, för att verifiera
        ditt konto.
      </p>
    </OnbordingLayout>
  );
}

export default VerifyPage;
