import axios from "../shared/axios";
import { IApiResponse, IMeUser } from "../types/api";

const PREFIX = "/user";

export function getMe() {
  return axios.get<IApiResponse<IMeUser>>(`${PREFIX}/me`);
}

export function updateProfileImage(file: File) {
  const formData = new FormData();
  formData.append("avatar", file);
  return axios.put(`${PREFIX}/avatar`, formData);
}

export function updateProfileInfo({
  firstName,
  lastName,
  phoneNr,
}: {
  firstName: string;
  lastName: string;
  phoneNr: string;
}) {
  return axios.post(`${PREFIX}/me`, {
    firstName: firstName,
    lastName: lastName,
    phoneNr: phoneNr,
  });
}

export function promoteUserToAdmin({
  companyId,
  userId,
  role,
}: {
  companyId: string;
  userId: string;
  role: "admin" | "user";
}) {
  return axios.post<IApiResponse<{}>>(`${PREFIX}/promote`, {
    companyId,
    userId,
    role,
  });
}

export function generate2Fa({
  adminPanel,
  password,
}: {
  adminPanel: boolean;
  password: string;
}) {
  return axios.post<IApiResponse<{ secret: string }>>(`${PREFIX}/generate2fa`, {
    adminPanel,
    password,
  });
}

export function diable2Fa(password: string) {
  return axios.post<IApiResponse<{}>>(`${PREFIX}/disable2fa`, {
    password,
  });
}
