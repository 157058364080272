import axios from "../shared/axios";
import { IApiResponse, IVoucher, IVoucherFilter } from "../types/api";

const PREFIX = "/vouchers";

export function getVouchers({
  companyId,
  filter,
}: {
  companyId: string;
  filter?: IVoucherFilter;
}) {
  return axios.post<
    IApiResponse<{
      vouchers: IVoucher[];
      metadata: {
        currentPage: number;
        pages: number;
        totalVouchers: number;
      };
      warnings: string[];
    }>
  >(`${PREFIX}/list`, {
    companyId,
    filter,
  });
}
