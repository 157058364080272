import "./Health.scss";

import {
  eachMonthOfInterval,
  endOfMonth,
  fromUnixTime,
  getUnixTime,
  startOfMonth,
  subMonths,
} from "date-fns";
import { motion, Variants } from "framer-motion";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import CircleChart from "../../components/CircleChart/CircleChart";
import NoticeRow from "../../components/NoticeRow/NoticeRow";
import Button from "../../components/UI/Button/Button";
import Dropdown from "../../components/UI/Dropdown/Dropdown";
import CustomAlertPopup from "../../containers/CustomAlertPopup/CustomAlertPopup";
import ExtendedFiltering from "../../containers/ExtendedFiltering/ExtendedFiltering";
import { QUERY_PARAM } from "../../shared/enums";
import { useSearchQuery } from "../../shared/hooks/useSearchQuery";
import { getFinancialYearsDropdown } from "../../shared/utility";
import { months, years } from "../../shared/values";
import { setFinancialYear } from "../../store/slices/company";
import { IVariable } from "../../types/api";
import { IReduxState } from "../../types/redux";

interface IHealthProps {}

function Health(props: IHealthProps) {
  const dispatch = useDispatch();
  const {
    company: { companyId, financialYear, financialYears },
    extendedFiltering: { tagFilter, timePeriod, presetMonth },
  } = useSelector((state: IReduxState) => state);
  const navigate = useNavigate();

  const query = useSearchQuery();

  const [breakMonth, setBreakMonth] = useState(
    `${subMonths(new Date(), 1).getMonth() + 1}`
  );

  const [fromMonth, setFromMonth] = useState("1");
  const [fromYear, setFromYear] = useState(
    subMonths(new Date(), presetMonth === "current" ? 0 : 1)
      .getFullYear()
      .toString()
  );
  const [toMonth, setToMonth] = useState(
    `${subMonths(new Date(), presetMonth === "current" ? 0 : 1).getMonth() + 1}`
  );
  const [toYear, setToYear] = useState(
    subMonths(new Date(), presetMonth === "current" ? 0 : 1)
      .getFullYear()
      .toString()
  );

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editVariable, setEditVariable] = useState<IVariable | undefined>(
    undefined
  );

  useEffect(() => {
    setToMonth(
      `${
        subMonths(new Date(), presetMonth === "current" ? 0 : 1).getMonth() + 1
      }`
    );
    setBreakMonth(
      `${
        subMonths(new Date(), presetMonth === "current" ? 0 : 1).getMonth() + 1
      }`
    );
  }, [presetMonth]);

  useEffect(() => {
    const fromQuery = query.get(QUERY_PARAM.From);
    const toQuery = query.get(QUERY_PARAM.To);
    if (!fromQuery || !toQuery) return;

    const fromQueryDate = fromUnixTime(+fromQuery);
    const toQueryDate = fromUnixTime(+toQuery);

    setFromMonth(`${fromQueryDate.getMonth() + 1}`);
    setFromYear(fromQueryDate.getFullYear().toString());
    setToMonth(`${toQueryDate.getMonth() + 1}`);
    setToYear(toQueryDate.getFullYear().toString());
    setBreakMonth(`${toQueryDate.getMonth() + 1}`);

    financialYears.forEach((fYear) => {
      if (+fromQuery >= fYear.fromDate && +toQuery <= fYear.toDate) {
        dispatch(setFinancialYear(fYear.financialYear.toString()));
      }
    });
  }, [dispatch, financialYears, query]);

  const fromDate = startOfMonth(fromUnixTime(financialYear.from));
  const toDate = endOfMonth(fromUnixTime(financialYear.to));

  const eachMonth = eachMonthOfInterval({ start: fromDate, end: toDate });

  const breakDates = eachMonth.filter((d) => d.getMonth() + 1 === +breakMonth);

  const breakDate = breakDates.reduce((prev, current) =>
    getUnixTime(prev) > getUnixTime(current) ? prev : current
  );

  let from = getUnixTime(fromDate);
  let to = getUnixTime(toDate);
  let breakUnix = getUnixTime(endOfMonth(breakDate));

  if (timePeriod === "custom") {
    from = getUnixTime(startOfMonth(new Date(+fromYear, +fromMonth - 1, 1)));
    to = getUnixTime(endOfMonth(new Date(+toYear, +toMonth - 1, 1)));
    breakUnix = getUnixTime(endOfMonth(new Date(+toYear, +toMonth - 1, 1)));
  }

  const rowVariants: Variants = {
    offscreen: {
      y: 20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
      },
    },
  };

  return (
    <div className="health">
      <div className="controls">
        <div className="dropdowns">
          <Button
            label="Skapa ny notis"
            color="black"
            onClick={() => setIsPopupOpen(true)}
          />
          {timePeriod === "financial-year" ? (
            <>
              <Dropdown
                title="År"
                options={getFinancialYearsDropdown(financialYears)}
                onSelect={(v) => dispatch(setFinancialYear(v))}
                value={financialYear.identifier}
                width="200px"
              />
              <Dropdown
                title="Månad"
                options={months}
                onSelect={setBreakMonth}
                value={breakMonth}
              />
            </>
          ) : (
            <>
              <Dropdown
                title="Från år"
                options={years()}
                onSelect={setFromYear}
                value={fromYear}
              />
              <Dropdown
                title="Från månad"
                options={months}
                onSelect={setFromMonth}
                value={fromMonth}
              />
              <Dropdown
                title="Till år"
                options={years()}
                onSelect={setToYear}
                value={toYear}
              />
              <Dropdown
                title="Till månad"
                options={months}
                onSelect={(v) => {
                  setToMonth(v);
                  setBreakMonth(v);
                }}
                value={toMonth}
              />
            </>
          )}

          <ExtendedFiltering />
        </div>
      </div>
      <CustomAlertPopup
        showPopup={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
      />

      {/* Automatiska notiser */}

      <motion.div
        className="automatic-notifications"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.1 }}
        variants={rowVariants}
        transition={{ ease: "easeInOut" }}
      >
        <div className="top">
          <span className="text-m-m">Automatiska notiser</span>
          <button className="icon-btn">
            <i className="fa-solid fa-circle-info"></i>
          </button>
        </div>
        <div className="content">
          <div className="section-row">
            <Link
              to={{
                pathname: "/",
                search: `${QUERY_PARAM.Graph}=income`,
              }}
              className="section"
            >
              <div className="content">
                <div className="values">
                  <div className="value">
                    <p className="title text-m-r">Intäkter</p>
                    <p className="text-l-m">1 873 293</p>
                  </div>
                  <div className="value">
                    <p className="title text-m-r">Budget</p>
                    <p className="text-l-m">1 873 293</p>
                  </div>
                </div>
                <div className="values">
                  <div className="value">
                    <p className="title text-m-r">Notis triggas</p>
                    <div className="chips">
                      <div className="chip">
                        <p className="text-m-m">50</p>
                        <p className="text-m-m">%</p>
                      </div>
                      <div className="chip">
                        <p className="text-m-m">75</p>
                        <p className="text-m-m">%</p>
                      </div>
                      <div className="chip">
                        <p className="text-m-m">100</p>
                        <p className="text-m-m">%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <CircleChart
                value={70}
                compareValue={70}
                color="#000D33"
                backgroundColor="#EFEFFF"
              />
            </Link>
            <Link
              to={{
                pathname: "/",
                search: `${QUERY_PARAM.Graph}=income`,
              }}
              className="section"
            >
              <div className="content">
                <div className="values">
                  <div className="value">
                    <p className="title text-m-r">Intäkter</p>
                    <p className="text-l-m">1 873 293</p>
                  </div>
                  <div className="value">
                    <p className="title text-m-r">Budget</p>
                    <p className="text-l-m">1 873 293</p>
                  </div>
                </div>
                <div className="values">
                  <div className="value">
                    <p className="title text-m-r">Notis triggas</p>
                    <div className="chips">
                      <div className="chip">
                        <p className="text-m-m">50</p>
                        <p className="text-m-m">%</p>
                      </div>
                      <div className="chip">
                        <p className="text-m-m">75</p>
                        <p className="text-m-m">%</p>
                      </div>
                      <div className="chip">
                        <p className="text-m-m">100</p>
                        <p className="text-m-m">%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <CircleChart
                value={70}
                compareValue={70}
                color="#000D33"
                backgroundColor="#EFEFFF"
              />
            </Link>
            <Link
              to={{
                pathname: "/",
                search: `${QUERY_PARAM.Graph}=income`,
              }}
              className="section"
            >
              <div className="content">
                <div className="values">
                  <div className="value">
                    <p className="title text-m-r">Intäkter</p>
                    <p className="text-l-m">1 873 293</p>
                  </div>
                  <div className="value">
                    <p className="title text-m-r">Budget</p>
                    <p className="text-l-m">1 873 293</p>
                  </div>
                </div>
                <div className="values">
                  <div className="value">
                    <p className="title text-m-r">Notis triggas</p>
                    <div className="chips">
                      <div className="chip">
                        <p className="text-m-m">50</p>
                        <p className="text-m-m">%</p>
                      </div>
                      <div className="chip">
                        <p className="text-m-m">75</p>
                        <p className="text-m-m">%</p>
                      </div>
                      <div className="chip">
                        <p className="text-m-m">100</p>
                        <p className="text-m-m">%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <CircleChart
                value={70}
                compareValue={70}
                color="#000D33"
                backgroundColor="#EFEFFF"
              />
            </Link>
          </div>
        </div>
      </motion.div>
      <div className="header">
        <span className="text-m-m"># IT relaterade kostnader</span>
      </div>
      <motion.div
        className="list"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.1 }}
        variants={rowVariants}
        transition={{ ease: "easeInOut" }}
      >
        <NoticeRow />
        <NoticeRow />
        <NoticeRow />
        <NoticeRow />
      </motion.div>
      <div className="header">
        <span className="text-m-m"># IT relaterade kostnader</span>
      </div>
      <motion.div
        className="list"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.1 }}
        variants={rowVariants}
        transition={{ ease: "easeInOut" }}
      >
        <NoticeRow />
        <NoticeRow />
        <NoticeRow />
        <NoticeRow />
      </motion.div>
    </div>
  );
}

export default Health;
