import { fromUnixTime, lightFormat } from "date-fns";
import { months } from "../../../shared/values";
import { IBalanceReportRow } from "../../../types/api";
import BalanceReportRow from "../BalanceReportRow/BalanceReportRow";
import BalanceReportSubrow from "../BalanceReportSubrow/BalanceReportSubrow";
import "./BalanceReportSection.scss";

interface IBalanceReportSectionProps {
  title: string;
  rows: IBalanceReportRow;
  from: number;
  to: number;
}

function BalanceReportSection(props: IBalanceReportSectionProps) {
  const fromString = `${lightFormat(fromUnixTime(props.from), "d")} ${months[
    fromUnixTime(props.from).getMonth()
  ].label.slice(0, 3)}`;

  const toString = `${lightFormat(fromUnixTime(props.to), "d")} ${months[
    fromUnixTime(props.to).getMonth()
  ].label.slice(0, 3)}`;

  const sectionSumIb = Object.keys(props.rows).reduce(
    (total, key) => total + (key === "Summa" ? 0 : props.rows[key].IB),
    0
  );
  const sectionSumUb = Object.keys(props.rows).reduce(
    (total, key) => total + (key === "Summa" ? 0 : props.rows[key].UB),
    0
  );

  return (
    <table className="balance-report-section">
      <thead>
        <tr>
          <th className="title text-m-m">{props.title}</th>
          <th className="text-m-m">{fromString}</th>
          <th className="text-m-m">{`${fromString} - ${toString}`}</th>
          <th className="text-m-m">{toString}</th>
        </tr>
      </thead>
      <tbody>
        {props.title === "Tillgångar" && (
          <>
            <BalanceReportRow
              title="Anläggninstillgångar"
              rows={props.rows}
              keys={[
                "Immateriella anläggningstillgångar",
                "Byggnader och mark",
                "Maskiner och inventarier",
                "Finansiella anläggningstillgångar",
              ]}
            />
            <BalanceReportRow
              title="Omsättningstillgångar"
              rows={props.rows}
              keys={[
                "Lager",
                "Kundfordringar",
                "Övriga kostfristiga fordringar",
                "Förutbetalda kostnader och upplupna intäkter",
                "Kortfristiga placeringar",
                "Kassa och bank",
              ]}
            />
          </>
        )}
        {props.title === "Eget kapital och skulder" && (
          <>
            <BalanceReportRow
              title="Eget kapital"
              rows={props.rows}
              keys={["Eget kapital", "Obeskattade reserver"]}
            />
            <BalanceReportRow
              title="Långfristiga skulder"
              rows={props.rows}
              keys={["Långfristiga skulder"]}
            />
            <BalanceReportRow
              title="Kortfristiga skulder"
              rows={props.rows}
              keys={[
                "Leverantörsskulder",
                "Skatteskulder",
                "Moms och särskilda punktskatter",
                "Personalens skatter, avgifter och löneavdrag",
                "Upplupna kostnader förutbetalda intäkter",
                "Övriga kortfristiga skulder",
              ]}
            />
          </>
        )}
        <BalanceReportSubrow
          title={`Summa ${props.title}`}
          ib={sectionSumIb}
          ub={sectionSumUb}
          isBold
        />
      </tbody>
    </table>
  );
}

export default BalanceReportSection;
