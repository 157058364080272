import { AxiosError } from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  adminLogin,
  loginEmailAndPassword,
  resetPassword,
} from "../../api/auth";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import OnbordingLayout from "../../hoc/OnbordingLayout/OnbordingLayout";
import { login } from "../../store/thunks/auth";
import { IReduxState } from "../../types/redux";
import "./AdminLogin.scss";

interface IAdminLoginProps {}

function AdminLogin(props: IAdminLoginProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [hasForgottenPassword, setHasForgottenPassword] = useState(false);
  const [isForgotLoading, setIsForgotLoading] = useState(false);
  const isLoading = useSelector((state: IReduxState) => state.auth.loading);

  async function loginHandler() {
    if (!email || !password || !code) {
      toast.error(
        "Du måste ange e-postadress, lösenord och kod för att logga in."
      );
      return;
    }

    try {
      const [userRes, adminRes] = await Promise.all([
        loginEmailAndPassword({ email, password }),
        adminLogin({ email, password, code }),
      ]);

      login({
        accessToken: userRes.data.payload.access_token,
        refreshToken: userRes.data.payload.refresh_token,
        adminAccessToken: adminRes.data.payload.adminAccessToken,
        adminRefreshToken: adminRes.data.payload.adminRefreshToken,
      });
    } catch (e) {
      const error = e as AxiosError;

      const errorText =
        error.response?.status === 401
          ? "Felaktiga inloggningsuppgifter"
          : "Något gick fel";
      toast.error(errorText);
    }
  }

  const forgotPasswordHandler = async (email: string) => {
    if (!email) {
      toast.error("Du måste ange e-postadress för att få ett nytt lösenord.");
      return;
    }
    setIsForgotLoading(true);
    try {
      await resetPassword(email);
    } catch (error) {
      toast.error("Något gick fel");
      setIsForgotLoading(false);
      return;
    }
    setIsForgotLoading(false);
    toast.success("Ett nytt lösenord har skickats till din e-postadress.");
  };

  return (
    <OnbordingLayout>
      <p className="login-content-title space-grotesk">Admin logga in</p>
      <form
        className="login-content-form"
        onSubmit={(e) => {
          e.preventDefault();
          loginHandler();
        }}
      >
        <Input
          type="email"
          placeholder="E-postadress"
          label="E-postadress"
          value={email}
          onChange={(e) => {
            setEmail(e);
            setForgotPasswordEmail(e);
          }}
        />
        <Input
          type="password"
          placeholder="Lösenord"
          label="Lösenord"
          value={password}
          onChange={setPassword}
        />
        <Input
          type="text"
          placeholder="Kod"
          label="Kod"
          value={code}
          onChange={setCode}
        />
        <Button color="black" label="Logga in" isLoading={isLoading} />
      </form>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          forgotPasswordHandler(forgotPasswordEmail);
        }}
        className="login-content-forgot-password"
      >
        <p onClick={() => setHasForgottenPassword((state) => !state)}>
          {hasForgottenPassword ? "Avbryt" : "Glömt lösenordet?"}
        </p>
        {hasForgottenPassword && (
          <>
            <Input
              type="email"
              placeholder="E-postadress"
              label="E-postadress"
              value={forgotPasswordEmail}
              onChange={setForgotPasswordEmail}
            />
            <Button
              color="black"
              label="Återställ"
              isLoading={isForgotLoading}
            />
          </>
        )}
      </form>
    </OnbordingLayout>
  );
}

export default AdminLogin;
