import Checkbox from "../../../UI/Checkbox/Checkbox";
import "./AccountRow.scss";

interface IAccountRowProps {
  accountName: string;
  accountNumber: number;
  isActive: boolean;
  onCheck: (checked: boolean) => void;
}

function AccountRow(props: IAccountRowProps) {
  const accountRowClasses = ["account-row", "text-m-r"];
  if (props.isActive) accountRowClasses.push("active");

  return (
    <div
      className={accountRowClasses.join(" ")}
      onClick={() => props.onCheck(!props.isActive)}
    >
      <span className="account-number">{props.accountNumber}</span>
      <span className="account-name">{props.accountName}</span>
      <Checkbox checked={props.isActive} theme="dark" short />
    </div>
  );
}

export default AccountRow;
