import DotsModal from "../../DotsModal/DotsModal";
import Checkbox from "../../UI/Checkbox/Checkbox";
import Switch from "../../UI/Switch/Switch";
import "./AlertRow.scss";

interface IAlertRowProps {
  title: string;
  description: string;
  onSelect: () => void;
  isSelected: boolean;
  editMode?: boolean;
  onEdit: () => void;
  onDelete: () => void;
  onToggleAlert: (shouldActivate: boolean) => void;
  isActive?: boolean;
  isGlobal?: boolean;
}

function AlertRow(props: IAlertRowProps) {
  return (
    <div className="alert-row">
      <div className="title">
        <span className="text-s-r">{props.title}</span>
        {props.isGlobal && <i className="fa-light fa-globe" />}
      </div>
      <div className="content">
        <span className="description text-m-r">{props.description}</span>
        {!props.editMode ? (
          <Checkbox
            checked={props.isSelected}
            onClick={props.onSelect}
            theme="dark"
          />
        ) : (
          <>
            <Switch
              isActive={!!props.isActive}
              onChange={props.onToggleAlert}
            />
            {!props.isGlobal && (
              <DotsModal
                title="Notis"
                content={[
                  {
                    title: "Redigera notis",
                    onClick: props.onEdit,
                  },
                  {
                    title: "Radera notis",
                    onClick: props.onDelete,
                  },
                ]}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default AlertRow;
