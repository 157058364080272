import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { environment } from "../environment";

import * as analytics from "../ga4";

export function useAnalytics() {
  const location = useLocation();

  const TRACKING_ID = environment.gaTrackingId;

  useEffect(() => {
    if (!TRACKING_ID) return;

    analytics.init(TRACKING_ID);
  }, [TRACKING_ID]);

  useEffect(() => {
    if (!TRACKING_ID) return;
    const path = location.pathname + location.search;
    analytics.sendPageview(path);
  }, [TRACKING_ID, location]);
}

export default useAnalytics;
