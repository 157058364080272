import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteGroup,
  getCompaniesInGroup,
  getGroups,
  getUsersInGroup,
  removeMeFromGroup,
} from "../../api/group";
import { getMe } from "../../api/user";
import Button from "../../components/UI/Button/Button";
import Dropdown from "../../components/UI/Dropdown/Dropdown";
import Spinner from "../../components/UI/Spinner/Spinner";
import TextButton from "../../components/UI/TextButton/TextButton";
import CreateGroupPopup from "../../containers/CreateGroupPopup/CreateGroupPopup";
import GroupCompanies from "../../containers/GroupCompanies/GroupCompanies";
import GroupInfo from "../../containers/GroupInfo/GroupInfo";
import GroupSettingsRow from "../../containers/GroupSettingsRow/GroupSettingsRow";
import GroupUsers from "../../containers/GroupUsers/GroupUsers";
import Popup from "../../hoc/Popup/Popup";
import { API_MESSAGE } from "../../shared/enums";
import { nimyaAlert } from "../../shared/nimyaAlert";
import { convertToDropdown } from "../../shared/utility";
import { IReduxState } from "../../types/redux";
import "./AccountingFirmGroups.scss";

interface IAccountingFirmGroupsProps {}

function AccountingFirmGroups(props: IAccountingFirmGroupsProps) {
  const { agency } = useSelector((state: IReduxState) => state.consult);

  const [chosenGroupId, setChosenGroupId] = useState("");
  const [adminEmails, setAdminEmails] = useState<string[]>([]);
  const [userEmail, setUserEmail] = useState("");
  const [openGroupSetting, setOpenGroupSetting] = useState<
    "info" | "users" | "companies" | ""
  >("");
  const [isLeavingGroup, setIsLeavingGroup] = useState(false);
  const [isDeletingGroup, setIsDeletingGroup] = useState(false);
  const [isGroupPopupOpen, setIsGroupPopupOpen] = useState(false);

  useEffect(() => {
    getMe().then((res) => setUserEmail(res.data.payload.email));
  }, []);

  const {
    data: groups,
    status: groupStatus,
    refetch: reloadGroups,
  } = useQuery({
    queryKey: ["accounting-firm-groups", agency!._id],
    queryFn: async ({ queryKey: [, agencyId] }) => {
      const res = await getGroups(agencyId);
      if (res.data.message === API_MESSAGE.NoGroups) return [];

      if (!chosenGroupId) {
        setChosenGroupId(res.data.payload[0].groupId);
      }
      return res.data.payload;
    },
  });

  const {
    data: currentGroup,
    status: currentGroupStatus,
    refetch: reloadCurrentGroup,
  } = useQuery({
    queryKey: ["group", chosenGroupId],
    queryFn: async ({ queryKey: [, groupId] }) => {
      const [cRes, uRes] = await Promise.all([
        getCompaniesInGroup(groupId),
        getUsersInGroup(groupId),
      ]);

      setAdminEmails(
        uRes.data.payload
          .filter((user) => user.role === "admin")
          .map((user) => user.email)
      );

      return {
        companies: cRes.data.payload,
        users: uRes.data.payload,
      };
    },
    enabled: !!chosenGroupId,
  });

  async function leaveGroupHandler() {
    if (
      !(await nimyaAlert({
        title: "Lämna gruppen",
        message: "Är du säker på att du vill lämna gruppen?",
      }))
    )
      return;

    setIsLeavingGroup(true);

    try {
      await removeMeFromGroup(chosenGroupId);

      setIsLeavingGroup(false);
      setChosenGroupId("");
      toast.success("Du lämnade gruppen");
      reloadGroups();
    } catch (error: any) {
      if (error.response.data.message === API_MESSAGE.OwnerCantLeaveGroup) {
        toast.error("Gruppens ägare kan inte lämna gruppen");
      } else {
        toast.error("Något gick fel");
      }
      setIsLeavingGroup(false);
    }
  }

  async function groupDeleteHandler() {
    if (
      !(await nimyaAlert({
        title: "Ta bort grupp",
        message: "Är du säker på att du vill ta bort gruppen?",
      }))
    )
      return;

    if (
      !(await nimyaAlert({
        title: "Ta bort grupp",
        message: "Ange 'Ta bort grupp' för att ta bort gruppen",
        conifrmText: "Ta bort grupp",
      }))
    )
      return;

    setIsDeletingGroup(true);

    try {
      await deleteGroup(chosenGroupId);
      toast.success("Gruppen togs bort");
      setChosenGroupId("");
      setIsDeletingGroup(false);
      reloadGroups();
    } catch (error) {
      toast.error("Något gick fel");
      setIsDeletingGroup(false);
    }
  }

  return (
    <div className="accounting-firm-groups">
      <div className="top">
        {groupStatus === "success" && (
          <>
            <Dropdown
              title="Välj grupp"
              options={convertToDropdown({
                list: groups,
                labelKey: "name",
                valueKey: "groupId",
              })}
              value={chosenGroupId || ""}
              onSelect={setChosenGroupId}
              width="270px"
            />
            {adminEmails.includes(userEmail) && (
              <TextButton
                label="Ta bort grupp"
                onClick={groupDeleteHandler}
                isLoading={isDeletingGroup}
              />
            )}
            <TextButton
              label="Lämna grupp"
              onClick={leaveGroupHandler}
              isLoading={isLeavingGroup}
            />
            <TextButton
              label="Skapa ny grupp"
              onClick={() => setIsGroupPopupOpen(true)}
            />
            {adminEmails.includes(userEmail) && (
              <div className="actions">
                <span className="text-m-r">Redigera grupp</span>
                <div
                  className="group-actions waves-effect waves-light"
                  onClick={() => setOpenGroupSetting("info")}
                >
                  <i className="fa-solid fa-pen-line" />
                </div>
                <div
                  className="group-actions waves-effect waves-light"
                  onClick={() => setOpenGroupSetting("users")}
                >
                  <i className="fa-solid fa-users-gear" />
                </div>
                <div
                  className="group-actions waves-effect waves-light"
                  onClick={() => setOpenGroupSetting("companies")}
                >
                  <i className="fa-solid fa-buildings" />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <Popup
        showPopup={!!openGroupSetting}
        onClose={() => setOpenGroupSetting("")}
      >
        {openGroupSetting === "info" ? (
          <GroupInfo
            groupId={chosenGroupId}
            onSave={() => {
              setOpenGroupSetting("");
              reloadGroups();
              reloadCurrentGroup();
            }}
            onDelete={() => {
              setOpenGroupSetting("");
              groupDeleteHandler();
            }}
            agencyId={agency!._id}
          />
        ) : openGroupSetting === "users" ? (
          <GroupUsers
            groupId={chosenGroupId}
            onSave={() => {
              setOpenGroupSetting("");
              reloadCurrentGroup();
            }}
            isEditing={true}
          />
        ) : openGroupSetting === "companies" ? (
          <GroupCompanies
            groupId={chosenGroupId}
            onSave={() => {
              setOpenGroupSetting("");
              reloadCurrentGroup();
            }}
            saveButtonLabel="Spara"
            agencyId={agency!._id}
          />
        ) : null}
      </Popup>
      {groupStatus === "success" &&
        (!groups.length ? (
          <div className="empty-list">
            <p className="title">Du är inte med i en grupp</p>
            <p className="sub-title">
              Klicka på knappen nedan för att skapa en ny grupp
            </p>
            <Button
              color="black"
              label="Skapa ny grupp"
              onClick={() => setIsGroupPopupOpen(true)}
            />
          </div>
        ) : currentGroupStatus === "success" ? (
          !currentGroup.companies.length ? (
            <div className="empty-list">
              <p className="title">Denna grupp innehåller inga företag</p>
              <p className="sub-title">
                Klicka på knappen nedan för att lägga till företag i gruppen
              </p>
              <Button
                color="black"
                label="Lägg till företag"
                onClick={() => setOpenGroupSetting("companies")}
              />
            </div>
          ) : (
            currentGroup.companies.map((gc) => (
              <GroupSettingsRow
                key={gc._id}
                companyId={gc._id}
                name={gc.name}
                users={currentGroup.users}
              />
            ))
          )
        ) : (
          <Spinner />
        ))}

      <Popup
        showPopup={isGroupPopupOpen}
        onClose={() => setIsGroupPopupOpen(false)}
      >
        <CreateGroupPopup
          agencyId={agency!._id}
          onCreate={() => reloadGroups()}
          onDone={async (groupId) => {
            setIsGroupPopupOpen(false);
            await reloadGroups();
            setChosenGroupId(groupId);
          }}
        />
      </Popup>
    </div>
  );
}

export default AccountingFirmGroups;
