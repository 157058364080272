import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IConsultAgency } from "../../types/api";
import { IConsultState } from "../../types/redux";

const initialState: IConsultState = {
  consults: [],
  agency: undefined,
};

const consultSlice = createSlice({
  name: "consult",
  initialState,
  reducers: {
    setConsults(state, action: PayloadAction<IConsultAgency[]>) {
      state.consults = action.payload;
    },
    setAgency(state, action: PayloadAction<IConsultAgency>) {
      state.agency = action.payload;
    },
  },
});

export const { setConsults, setAgency } = consultSlice.actions;

export default consultSlice.reducer;
