import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LOCAL_STORAGE_KEY } from "../../shared/enums";
import {
  AlertTimePeriod,
  CompareWith,
  IExtendedFilteringState,
  PresetMonth,
  TimePeriod,
} from "../../types/redux";

const initialState: IExtendedFilteringState = {
  compareWith: "previous-year",
  timePeriod: "financial-year",
  presetMonth: "previous",
  tagFilter: "all",
  alertTimePeriod: "month-year",
};

let localStorageExtendedFiltering: IExtendedFilteringState | undefined;

const extendedFilterString = localStorage.getItem(
  LOCAL_STORAGE_KEY.ExtendedFilter
);

if (extendedFilterString) {
  localStorageExtendedFiltering = JSON.parse(extendedFilterString);
  if (localStorageExtendedFiltering) {
    localStorageExtendedFiltering.tagFilter = "all";
  }
}

const extendedFilteringSlice = createSlice({
  name: "extendedFiltering",
  initialState: localStorageExtendedFiltering || initialState,
  reducers: {
    setCompareWith(state, action: PayloadAction<CompareWith>) {
      state.compareWith = action.payload;
    },
    setTimePeriod(state, action: PayloadAction<TimePeriod>) {
      state.timePeriod = action.payload;
    },
    setPresetMonth(state, action: PayloadAction<PresetMonth>) {
      state.presetMonth = action.payload;
    },
    setTagFilter(state, action: PayloadAction<string>) {
      if (action.payload === "all") {
        state.tagFilter = "all";
        return;
      }
      let newTagFilter = [];
      if (state.tagFilter !== "all") {
        newTagFilter.push(...state.tagFilter);
      }

      if (newTagFilter.includes(action.payload)) {
        newTagFilter = newTagFilter.filter((tag) => tag !== action.payload);
      } else {
        newTagFilter.push(action.payload);
      }

      state.tagFilter = newTagFilter;
    },
    setAlertTimePeriod(state, action: PayloadAction<AlertTimePeriod>) {
      state.alertTimePeriod = action.payload;
    },
  },
});

export const {
  setCompareWith,
  setTimePeriod,
  setPresetMonth,
  setTagFilter,
  setAlertTimePeriod,
} = extendedFilteringSlice.actions;

export default extendedFilteringSlice.reducer;
