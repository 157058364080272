import { useState } from "react";
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import TextButton from "../../../components/UI/TextButton/TextButton";
import { IAdminUserFilter } from "../../../types/api";
import "./AdminUsersFilter.scss";

interface IAdminUsersFilterProps {
  onFilter: (filter: IAdminUserFilter) => void;
}

function AdminUsersFilter(props: IAdminUsersFilterProps) {
  const [hasMoreOptions, setHasMoreOptions] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNr, setPhoneNr] = useState("");

  function clearHandler() {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNr("");
    props.onFilter(getUserFilter());
  }

  function getUserFilter() {
    const filter: IAdminUserFilter = {
      firstName: firstName ? firstName : undefined,
      lastName: lastName ? lastName : undefined,
      email: email ? email : undefined,
      phoneNr: phoneNr ? phoneNr : undefined,
    };

    return filter;
  }

  return (
    <form
      className="admin-users-filter"
      onSubmit={(e) => {
        e.preventDefault();
        props.onFilter(getUserFilter());
      }}
    >
      <div className="admin-users-group">
        <div className="group-input">
          <Input
            type="text"
            label="Förnamn"
            value={firstName}
            onChange={setFirstName}
            placeholder="Förnamn"
            width="100%"
            short
          />
        </div>
        <div className="group-input">
          <Input
            type="text"
            label="Efternamn"
            value={lastName}
            onChange={setLastName}
            placeholder="Efternamn"
            width="100%"
            short
          />
        </div>
        <div className="group-input">
          <Input
            type="text"
            label="E-post"
            value={email}
            onChange={setEmail}
            placeholder="E-post"
            width="100%"
            short
          />
        </div>
        <div className="group-input">
          <Input
            type="text"
            label="Telefonnummer"
            value={phoneNr}
            onChange={setPhoneNr}
            placeholder="Telefonnummer"
            width="100%"
            short
          />
        </div>
      </div>
      <div className="admin-users-buttons">
        <Button label="Filtrera" color="black" width="200px" short />
        <Button
          label="Rensa filter"
          color="white"
          width="200px"
          onClick={clearHandler}
          dontSubmit
          short
        />
        <TextButton
          label={hasMoreOptions ? "Komprimera filtrering" : "Utöka filtrering"}
          onClick={() => setHasMoreOptions((state) => !state)}
        />
      </div>
    </form>
  );
}

export default AdminUsersFilter;
