import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import Button from "../components/UI/Button/Button";
import Input from "../components/UI/Input/Input";
import Popup from "../hoc/Popup/Popup";
import "../styles/nimya-alert.scss";

export async function nimyaAlert({
  title,
  message,
  conifrmText,
}: {
  title: string;
  message: string;
  conifrmText?: string;
}) {
  let confirmed = false;

  confirmed = await new Promise((resolve, reject) =>
    confirmAlert({
      title,
      message,
      customUI: ({ onClose, title, message }) => {
        const CustomUI = ({ onClose, title, message }: any) => {
          const [value, setValue] = useState("");
          const [isWrongConfrimText, setIsWrongConfrimText] = useState(false);

          return (
            <Popup
              showPopup={true}
              onClose={() => {
                onClose();
                resolve(false);
              }}
              style={{
                maxWidth: 500,
              }}
            >
              <div className="nimya-alert-wrapper">
                <p className="title text-l-m">{title}</p>
                <p className="message text-s-r">{message}</p>

                {conifrmText && (
                  <div className="alert-input">
                    {isWrongConfrimText && (
                      <p className="error-message text-s-r">
                        Du har skrivit fel text
                      </p>
                    )}
                    <Input
                      type="text"
                      value={value}
                      onChange={setValue}
                      width="100%"
                      short
                    />
                  </div>
                )}
                <div className="buttons">
                  <Button
                    color="black"
                    label="Ok"
                    onClick={() => {
                      if (conifrmText && value !== conifrmText) {
                        setIsWrongConfrimText(true);
                        return;
                      }

                      onClose();
                      resolve(true);
                    }}
                    short
                    width="100px"
                  />
                  <Button
                    color="black"
                    label="Tillbaka"
                    onClick={() => {
                      onClose();
                      resolve(false);
                    }}
                    short
                    width="100px"
                  />
                </div>
              </div>
            </Popup>
          );
        };

        return <CustomUI onClose={onClose} title={title} message={message} />;
      },
      overlayClassName: "nimya-custom-alert",
    })
  );

  return confirmed;
}
