import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getTags } from "../../api/company";
import { deleteCustomVariable, getCustomVariables } from "../../api/report";
import { useDispatch, useSelector } from "react-redux";
import { QUERY_PARAM, ROUTE, ROUTE_LABEL } from "../../shared/enums";
import Button from "../../components/UI/Button/Button";
import Spinner from "../../components/UI/Spinner/Spinner";
import CustomKeyValuesPopup from "../../containers/CustomKeyValuesPopup/CustomKeyValuesPopup";
import KeyValuesTable from "../../containers/KeyValuesTable/KeyValuesTable";
import { useReportValues } from "../../shared/hooks/useReportValues";
import { nimyaAlert } from "../../shared/nimyaAlert";
import { IVariable } from "../../types/api";
import { IReduxState } from "../../types/redux";
import Dropdown from "../../components/UI/Dropdown/Dropdown";
import "./KeyValues.scss";
import ExtendedFiltering from "../../containers/ExtendedFiltering/ExtendedFiltering";
import { eachMonthOfInterval, endOfMonth, fromUnixTime, getUnixTime, startOfMonth, subMonths } from "date-fns";
import { setFinancialYear } from "../../store/slices/company";
import { useSearchQuery } from "../../shared/hooks/useSearchQuery";
import { getFinancialYearsDropdown } from "../../shared/utility";
import { months, years } from "../../shared/values";


interface IKeyValuesProps {}

function KeyValues(props: IKeyValuesProps) {
  const dispatch = useDispatch();
  const {
    company: { companyId, financialYear, financialYears },
    extendedFiltering: { tagFilter, timePeriod, presetMonth },
  } = useSelector((state: IReduxState) => state);
  const navigate = useNavigate();

  const query = useSearchQuery();

  const [breakMonth, setBreakMonth] = useState(
    `${subMonths(new Date(), 1).getMonth() + 1}`
  );

  const [fromMonth, setFromMonth] = useState("1");
  const [fromYear, setFromYear] = useState(
    subMonths(new Date(), presetMonth === "current" ? 0 : 1)
      .getFullYear()
      .toString()
  );
  const [toMonth, setToMonth] = useState(
    `${subMonths(new Date(), presetMonth === "current" ? 0 : 1).getMonth() + 1}`
  );
  const [toYear, setToYear] = useState(
    subMonths(new Date(), presetMonth === "current" ? 0 : 1)
      .getFullYear()
      .toString()
  );
  
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editVariable, setEditVariable] = useState<IVariable | undefined>(
    undefined
  );
  
  useEffect(() => {
    setToMonth(
      `${
        subMonths(new Date(), presetMonth === "current" ? 0 : 1).getMonth() + 1
      }`
    );
    setBreakMonth(
      `${
        subMonths(new Date(), presetMonth === "current" ? 0 : 1).getMonth() + 1
      }`
    );
  }, [presetMonth]);

  useEffect(() => {
    const fromQuery = query.get(QUERY_PARAM.From);
    const toQuery = query.get(QUERY_PARAM.To);
    if (!fromQuery || !toQuery) return;

    const fromQueryDate = fromUnixTime(+fromQuery);
    const toQueryDate = fromUnixTime(+toQuery);

    setFromMonth(`${fromQueryDate.getMonth() + 1}`);
    setFromYear(fromQueryDate.getFullYear().toString());
    setToMonth(`${toQueryDate.getMonth() + 1}`);
    setToYear(toQueryDate.getFullYear().toString());
    setBreakMonth(`${toQueryDate.getMonth() + 1}`);

    financialYears.forEach((fYear) => {
      if (+fromQuery >= fYear.fromDate && +toQuery <= fYear.toDate) {
        dispatch(setFinancialYear(fYear.financialYear.toString()));
      }
    });
  }, [dispatch, financialYears, query]);

 /* const { from, to, breakUnix, setControlsComponent } = useReportValues();

  useEffect(() => {
    setControlsComponent(
      <Button
        label="Skapa nyckeltal"
        color="black"
        onClick={() => setIsPopupOpen(true)}
      />
    );
  }, [setControlsComponent]);
*/


  const {
    data,
    isSuccess,
    isLoading,
    refetch: refetchVariables,
  } = useQuery({
    queryKey: ["variables", companyId],
    queryFn: async ({ queryKey: [, companyId] }) => {
      const res = await getCustomVariables(companyId);

      return res.data.payload.variables;
    },
  });

  const { data: tags, isSuccess: isTagsSuccess } = useQuery({
    queryKey: ["custom-tags", companyId],
    queryFn: async ({ queryKey: [, companyId] }) => {
      const res = await getTags(companyId);

      return res.data.payload.tags;
    },
  });

  async function deleteVariableHandler(variable: IVariable) {
    if (
      !(await nimyaAlert({
        title: "Ta bort variabel",
        message: `Är du säker på att du vill ta bort ${variable.name}? (Detta går inte att ångra)`,
      }))
    )
      return;

    try {
      await deleteCustomVariable({ companyId, variableId: variable._id });
      toast.success("Nyckeltalet togs bort");
      await refetchVariables();
    } catch (err) {
      toast.error("Något gick fel när nyckeltalet skulle tas bort");
    }
  }

  function alertCreateHandler(variable: IVariable) {
    navigate(`${ROUTE.Alerts}?variableId=${variable._id}`);
  }

  const fromDate = startOfMonth(fromUnixTime(financialYear.from));
  const toDate = endOfMonth(fromUnixTime(financialYear.to));

  const eachMonth = eachMonthOfInterval({ start: fromDate, end: toDate });

  const breakDates = eachMonth.filter((d) => d.getMonth() + 1 === +breakMonth);

  const breakDate = breakDates.reduce((prev, current) =>
    getUnixTime(prev) > getUnixTime(current) ? prev : current
  );

  let from = getUnixTime(fromDate);
  let to = getUnixTime(toDate);
  let breakUnix = getUnixTime(endOfMonth(breakDate));

  if (timePeriod === "custom") {
    from = getUnixTime(startOfMonth(new Date(+fromYear, +fromMonth - 1, 1)));
    to = getUnixTime(endOfMonth(new Date(+toYear, +toMonth - 1, 1)));
    breakUnix = getUnixTime(endOfMonth(new Date(+toYear, +toMonth - 1, 1)));
  }

  return (
    <div className="key-values">
       <div className="controls">
        
          <Button
          label="Skapa nyckeltal"
          color="black"
          onClick={() => setIsPopupOpen(true)}
        />
        <div className="dropdowns">

        {timePeriod === "financial-year" ? (
          <>
            <Dropdown
              title="År"
              options={getFinancialYearsDropdown(financialYears)}
              onSelect={(v) => dispatch(setFinancialYear(v))}
              value={financialYear.identifier}
              width="200px"
            />
            <Dropdown
              title="Månad"
              options={months}
              onSelect={setBreakMonth}
              value={breakMonth}
            />
          </>
        ) : (
          <>
            <Dropdown
              title="Från år"
              options={years()}
              onSelect={setFromYear}
              value={fromYear}
            />
            <Dropdown
              title="Från månad"
              options={months}
              onSelect={setFromMonth}
              value={fromMonth}
            />
            <Dropdown
              title="Till år"
              options={years()}
              onSelect={setToYear}
              value={toYear}
            />
            <Dropdown
              title="Till månad"
              options={months}
              onSelect={(v) => {
                setToMonth(v);
                setBreakMonth(v);
              }}
              value={toMonth}
            />
          </>
        )}
        
        <ExtendedFiltering />
        </div>
      </div>
      <CustomKeyValuesPopup
        showPopup={isPopupOpen}
        onClose={() => {
          setIsPopupOpen(false);
          setEditVariable(undefined);
        }}
        editVariable={editVariable}
        onEditVariableFromList={(v) => setEditVariable(v)}
      />
      {isLoading ? (
        <Spinner />
      ) : (
        isSuccess &&
        tagFilter === "all" && (
          <KeyValuesTable
            title="Globala nyckeltal"
            companyId={companyId}
            variables={data.filter(
              (v) => !!v.showInTable && v.global && !v.tags.length
            )}
            from={from}
            to={to}
            breakUnix={breakUnix}
            isGlobal
          />
        )
      )}
      {isTagsSuccess &&
        isSuccess &&
        tags
          .filter((t) =>
            tagFilter === "all" ? true : tagFilter.includes(t.name)
          )
          .map((t) => (
            <KeyValuesTable
              key={t.name}
              title={`# ${t.name}`}
              companyId={companyId}
              variables={data.filter(
                (v) => !!v.showInTable && v.tags.includes(t.name)
              )}
              from={from}
              to={to}
              breakUnix={breakUnix}
              onEditVariable={(v) => {
                setEditVariable(v);
                setIsPopupOpen(true);
              }}
              onDeleteVariable={deleteVariableHandler}
              onAlertCreate={alertCreateHandler}
            />
          ))}
      {isSuccess && tagFilter === "all" && (
        <KeyValuesTable
          title="# Övriga"
          companyId={companyId}
          variables={data.filter(
            (v) => !!v.showInTable && !v.tags.length && !v.global
          )}
          from={from}
          to={to}
          breakUnix={breakUnix}
          onEditVariable={(v) => {
            setEditVariable(v);
            setIsPopupOpen(true);
          }}
          onDeleteVariable={deleteVariableHandler}
          onAlertCreate={alertCreateHandler}
        />
      )}
    </div>
  );
}

export default KeyValues;
