import { useOutletContext } from "react-router-dom";
import { ILicense } from "../../types/api";

interface ContextType {
  licenses: ILicense[];
  setControlsComponent: (component?: JSX.Element) => void;
}

export function useSettingsValues() {
  return useOutletContext<ContextType>();
}
