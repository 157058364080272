import { eachMonthOfInterval, fromUnixTime, lightFormat } from "date-fns";
import { months } from "../../shared/values";
import { ILiquidityReport, ILiquidityArray } from "../../types/api";
import "./LiquidityReport.scss";
import LiquidityReportRow from "./LiquidityReportRow/LiquidityReportRow";

interface ILiquidityReportProps {
  from: number;
  to: number;
  liquidityReport: ILiquidityReport;
  onInitialLiquidityChanged: (initialLiquidity: number) => void;
  initialLiquidity: number;
}

function LiquidityReport(props: ILiquidityReportProps) {
  function convertToNumberArray(liquidityArray: ILiquidityArray) {
    return Object.entries(liquidityArray)
      .filter(([key]) => +key >= 0)
      .map(([, val]) => val)
      .slice(0, 12);
  }

  const monthsArray = eachMonthOfInterval({
    start: fromUnixTime(props.from),
    end: fromUnixTime(props.to),
  }).map((d) => lightFormat(d, "yyyy-M"));

  function cellHoverHandler(cellIndex: number, entering: boolean) {
    const liquidityMonthStartRowEl = document.querySelector(
      ".liquidity-report-row"
    );

    if (liquidityMonthStartRowEl) {
      const cellEl = liquidityMonthStartRowEl.children[
        cellIndex + 1
      ] as HTMLElement;
      if (cellEl) {
        if (entering) {
          cellEl.style.background = "#efefff";
        } else {
          cellEl.style.background = "#fff";
        }
      }
    }
  }

  return (
    <div className="liquidity-report">
      <div className="top">
        <p className="title text-m-m">Beräkningar</p>
      </div>
      <table>
        <thead>
          <tr>
            {monthsArray.map((mKey) => (
              <th key={mKey} className="text-m-m">
                {months[+mKey.split("-")[1] - 1].label.slice(0, 3)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <LiquidityReportRow
            title="Pengar på banken vid månadens start"
            data={convertToNumberArray(
              props.liquidityReport.liquidityMonthStart
            )}
            onInitialLiquidityChanged={props.onInitialLiquidityChanged}
            initialLiquidity={props.initialLiquidity}
          />
          <LiquidityReportRow
            title="Intäkter ink. moms"
            data={convertToNumberArray(props.liquidityReport.income)}
          />
          <LiquidityReportRow
            title="Fordringsbetalningar"
            data={convertToNumberArray(props.liquidityReport.claimIncome)}
            backgroundColor="#E8CCFF"
          />
          <LiquidityReportRow
            title="Skuldbetalningar"
            data={convertToNumberArray(props.liquidityReport.claimCost)}
            backgroundColor="#FFCCCC"
          />
          <LiquidityReportRow
            title="Utgifter ink. moms"
            data={convertToNumberArray(props.liquidityReport.expenses)}
          />
          <LiquidityReportRow
            title="Momsskuld/fordran"
            data={convertToNumberArray(props.liquidityReport.vatDept)}
          />
          <LiquidityReportRow
            title="Lönekostnader"
            data={convertToNumberArray(props.liquidityReport.personalCost)}
          />
          <LiquidityReportRow
            title="Pengar på banken vid månadens slut"
            data={convertToNumberArray(props.liquidityReport.liquidityMonthEnd)}
            onCellHover={cellHoverHandler}
          />
          <LiquidityReportRow
            title="Förändring"
            data={convertToNumberArray(
              props.liquidityReport.liquidityDifference
            )}
          />
        </tbody>
      </table>
    </div>
  );
}

export default LiquidityReport;
