import { fromUnixTime, lightFormat } from "date-fns";
import { motion, Variants } from "framer-motion";
import { useState } from "react";
import { useQuery } from "react-query";
import { adminGetUser } from "../../api/admin";
import UserInfoRow from "./UserInfoRow/UserInfoRow";
import "./UserRow.scss";

interface IUserRowProps {
  email: string;
  firstName?: string;
  lastName?: string;
  userId: string;
}

function UserRow(props: IUserRowProps) {
  const [isOpen, setIsOpen] = useState(false);

  const { data, isSuccess, isLoading } = useQuery({
    queryKey: ["admin-user", props.userId],
    queryFn: async () => {
      const res = await adminGetUser(props.userId);
      return res.data.payload.user;
    },
    enabled: isOpen,
  });

  function unixToDateString(unix?: number) {
    if (!unix) return "";
    return lightFormat(fromUnixTime(unix), "yyyy-MM-dd HH:mm:ss");
  }

  const rowVariants: Variants = {
    offscreen: {
      y: 20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
      },
    },
  };

  const rowTablesVariants: Variants = {
    closed: {
      height: 0,
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
    open: {
      height: "auto",
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <motion.div
      className="user-row"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.1 }}
      variants={rowVariants}
      transition={{ ease: "easeInOut" }}
    >
      <div
        className="row text-m-m"
        onClick={() => setIsOpen((state) => !state)}
      >
        <span>{props.email}</span>
        <span>{`${props.firstName || ""} ${props.lastName || ""}`}</span>
        <i
          className="fa-solid fa-caret-down"
          style={isOpen ? { transform: "rotate(180deg)" } : {}}
        />
      </div>
      <motion.div
        className="user-info"
        variants={rowTablesVariants}
        animate={isOpen && !isLoading ? "open" : "closed"}
      >
        {isSuccess && (
          <div className="user-info-wrapper">
            <UserInfoRow title="Användar Id" value={data._id} />
            <UserInfoRow
              title="Skapad"
              value={unixToDateString(data.created)}
            />
            <UserInfoRow title="Email" value={data.email} />
            <UserInfoRow title="Förnamn" value={data.first_name} />
            <UserInfoRow title="Efternamn" value={data.last_name} />
            <UserInfoRow title="Inbjuden av" value={data.invitedBy} />
            <UserInfoRow
              title="Har satt upp ett lösenord"
              value={data.isLocal}
            />
            <UserInfoRow
              title="Senaste Autentisering"
              value={unixToDateString(data.lastAuthenticated)}
            />
            <UserInfoRow
              title="Senaste Login"
              value={unixToDateString(data.lastLogin)}
            />
            <UserInfoRow
              title="Senaste inloggningsförsök"
              value={unixToDateString(data.lastLoginAttempt)}
            />
            <UserInfoRow
              title="Telefonnummer"
              value={data.phone_nr || undefined}
            />
            <UserInfoRow
              title="Tidigare senast Autentisering"
              value={unixToDateString(data.previousLastAuthenticated)}
            />
            <UserInfoRow
              title="Antal återställningar av lösenord"
              value={data.resetedPassword}
            />
          </div>
        )}
      </motion.div>
    </motion.div>
  );
}

export default UserRow;
