import { IFormulaItem } from "../../../types/internal";
import "./KeyValueArithmetic.scss";

interface IKeyValueArithmeticProps {
  type: "+" | "-" | "*" | "/" | "(" | ")";
  onClick: (arithmetic: IFormulaItem) => void;
}

function KeyValueArithmetic(props: IKeyValueArithmeticProps) {
  let displayValue;
  switch (props.type) {
    case "+":
      displayValue = <i className="fa-solid fa-plus" />;
      break;
    case "-":
      displayValue = <i className="fa-solid fa-minus" />;
      break;
    case "*":
      displayValue = <i className="fa-solid fa-times" />;
      break;
    case "/":
      displayValue = <i className="fa-solid fa-divide" />;
      break;
    case "(":
      displayValue = <i className="fa-solid fa-bracket-round" />;
      break;
    case ")":
      displayValue = <i className="fa-solid fa-bracket-round-right" />;
      break;

    default:
      break;
  }

  return (
    <div
      className="key-value-arithmetic"
      onClick={() =>
        props.onClick({
          type: "arithmetic",
          value: props.type,
        })
      }
    >
      {displayValue}
    </div>
  );
}

export default KeyValueArithmetic;
