import {
  eachMonthOfInterval,
  fromUnixTime,
  getUnixTime,
  lightFormat,
  subYears,
} from "date-fns";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getVariablesResult } from "../../api/report";
import LegendText from "../../components/LegendText/LegendText";
import Spinner from "../../components/UI/Spinner/Spinner";
import { getReportTextColor } from "../../shared/reportUtility";
import { months } from "../../shared/values";
import { IKeyValues, IVariable } from "../../types/api";
import { CompareWith, IReduxState } from "../../types/redux";
import KeyValuesRow from "./KeyValuesRow/KeyValuesRow";
import "./KeyValuesTable.scss";

interface IKeyValuesTableProps {
  companyId: string;
  variables: IVariable[];
  from: number;
  to: number;
  breakUnix: number;
  title: string;
  isGlobal?: boolean;
  onEditVariable?: (variable: IVariable) => void;
  onDeleteVariable?: (variable: IVariable) => void;
  onAlertCreate?: (variable: IVariable) => void;
}

function KeyValuesTable(props: IKeyValuesTableProps) {
  const { compareWith } = useSelector(
    (state: IReduxState) => state.extendedFiltering
  );

  const { data, isLoading, isSuccess } = useQuery<
    { result: IKeyValues; compare: IKeyValues },
    unknown,
    { result: IKeyValues; compare: IKeyValues },
    ["key-values", string, number, number, number, IVariable[], CompareWith]
  >({
    queryKey: [
      "key-values",
      props.companyId,
      props.from,
      props.to,
      props.breakUnix,
      props.variables,
      compareWith,
    ],
    queryFn: async ({
      queryKey: [, companyId, from, to, breakUnix, variables, compareWith],
    }) => {
      const compareFrom = getUnixTime(
        subYears(fromUnixTime(from), compareWith === "budget" ? 0 : 1)
      );
      const compareTo = getUnixTime(
        subYears(fromUnixTime(to), compareWith === "budget" ? 0 : 1)
      );

      const [result, compare] = await Promise.all([
        getVariablesResult({
          companyId,
          from,
          to: breakUnix,
          variables: variables.map((v) => v.name),
        }),
        getVariablesResult({
          companyId,
          from: compareFrom,
          to: compareTo,
          variables: variables.map((v) => v.name),
          budget: compareWith === "budget",
        }),
      ]);

      return { result, compare: compare };
    },
    enabled: props.variables.length > 0,
  });

  const monthsArray = eachMonthOfInterval({
    start: fromUnixTime(props.from),
    end: fromUnixTime(props.to),
  }).map((d) => lightFormat(d, "yyyy-M"));

  const hasForecast = props.breakUnix !== props.to;

  const spannText = `(${months[fromUnixTime(props.from).getMonth()].label.slice(
    0,
    3
  )} - ${months[fromUnixTime(props.breakUnix).getMonth()].label.slice(0, 3)})`;

  const forecastSpannText = `(${months[
    fromUnixTime(props.breakUnix).getMonth()
  ].label.slice(0, 3)} - ${months[
    fromUnixTime(props.to).getMonth()
  ].label.slice(0, 3)})`;

  function globalAccountRangeSortFunction(a: IVariable, b: IVariable) {
    const aIndex = contextSort.indexOf(a.context || "");
    const bIndex = contextSort.indexOf(b.context || "");
    return aIndex - bIndex;
  }

  const contextSort = ["income", "expense", "result"];

  return props.variables.length ? (
    <div className="key-values-table">
      <div className="top">
        <span className="text-m-m">
          {props.isGlobal && <i className="fa-light fa-globe" />}
          {props.title}
        </span>
        <div className="ledgend-wrapper">
          <LegendText label={`Verklighet ${spannText}`} color="#000D33" />
          <LegendText
            label={`${
              compareWith === "budget" ? "Budget" : "Föregående år"
            } ${spannText}`}
            color="#818094"
          />
        </div>
        <div className="ledgend-wrapper">
          {hasForecast && (
            <LegendText
              label={`Prognos ${forecastSpannText}`}
              color="#818094"
              noDot
            />
          )}
          <LegendText label="Intäkter" color={getReportTextColor("income")} />
          <LegendText label="Kostnader" color={getReportTextColor("expense")} />
          <LegendText label="Resultat" color={getReportTextColor("result")} />
        </div>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        isSuccess && (
          <div className="report-wrapper">
            <table>
              <thead>
                <tr>
                  {monthsArray.map((mKey) => (
                    <th key={mKey} className="text-m-m">
                      {months[+mKey.split("-")[1] - 1].label.slice(0, 3)}
                    </th>
                  ))}
                  <th className="text-m-m">Sum</th>
                </tr>
              </thead>
              <tbody>
                {props.variables
                  .sort(globalAccountRangeSortFunction)
                  .map((v) => (
                    <KeyValuesRow
                      key={v.name}
                      name={v.name}
                      row={data.result[v.name]}
                      compareRow={data.compare[v.name]}
                      monthKeys={monthsArray}
                      isPercent={v.isPercent}
                      isGlobal={v.global}
                      breakUnix={props.breakUnix}
                      variableContext={v.context}
                      compareWith={compareWith}
                      onEdit={
                        props.onEditVariable
                          ? () => props.onEditVariable!(v)
                          : undefined
                      }
                      onArchive={() => {
                        toast.info("Funktionen är inte implementerad än");
                      }}
                      onDelete={
                        props.onDeleteVariable
                          ? () => props.onDeleteVariable!(v)
                          : undefined
                      }
                      onCopy={() => {
                        toast.info("Funktionen är inte implementerad än");
                      }}
                      onAlertCreate={
                        props.onAlertCreate
                          ? () => props.onAlertCreate!(v)
                          : undefined
                      }
                    />
                  ))}
              </tbody>
            </table>
          </div>
        )
      )}
    </div>
  ) : null;
}

export default KeyValuesTable;
