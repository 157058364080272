import "./UnreadIndicator.scss";

interface IUnreadIndicatorProps {
  amount: number;
  position?: {
    top?: number;
    left?: number;
    bottom?: number;
    right?: number;
  };
  title?: string;
}

function UnreadIndicator(props: IUnreadIndicatorProps) {
  return props.amount > 0 ? (
    <div
      className="unread-indicator"
      style={
        props.position
          ? props.position
          : {
              top: 0,
              right: 0,
            }
      }
      title={
        props.title
          ? props.title
          : props.amount === 1
          ? `${props.amount} oläst rapport`
          : `${props.amount} olästa rapporter`
      }
    >
      {props.amount >= 100 ? "99+" : props.amount}
    </div>
  ) : null;
}

export default UnreadIndicator;
