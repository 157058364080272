import nodeAxios, { AxiosError } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { ClientJS } from "clientjs";
import { rehydrateAdminToken } from "../api/auth";
import store from "../store";
import { authSetIsBackendDown } from "../store/slices/auth";
import { adminLogout } from "../store/thunks/auth";
import { LOCAL_STORAGE_KEY } from "./enums";
import { environment } from "./environment";

const adminAxios = nodeAxios.create({
  baseURL: environment.apiBaseUrl,
});

adminAxios.interceptors.request.use((config) => {
  const client = new ClientJS();

  config.headers["Authorization"] = `Bearer ${localStorage.getItem(
    LOCAL_STORAGE_KEY.AdminToken
  )}`;
  config.headers["nimya-fingerprint"] = client.getFingerprint();

  return config;
});

//codes when backend is down 523 522 521 520
adminAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (
      window.navigator.onLine &&
      !error.response &&
      error.code === "ERR_NETWORK"
    ) {
      store.dispatch(authSetIsBackendDown(true));
    }
    return Promise.reject(error);
  }
);

const refreshTokenInstance = nodeAxios.create({
  baseURL: environment.apiBaseUrl,
});

refreshTokenInstance.interceptors.response.use(
  (res) => res,
  (err) => {
    throw err;
  }
);

createAuthRefreshInterceptor(
  adminAxios,
  async (failedRequest) => {
    const refreshToken = localStorage.getItem(
      LOCAL_STORAGE_KEY.AdminRefreshToken
    );
    if (!refreshToken) {
      adminLogout();
      return Promise.reject(failedRequest);
    }

    let res;
    try {
      res = await rehydrateAdminToken({
        axios: refreshTokenInstance,
        refreshToken,
      });
    } catch (error) {
      adminLogout();
      return;
    }

    localStorage.setItem(
      LOCAL_STORAGE_KEY.AdminToken,
      res.data.payload.adminAccessToken
    );
    localStorage.setItem(
      LOCAL_STORAGE_KEY.AdminRefreshToken,
      res.data.payload.adminRefreshToken
    );

    failedRequest.response.config.headers["Authorization"] =
      "Bearer " + res.data.payload.adminAccessToken;
    return await Promise.resolve();
  },
  {
    statusCodes: [401, 406, 410],
  }
);

export default adminAxios;
