import { useEffect, useState } from "react";
import "./Countdown.scss";

interface ICountdownProps {
  duration: number;
  title?: string;
  onFinish?: () => void;
}

function Countdown(props: ICountdownProps) {
  //In seconds
  const [timeLeft, setTimeLeft] = useState(props.duration);

  useEffect(() => {
    if (timeLeft <= 0 && props.onFinish) {
      props.onFinish();
    }
    const timer =
      timeLeft > 0 &&
      setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

    return () => {
      if (timer) clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  useEffect(() => {
    setTimeLeft(props.duration);
  }, [props.duration]);

  function formatSeconds(seconds: number) {
    if (seconds < 0) return "00:00:00";

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsLeft = Math.floor(seconds % 60);

    const hourString = `0${hours}`.slice(-2);
    const minuteString = `0${minutes}`.slice(-2);
    const secondString = `0${secondsLeft}`.slice(-2);

    return `${hourString}.${minuteString}.${secondString}`;
  }

  return (
    <div className="countdown">
      {props.title}
      <i className="fa-regular fa-clock" />
      {formatSeconds(timeLeft)}
    </div>
  );
}

export default Countdown;
