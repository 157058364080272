import { fromUnixTime } from "date-fns";
import { motion, Variants } from "framer-motion";
import { Link } from "react-router-dom";
import { QUERY_PARAM, ROUTE } from "../../shared/enums";
import { convertToNumberWithSpaces, getBranchName } from "../../shared/utility";
import { months } from "../../shared/values";
import { setCompany } from "../../store/thunks/company";
import Button from "../UI/Button/Button";
import "./OverviewRow.scss";

interface IOverviewRowProps {
  name: string;
  companyId: string;
  income: number;
  expenses: number;
  budgetIncome: number;
  budgetExpenses: number;
  cashAndBank: number;
  capital: number;
  branch?: string;
  employees?: number;
  from: number;
  to: number;
}

function OverviewRow(props: IOverviewRowProps) {
  const rowVariants: Variants = {
    offscreen: {
      y: 20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
      },
    },
  };

  const spannText = `(${months[fromUnixTime(props.from).getMonth()].label.slice(
    0,
    3
  )} - ${months[fromUnixTime(props.to).getMonth()].label.slice(0, 3)})`;

  return (
    <motion.div
      className="overview-row"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.1 }}
      variants={rowVariants}
      transition={{ ease: "easeInOut" }}
    >
      <div className="top text-m-m">{props.name}</div>
      <div className="content">
        <Link
          className="section"
          to={{
            pathname: ROUTE.Result,
            search: `${QUERY_PARAM.Graph}=income`,
          }}
          onClick={() => setCompany(props.companyId)}
        >
          <div className="section-value">
            <p className="title text-m-r">Intäkter {spannText}</p>
            <p className="value text-l-m">
              {convertToNumberWithSpaces(props.income)}
            </p>
          </div>
          <div className="section-value">
            <p className="title text-m-r">Budget</p>
            <p className="value text-l-m">
              {convertToNumberWithSpaces(props.budgetIncome)}
            </p>
          </div>
        </Link>
        <Link
          className="section"
          to={{
            pathname: ROUTE.Result,
            search: `${QUERY_PARAM.Graph}=expense`,
          }}
          onClick={() => setCompany(props.companyId)}
        >
          <div className="section-value">
            <p className="title text-m-r">Kostnader {spannText}</p>
            <p className="value text-l-m">
              {convertToNumberWithSpaces(props.expenses)}
            </p>
          </div>
          <div className="section-value">
            <p className="title text-m-r">Budget</p>
            <p className="value text-l-m">
              {convertToNumberWithSpaces(props.budgetExpenses)}
            </p>
          </div>
        </Link>
        <Link
          className="section"
          to={{
            pathname: ROUTE.Result,
            search: `${QUERY_PARAM.Graph}=result`,
          }}
          onClick={() => setCompany(props.companyId)}
        >
          <div className="section-value">
            <p className="title text-m-r">Resultat {spannText}</p>
            <p className="value text-l-m">
              {convertToNumberWithSpaces(props.income + props.expenses)}
            </p>
          </div>
          <div className="section-value">
            <p className="title text-m-r">Budget</p>
            <p className="value text-l-m">
              {convertToNumberWithSpaces(
                props.budgetIncome + props.budgetExpenses
              )}
            </p>
          </div>
        </Link>
        <Link
          className="section"
          to={ROUTE.CashFlow}
          onClick={() => setCompany(props.companyId)}
        >
          <div className="section-value">
            <p className="title text-m-r">Kassa & Bank</p>
            <p className="value text-l-m">
              {convertToNumberWithSpaces(props.cashAndBank)}
            </p>
          </div>
          <div className="section-value">
            <p className="title text-m-r">Eget kapital</p>
            <p className="value text-l-m">
              {convertToNumberWithSpaces(props.capital)}
            </p>
          </div>
        </Link>
        {props.branch && props.employees ? (
          <div className="section">
            <div className="section-value">
              <p className="title text-m-r">Bransch</p>
              <p className="value text-l-m">
                {getBranchName(props.branch) || "Inte angivet"}
              </p>
            </div>
            <div className="section-value">
              <p className="title text-m-r">Medarbetare</p>
              <p className="value text-l-m">
                {props.employees || "Inte angivet"}
              </p>
            </div>
          </div>
        ) : (
          <div className="section">
            <div className="section-value">
              <p className="title text-m-r">Komplettera din profil</p>
              <p className="value">{"ㅤ"}</p>
            </div>
            <div className="sectio-value">
              <p className="title">{"ㅤ"}</p>
              <Link
                to={ROUTE.CompanySettings}
                onClick={() => setCompany(props.companyId)}
              >
                <Button label="Till inställningar" color="black" short />
              </Link>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
}

export default OverviewRow;
