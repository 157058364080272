import { fromUnixTime, isSameMonth } from "date-fns";
import { useState } from "react";
import { ECONOMIC_TERM } from "../../../shared/enums";
import {
  getReportBackgroundColor,
  getReportTextColor,
  getResultRowHybridSum,
  getResultRowSum,
} from "../../../shared/reportUtility";
import {
  convertToNumberWithSpaces,
  formatKeyForCompare,
  isDateAfter,
  stringToDate,
} from "../../../shared/utility";
import { IAccount, IResultReportRow } from "../../../types/api";
import { RowType } from "../../../types/internal";
import { CompareWith } from "../../../types/redux";
import ResultReportCell from "../ResultReportCell/ResultReportCell";
import ResultReportSubrow from "../ResultReportSubrow/ResultReportSubrow";
import "./ResultReportRow.scss";

interface IResultReportRowProps {
  title: string;
  rowType: RowType;
  row: IResultReportRow;
  compareRow?: IResultReportRow;
  monthsArray: string[];
  breakUnix: number;
  noSubrows?: boolean;
  compareWith: CompareWith;
  accountRecord?: Record<number, IAccount>;
}

function ResultReportRow(props: IResultReportRowProps) {
  const [isOpen, setIsOpen] = useState(false);

  const rowSum = getResultRowSum(props.row.Summa, props.breakUnix);

  const compareRowSum = getResultRowSum(props.compareRow?.Summa || {});

  const hybridSum = getResultRowHybridSum({
    row: props.row.Summa,
    compareRow: props.compareRow?.Summa || {},
    compareWith: props.compareWith,
    breakUnix: props.breakUnix,
    monthsArray: props.monthsArray,
  });

  return (
    <>
      <tr
        className="result-report-row-title"
        style={{
          backgroundColor: getReportBackgroundColor(props.rowType),
          cursor: props.noSubrows ? "auto" : "pointer",
        }}
      >
        <td
          className="text-s-r"
          colSpan={100}
          onClick={() => setIsOpen((state) => !state)}
        >
          <div className="title-wrapper">
            <i
              className={
                props.noSubrows
                  ? ""
                  : isOpen
                  ? "fa-solid fa-chevron-up"
                  : "fa-solid fa-chevron-down"
              }
            />
            <span>{props.title}</span>
            <span
              className="hybrid-sum text-xs-m"
              style={{
                color: getReportTextColor(props.rowType),
              }}
            >
              {convertToNumberWithSpaces(hybridSum)}
            </span>
          </div>
        </td>
      </tr>
      <tr className="result-report-row">
        {props.monthsArray.map((mKey) => (
          <ResultReportCell
            key={mKey}
            value={props.row.Summa[mKey] || 0}
            compareValue={
              props.compareRow?.Summa[
                formatKeyForCompare(mKey, props.compareWith === "budget")
              ] || 0
            }
            isAfterBreak={isDateAfter(
              stringToDate(mKey),
              fromUnixTime(props.breakUnix)
            )}
            rowType={props.rowType}
            isBreakMonth={isSameMonth(
              stringToDate(mKey),
              fromUnixTime(props.breakUnix)
            )}
          />
        ))}
        <ResultReportCell
          value={rowSum}
          compareValue={compareRowSum}
          rowType={props.rowType}
        />
      </tr>
      {isOpen &&
        Object.keys(props.row)
          .filter(
            (key) => key !== ECONOMIC_TERM.Sum && key !== ECONOMIC_TERM.Interval
          )
          .map((key) => (
            <ResultReportSubrow
              key={key}
              title={key}
              rowType={props.rowType}
              row={props.row[key]}
              compareRow={props.compareRow?.[key]}
              monthsArray={props.monthsArray}
              breakUnix={props.breakUnix}
              compareWith={props.compareWith}
              accountRecord={props.accountRecord}
            />
          ))}
    </>
  );
}

export default ResultReportRow;
