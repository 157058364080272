import { eachMonthOfInterval, fromUnixTime, lightFormat } from "date-fns";
import {
  IBalanceReport,
  ICashflowReport,
  IPeriodBalances,
  IResultReport,
  IResultReportSubrow,
} from "../types/api";
import { IKeyString, RowType } from "../types/internal";
import { CompareWith } from "../types/redux";
import clone from "./clone";
import { ECONOMIC_TERM } from "./enums";
import { formatKeyForCompare, isDateAfter, stringToDate } from "./utility";

export function formatResultReport(report: IResultReport) {
  const newReport = clone(report);

  Object.keys(newReport).forEach((key) => {
    Object.keys(newReport[key]).forEach((subKey) => {
      if (subKey === "Intervall") return;
      Object.keys(newReport[key][subKey]).forEach((mKey) => {
        if (mKey === "Intervall") return;

        if (!newReport[key][subKey][mKey]) {
          newReport[key][subKey][mKey] = 0;
        }
        if (key !== "Intäkter") {
          newReport[key][subKey][mKey] = newReport[key][subKey][mKey] * -1;
        }
      });
    });
  });
  return newReport;
}

export function getExpenses({
  report,
  from,
  to,
}: {
  report: IResultReport;
  from: number;
  to: number;
}) {
  let expense = 0;

  Object.keys(report).forEach((key) => {
    if (key === "Intäkter") return;

    Object.keys(report[key].Summa).forEach((mKey) => {
      if (
        isDateAfter(stringToDate(mKey), fromUnixTime(from)) &&
        isDateAfter(fromUnixTime(to), stringToDate(mKey))
      ) {
        expense += report[key].Summa[mKey];
      }
    });
  });

  return expense;
}

export function getIncome({
  report,
  from,
  to,
}: {
  report: IResultReport;
  from: number;
  to: number;
}) {
  let income = 0;

  const fromDate = fromUnixTime(from);
  const toDate = fromUnixTime(to);

  Object.keys(report).forEach((key) => {
    if (key !== ECONOMIC_TERM.Income) return;

    Object.keys(report[key].Summa).forEach((mKey) => {
      const date = stringToDate(mKey);

      if (isDateAfter(date, fromDate) && isDateAfter(toDate, date)) {
        income += report[key].Summa[mKey];
      }
    });
  });

  return income;
}

export function getResult({
  report,
  from,
  to,
}: {
  report: IResultReport;
  from: number;
  to: number;
}) {
  let result = 0;

  Object.keys(report).forEach((key) => {
    Object.keys(report[key].Summa).forEach((mKey) => {
      if (
        isDateAfter(stringToDate(mKey), fromUnixTime(from)) &&
        isDateAfter(fromUnixTime(to), stringToDate(mKey))
      ) {
        result += report[key].Summa[mKey];
      }
    });
  });

  return result;
}

export function getIncomeMonthly({
  report,
  from,
  to,
  breakUnix,
}: {
  report: IResultReport;
  from: number;
  to: number;
  breakUnix?: number;
}) {
  const income = report[ECONOMIC_TERM.Income].Summa;
  const months = eachMonthOfInterval({
    start: fromUnixTime(from),
    end: fromUnixTime(to),
  }).map((d) => lightFormat(d, "yyyy-M"));
  const breakDate = breakUnix ? fromUnixTime(breakUnix) : undefined;

  return months.map((mKey) => {
    if (breakDate && isDateAfter(stringToDate(mKey), breakDate)) return 0;

    return income[mKey] || 0;
  });
}

export function getExpenseMonthly({
  report,
  from,
  to,
  breakUnix,
}: {
  report: IResultReport;
  from: number;
  to: number;
  breakUnix?: number;
}) {
  const expenses: number[] = [];
  const months = eachMonthOfInterval({
    start: fromUnixTime(from),
    end: fromUnixTime(to),
  }).map((d) => lightFormat(d, "yyyy-M"));
  const breakDate = breakUnix ? fromUnixTime(breakUnix) : undefined;

  Object.keys(report).forEach((key) => {
    if (key === ECONOMIC_TERM.Income) return;

    months.forEach((mKey, i) => {
      if (expenses[i] === undefined) expenses[i] = 0;

      const date = stringToDate(mKey);

      if (breakDate && isDateAfter(date, breakDate)) return;

      expenses[i] += (report[key].Summa[mKey] || 0) * -1;
    });
  });

  return expenses;
}

export function getResultMonthly({
  report,
  from,
  to,
  breakUnix,
}: {
  report: IResultReport;
  from: number;
  to: number;
  breakUnix?: number;
}) {
  const result: number[] = [];
  const months = eachMonthOfInterval({
    start: fromUnixTime(from),
    end: fromUnixTime(to),
  }).map((d) => lightFormat(d, "yyyy-M"));
  const breakDate = breakUnix ? fromUnixTime(breakUnix) : undefined;

  Object.keys(report).forEach((key) => {
    months.forEach((mKey, i) => {
      if (result[i] === undefined) result[i] = 0;

      if (breakDate && isDateAfter(stringToDate(mKey), breakDate)) return;

      result[i] += report[key].Summa[mKey] || 0;
    });
  });

  return result;
}

export function getResultRowSum(row: IResultReportSubrow, breakUnix?: number) {
  return Object.keys(row).reduce((total, mKey) => {
    if (mKey === "Intervall") return total;

    if (breakUnix && isDateAfter(stringToDate(mKey), fromUnixTime(breakUnix)))
      return total;

    return total + row[mKey];
  }, 0);
}

export function getResultRowHybridSum({
  row,
  compareRow,
  monthsArray,
  breakUnix,
  compareWith = "previous-year",
}: {
  row: IResultReportSubrow;
  compareRow: IResultReportSubrow;
  monthsArray: string[];
  compareWith?: CompareWith;
  breakUnix?: number;
}) {
  return monthsArray.reduce((total, mKey) => {
    let valueToAdd = 0;

    if (!!row[mKey]) valueToAdd = row[mKey];

    if (breakUnix && isDateAfter(stringToDate(mKey), fromUnixTime(breakUnix)))
      valueToAdd =
        compareRow[formatKeyForCompare(mKey, compareWith === "budget")] || 0;

    return total + valueToAdd;
  }, 0);
}

export function getReportTextColor(type: RowType) {
  switch (type) {
    case "income":
      return "#5B93FF";

    case "expense":
      return "#EF5FFC";

    case "result":
      return "#00B448";
  }
}

export function getReportBackgroundColor(type: RowType) {
  switch (type) {
    case "income":
      return "#DEEAFF";

    case "expense":
      return "#FDF8FF";

    case "result":
      return "#E8F8F9";
  }
}

export function convertResultReportToAccountRangeMap(report: IResultReport) {
  const result: IKeyString<IKeyString<number>> = {};
  Object.keys(report).forEach((key) => {
    const isCost = key !== ECONOMIC_TERM.Income;
    Object.keys(report[key]).forEach((subKey) => {
      if (subKey === ECONOMIC_TERM.Interval || subKey === ECONOMIC_TERM.Sum)
        return;
      Object.keys(report[key][subKey]).forEach((monthKey) => {
        if (monthKey === ECONOMIC_TERM.Interval) return;
        const accountRange = report[key][subKey].Intervall.join("-");
        if (!result[accountRange]) {
          result[accountRange] = {};
        }
        const newMonthKey = `${+monthKey.split("-")[0] + 1}-${
          monthKey.split("-")[1]
        }`;
        if (!result[accountRange][newMonthKey]) {
          result[accountRange][newMonthKey] = 0;
        }
        result[accountRange][newMonthKey] += isCost
          ? -1 * report[key][subKey][monthKey]
          : report[key][subKey][monthKey];
      });
    });
  });
  return result;
}

export function convertPeriodBalancesToResultReport(
  resultReport: IResultReport,
  budgetPbs: IKeyString<IKeyString<number>>
) {
  const result: IResultReport = {};
  Object.keys(resultReport).forEach((key) => {
    result[key] = {
      Summa: {},
      Intervall: resultReport[key].Intervall,
    };
    const [rangeStart, rangeEnd] = resultReport[key].Intervall as number[];
    Object.keys(budgetPbs).forEach((account) => {
      if (+account >= rangeStart && +account <= rangeEnd) {
        Object.keys(budgetPbs[account]).forEach((period) => {
          if (!result[key].Summa[period]) result[key].Summa[period] = 0;
          result[key].Summa[period] += budgetPbs[account][period];
        });
      }
    });
    Object.keys(resultReport[key]).forEach((subKey) => {
      if (subKey === ECONOMIC_TERM.Interval || subKey === ECONOMIC_TERM.Sum)
        return;
      result[key][subKey] = {
        Intervall: resultReport[key][subKey].Intervall,
      };
      const [rangeStart, rangeEnd] = resultReport[key][subKey]
        .Intervall as number[];

      Object.keys(budgetPbs).forEach((account) => {
        if (+account >= rangeStart && +account <= rangeEnd) {
          Object.keys(budgetPbs[account]).forEach((period) => {
            if (!result[key][subKey][period]) result[key][subKey][period] = 0;
            result[key][subKey][period] += budgetPbs[account][period];
          });
        }
      });
    });
  });
  return result;
}

export function getBalanceExplainedReport({
  balance,
  pbs,
  from,
  to,
}: {
  balance: IBalanceReport;
  pbs: IPeriodBalances;
  from: number;
  to: number;
}) {
  const result: IKeyString<IKeyString<{ value: number; text?: string }>> = {
    //"Är det egna kapitalet förbrukat?": {},
    "Pengar på banken": {},
    "Kortsiktig betalningsförmåga": {},
    //"Skuld till aktieägarna": {},
  };

  const monthsArray = eachMonthOfInterval({
    start: fromUnixTime(from),
    end: fromUnixTime(to),
  }).map((d) => lightFormat(d, "yyyy-M"));

  const pbResult = getResultFromPeriodBalances({ pbs, monthsArray });

  const paymentAbility = getPaymentAbility(pbs, monthsArray);

  monthsArray.forEach((mKey, i) => {
    result["Pengar på banken"][mKey] = {
      value: paymentAbility["Kassa och bank"][mKey],
    };

    const shortTermDebtRatio =
      ((paymentAbility["Korta fodringar"][mKey] +
        paymentAbility["Kassa och bank"][mKey]) /
        paymentAbility["Korta skulder"][mKey]) *
      100;

    result["Kortsiktig betalningsförmåga"][mKey] = {
      value: isNaN(shortTermDebtRatio) ? 0 : shortTermDebtRatio,
      text: isNaN(shortTermDebtRatio)
        ? "-"
        : shortTermDebtRatio > 100
        ? "Ja"
        : "Nej",
    };
  });

  return result;
}

export function getResultFromPeriodBalances({
  pbs,
  monthsArray,
}: {
  pbs: IPeriodBalances;
  monthsArray: string[];
}) {
  const result: IKeyString<number> = {};

  Object.keys(pbs).forEach((account) => {
    if (+account < 3000 || +account > 8998) return;
    monthsArray.forEach((mKey) => {
      if (!result[mKey]) result[mKey] = 0;

      if (+account >= 3000 && +account < 4000) {
        result[mKey] += pbs[account][mKey] || 0;
      } else {
        result[mKey] -= pbs[account][mKey] || 0;
      }
    });
  });

  return result;
}

export function getCashflowPosetiveAndNegative(cashFlow?: ICashflowReport) {
  let positive: { label: string; value: number }[] = [];
  let negative: { label: string; value: number }[] = [];

  const positiveKeys = ["Försäljningsintäkter"];
  const negativeKeys = [
    "Material och varor",
    "Externa kostnader",
    "Personalkostnader",
    "Skatter",
  ];

  const indifferKeys = ["Räntekostnader/-intäkter"];

  positiveKeys.forEach((key) => {
    positive.push({
      label: key,
      value: cashFlow?.Resultat?.[key]?.UB || 0,
    });
  });

  negativeKeys.forEach((key) => {
    negative.push({
      label: key,
      value: cashFlow?.Resultat?.[key]?.UB || 0,
    });
  });

  indifferKeys.forEach((key) => {
    const value = cashFlow?.Resultat?.[key]?.UB || 0;
    if (value > 0) {
      positive.push({
        label: key,
        value,
      });
    } else {
      negative.push({
        label: key,
        value,
      });
    }
  });

  positive.sort((a, b) => b.value - a.value);
  negative.sort((a, b) => b.value - a.value);

  return { positive, negative };
}

export function getPaymentAbility(pbs: IPeriodBalances, monthsArray: string[]) {
  const result: IKeyString<IKeyString<number>> = {
    "Korta fodringar": {},
    "Kassa och bank": {},
    "Korta skulder": {},
  };

  Object.keys(pbs).forEach((account) => {
    if (+account >= 1400 && +account <= 1699) {
      if (!result["Korta fodringar"]["IB"]) {
        result["Korta fodringar"]["IB"] = 0;
      }
      result["Korta fodringar"]["IB"] += pbs[account].IB || 0;
      monthsArray.forEach((month) => {
        if (!result["Korta fodringar"][month]) {
          result["Korta fodringar"][month] = 0;
        }
        result["Korta fodringar"][month] += pbs[account][month] || 0;
      });
    }

    if (+account >= 1900 && +account <= 1999) {
      if (!result["Kassa och bank"]["IB"]) {
        result["Kassa och bank"]["IB"] = 0;
      }
      result["Kassa och bank"]["IB"] += pbs[account].IB || 0;
      monthsArray.forEach((month) => {
        if (!result["Kassa och bank"][month]) {
          result["Kassa och bank"][month] = 0;
        }
        result["Kassa och bank"][month] += pbs[account][month] || 0;
      });
    }

    if (+account >= 2400 && +account <= 2959) {
      if (!result["Korta skulder"]["IB"]) {
        result["Korta skulder"]["IB"] = 0;
      }
      result["Korta skulder"]["IB"] += pbs[account].IB || 0;
      monthsArray.forEach((month) => {
        if (!result["Korta skulder"][month]) {
          result["Korta skulder"][month] = 0;
        }
        result["Korta skulder"][month] += pbs[account][month] || 0;
      });
    }
  });

  return result;
}
