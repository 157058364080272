import "./BankCard.scss";

import React, { useEffect } from "react";

import { ReactComponent as Americanexpress } from "../../assets/images/Americanexpress.svg";
import { ReactComponent as Discover } from "../../assets/images/Discover.svg";
import { ReactComponent as Ifwecantfindcard } from "../../assets/images/ifwecantfindcard.svg";
import { ReactComponent as Mastercard } from "../../assets/images/Mastercard.svg";
import { ReactComponent as Visa } from "../../assets/images/Visa.svg";

interface Props {
  cardNumber: string;
  expMonth: number;
  expYear: number;
  cardType: string;
  isDefault: boolean;
  setAsDefaultCard: () => void;
  deleteCard: () => void;
}

function BankCard({
  cardNumber,
  expMonth,
  expYear,
  cardType,
  isDefault,
  setAsDefaultCard,
  deleteCard,
}: Props) {
  const expiryDate =
    (expMonth < 10 ? "0" + expMonth : expMonth) +
    "/" +
    expYear.toString().substr(2, 2);

  const cardIcon = () => {
    switch (cardType) {
      case "visa":
        return <Visa />;
      case "mastercard":
        return <Mastercard />;
      case "discover":
        return <Discover />;
      case "amex":
        return <Americanexpress />;
      default:
        return <Ifwecantfindcard />;
    }
  };

  useEffect(() => {}, [isDefault]);
  return (
    <div className="bank-card">
      <div className="top">
        <div className="card-icon">{cardIcon()}</div>
        <div className="actions">
          <button className="icon" onClick={setAsDefaultCard}>
            {isDefault ? (
              <i className="fa-solid fa-star active"></i>
            ) : (
              <i className="fa-regular fa-star active"></i>
            )}
          </button>
          <button className="icon" onClick={deleteCard}>
            <i className="fa-regular fa-trash-can delete"></i>
          </button>
        </div>
      </div>
      <div className="bottom">
        <div className="item">
          <p className="label text-m-r">Kortnummer</p>
          <p className="value text-m-m">*** *** *** {cardNumber}</p>
        </div>
        <div className="item">
          <p className="label text-m-r">Utgångsdatum</p>
          <p className="value text-m-m">{expiryDate}</p>
        </div>
        <div className="item">
          <p className="label text-m-r">Kort</p>
          <p className="value text-m-m">{cardType}</p>
        </div>
      </div>
    </div>
  );
}

export default BankCard;
