import { useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery } from "react-query";
import { useSelector } from "react-redux";
import { getVouchers } from "../../api/voucher";
import ShowingAllMessage from "../../components/ShowingAllMessage/ShowingAllMessage";
import Spinner from "../../components/UI/Spinner/Spinner";
import Voucher from "../../components/Voucher/Voucher";
import { API_MAX_PER_PAGE } from "../../shared/enums";
import { IVoucher, IVoucherFilter } from "../../types/api";
import { IReduxState } from "../../types/redux";
import VoucherFilter from "./VoucherFilter/VoucherFilter";
import "./Vouchers.scss";

interface IVouchersProps {}

function Vouchers(props: IVouchersProps) {
  const { companyId } = useSelector((state: IReduxState) => state.company);

  const voucherWRef = useRef<HTMLDivElement>(null);
  const counterRef = useRef<HTMLDivElement>(null);

  const [voucherFilter, setVoucherFilter] = useState<IVoucherFilter>({});
  const [totalVouchers, setTotalVouchers] = useState(0);

  const sort: IVoucherFilter["sort"] = [
    "year:desc", //TODO visma has string financial years
    "voucherSeries:asc",
    "voucherNumber:desc",
  ];

  const { data, isSuccess, fetchNextPage, hasNextPage } = useInfiniteQuery<
    IVoucher[],
    unknown,
    IVoucher[],
    ["vouchers", string, IVoucherFilter, IVoucherFilter["sort"]]
  >({
    queryKey: ["vouchers", companyId, voucherFilter, sort],
    queryFn: async ({
      queryKey: [, companyId, voucherFilter, sort],
      pageParam = 1,
    }) => {
      const res = await getVouchers({
        companyId,
        filter: {
          ...voucherFilter,
          page: pageParam,
          sort: sort,
        },
      });
      setTotalVouchers(res.data.payload.metadata.totalVouchers);

      return res.data.payload.vouchers;
    },
    getNextPageParam: (lastPage, pages) =>
      lastPage.length < API_MAX_PER_PAGE.Vouchers
        ? undefined
        : pages.length + 1,
  });

  const scrollHandler = () => {
    let count = 0;

    Array.from(voucherWRef.current!.querySelectorAll(".voucher-wrapper")).find(
      (el) => {
        const coditionHeight = window.innerHeight;
        const cordinat = el.getBoundingClientRect().top;
        count++;
        return coditionHeight < cordinat;
      }
    );
    if (counterRef.current)
      counterRef.current.textContent = `${count - 1}/${totalVouchers}`;
  };

  return (
    <div className="vouchers">
      <VoucherFilter
        onFilter={(f) => {
          setVoucherFilter(f);
        }}
      />
      {isSuccess ? (
        <InfiniteScroll
          dataLength={data.pages.flat().length}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          loader={<Spinner />}
          endMessage={
            <ShowingAllMessage text="Du har visat alla verifikationer" />
          }
          style={{
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
          scrollThreshold={0.5}
          onScroll={(e) => scrollHandler()}
        >
          <div className="voucher-list-wrapper" ref={voucherWRef}>
            {data.pages.flat().map((voucher, i) => (
              <Voucher
                key={voucher._id}
                description={voucher.description || ""}
                rows={voucher.voucherRows || []}
                voucherSerie={`${voucher.voucherSeries}${voucher.voucherNumber}`}
                transactionDate={voucher.transactionDate || 0}
              />
            ))}
          </div>
        </InfiniteScroll>
      ) : (
        <Spinner padding="10px" />
      )}
      {!!totalVouchers && (
        <div className="counter" ref={counterRef}>{`14/${totalVouchers}`}</div>
      )}
    </div>
  );
}

export default Vouchers;
