import { sendFingerprint } from "../../api/log";
import { LOCAL_STORAGE_KEY } from "../../shared/enums";
import store from "../index";
import {
  adminAuthLogout,
  authLogout,
  authStart,
  authSuccess,
} from "../slices/auth";

const { dispatch } = store;

export async function login({
  accessToken,
  refreshToken,
  adminAccessToken,
  adminRefreshToken,
}: {
  accessToken: string;
  refreshToken: string;
  adminAccessToken?: string;
  adminRefreshToken?: string;
}) {
  dispatch(authStart());
  localStorage.setItem(LOCAL_STORAGE_KEY.Token, accessToken);
  localStorage.setItem(LOCAL_STORAGE_KEY.RefreshToken, refreshToken);
  if (adminAccessToken) {
    localStorage.setItem(LOCAL_STORAGE_KEY.AdminToken, adminAccessToken);
  }
  if (adminRefreshToken) {
    localStorage.setItem(
      LOCAL_STORAGE_KEY.AdminRefreshToken,
      adminRefreshToken
    );
  }

  await sendFingerprint();

  dispatch(authSuccess({ accessToken, adminAccessToken }));
}

export function logout() {
  localStorage.removeItem(LOCAL_STORAGE_KEY.Token);
  localStorage.removeItem(LOCAL_STORAGE_KEY.RefreshToken);
  localStorage.removeItem(LOCAL_STORAGE_KEY.AdminToken);
  localStorage.removeItem(LOCAL_STORAGE_KEY.AdminRefreshToken);
  dispatch(authLogout());
}

export function adminLogout() {
  localStorage.removeItem(LOCAL_STORAGE_KEY.AdminToken);
  localStorage.removeItem(LOCAL_STORAGE_KEY.AdminRefreshToken);
  dispatch(adminAuthLogout());
}

export function checkAuthState() {
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY.Token);
  const adminAccessToken =
    localStorage.getItem(LOCAL_STORAGE_KEY.AdminToken) || undefined;
  if (accessToken) {
    dispatch(authSuccess({ accessToken, adminAccessToken }));
  }
}
