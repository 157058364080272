import { useState } from "react";
import { toast } from "react-toastify";
import { createConsulting } from "../../api/consult";
import Button from "../../components/UI/Button/Button";
import Checkbox from "../../components/UI/Checkbox/Checkbox";
import Input from "../../components/UI/Input/Input";
import Popup from "../../hoc/Popup/Popup";
import "./CreateAccountingFirmPopup.scss";

interface ICreateAccountingFirmPopupProps {
  showPopup: boolean;
  onClose: (hasCreated: boolean) => void;
}

function CreateAccountingFirmPopup(props: ICreateAccountingFirmPopupProps) {
  const [isCreating, setIsCreating] = useState(false);
  const [isInfoCorrect, setIsInfoCorrect] = useState(false);
  const [isAllowedToHandleInfo, setIsAllowedToHandleInfo] = useState(false);

  const [accountingFirmData, setAccountingFirmData] = useState<{
    name: string;
    orgNr: string;
    address: string;
    address2: string;
    zipcode: string;
    country: string;
    city: string;
    contactPersonEmail: string;
    contactPersonFirstName: string;
    contactPersonLastName: string;
    contactPersonSsn: string;
  }>({
    name: "",
    orgNr: "",
    address: "",
    address2: "",
    zipcode: "",
    country: "",
    city: "",
    contactPersonEmail: "",
    contactPersonFirstName: "",
    contactPersonLastName: "",
    contactPersonSsn: "",
  });

  async function createAccountingFirmHandler() {
    if (!isInfoCorrect) {
      toast.error("Du måste godkänna att uppgifterna är korrekta");
      return;
    }

    if (!isAllowedToHandleInfo) {
      toast.error("Du måste godkänna att vi får hantera personuppgifterna");
      return;
    }

    setIsCreating(true);

    try {
      await createConsulting({
        name: accountingFirmData.name,
        orgNr: accountingFirmData.orgNr,
        address: accountingFirmData.address,
        address2: accountingFirmData.address2,
        zipcode: accountingFirmData.zipcode,
        country: accountingFirmData.country,
        city: accountingFirmData.city,
        contactPersonEmail: accountingFirmData.contactPersonEmail,
        contactPersonFirstName: accountingFirmData.contactPersonFirstName,
        contactPersonLastName: accountingFirmData.contactPersonLastName,
        contactPersonSsn: accountingFirmData.contactPersonSsn,
      });
      toast.success("Redovisningsbyrån har skapats, och inväntar godkännande");
      setIsCreating(false);
    } catch (error) {
      toast.error("Något gick fel");
      setIsCreating(false);
    }
  }

  return (
    <Popup
      showPopup={props.showPopup}
      onClose={() => props.onClose(false)}
      leftTopElement={
        <span className="text-m-m" style={{ color: "#fff" }}>
          Företagsuppgifter
        </span>
      }
    >
      <div className="create-accounting-firm-popup">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            createAccountingFirmHandler();
          }}
        >
          <div className="profile-title">
            <span className="text-m-m">Organisation</span>
          </div>
          <div className="profile-section">
            <span className="text-s-r">Organisationsnummer</span>
            <Input
              type="text"
              value={accountingFirmData.orgNr}
              onChange={(v) =>
                setAccountingFirmData({ ...accountingFirmData, orgNr: v })
              }
              short
              placeholder="Organisationsnummer"
              width="70%"
            />
          </div>
          <div className="profile-section">
            <span className="text-s-r">Namn på företag</span>
            <Input
              type="text"
              value={accountingFirmData.name}
              onChange={(v) =>
                setAccountingFirmData({ ...accountingFirmData, name: v })
              }
              short
              placeholder="Namn på företag"
              width="70%"
            />
          </div>
          <div className="profile-section">
            <span className="text-s-r">Adress</span>
            <Input
              type="text"
              value={accountingFirmData.address}
              onChange={(v) =>
                setAccountingFirmData({ ...accountingFirmData, address: v })
              }
              short
              placeholder="Adress"
              width="35%"
            />
            <Input
              type="text"
              value={accountingFirmData.address2}
              onChange={(v) =>
                setAccountingFirmData({ ...accountingFirmData, address2: v })
              }
              short
              placeholder="Adress 2"
              width="calc(70% - 15px - 35%)"
            />
          </div>
          <div className="profile-section">
            <span className="text-s-r">Postnummer & Stad</span>
            <Input
              type="text"
              value={accountingFirmData.zipcode}
              onChange={(v) =>
                setAccountingFirmData({ ...accountingFirmData, zipcode: v })
              }
              short
              placeholder="Postnummer"
              width="100px"
            />
            <Input
              type="text"
              value={accountingFirmData.city}
              onChange={(v) =>
                setAccountingFirmData({ ...accountingFirmData, city: v })
              }
              short
              placeholder="Stad"
              width="calc(70% - 15px - 100px)"
            />
          </div>
          <div className="profile-section">
            <span className="text-s-r">Land</span>
            <Input
              type="text"
              value={accountingFirmData.country}
              onChange={(v) =>
                setAccountingFirmData({ ...accountingFirmData, country: v })
              }
              short
              placeholder="Land"
              width="70%"
            />
          </div>
          <div className="profile-title">
            <span className="text-m-m">Kontaktperson</span>
          </div>
          <div className="profile-section">
            <span className="text-s-r">Kontaktperson e-post</span>
            <Input
              type="text"
              value={accountingFirmData.contactPersonEmail}
              onChange={(v) =>
                setAccountingFirmData({
                  ...accountingFirmData,
                  contactPersonEmail: v,
                })
              }
              short
              placeholder="Kontaktperson e-post"
              width="70%"
            />
          </div>
          <div className="profile-section">
            <span className="text-s-r">Kontaktperson förnamn</span>
            <Input
              type="text"
              value={accountingFirmData.contactPersonFirstName}
              onChange={(v) =>
                setAccountingFirmData({
                  ...accountingFirmData,
                  contactPersonFirstName: v,
                })
              }
              short
              placeholder="Kontaktperson förnamn"
              width="70%"
            />
          </div>
          <div className="profile-section">
            <span className="text-s-r">Kontaktperson efternamn</span>
            <Input
              type="text"
              value={accountingFirmData.contactPersonLastName}
              onChange={(v) =>
                setAccountingFirmData({
                  ...accountingFirmData,
                  contactPersonLastName: v,
                })
              }
              short
              placeholder="Kontaktperson efternamn"
              width="70%"
            />
          </div>
          <div className="profile-section">
            <span className="text-s-r">Kontaktperson personnummer</span>
            <Input
              type="text"
              value={accountingFirmData.contactPersonSsn}
              onChange={(v) =>
                setAccountingFirmData({
                  ...accountingFirmData,
                  contactPersonSsn: v,
                })
              }
              short
              placeholder="Kontaktperson personnummer"
              width="70%"
            />
          </div>
          <div className="profile-title">
            <span className="text-m-m">Godkännande</span>
          </div>

          <div className="profile-checkboxes">
            <Checkbox
              checked={isInfoCorrect}
              theme="dark"
              label="Lovar du på heder och samvete att informationen är korrekt"
              onClick={() => setIsInfoCorrect(!isInfoCorrect)}
              short
            />
            <Checkbox
              checked={isAllowedToHandleInfo}
              theme="dark"
              label="Ger du oss rättigheterna att behandla personuppgifterna"
              onClick={() => setIsAllowedToHandleInfo(!isAllowedToHandleInfo)}
              short
            />
          </div>
          <div className="profile-save-wrapper">
            <Button
              color="black"
              label="Skapa"
              isLoading={isCreating}
              width="200px"
              short
            />
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default CreateAccountingFirmPopup;
