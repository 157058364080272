import './CustomAlertPopup.scss';

import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
    createEconomicAlert, getCustomVariables, setHealthAlert, updateAlert
} from '../../api/report';
import VariableList from '../../components/CustomVariableComponents/VariableList/VariableList';
import StatementList, { IStatement } from '../../components/StatementList/StatementList';
import Button from '../../components/UI/Button/Button';
import Checkbox from '../../components/UI/Checkbox/Checkbox';
import Dropdown from '../../components/UI/Dropdown/Dropdown';
import Input from '../../components/UI/Input/Input';
import Popup from '../../hoc/Popup/Popup';
import { ALERT_VALUE, QUERY_PARAM, VARIABLE_VALUE } from '../../shared/enums';
import { useSearchQuery } from '../../shared/hooks/useSearchQuery';
import {
    findStatementInAlert, findVariableInAlert, getAlertBreakValue
} from '../../shared/variableUtility';
import { HealthAlertScope, IAlert, IVariable } from '../../types/api';
import { IReduxState } from '../../types/redux';
import TagsSelector from '../TagsSelector/TagsSelector';

interface ICustomAlertPopupProps {
  showPopup: boolean;
  onClose: (hasCreated: boolean) => void;
  editAlert?: IAlert;
}

const ScopeOptions = [
  {
    value: HealthAlertScope.DAILY,
    label: HealthAlertScope.DAILY,
  },
  {
    value: HealthAlertScope.WEEKLY,
    label: HealthAlertScope.WEEKLY,
  },
  {
    value: HealthAlertScope.MONTHLY,
    label: HealthAlertScope.MONTHLY,
  },
];

function CustomAlertPopup(props: ICustomAlertPopupProps) {
  const { companyId } = useSelector((state: IReduxState) => state.company);
  const query = useSearchQuery();

  const [alertVariable, setAlertVariable] = useState<IVariable | undefined>();
  const [selectedStatement, setSelectedStatement] = useState<
    IStatement | undefined
  >();
  const [breakValue, setBreakValue] = useState("");
  const [scope, setScope] = useState<HealthAlertScope>(
    HealthAlertScope.MONTHLY
  );
  const [active, setActive] = useState<boolean>(false);
  const [hasSetActive, setHasSetActive] = useState<boolean>(false);
  const [needBreakValue, setNeedBreakValue] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isCreating, setIsCreating] = useState(false);

  const { data: variables, isSuccess: isVariablesSuccess } = useQuery<
    IVariable[],
    unknown,
    IVariable[],
    ["variables", string]
  >({
    queryKey: ["variables", companyId],
    queryFn: async ({ queryKey: [, companyId] }) => {
      const res = await getCustomVariables(companyId);

      return res.data.payload.variables;
    },
  });

  useEffect(() => {
    if (props.editAlert && isVariablesSuccess) {
      const variable = findVariableInAlert(props.editAlert, variables);
      if (variable) {
        const statement = findStatementInAlert(props.editAlert, variable);
        setSelectedStatement(statement);
      }
      const editBreakValue = getAlertBreakValue(props.editAlert);
      setBreakValue(editBreakValue || "");
      setNeedBreakValue(editBreakValue !== undefined);
      setAlertVariable(variable);
      setDescription(props.editAlert.description || "");
      setSelectedTags(props.editAlert.tags);
    }
  }, [isVariablesSuccess, props.editAlert, variables]);

  useEffect(() => {
    const queryVariableId = query.get(QUERY_PARAM.VariableId);
    if (queryVariableId && isVariablesSuccess) {
      const variable = variables.find(
        (variable) => variable._id === queryVariableId
      );
      if (variable) {
        setAlertVariable(variable);
      }
    }
  }, [isVariablesSuccess, query, variables]);

  function closeHandler(hasCreated: boolean) {
    setAlertVariable(undefined);
    setSelectedStatement(undefined);
    setBreakValue("");
    setDescription("");
    setSelectedTags([]);

    if (hasCreated) {
      props.onClose(true);
    } else {
      props.onClose(false);
    }
  }

  async function createAlertHandler() {
    if (!alertVariable) {
      toast.error("Du måste välja en variabel");
      return;
    }

    if (!selectedStatement) {
      toast.error("Du måste välja en villkor");
      return;
    }

    // if (needBreakValue && !breakValue) {
    //   toast.error("Du måste ange ett brytvärde");
    //   return;
    // }

    setIsCreating(true);

    // const formulaString = selectedStatement.value.replace(
    //   ALERT_VALUE.BreakValueCalcSymbol,
    //   breakValue
    // );

    // const alertName = `${alertVariable.name.replace(
    //   VARIABLE_VALUE.GlobalPrefix,
    //   ""
    // )} ${
    //   needBreakValue
    //     ? selectedStatement.name.replace(
    //         ALERT_VALUE.BreakValueTitleSymbol,
    //         breakValue
    //       )
    //     : selectedStatement.name
    // }`;

    try {
      // await createEconomicAlert({
      //   companyId,
      //   variableString: formulaString,
      //   name: alertName,
      //   isPercent: alertVariable.isPercent || false,
      //   global: false,
      //   description,
      //   tags: selectedTags,
      // });

      await setHealthAlert({
        companyId: companyId,
        variableId: alertVariable._id,
        scope: scope,
        active: active,
      });

      setIsCreating(false);
      toast.success("Notis skapad");
      closeHandler(true);
    } catch (error: any) {
      setIsCreating(false);
      toast.error("Något gick fel");
      toast.error(error.response.data.message, {
        autoClose: false,
      });
    }
  }

  async function updateAlertHandler() {
    if (!alertVariable) {
      toast.error("Du måste välja en variabel");
      return;
    }

    if (!selectedStatement) {
      toast.error("Du måste välja en villkor");
      return;
    }

    if (needBreakValue && !breakValue) {
      toast.error("Du måste ange ett brytvärde");
      return;
    }

    setIsCreating(true);

    const formulaString = selectedStatement.value.replace(
      ALERT_VALUE.BreakValueCalcSymbol,
      breakValue
    );

    const alertName = `${alertVariable.name.replace(
      VARIABLE_VALUE.GlobalPrefix,
      ""
    )} ${
      needBreakValue
        ? selectedStatement.name.replace(
            ALERT_VALUE.BreakValueTitleSymbol,
            breakValue
          )
        : selectedStatement.name
    }`;

    try {
      await updateAlert({
        variableId: props.editAlert!._id,
        variableString: formulaString,
        name: alertName,
        isPercent: alertVariable.isPercent || false,
        global: false,
        description,
        tags: selectedTags,
      });

      setIsCreating(false);
      toast.success("Notis uppdaterad");
      closeHandler(true);
    } catch (error: any) {
      setIsCreating(false);
      toast.error("Något gick fel");
      toast.error(error.response.data.message, {
        autoClose: false,
      });
    }
  }

  function variableSelectedHandler(vId: string) {
    const variable = variables!.find((v) => v._id === vId);

    if (!variable) return;

    setAlertVariable(variable);
    setDescription(
      `${variable.name.replace(VARIABLE_VALUE.GlobalPrefix, "")} är ${
        ALERT_VALUE.DescriptionValue
      } ${variable.isPercent ? "procent" : "kronor"} denna månad`
    );
  }

  return (
    <Popup
      className="custom-alert-popup"
      showPopup={props.showPopup}
      onClose={() => closeHandler(false)}
    >
      <div className="header">
        <span className="text-m-m">{`${(alertVariable?.name || "").replace(
          VARIABLE_VALUE.GlobalPrefix,
          ""
        )} ${selectedStatement?.name || ""}`}</span>
        {/* {!!selectedStatement &&
          (needBreakValue ? !!breakValue : true) &&
          description && (
            <>
              <TagsSelector
                selectedTags={selectedTags}
                onTagSelected={(name, checked) => {
                  if (checked) {
                    setSelectedTags((state) => [...state, name]);
                  } else {
                    setSelectedTags((state) => state.filter((t) => t !== name));
                  }
                }}
              />
              <Button
                label={props.editAlert ? "Spara notis" : "Skapa notis"}
                color="black"
                onClick={
                  props.editAlert ? updateAlertHandler : createAlertHandler
                }
                isLoading={isCreating}
                short
              />
            </>
          )} */}
        <TagsSelector
          selectedTags={selectedTags}
          onTagSelected={(name, checked) => {
            if (checked) {
              setSelectedTags((state) => [...state, name]);
            } else {
              setSelectedTags((state) => state.filter((t) => t !== name));
            }
          }}
        />
        <Button
          label={props.editAlert ? "Spara notis" : "Skapa notis"}
          color="black"
          onClick={props.editAlert ? updateAlertHandler : createAlertHandler}
          isLoading={isCreating}
          short
        />
      </div>
      <div className="content">
        {alertVariable ? (
          <StatementList
            variable={alertVariable}
            onSelect={(statement, hasBreakValue) => {
              setSelectedStatement(statement);
              setNeedBreakValue(hasBreakValue);
            }}
            selectedStatement={selectedStatement}
          />
        ) : (
          <VariableList
            variables={variables || []}
            onSelect={variableSelectedHandler}
            noCustom
          />
        )}
        <div className="steps text-m-r">
          {alertVariable ? (
            <div className="step">
              <p className="title">1. Notisen avser</p>
              <p className="value">
                {alertVariable.name.replace(VARIABLE_VALUE.GlobalPrefix, "")}
              </p>
            </div>
          ) : (
            <div className="step">
              <p className="title"></p>
              <p className="value">1. Välj vad notisen avser till vänster</p>
            </div>
          )}
          {!!alertVariable && (
            <div
              className="step"
              style={{
                borderBottom: selectedStatement ? undefined : "none",
              }}
            >
              <p className="title">
                {selectedStatement
                  ? "2. Notisen är följande"
                  : "2. Välj när notisen ska aktiveras i listan till vänster"}
              </p>
              <p className="value">
                {selectedStatement
                  ? `${alertVariable.name.replace(
                      VARIABLE_VALUE.GlobalPrefix,
                      ""
                    )} ${selectedStatement?.name || ""}`
                  : ""}
              </p>
            </div>
          )}
          {/* {!!selectedStatement && needBreakValue && (
            <div className="step">
              <p className="title">
                3.{" "}
                {selectedStatement.isBreakValueInPercent ? "Procent" : "Kronor"}
              </p>
              <p className="value">
                <Input
                  type="number"
                  value={breakValue}
                  onChange={setBreakValue}
                  short
                />
              </p>
            </div>
          )} */}
          {!!selectedStatement && scope && (
            <>
              <div className="step">
                <p className="title">
                  3. Välj hur ofta notisen ska skickas och om den ska vara
                </p>
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
                >
                  <p className="value">
                    <Dropdown
                      options={ScopeOptions}
                      onSelect={(value) => {
                        setScope(value as HealthAlertScope);
                        setHasSetActive(true);
                      }}
                      value={scope}
                      title=""
                      short
                    />
                  </p>
                  <p className="value"></p>
                </div>
              </div>
            </>
          )}
          {!!selectedStatement && hasSetActive && (
            <div className="step">
              <p className="title">
                4. Välj om notisen ska vara aktiv eller inaktiv
              </p>
              <p className="value">
                <Checkbox
                  checked={active}
                  onClick={() => setActive((state) => !state)}
                  theme="dark"
                />
              </p>
            </div>
          )}
          {/* {!!selectedStatement && (needBreakValue ? !!breakValue : true) && (
            <div className="step">
              <p className="title">Hur ska notisen rapporteras?</p>
              <p className="title">
                Skriv “<span>{ALERT_VALUE.DescriptionValue}</span>” där du vill
                att utfallet för den variabeln "
                <span>
                  {alertVariable?.name.replace(VARIABLE_VALUE.GlobalPrefix, "")}
                </span>
                " ska visas. Nedan finns ett exempel:
              </p>
              <p className="value">
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </p>
            </div>
          )} */}
        </div>
      </div>
    </Popup>
  );
}

export default CustomAlertPopup;
