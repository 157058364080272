import { useState } from "react";
import GroupCompanies from "../GroupCompanies/GroupCompanies";
import GroupInfo from "../GroupInfo/GroupInfo";
import GroupUsers from "../GroupUsers/GroupUsers";
import "./CreateGroupPopup.scss";

interface ICreateGroupPopupProps {
  onCreate: (groupId: string) => void;
  onDone: (groupId: string) => void;
  agencyId: string;
}

function CreateGroupPopup(props: ICreateGroupPopupProps) {
  const [step, setStep] = useState(1);
  const [groupId, setGroupId] = useState("");

  return (
    <>
      {step === 1 ? (
        <GroupInfo
          agencyId={props.agencyId}
          bottomLabel={`Steg ${step}/3 - Namnge gruppen`}
          onSave={(groupId) => {
            setGroupId(groupId);
            setStep(2);
            props.onCreate(groupId);
          }}
        />
      ) : step === 2 ? (
        <GroupUsers
          groupId={groupId}
          onSave={() => setStep(3)}
          bottomLabel={`Steg ${step}/3 - Lägg till medlemmar`}
          isEditing={false}
        />
      ) : step === 3 ? (
        <GroupCompanies
          groupId={groupId}
          onSave={() => props.onDone(groupId)}
          bottomLabel={`Steg ${step}/3 - Lägg till företag`}
          saveButtonLabel="Skapa grupp"
          agencyId={props.agencyId}
        />
      ) : null}
    </>
  );
}

export default CreateGroupPopup;
