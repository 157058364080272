import { useState } from "react";
import { Outlet } from "react-router-dom";
import ParentRouteNav from "../../components/ParentRouteNav/ParentRouteNav";
import { ROUTE, ROUTE_LABEL } from "../../shared/enums";
import "./Admin.scss";

interface IAdminProps {}

function Admin(props: IAdminProps) {
  const [controlsComponent, setControlsComponent] = useState<
    React.ReactNode | undefined
  >(undefined);

  const navItems = [
    { path: ROUTE.AdminUsers, label: ROUTE_LABEL.AdminUsers },
    {
      path: ROUTE.AdminAccountingFirms,
      label: ROUTE_LABEL.AdminAccountingFirms,
    },
  ];

  return (
    <div className="admin">
      <div className="controls">
        <ParentRouteNav navItems={navItems} />
        {controlsComponent}
      </div>
      <Outlet
        context={{
          setControlsComponent,
        }}
      />
    </div>
  );
}

export default Admin;
