import axios from "../shared/axios";
import { IApiResponse, IUnreadMessage } from "../types/api";

const PREFIX = "/notices";

export function readNotice({
  noticeId,
  commentId,
}: {
  noticeId: string;
  commentId: string;
}) {
  return axios.post<IApiResponse<{}>>(`${PREFIX}/seen`, {
    noticeId,
    commentId,
  });
}

export function getUnreadComments() {
  return axios.post<IApiResponse<IUnreadMessage[]>>(`${PREFIX}/get`, {
    options: {
      showTypes: ["UnreadComment"],
      seen: false,
    },
  });
}
