import { IAccount } from "../../types/api";
import "./AccountSearch.scss";

interface IAccountSearchProps {
  query: string;
  accounts: IAccount[];
  onAccountClick?: (account: string) => void;
}

const AccountSearch = (props: IAccountSearchProps) => {
  return (
    <div className="account-search">
      {props.query.length > 1 &&
        props.accounts
          .filter((a) => String(a.account).startsWith(props.query))
          .sort((a, b) => a.account - b.account)
          .map((a) => (
            <div
              key={a.account}
              className="account-search--account"
              onClick={() =>
                props.onAccountClick
                  ? props.onAccountClick(String(a.account))
                  : undefined
              }
            >
              <span>{a.account}</span>=<span>{a.description}</span>
            </div>
          ))}
    </div>
  );
};

export default AccountSearch;
