import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { generateFortnoxUrl } from "../../api/fortnox";
import { generateVismaUrl } from "../../api/visma";
import Button from "../../components/UI/Button/Button";
import OnbordingLayout from "../../hoc/OnbordingLayout/OnbordingLayout";
import { QUERY_PARAM } from "../../shared/enums";
import { useSearchQuery } from "../../shared/hooks/useSearchQuery";
import { logout } from "../../store/thunks/auth";
import "./FirstCompany.scss";

interface IFirstCompanyProps {}

function FirstCompany(props: IFirstCompanyProps) {
  const query = useSearchQuery();

  const [isFortnoxLoading, setIsFortnoxLoading] = useState(false);
  const [isVismaLoading, setIsVismaLoading] = useState(false);

  useEffect(() => {
    showQueryToast(
      query.get(QUERY_PARAM.CompanyError),
      query.get(QUERY_PARAM.CompanyErrorMessage)
    );
  }, [query]);

  function showQueryToast(
    errorQuery: string | null,
    messageQuery: string | null
  ) {
    if (!errorQuery || !messageQuery) return;

    const hasError = errorQuery === "true";

    if (!hasError) {
      toast.success("Företaget lades till");
      return;
    }

    let errorText = "";
    switch (messageQuery) {
      case "error_missing_license":
        errorText =
          "Detta företag saknar licens för att lägga till integrationer";
        break;
      case "error_insufficient_permissions":
        errorText = "Du saknar rätt behörigheter i företaget";
        break;
      default:
        errorText = "Ett okänt fel har inträffat";
        break;
    }
    toast.error(errorText);
  }

  function addFortnoxCompanyHandler() {
    setIsFortnoxLoading(true);
    generateFortnoxUrl()
      .then((res) => {
        window.location.href = res.data.payload.url;
      })
      .catch(() => {
        setIsFortnoxLoading(false);
        toast.error("Kunde inte skapa fortnox länk");
      });
  }

  function addVismaCompanyHandler() {
    setIsVismaLoading(true);
    generateVismaUrl()
      .then((res) => {
        window.location.href = res.data.payload.url;
      })
      .catch(() => {
        setIsVismaLoading(false);
        toast.error("Kunde inte skapa visma länk");
      });
  }

  function logoutHandler() {
    logout();
  }

  return (
    <OnbordingLayout>
      <p className="no-companies--title space-grotesk">Lägg till ett företag</p>
      <div className="no-companies--description">
        <p>
          <span>Viktigt</span> du behöver vara systemadmin i företag. Följande
          licenser krävs:
        </p>
        <ul>
          <li>Integrationslicensen</li>
          <li>Bokföringslicensen</li>
        </ul>
      </div>
      <div className="no-companies--sources">
        <div className="no-comp-source">Lägg till företag</div>
        <div className="no-comp-source">
          Lägg till företag Fornox
          <Button
            color="black"
            label="Fortnox"
            width="160px"
            isLoading={isFortnoxLoading}
            onClick={addFortnoxCompanyHandler}
          />
        </div>
        <div className="no-comp-source">
          Lägg till företag Visma
          <Button
            color="black"
            label="Visma"
            width="160px"
            isLoading={isVismaLoading}
            onClick={addVismaCompanyHandler}
          />
        </div>
      </div>
      <div className="no-companies--logout" onClick={logoutHandler}>
        Logga ut
        <i className="fa-solid fa-arrow-right-from-bracket" />
      </div>
    </OnbordingLayout>
  );
}

export default FirstCompany;
