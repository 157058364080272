import { forwardRef, Ref } from "react";
import "./DotsButton.scss";

interface IDotsButtonProps {
  onClick: () => void;
  amount?: number;
  color?: string;
}

const DotsButton = forwardRef(
  (props: IDotsButtonProps, ref: Ref<HTMLDivElement>) => {
    return (
      <div className="dots-button" ref={ref} onClick={props.onClick}>
        {Array(props.amount || 3)
          .fill(0)
          .map((_, i) => (
            <div key={i} style={{ backgroundColor: props.color }} />
          ))}
      </div>
    );
  }
);

export default DotsButton;
