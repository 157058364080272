import { motion, Variants } from "framer-motion";
import { forwardRef, Ref, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Select, { CSSObjectWithLabel } from "react-select";
import { toast } from "react-toastify";
import { generateFortnoxUrl } from "../../api/fortnox";
import { generateVismaUrl } from "../../api/visma";
import Spinner from "../../components/UI/Spinner/Spinner";
import TextButton from "../../components/UI/TextButton/TextButton";
import { ERROR_TEXT } from "../../shared/enums";
import { useClickOutside } from "../../shared/hooks/useClickOutside";
import { convertToDropdown } from "../../shared/utility";
import { setCompany } from "../../store/thunks/company";
import { IReduxState } from "../../types/redux";
import "./CompanySelector.scss";

interface ICompanySelectorProps {}

function CompanySelector(props: ICompanySelectorProps) {
  const {
    companyId,
    companies,
    loading,
    companyData: { isRedoSetup, dataSource },
  } = useSelector((state: IReduxState) => state.company);

  const selectorRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  useClickOutside([buttonRef, selectorRef], () => setIsOpen(false));

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) return;
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, [isOpen]);

  const companySelectorVariants: Variants = {
    closed: {
      height: 0,
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
    open: {
      height: "auto",
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  };

  async function companyClickHandler(companyId: string) {
    setIsOpen(false);
    await setCompany(companyId);
  }

  async function redoSetup(type: "fortnox" | "visma" | "") {
    if (!type) return;

    let redoUrl = "";

    try {
      if (type === "fortnox") {
        const res = await generateFortnoxUrl();
        redoUrl = res.data.payload.url;
      } else if (type === "visma") {
        const res = await generateVismaUrl();
        redoUrl = res.data.payload.url;
      }
    } catch (error) {
      toast.error(`Kunde inte skapa ${type} länk`);
    }

    if (!redoUrl) return;

    window.location.href = redoUrl;
  }

  const comps = companies.filter((c) => !c.isConcern);

  const company = companies.find((c) => c.companyId === companyId);

  return (
    <>
      <div className="company-selector-wrapper">
        <div
          className="company-click"
          onClick={() => setIsOpen((state) => !state)}
          ref={buttonRef}
        >
          <div className="company-button waves-effect waves-light">
            <i className="fa-solid fa-store" />
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <span className="text-m-m">{company?.name || ""}</span>
          )}
        </div>
        <motion.div
          className="company-selector"
          variants={companySelectorVariants}
          animate={isOpen ? "open" : "closed"}
          ref={selectorRef}
        >
          {isOpen && (
            <CompanySelectorDropdown
              companyOptions={convertToDropdown({
                list: comps,
                labelKey: "name",
                valueKey: "companyId",
              })}
              companyId={companyId}
              onSelect={companyClickHandler}
              ref={searchRef}
            />
          )}
        </motion.div>
      </div>
      {isRedoSetup && !loading && (
        <TextButton
          label={ERROR_TEXT.RedoSetupClickHere}
          onClick={() => redoSetup(dataSource)}
          title={`Länk till ${dataSource}`}
          color="red"
        />
      )}
    </>
  );
}

const CompanySelectorDropdown = forwardRef(
  (
    props: {
      companyOptions: { label: string; value: string }[];
      companyId: string;
      onSelect: (companyId: string) => void;
    },
    ref: Ref<any>
  ) => {
    return (
      <Select
        ref={ref}
        isSearchable
        value={
          props.companyOptions.find((c) => c.value === props.companyId) || null
        }
        options={props.companyOptions}
        onChange={(e) => props.onSelect(e?.value || "")}
        menuIsOpen={true}
        autoFocus
        noOptionsMessage={() => "Inga resultat"}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
        }}
        styles={{
          container: (base) => ({
            ...base,
            width: "100%",
            backgroundColor: "#fff",
            padding: "10px 20px",
            borderRadius: "10px 10px 0 0",
            border: "1px solid #efefff",
            boxSizing: "border-box",
          }),
          control: (base) => ({
            ...base,
            width: "100%",
            color: "#000D33",
            borderRadius: "40px",
            border: "1px solid #A6A6AF",
            "&:hover": {
              border: "1px solid #A6A6AF",
            },
            minHeight: "30px",
            fontSize: "13px",
            fontWeight: 400,
            cursor: "pointer",
            boxShadow: "none",
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused ? "#EFEFFF" : "transparent",
            color: "#000D33",
            zIndex: 1000,
            fontSize: "13px",
            fontWeight: 400,
            cursor: "pointer",
            padding: "10px 20px",
            borderBottom: "1px solid #efefff",
            ":active": {
              backgroundColor: "#EFEFFF",
            },
          }),
          menu: (base) => ({
            ...base,
            zIndex: 100000,
            marginTop: 0,
            boxShadow: "none",
            border: "1px solid #efefff",
            borderTop: "none",
            borderRadius: "0 0 10px 10px",
            left: -1,
            width: "calc(100% + 2px)",
          }),
          menuList: (base) => ({
            ...base,
            height: "100%",
            paddingBottom: 0,
          }),
          valueContainer: (base: CSSObjectWithLabel) => ({
            ...base,
            padding: "2px 8px",
          }),
        }}
      />
    );
  }
);

export default CompanySelector;
