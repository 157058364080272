import axios from "../shared/axios";
import {
  IApiResponse,
  IConsultActiveCompany,
  IConsultAgency,
  IConsultEmployee,
  IConsultInactiveCompany,
  IConsultSearchResult,
} from "../types/api";

const PREFIX = "/consult";

export function createConsulting({
  name,
  orgNr,
  address,
  address2,
  zipcode,
  country,
  city,
  contactPersonEmail,
  contactPersonFirstName,
  contactPersonLastName,
  contactPersonSsn,
}: {
  name: string;
  orgNr: string;
  address: string;
  address2: string;
  zipcode: string;
  country: string;
  city: string;
  contactPersonEmail: string;
  contactPersonFirstName: string;
  contactPersonLastName: string;
  contactPersonSsn: string;
}) {
  return axios.post(`${PREFIX}/create`, {
    name,
    orgNr,
    address,
    address2,
    zipcode,
    country,
    city,
    contactPersonEmail,
    contactPersonFirstName,
    contactPersonLastName,
    contactPersonSsn,
  });
}

export function updateConsulting({
  consultId,
  name,
  address,
  address2,
  zipcode,
  country,
  city,
  contactPersonEmail,
  contactPersonFirstName,
  contactPersonLastName,
  contactPersonSsn,
}: {
  consultId: string;
  name: string;
  address: string;
  address2: string;
  zipcode: string;
  country: string;
  city: string;
  contactPersonEmail: string;
  contactPersonFirstName: string;
  contactPersonLastName: string;
  contactPersonSsn: string;
}) {
  return axios.post(`${PREFIX}/update`, {
    consultId,
    name,
    address,
    address2,
    zipcode,
    country,
    city,
    contactPersonEmail,
    contactPersonFirstName,
    contactPersonLastName,
    contactPersonSsn,
  });
}

export function searchConsulting(filter: string) {
  return axios.get<
    IApiResponse<{
      hits: IConsultSearchResult[];
    }>
  >(`${PREFIX}/search`, {
    params: {
      filter,
    },
  });
}

export function inviteToConsulting({
  consultId,
  email,
}: {
  consultId: string;
  email: string;
}) {
  return axios.post(`${PREFIX}/users`, {
    consultId,
    email,
  });
}

export function removeFromConsulting({
  consultId,
  userId,
}: {
  consultId: string;
  userId: string;
}) {
  return axios.delete(`${PREFIX}/users/${consultId}/${userId}`);
}

export function getConsultingEmployees({
  consultId,
  companyId,
}: {
  consultId: string;
  companyId?: string;
}) {
  return axios.get<IApiResponse<{ employees: IConsultEmployee[] }>>(
    `${PREFIX}/users/${consultId}`,
    {
      params: {
        companyId,
      },
    }
  );
}

export function getConsultingClients(consultId: string) {
  return axios.get<
    IApiResponse<{
      activeCompanies: IConsultActiveCompany[];
      inactiveCompanies: IConsultInactiveCompany[];
    }>
  >(`${PREFIX}/clients/${consultId}`);
}

export function confirmConsultingClient({
  consultId,
  companyId,
  confirm,
}: {
  consultId: string;
  companyId: string;
  confirm: boolean;
}) {
  return axios.post(`${PREFIX}/confirmClient`, {
    consultId,
    companyId,
    confirm,
  });
}

export function deleteConsulting(consultId: string) {
  return axios.delete(`${PREFIX}/delete/${consultId}`);
}

export function getConsultingAgencies() {
  return axios.get<IApiResponse<IConsultAgency[]>>(`${PREFIX}/list`);
}

export function updateUserRole({
  consultId,
  userId,
  role,
}: {
  consultId: string;
  userId: string;
  role: "admin" | "user";
}) {
  return axios.post(`${PREFIX}/role`, {
    consultId,
    userId,
    role,
  });
}
