import nodeAxios, { AxiosError } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { rehydrateToken } from "../api/auth";
import store from "../store";
import { authSetIsBackendDown } from "../store/slices/auth";
import { logout } from "../store/thunks/auth";
import { LOCAL_STORAGE_KEY } from "./enums";
import { environment } from "./environment";

const axios = nodeAxios.create({
  baseURL: environment.apiBaseUrl,
});

axios.interceptors.request.use((config) => {
  config.headers["Authorization"] = `Bearer ${localStorage.getItem(
    LOCAL_STORAGE_KEY.Token
  )}`;

  return config;
});

//codes when backend is down 523 522 521 520
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (
      window.navigator.onLine &&
      !error.response &&
      error.code === "ERR_NETWORK"
    ) {
      store.dispatch(authSetIsBackendDown(true));
    }
    return Promise.reject(error);
  }
);

const refreshTokenInstance = nodeAxios.create({
  baseURL: environment.apiBaseUrl,
});

refreshTokenInstance.interceptors.response.use(
  (res) => res,
  (err) => {
    throw err;
  }
);

createAuthRefreshInterceptor(
  axios,
  async (failedRequest) => {
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEY.RefreshToken);
    if (!refreshToken) {
      logout();
      return Promise.reject(failedRequest);
    }

    let res;
    try {
      res = await rehydrateToken({ axios: refreshTokenInstance, refreshToken });
    } catch (error) {
      logout();
      return;
    }
    localStorage.setItem(
      LOCAL_STORAGE_KEY.Token,
      res.data.payload.access_token
    );
    localStorage.setItem(
      LOCAL_STORAGE_KEY.RefreshToken,
      res.data.payload.refresh_token
    );
    failedRequest.response.config.headers["Authorization"] =
      "Bearer " + res.data.payload.access_token;
    return await Promise.resolve();
  },
  {
    statusCodes: [401, 406, 410],
  }
);

export default axios;
