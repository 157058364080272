import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery } from "react-query";
import { adminGetConsultQueue, adminGetConsults } from "../../api/admin";
import ShowingAllMessage from "../../components/ShowingAllMessage/ShowingAllMessage";
import Button from "../../components/UI/Button/Button";
import Spinner from "../../components/UI/Spinner/Spinner";
import AccountingFirmRow from "../../containers/AccountingFirmRow/AccountingFirmRow";
import { API_MAX_PER_PAGE } from "../../shared/enums";
import { useAdminValues } from "../../shared/hooks/useAdminValues";
import "./AdminAccountingFirms.scss";

interface IAdminAccountingFirmsProps {}

function AdminAccountingFirms(props: IAdminAccountingFirmsProps) {
  const { setControlsComponent } = useAdminValues();

  const [mode, setMode] = useState<"queue" | "view">("queue");

  const {
    data: queue,
    status: queueStatus,
    fetchNextPage: fetchNextQueuePage,
    hasNextPage: hasNextQueuePage,
    refetch: refetchQueue,
  } = useInfiniteQuery({
    queryKey: ["admin-consult-queue"],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await adminGetConsultQueue({
        page: pageParam,
      });

      return res.data.payload.consultingCompanies;
    },
    getNextPageParam: (lastPage, pages) =>
      lastPage.length < API_MAX_PER_PAGE.ConsultQueue
        ? undefined
        : pages.length + 1,
  });

  const {
    data: consults,
    status: consultsStatus,
    fetchNextPage: fetchNextConsultPage,
    hasNextPage: hasNextConsultPage,
    refetch: refetchConsults,
  } = useInfiniteQuery({
    queryKey: ["admin-consults"],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await adminGetConsults({
        page: pageParam,
      });

      return res.data.payload.consultingCompanies;
    },
    getNextPageParam: (lastPage, pages) =>
      lastPage.length < API_MAX_PER_PAGE.AdminConsults
        ? undefined
        : pages.length + 1,
  });

  useEffect(() => {
    setControlsComponent(
      <div
        style={{
          display: "flex",
          gap: 20,
        }}
      >
        <Button
          label="Kö"
          color={mode === "queue" ? "black" : "white"}
          onClick={() => setMode("queue")}
        />
        <Button
          label="Visa"
          color={mode === "view" ? "black" : "white"}
          onClick={() => setMode("view")}
        />
      </div>
    );
  }, [mode, setControlsComponent]);

  return (
    <div className="admin-accounting-firms">
      {mode === "queue"
        ? queueStatus === "success" && (
            <InfiniteScroll
              dataLength={queue.pages.flat().length}
              next={fetchNextQueuePage}
              hasMore={!!hasNextQueuePage}
              loader={<Spinner />}
              endMessage={<ShowingAllMessage text="Du visar hela byråkön" />}
              style={{
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
              scrollThreshold={0.5}
            >
              {queue.pages.flat().map((consult) => (
                <AccountingFirmRow
                  key={consult._id}
                  consultId={consult._id}
                  name={consult.name}
                  orgNr={consult.orgNr}
                  createdAt={consult.created}
                  onAction={() => {
                    refetchQueue();
                    refetchConsults();
                  }}
                  isQueue
                />
              ))}
            </InfiniteScroll>
          )
        : mode === "view"
        ? consultsStatus === "success" && (
            <InfiniteScroll
              dataLength={consults.pages.flat().length}
              next={fetchNextConsultPage}
              hasMore={!!hasNextConsultPage}
              loader={<Spinner />}
              endMessage={<ShowingAllMessage text="Du visar alla byråer" />}
              style={{
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
              scrollThreshold={0.5}
            >
              {consults.pages.flat().map((consult) => (
                <AccountingFirmRow
                  key={consult._id}
                  consultId={consult._id}
                  name={consult.name}
                  orgNr={consult.orgNr}
                  createdAt={consult.created}
                  onAction={() => {}}
                />
              ))}
            </InfiniteScroll>
          )
        : null}
    </div>
  );
}

export default AdminAccountingFirms;
